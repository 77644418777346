import {
  SET_TRANSACTION_PERIOD,
  SET_ACCOUNT_NAME,
  SET_SELECTED_FORM,
  SET_BUSINESS_TYPE,
  SET_BUSINESS_TYPE_DETAILS,
  SET_PUBLIC_ACCESS_TOKEN,
  REMOVE_PUBLIC_ACCESS_TOKEN,
  CLEAR_ONBOARDING,
} from '../ActionTypes';

export const setTransactionPeriod = res => ({
  type: SET_TRANSACTION_PERIOD,
  payload: {
    res,
  },
});

export const setAccountName = res => ({
  type: SET_ACCOUNT_NAME,
  payload: {
    res,
  },
});

export const setForm = res => ({
  type: SET_SELECTED_FORM,
  payload: {
    res,
  },
});

export const setBusinessType = res => ({
  type: SET_BUSINESS_TYPE,
  payload: {
    res,
  },
});

export const setBusinessTypeDetails = res => ({
  type: SET_BUSINESS_TYPE_DETAILS,
  payload: {
    res,
  },
});

export const setPlaidPublicAccessToken = payload => ({
  type: SET_PUBLIC_ACCESS_TOKEN,
  payload,
});

export const removePlaidPublicAccessToken = payload => ({
  type: REMOVE_PUBLIC_ACCESS_TOKEN,
});

export const clearOnboarding = () => ({
  type: CLEAR_ONBOARDING,
});
