import React from "react";
import { Segment, Grid, Table, Divider } from "semantic-ui-react";
import { currencyFormat } from "libraries/utils";
import { connect } from "react-redux";
import "./styles.scss";

class InvoicePreview extends React.Component {
  render() {
    const getItemTax = itemTaxRates => {
      let sum = 0;
      if (
        itemTaxRates &&
        itemTaxRates.length > 0 &&
        this.props.invoiceData.tax_rate_data
      ) {
        itemTaxRates.forEach(tax_id => {
          const rate = this.props.invoiceData.tax_rate_data.find(
            el => el.tax_rate_id == tax_id
          );
          if (rate != undefined) sum += rate.percentage;
        });
      }
      if (sum == 0) return "";
      else return sum + "%";
    };

    const getCoupon = () => {
      let coupon = "";
      if (this.props.invoiceData.coupon.discount_type === "percent")
        coupon = this.props.invoiceData.coupon.discount + "%";
      else coupon = "$" + this.props.invoiceData.coupon.discount;
      return coupon;
    };

    return (
      <Segment style={{ padding: "50px" }}>
        <Grid className="Invoice--manage" widths="equal">
          <Grid.Column floated="left" width={8}>
            <p className="Invoice--title">{this.props.userProfile.fullName}</p>
            <p style={{ margin: "0px" }}>{this.props.userProfile.email}</p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid>
              <Grid.Column width={16}>
                <p style={{ float: "right", fontSize: "27px" }}>Invoice</p>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={16}>
                <Grid style={{ float: "right" }}>
                  <table className="Detail-table1">
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>Date of issue</td>
                        <td style={{ textAlign: "right" }}>
                          {this.props.invoiceData.invoice_date}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}>Date due</td>
                        <td style={{ textAlign: "right" }}>
                          {this.props.invoiceData.due_date}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <h3 style={{ margin: "0px" }}>Bill to</h3>
            <p style={{ margin: "0px" }}>
              {this.props.invoiceData.receiver_name}
            </p>
            <p style={{ margin: "0px" }}>
              {this.props.invoiceData.receiver_email}
            </p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <h3 style={{ margin: "0px" }}>Address</h3>
            <p style={{ margin: "0px" }}>{this.props.invoiceData.address}</p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16}>
            <p style={{ fontSize: "25px", margin: "0px" }}>
              ${currencyFormat(this.props.invoiceData.total)}
            </p>
            <p>{this.props.invoiceData.memo}</p>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16}>
            <Table basic="very" striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    collapsing
                    style={{
                      width: "40%",
                      color: "black"
                    }}
                  >
                    Description
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "10%", color: "black" }}>
                    {this.props.invoiceData.invoice_template == 1 && "Qty"}
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "15%", color: "black" }}>
                    {this.props.invoiceData.invoice_template == 1 &&
                      "Unit Price"}
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "15%", color: "black" }}>
                    {this.props.invoiceData.tax != 0 && "Tax"}
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "20%", color: "black" }}>
                    Amount
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.invoiceData.line_items.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{item.description}</Table.Cell>
                    <Table.Cell>
                      {this.props.invoiceData.invoice_template == 1 &&
                        item.quantity}
                    </Table.Cell>
                    <Table.Cell>
                      {this.props.invoiceData.invoice_template == 1 &&
                        "$" + currencyFormat(item.unitPrice)}
                    </Table.Cell>
                    <Table.Cell>{getItemTax(item.taxRates)}</Table.Cell>
                    <Table.Cell>
                      ${currencyFormat(Number(item.amount))}
                    </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell colSpan="3" style={{ background: "white" }} />
                  <Table.Cell>Subtotal</Table.Cell>
                  <Table.Cell>
                    ${currencyFormat(this.props.invoiceData.subtotal)}
                  </Table.Cell>
                </Table.Row>
                {this.props.invoiceData.tax != 0 && (
                  <Table.Row>
                    <Table.Cell colSpan="3" style={{ background: "white" }} />
                    <Table.Cell>Tax</Table.Cell>
                    <Table.Cell>
                      ${currencyFormat(this.props.invoiceData.tax)}
                    </Table.Cell>
                  </Table.Row>
                )}

                {this.props.invoiceData.discount != 0 && (
                  <Table.Row>
                    <Table.Cell
                      colSpan="3"
                      style={{ border: "1px solid white", background: "white" }}
                    />
                    <Table.Cell>Discount ({getCoupon()})</Table.Cell>
                    <Table.Cell>
                      -$
                      {currencyFormat(Number(this.props.invoiceData.discount))}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell
                    colSpan="3"
                    style={{ border: "1px solid white", background: "white" }}
                  />
                  <Table.Cell>Total</Table.Cell>
                  <Table.Cell>
                    ${currencyFormat(this.props.invoiceData.total)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>{this.props.invoiceData.footer}</Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16}>
            <Divider className="Invoice--divider" />
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = ({ session: { userProfile } }) => ({
  userProfile
});

export default connect(
  mapStateToProps,
  null
)(InvoicePreview);
