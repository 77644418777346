import React from 'react';
import { connect } from 'react-redux';
import {
  Table, Segment, Button, Icon,
} from 'semantic-ui-react';
import cs from 'classnames';
import withNotifications from 'components/hocs/WithNotifications';
import { exportPNLTransactions } from 'libraries/api-service';
import './styles.scss';

import { slashFormatDate, exportXLSX } from 'libraries/utils';

export class DetailTable extends React.Component {
  state = {
    isDownloading: false,
  }

  exportTransactions = (transactions, type) => {
    const ids = transactions.map(transaction => transaction.transaction_id);
    const { username, token } = this.props;
    return exportPNLTransactions(username, token, type, ids)
      .then(({ data }) => {
        const binaryDataFlow = data;
        const { dateRange } = this.props;
        exportXLSX(binaryDataFlow, `Synkbooks-PNL-${dateRange}-${type}--transactions-details.xlsx`);
      });
  };

  download = () => {
    this.setState({ isDownloading: true });
    this.exportTransactions(this.props.transactions, this.props.type)
      .then(() => this.setState({ isDownloading: false }))
      .catch(() => {
        this.setState({ isDownloading: false });
        this.props.pushNotification('NOT_PNL_DOWNLOAD_FAIL', 'Download failed',
          'An error ocurred while trying to download your transactions. Validate the selected category has at least 1 transaction and try again',
          'error', 7000);
      });
  }

  render() {
    return (
      <>
        <Segment attached className={cs('Detail-Table__Header')}>
          {`${this.props.selectedCategory} Transaction Details`}
          <Icon
            name="window close"
            className="Detail-Table__Close-Icon"
            onClick={this.props.hideTable}
            onKeyPress={this.props.hideTable}
            role="button"
          />
          <Button
            primary
            icon
            labelPosition="left"
            onClick={this.download}
            loading={this.state.isDownloading}
            disabled={this.state.isDownloading}
          >
            <Icon name="file excel" />
            Export
          </Button>
        </Segment>
        <Segment className="Detail-Table--Simplified" attached>
          <Table basic unstackable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Transaction</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Category</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Subcategory</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Income</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Expense</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                this.props.transactions.map(transaction => (
                  <Table.Row key={transaction.transaction_id}>
                    <Table.Cell>{slashFormatDate(transaction.date)}</Table.Cell>
                    <Table.Cell>{transaction.description}</Table.Cell>
                    <Table.Cell>{ transaction.category }</Table.Cell>
                    <Table.Cell>{transaction.subcategory}</Table.Cell>
                    <Table.Cell>{transaction.amount < 0 && `$${transaction.amount * -1}`}</Table.Cell>
                    <Table.Cell>{transaction.amount > 0 && `$${transaction.amount}`}</Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
        </Segment>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  session: { username, token },
  profitAndLoss: { dateRange },
}) => ({
  username,
  token,
  dateRange,
});

export default connect(mapStateToProps, null)(withNotifications(DetailTable));
