import React from 'react';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { Segment } from 'semantic-ui-react';
import Template from './template';

import PnlHeader from '../Header';

export class TaxFormView extends React.Component {
  state = {};

  hasChildren = (row) => {
    const childrens = ['cogs_categories', 'other_categories'];
    return childrens.filter(child => Object.prototype.hasOwnProperty.call(row, child)).length > 0;
  };

  /* eslint-disable no-plusplus */
  normalizeData = (form, data) => {
    const normalized = [...data]
      .map(group => ({
        ...group,
        items: [...group.items, ...(group.formView ? group.formView : [])],
      }))
      .map(group => ({
        ...group,
        items: group.items.sort((a, b) => {
          const idxA = a.index;
          const idxB = b.index;
          if (idxA && idxB && idxA.match(/\d/) && idxB.match(/\d/)) {
            const nIdxA = parseInt(idxA, 10);
            const nIdxB = parseInt(idxB, 10);
            if (nIdxA < nIdxB) return -1;
            if (nIdxA > nIdxB) return 1;
          }
          return 0;
        }),
      }))
      .map((item) => {
        if (item.group_name === 'Balances not part of Profit and Loss') return null;
        const items = (item.items
            && item.items.reduce((acc, cur) => {
              const hasIndex = cur.index.match(/[a-zA-Z]/);
              let subsection = cur.index;
              if (hasIndex && cur.index[hasIndex.index].charCodeAt(0) > 97) {
                subsection = cur.index[hasIndex.index];
              }
              let rows = [
                {
                  id: cur.id || uuidv4(),
                  categoryName: cur.category_name,
                  total: cur.total || cur.total_cogs || cur.total_other,
                  subsection,
                },
              ];
              if (this.hasChildren(cur)) {
                rows[0].subsection = cur.index;
                const categories = cur.cogs_categories || cur.other_categories;
                const newItems = categories.map(row => ({
                  id: cur.id || uuidv4(),
                  categoryName: row.cogs_type || row.other_type,
                  total: row.total,
                }));
                rows = [...rows, ...newItems];
              }
              return [...acc, ...rows];
            }, []))
          || [];

        item.group_name === 'Income'
          && form !== 'Schedule E'
          && items.push({ id: uuidv4(), categoryName: 'Gross profit', total: item.gross_profit })
          && items.push({ id: uuidv4(), categoryName: 'Total income', total: item.gross_income });

        if (item.group_name === 'Expenses' && form !== 'Schedule E') {
          items.push({
            id: uuidv4(),
            categoryName: 'Total deductions',
            total: item.total_expenses_with_fv,
          });
        }
        return { groupName: item.group_name, items };
      })
      .filter(Boolean);
    return normalized;
  };

  render() {
    const { form, pnlData } = this.props;
    return (
      <>
        <PnlHeader
          updatePnl={this.props.updatePnl}
          hideTable={this.props.hideTable}
        />
        <Segment attached loading={this.props.isLoading}>
          <Template scheduleName={form} data={this.normalizeData(form, pnlData)} />
        </Segment>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  profitAndLoss: { pnlData },
  session: {
    userProfile: { form },
  },
}) => ({
  pnlData,
  form,
});

export default connect(
  mapStateToProps,
  null,
)(TaxFormView);
