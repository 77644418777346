import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

// TODO: API Payloads should be standardized to only accept camelCased keys rather than
// snake_cased keys.

export const balanceSheetList = (year) => apiRequestV2('post', `${basePath}/balance-sheet/list`, {
  body: {
    year
  }
}).then(res => res.data);

export const updateBalanceSheetList = (data) => apiRequestV2('post', `${basePath}/balance-sheet/update`, {
  body: {
    data
  },
}).then(res => res.data);