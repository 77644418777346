import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import Routes from 'routes';

const PrivateRoute = ({ children, session, ...rest }) => {
  const { isAuthenticated } = session;

  return (
    <Route
      {...rest}
      render={({ location }) => (
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.Login,
              state: { from: location },
            }}
          />
        )
      )}
    />
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(PrivateRoute);
