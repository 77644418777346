import { deleteDuplicatesBy } from 'libraries/utils';
import {
  ADD_SELECTED_CLIENT_TRANSACTION,
  REMOVE_SELECTED_CLIENT_TRANSACTION,
  RESET_SELECTED_CLIENT_TRANSACTIONS,
  ADD_SELECTED_CLIENT_PAGE,
  REMOVE_SELECTED_CLIENT_PAGE,
  RESET_SELECTED_CLIENT_PAGES,
  SET_CLIENT_TRANSACTIONS,
  UPDATE_CLIENT_TRANSACTIONS,
  CLEAR_CLIENT_TRANSACTIONS,
  MARK_AS_SEEN_CLIENT_TRANSACTIONS,
} from '../ActionTypes';

const initialState = {
  clientEmail: '',
  allTransactions: [],
  pagesSelected: [],
  selected: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_SELECTED_CLIENT_TRANSACTION: {
      return {
        ...state,
        selected: [...state.selected, payload],
      };
    }
    case REMOVE_SELECTED_CLIENT_TRANSACTION: {
      const index = state.selected.indexOf(payload);
      return {
        ...state,
        selected: [
          ...state.selected.slice(0, index),
          ...state.selected.slice(index + 1),
        ],
      };
    }
    case RESET_SELECTED_CLIENT_TRANSACTIONS: {
      return {
        ...state,
        selected: [],
      };
    }
    case ADD_SELECTED_CLIENT_PAGE: {
      const newSelected = [
        ...state.selected,
        ...payload.ids,
      ];
      return {
        ...state,
        pagesSelected: [
          ...state.pagesSelected,
          payload.page,
        ],
        selected: newSelected.filter((v, i) => newSelected.indexOf(v) === i),
      };
    }
    case REMOVE_SELECTED_CLIENT_PAGE: {
      const pageIndex = state.pagesSelected.indexOf(payload.page);
      return {
        ...state,
        pagesSelected: [
          ...state.pagesSelected.slice(0, pageIndex),
          ...state.pagesSelected.slice(pageIndex + 1),
        ],
        selected: state.selected.filter(transaction => !payload.ids.includes(transaction)),
      };
    }
    case RESET_SELECTED_CLIENT_PAGES: {
      return {
        ...state,
        pagesSelected: [],
      };
    }
    case SET_CLIENT_TRANSACTIONS: {
      const transactions = {
        ...state,
        allTransactions: payload,
      };
      return transactions;
    }
    case UPDATE_CLIENT_TRANSACTIONS: {
      const { newTransactions } = payload;
      const transactions = state.allTransactions.concat(newTransactions);
      const filterdTransactions = {
        allTransactions: deleteDuplicatesBy(transactions, 'transaction_id'),
        pagesSelected: [],
        selected: [],
      };
      return filterdTransactions;
    }
    case MARK_AS_SEEN_CLIENT_TRANSACTIONS: {
      const checkedTransactionIds = new Set();
      payload.transactions.forEach(item => checkedTransactionIds.add(item));

      const allTransactions = state.allTransactions.map((item) => {
        const auxItem = { ...item };
        if (auxItem.seen !== 'checked') {
          auxItem.seen = checkedTransactionIds.has(item.transaction_id) ? 'checked' : item.seen;
        }
        return auxItem;
      });
      return { ...state, allTransactions };
    }
    case CLEAR_CLIENT_TRANSACTIONS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
