import React from 'react';
import {
  Header, Container, Icon, Popup,
} from 'semantic-ui-react';
import './styles.scss';

const tooltipText = 'This is the tax form you will file at the end of the year. Knowing the tax form you file at the end of the year will help categorize your expenses.';

const FormToolTip = () => (
  <Container>
    <div className="ToolTip-Box__QM">
      <Header as="h5">
        Select your form
        <Popup
          trigger={<Icon name="question circle" />}
          content={tooltipText}
          header="What is this?"
          inverted
          position="bottom center"
        />
      </Header>
    </div>
  </Container>
);

export default FormToolTip;
