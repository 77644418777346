import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Icon,
  Header,
  Container,
} from 'semantic-ui-react';

import { createInvitation } from 'libraries/api-v2/invitations-service';
import './styles.scss';

class ConfirmSendInvitationView extends Component {
  state = {
    inviteeFullName: this.props.inviteeFullName,
    inviteeEmail: this.props.inviteeEmail,
  }

  onCancelInvitation = () => {
    const { onCancel } = this.props;

    onCancel();
  }

  onSendInvitation = () => {
    const { onSend, username, token } = this.props;
    const { inviteeFullName, inviteeEmail } = this.state;
    const data = {
      inviteeFullName,
      inviteeEmail,
    };

    createInvitation(username, token, data).then(() => {
      // clear invitee
      onSend();
    }); // TODO: catch any errors
  }

  render() {
    const { inviteeEmail, inviteeFullName } = this.state;

    return (
      <div className="ConfirmSendInvitation__Container">
        <Header className="ConfirmSendInvitation__Header" as="h2">
          <Icon className="ConfirmSendInvitation__Icon" name="warning sign" size="tiny" />
          <Header.Content>WAIT!</Header.Content>
          <Icon className="ConfirmSendInvitation__Icon" name="warning sign" size="tiny" />
        </Header>
        <p>
          { 'You are about to give '}
          <strong className="ConfirmSendInvitation__Invitee">{ inviteeFullName }</strong>
          { ' access to your books with limited management capabilities. Please make sure the email '}
          <strong className="ConfirmSendInvitation__Invitee">{ inviteeEmail }</strong>
          { ' is correct before sending your invite.' }
        </p>
        <br />
        <div className="ConfirmSendInvitation__Buttons">
          <Button
            className="ConfirmSendInvitation__Button__Cancel"
            onClick={this.onCancelInvitation}
            size="small"
          >
            Cancel
          </Button>
          <Button
            className="ConfirmSendInvitation__Button__SendInvite"
            onClick={this.onSendInvitation}
            primary
            size="small"
          >
            Send Invite
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, invitations }) => ({
  username: session.username,
  token: session.token,
  inviteeEmail: invitations.inviteeEmail,
  inviteeFullName: invitations.inviteeFullName,
});

export default connect(mapStateToProps, null)(ConfirmSendInvitationView);
