import React from 'react';
import { Grid, Label } from 'semantic-ui-react';
import cs from 'classnames';
import './styles.scss';

const TranTypeLabelsSection = props => (
  <Grid>
    <Grid.Column mobile={16} tablet={16} computer={16}>
      <p className="ECTransaction__Type">Transaction type</p>
    </Grid.Column>
    <Grid.Column mobile={16} tablet={16} computer={16}>
      <Grid columns="equal">
        <Grid.Column>
          <Label
            id="Income"
            className={cs({
              ECTransaction__Type: true,
              '--Active': props.transactionType === 'Income',
            })}
            onClick={() => props.handleLabelStatus('Income')}
          >
            { 'INCOME' }
          </Label>
        </Grid.Column>
        <Grid.Column>
          <Label
            id="Expense"
            className={cs({
              ECTransaction__Type: true,
              '--Active': props.transactionType === 'Expense',
            })}
            onClick={() => props.handleLabelStatus('Expense')}
          >
            { 'EXPENSE' }
          </Label>
        </Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid>
    </Grid.Column>
  </Grid>
);

export default TranTypeLabelsSection;
