import React from 'react';
import {
  Grid, Header, Dimmer, Loader,
} from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './styles.scss';
import Steps from 'components/presentationals/Steps';
import LayoutHeader from 'components/containers/Header';
import Routes from 'routes';
import Notifications from 'components/presentationals/Notification';
import SubscriptionStatus from 'components/containers/SubscriptionStatus';

export class PrivateLayout extends React.Component {
  componentDidMount() {
    const { onboarding, history } = this.props;
    !this.isOnboardingPath() && onboarding !== 'True' && history.push(Routes.OnboardingReportForm);
    this.shouldHaveBackground() && document.body.classList.add('Layout__Background--color-gray');
  }

  componentWillUnmount() {
    this.shouldHaveBackground() && document.body.classList.remove('Layout__Background--color-gray');
  }

  isOnboardingPath = () => this.props.location.pathname.match(/onboarding/);

  isDashboardPath = () => this.props.location.pathname.match(/dashboard/);

  isMyProfilePath = () => this.props.location.pathname.match(/my-profile/);

  isSubscription = () => this.props.location.pathname.match(/subscription/);

  isProfitAndLossPath = () => this.props.location.pathname.match(/profit-and-loss/);

  shouldHaveBackground = () => this.isDashboardPath() || this.isMyProfilePath()
    || this.isProfitAndLossPath() || this.isSubscription();

  render() {
    const { children, isLoggingOut } = this.props;
    return (<>
      {
        (!this.isSubscription() && !this.isMyProfilePath()) && <SubscriptionStatus />
      }
      <LayoutHeader {...this.props} />
      <div className="Layout__Body">
        {
          isLoggingOut && (
            <Dimmer
              active
              inverted
              className="Layout__Loader"
            >
              <Loader size="big" content="Logging out..." />
            </Dimmer>)
        }
        <Notifications isPrivate />
        <Grid container>
          {
            this.isOnboardingPath() && (
              <>
                <Grid.Row centered>
                  <Header as="h1">MY PROFILE</Header>
                </Grid.Row>
                <Grid.Row centered>
                  <Steps />
                </Grid.Row>
              </>
            )
          }
          <Grid.Row centered>
            <Grid.Column>
              { children }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>);
  }
}

const mapStateToProps = ({
  session: {
    userInfo: { onboarding },
    isLoggingOut,
  },
}) => ({
  onboarding,
  isLoggingOut,
});

export default connect(mapStateToProps, null)(withRouter(PrivateLayout));
