import React from 'react';
import TransactionsTable from 'components/containers/TransactionsTablev2';
import ProfitSummaryv2 from 'components/containers/ProfitSummaryv2';
import jsCookie from 'js-cookie';

const Transactions = () => {
  const clientEmail = jsCookie.get('active_client_email');

  if (clientEmail === undefined) {
    return (
      <p>Select a client from the Clients tab first.</p>
    );
  }

  return (
    <>
      <ProfitSummaryv2 />
      <TransactionsTable />
    </>
  );
};

export default Transactions;
