import React from 'react';
import {
  Grid, Form, Container, Dropdown, Message,
} from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { TaxFormInput } from 'taxUI';

import './styles.scss';
import Routes from 'routes';
import { signup } from 'libraries/api-service';
import AddNotificationsHandlers from 'components/hocs/WithNotifications';
import {
  isValidPassword, isValidEmail, FORM_ERROR_CODES,
} from 'libraries/formValidator';

export class SignUp extends React.Component {
  state = {
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAgreed: false,
    accountType: '',
    errors: [],
    isLoading: false,
  }

  lowerCaseTargetValue = (evt) => {
    const evtCopy = Object.assign({}, evt);
    evtCopy.target.value = evtCopy.target.value.toLowerCase();
    return evtCopy;
  }

  stripSpacesFromTargetValue = (evt) => {
    const evtCopy = Object.assign({}, evt);
    evtCopy.target.value = evtCopy.target.value.replace(/\s+/g, '');
    return evtCopy;
  }

  handleEmailInputChange = (evt) => {
    this.handleInputChange(
      this.lowerCaseTargetValue(
        this.stripSpacesFromTargetValue(evt),
      ),
    );
  }

  handleInputChange = ({ target }) => {
    this.setState({
      [target.id]: target.value,
    });
  }

  handleCheckboxChange = () => {
    this.setState(prevState => ({ termsAgreed: !prevState.termsAgreed }));
  }

  handleSelectChange = (e, { value }) => {
    this.setState({ accountType: value });
  }

  validPasswords = () => (this.state.password === this.state.confirmPassword)

  addFormError = (errorCode) => {
    this.setState(prevState => ({
      errors: [
        ...prevState.errors,
        FORM_ERROR_CODES[errorCode].content,
      ],
    }));
  }

  hasAccountTypeSelected = () => this.state.accountType !== '';

  hasValidFields = () => {
    let isValid = true;
    if (!this.validPasswords()) {
      isValid = false;
      this.addFormError(FORM_ERROR_CODES.PASSWORD_MISMATCH.id);
    }
    if (!isValidPassword(this.state.password)) {
      isValid = false;
      this.addFormError(FORM_ERROR_CODES.PASSWORD_INVALID_LENGTH.id);
    }
    if (!isValidEmail(this.state.email)) {
      isValid = false;
      this.addFormError(FORM_ERROR_CODES.EMAIL_INVALID_FORMAT.id);
    }
    if (!this.hasAccountTypeSelected()) {
      isValid = false;
      this.addFormError(FORM_ERROR_CODES.ACCOUNT_TYPE_MISSING.id);
    }
    if (!this.state.termsAgreed) {
      isValid = false;
      this.addFormError(FORM_ERROR_CODES.TERMS_UNCHECKED.id);
    }
    return isValid;
  }

  submitSignup = () => {
    this.setState({ errors: [] });
    if (this.hasValidFields()) {
      this.setState({ isLoading: true });
      const {
        fullName, password, accountType, email,
      } = this.state;
      signup(fullName, email, password, accountType)
        .then(() => {
          const nextId = this.props.notifications.active.length + 1;
          this.props.pushNotification(nextId, 'Email sent',
            'You will receive an email to verify your account. If you haven\'t received the email within some minutes, try again.',
            'success',
            7000);
          this.props.history.replace(Routes.Login);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          this.addFormError(FORM_ERROR_CODES.EMAIL_ALREADY_EXIST.id);
        });
    }
  }

  render() {
    return (
      <Grid.Column>
        <Form
          className="Signup-form"
          onSubmit={this.submitSignup}
          error={this.state.errors.length > 0}
          loading={this.state.isLoading}
        >
          <Form.Field>
            <TaxFormInput
              id="fullName"
              label="Company Name"
              placeholder="Company Name"
              type="text"
              value={this.state.fullName}
              onChange={this.handleInputChange}
              required
            />
          </Form.Field>
          <Form.Field>
            <TaxFormInput
              id="email"
              label="Email"
              placeholder="Email"
              type="email"
              value={this.state.email}
              onChange={this.handleEmailInputChange}
              required
            />
          </Form.Field>
          <Form.Field>
            <TaxFormInput
              id="password"
              label="Password"
              placeholder="Password"
              type="password"
              value={this.state.password}
              onChange={this.handleInputChange}
              required
            />
          </Form.Field>
          <Form.Field>
            <TaxFormInput
              id="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm Password"
              type="password"
              value={this.state.confirmPassword}
              onChange={this.handleInputChange}
              required
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              id="accountType"
              placeholder="Account type"
              value={this.state.accountType}
              onChange={this.handleSelectChange}
              required
              selection
              options={[
                { key: 1, value: 'client', text: 'Client' },
                {
                  key: 2,
                  value: 'professional',
                  text: 'Accounting Pro',
                },
              ]}
            />
          </Form.Field>
          <Form.Field>
            <Form.Checkbox
              checked={this.state.termsAgreed}
              onChange={this.handleCheckboxChange}
              label={
                {
                  children: (
                    <div>
                      I agree to the&nbsp;
                      <Link
                        to={Routes.TermsOfService}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={event => event.stopPropagation()}
                      >
                        Terms of Service
                      </Link>
                      &nbsp;and&nbsp;
                      <Link
                        to={Routes.PrivacyPolicy}
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={event => event.stopPropagation()}
                      >
                        &nbsp;Privacy Policy
                      </Link>
                    </div>),
                }
              }
            />
          </Form.Field>
          <Form.Field>
            {
              this.state.errors.length > 0
              && (
              <Message
                error
                header="Oops! Verify your information"
                list={this.state.errors}
              />
              )
            }
            <Container textAlign="center">
              <Form.Button
                content="SIGN UP"
                primary
                size="medium"
              />
            </Container>
          </Form.Field>
        </Form>
      </Grid.Column>
    );
  }
}

export default withRouter(AddNotificationsHandlers(SignUp)); // TODO: should not use withRouter
