import React from 'react';
import {
  Icon, Grid, Header, Button,
} from 'semantic-ui-react';
import './styles.scss';

const Confirmation = props => (
  <Grid.Row className="Confirm__Container">
    <Grid.Column width={2} />
    <Grid.Column width={12}>
      <Grid.Row>
        <Grid.Column className="Confirm__Icon">
          <Icon
            name="check circle"
            size="massive"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">Are you sure?</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="Confirm__Leyend">
        <Grid.Column>
          <p>
          Do you really want to delete this transaction?
          This process cannot be undone.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="Confirm__Buttons">
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Button
            onClick={props.handleClose}
          >
            CANCEL
          </Button>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Button
            onClick={props.handleDeletion}
            className="Confirm__Buttons-Continue"
          >
            CONTINUE
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid.Column>
    <Grid.Column width={2} />
  </Grid.Row>
);

export default Confirmation;
