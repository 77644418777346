import {
  UPDATE_PASSWORD_RECOVERY_EMAIL,
  UPDATE_PASSWORD_RECOVERY_PINCODE,
  CLEAR_RECOVERY_DATA,
} from '../ActionTypes';

const initialState = {
  email: '',
  verificationCode: '',
  password: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PASSWORD_RECOVERY_EMAIL:
      return {
        ...state,
        email: payload,
      };
    case UPDATE_PASSWORD_RECOVERY_PINCODE: {
      return {
        ...state,
        verificationCode: payload,
      };
    }
    case CLEAR_RECOVERY_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
