import {
  ADD_FILTER,
  SET_IS_SELECTING_CUSTOM_DATE,
} from '../ActionTypes';

export const addFilter = (type, value) => ({
  type: ADD_FILTER,
  payload: {
    type,
    value,
  },
});

export const setIsSelectingCustomDate = value => ({
  type: SET_IS_SELECTING_CUSTOM_DATE,
  payload: value,
});

export default addFilter;
