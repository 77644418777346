import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import Routes from 'routes';
import {setPlaidPublicKey, setPlaidEnvironment} from 'store/actions/plaid';
import {setOnboardingDone, setUserProfile} from 'store/actions/session';
import uuidv4 from 'uuid/v4';
import {
  Button, Message, Container, Confirm, Form,
} from 'semantic-ui-react';
import {
  setupUserAccount,
  getInstitutionById,
  getKeys,
} from 'libraries/api-service';
import jsCookie from 'js-cookie';
import withNotifications from 'components/hocs/WithNotifications';
import ErrorPlaidMessages from 'components/presentationals/ErrorPlaidMessages';
import LinkPlaid from '../../presentationals/LinkPlaid';
import SyncPlaidAccounts from '../../presentationals/SyncPlaidAccounts';
import {DateInput} from "semantic-ui-calendar-react";
import moment from "moment";

export class OnboardingPlaid extends Component {
  state = {
    linkedAccounts: [],
    finishing: false,
    isConfirmOpen: false,
    startDate: moment().subtract(2, 'year').format('MM/DD/YYYY')
  };

  constructor(props) {
    super(props);
    this.ErrorPlaidMessages = React.createRef();
  }

  componentDidMount() {
    const {username, token} = this.props;
    getKeys(username, token).then((res) => {
      const plaidPublicKey = res.data.plaid_public_key;
      const plaidEnv = res.data.plaid_env;
      this.props.setPlaidEnvironment(plaidEnv);
      this.props.setPlaidPublicKey(plaidPublicKey);
    });
  }

  componentWillUnmount() {
    const {removeNotification} = this.props;
    removeNotification('TRANSACTIONS_STILL_LOADING');
  }

  handleDateChange = (event, {name, value}) => {
    this.setState({startDate: value});
  }

  openPlaidModal = () => window.linkHandler && window.linkHandler.open();

  onPlaidSuccess = (publicToken,
                    {institution: {institution_id: institutionId, name}, accounts}) => {
    const {username, token} = this.props;
    getInstitutionById(username, token, institutionId).then(({logo, primaryColor}) => {
      const newInstitution = {
        id: uuidv4(),
        publicToken,
        name,
        logo,
        primaryColor,
        accounts: accounts.map(acc => acc.id),
      };
      this.setState(prevState => ({
        linkedAccounts: [...prevState.linkedAccounts, newInstitution],
      }));
    });
  };

  handleOnPlaidExit = (error, metadata) => {
    if (error && metadata) {
      this.setState({plaidError: true});
      this.ErrorPlaidMessages.current.handleOnExit(error, metadata);
    }
  };

  onFinishOnboarding = () => {
    const {linkedAccounts, startDate} = this.state;
    const publicTokens = linkedAccounts.map(item => ({
      public_access_token: item.publicToken,
      accounts: item.accounts,
    }));
    const {
      username, fullName, selectedForm, businessType, businessDetails, businessTypeCode,
    } = this.props;
    const profile = {
      fullName,
      email: username,
      form: selectedForm,
      businessType,
      businessTypeCode,
      businessDetails,
    };

    const planId = jsCookie.get('s_plan');
    const bookStartDate = moment(startDate).format("YYYY-MM-DD");

    this.setState({finishing: true}, () => {
      setupUserAccount(
        publicTokens,
        selectedForm,
        businessTypeCode,
        businessDetails,
        planId,
        bookStartDate
      )
        .then(() => this.props.setUserProfile(profile))
        .then(() => this.props.setOnboardingDone())
        .then(() => jsCookie.remove('s_plan'))
        .then(() => this.props.history.replace(Routes.DashboardTransactions))
        .catch(err => this.setState({finishing: false, error: err.message}, () => {
          setTimeout(() => this.setState({error: undefined}), 10000);
        }));
    });
  };

  onOpenConfirmationDialog = selId => this.setState({isConfirmOpen: true, selId});

  onCancelConfirmationDialog = () => this.setState({isConfirmOpen: false, selId: undefined});

  onConfirmConfirmationDialog = () => {
    const {selId} = this.state;
    this.setState(prevState => ({
      linkedAccounts: prevState.linkedAccounts.filter(acc => acc.id !== selId),
      isConfirmOpen: false,
      selId: undefined,
    }));
  }

  render() {
    const {
      isConfirmOpen, linkedAccounts, finishing, error, plaidError,
    } = this.state;
    const {plaidPublicKey, plaidEnv} = this.props;
    const isDisabled = finishing || linkedAccounts.length === 0 || plaidError;
    return (
      <>
        <Confirm
          open={isConfirmOpen}
          content="Do you confirm to remove this bank institution?"
          onCancel={this.onCancelConfirmationDialog}
          onConfirm={this.onConfirmConfirmationDialog}
        />
        {plaidEnv
        && plaidPublicKey && (
          <LinkPlaid
            env={plaidEnv}
            publicKey={plaidPublicKey}
            handleOnSuccess={this.onPlaidSuccess}
            handleOnExit={this.handleOnPlaidExit}
          />
        )}
        <Container text>
          <div style={{textAlign: 'center', fontSize: '1.3em'}}>Please add your banking institution</div>
          <SyncPlaidAccounts
            data={linkedAccounts}
            onClick={this.openPlaidModal}
            onDelete={this.onOpenConfirmationDialog}
            disableDelete={finishing}
          />
          <ErrorPlaidMessages
            section="onboarding"
            closeButton="true"
            ref={this.ErrorPlaidMessages}
          />
          {
            linkedAccounts.length > 0 ? (
              <Container style={{marginBottom: 40}} textAlign='center'>
                <Message>
                  <Message.Header>
                    <p>Please select the transaction start date</p>
                  </Message.Header>
                  <p>Some institutions allow up to 2 years, so please select a start date for us to download
                    transactions</p>
                </Message>
                <DateInput
                  name="startDate"
                  minDate={moment().subtract(2, 'year').format('MM/DD/YYYY')}
                  maxDate={moment(Date.now()).format(
                    "MM/DD/YYYY"
                  )}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                  dateFormat="MM/DD/YYYY"
                  value={this.state.startDate}
                  closable
                />
              </Container>
            ) : null
          }
          <div style={{textAlign: 'center'}}>
            <Button size="big" primary onClick={this.onFinishOnboarding} loading={finishing} disabled={isDisabled}>
              Finish
            </Button>
            {plaidError && (
              <Button size="big" primary onClick={this.onFinishOnboarding}>
                Continue to Dashboard
              </Button>
            )
            }
          </div>
          <br/>
          {finishing && plaidError === false && (
            <>
              <div className="taxLoader"/>
              <div style={{textAlign: 'center', color: '#f28291'}}>
                We are downloading all your transactions, this action might take a few minutes
              </div>
            </>
          )}
          {error && (<Message negative>An error ocurred while linking your accounts</Message>)}
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({
                           session: {token, username, userInfo: {fullName}},
                           plaid: {plaidPublicKey, plaidEnv},
                           onboarding: {
                             selectedForm, businessType, businessDetails, businessTypeCode,
                           },
                         }) => ({
  token,
  username,
  plaidPublicKey,
  plaidEnv,
  fullName,
  selectedForm,
  businessType,
  businessDetails,
  businessTypeCode,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setOnboardingDone,
  setPlaidPublicKey,
  setPlaidEnvironment,
  setUserProfile,
}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withNotifications(OnboardingPlaid)),
);
