import {
  SET_INVITEE_FULL_NAME,
  SET_INVITEE_EMAIL,
  CLEAR_INVITEE,
  SET_INVITATIONS,
  SET_RECEIVED_INVITATIONS,
  SET_SENT_INVITATIONS,
} from '../ActionTypes';

const initialState = {
  allInvitations: [],
  sent: [],
  received: [],
  inviteeFullName: '',
  inviteeEmail: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_INVITEE_EMAIL: {
      const newState = { ...state };
      newState.inviteeEmail = payload.res;
      return newState;
    }
    case SET_INVITEE_FULL_NAME: {
      const newState = { ...state };
      newState.inviteeFullName = payload.res;
      return newState;
    }
    case CLEAR_INVITEE: {
      const newState = { ...state };
      newState.inviteeFullName = '';
      newState.inviteeEmail = '';
      return newState;
    }
    case SET_INVITATIONS: {
      const invitations = {
        ...state,
        allInvitations: payload.invitations,
      };
      return invitations;
    }
    case SET_RECEIVED_INVITATIONS: {
      const invitations = {
        ...state,
        received: payload.invitations,
      };
      return invitations;
    }
    case SET_SENT_INVITATIONS: {
      const invitations = {
        ...state,
        sent: payload.invitations,
      };
      return invitations;
    }
    default:
      return state;
  }
};

export default reducer;
