import React from 'react';
import {BalanceSheetSummary} from "components/containers/BalanceSheet";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import withNotifications from "components/hocs/WithNotifications";
import {withRouter} from "react-router-dom";

export class BalanceSheet extends React.Component {
  render() {
    const { userProfile } = this.props;
    return (
      <>
        <BalanceSheetSummary userProfile={userProfile} />
      </>
    )
  }
}

const mapStateToProps = ({
                           session: {username, userProfile},
                         }) => ({
  username,
  userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withNotifications(withRouter(BalanceSheet)));