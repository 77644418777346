import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Image, Menu,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';

import taxlabLogo from 'images/logo.svg';
import HeaderProfileMenu from 'components/containers/HeaderProfileMenu';
import './styles.scss';
import Routes from 'routes';

export const PublicLayout = ({ children, userProfile }) => (
  <Fragment>
    <header className="PublicLayout--header">
      <Container textAlign="center">
        <Menu stackable text style={{ margin: 0 }}>
          <Menu.Item style={{ justifyContent: 'center' }}>
            <Link to="/">
              <Image src={taxlabLogo} />
            </Link>
          </Menu.Item>
          <Menu.Menu position="right" style={{ alignItems: 'center' }}>
            <Menu.Item>
              <HashLink
                to="/#pricing"
                className="ui basic button PublicLayout__Pricing_Button"
                smooth
                role="button"
              >
                Pricing
              </HashLink>
            </Menu.Item>
            <div className="divider" />
            <HeaderProfileMenu />
          </Menu.Menu>
        </Menu>
      </Container>
    </header>
    {children}
    <footer className="PublicLayout--footer">
      <Container>
        <h3>SynkBooks</h3>
        <nav className="PublicLayout__Footer__ContactUs">
          <Link to={Routes.ContactUs}>Contact Us</Link>
        </nav>
        <p>SynkBooks &copy; 2019 Copyright</p>
      </Container>
    </footer>
  </Fragment>
);

const mapStateToProps = ({
  session: {
    userProfile,
  },
}) => ({ userProfile });

export default connect(mapStateToProps, null)(PublicLayout);
