import uuidv4 from 'uuid/v4';
import {
  PUSH_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from '../ActionTypes';

const initialState = {
  active: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PUSH_NOTIFICATION: {
      payload.key = uuidv4();
      switch (payload.type) {
        case 'success': payload.icon = 'check'; break;
        case 'error': payload.icon = 'cancel'; break;
        case 'info': payload.icon = 'info'; break;
        default:
      }
      return {
        ...state,
        active: [...state.active, payload],
      };
    }
    case REMOVE_NOTIFICATION: {
      const filteredNotifications = state.active
        .filter(notification => notification.key !== payload.key);
      return {
        ...state,
        active: [...filteredNotifications],
      };
    }
    case CLEAR_NOTIFICATIONS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
