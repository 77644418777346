import React from 'react';
import {
  Button,
  Container,
  Dropdown,
  Form,
  Header,
  Icon,
  Message,
  Modal,
  Radio,
  Segment,
  Table
} from 'semantic-ui-react';
import './styles.scss';
import moment from "moment";
import {BALANCE_SHEET_ERROR_CODES} from "libraries/formValidator";
import {
  categoryNames,
  sections,
  subSections,
  SECTION_ASSETS,
  SECTION_LIABILITIES,
  SECTION_EQUITY,
  SUB_SECTION_CURRENT_ASSETS,
  SUB_SECTION_FIXED_ASSETS,
  SUB_SECTION_OTHER_ASSETS,
  SUB_SECTION_CURRENT_LIABILITIES,
  SUB_SECTION_LONG_TERM,

} from "libraries/balanceSheetCategoires";
import {YearInput} from "semantic-ui-calendar-react";
import {balanceSheetList, updateBalanceSheetList} from "libraries/api-v2/balance-sheet-service";
import {usdCurrency} from "libraries/utils";

export class BalanceSheetSummary extends React.Component {
  state = {
    isLoading: false,
    isUpdating: false,
    isBalanceSheetModalShowing: false,
    categories: null,
    priorYear: moment().subtract(1, 'year').format('YYYY'),
    priorList: [],
    currentYear: moment().format('YYYY'),
    currentList: [],
    errors: [],
    addNewItemToggle: false,
    newBalanceSheetList: [],
    newBalanceSheetYear: moment().format('YYYY'),
    newDialogType: 'add' | 'edit',
    sectionOptions: sections.map(item => ({
      key: item,
      text: item,
      value: item
    })),
    subSectionOptions: subSections.filter(e => e.section_type === SECTION_ASSETS).map(item => ({
      key: item.name,
      text: item.name,
      value: item.name,
    })),
    categoryNameOptions: categoryNames.map(item => ({
      key: item,
      text: item,
      value: item
    })),
    newSectionType: '',
    newSubSectionType: '',
    newCategoryName: '',
    newAmount: 0,
  }

  componentDidMount() {
    this.updateBalanceSheetData();
  }

  updateBalanceSheetData = () => {
    this.setState({ isLoading: true })
    balanceSheetList(this.state.currentYear)
      .then(({data, categories}) => {
        this.setState({
          categories,
          priorYear: data.prior && data.prior.year,
          priorList: data.prior && data.prior.list,
          currentYear: data.current && data.current.year,
          currentList: data.current && data.current.list,
          isLoading: false
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false
        })
      })
  }

  setOpen = (open, type='add') => {
    this.setState({
      isBalanceSheetModalShowing: open,
      newDialogType: type,
    });
  }

  handleChangeInput = (e, {name, value}) => {
    this.setState({[name]: value})
  }

  handleAddition = (e, {id, value}) => {
    this.setState((prevState) => ({
      ...prevState,
      [id]: [{
        key: value,
        text: value,
        value
      },
        ...prevState[id]
      ],
    }))
  }

  handleSectionTypeChange = (e, {id, name, value}) => {
    this.setState(prev => ({
      ...prev,
      subSectionOptions: subSections.filter(e => e.section_type === value).map(item => ({
        key: item.name,
        text: item.name,
        value: item.name,
      })),
      [name]: value
    }))
  }

  addFormError = (errorCode) => {
    this.setState(prevState => ({
      errors: [
        ...prevState.errors,
        BALANCE_SHEET_ERROR_CODES[errorCode].content,
      ],
    }));
  }

  onAddNewBalanceSheet = () => {
    const {newBalanceSheetList, newSectionType, newSubSectionType, newCategoryName, newAmount} = this.state;
    const existItem = newBalanceSheetList.findIndex(e => e.section_type === newSectionType && e.sub_section_type === newSubSectionType && e.category_name === newCategoryName) > -1;
    if (existItem) {
      this.addFormError(BALANCE_SHEET_ERROR_CODES.BALANCE_SHEET_ITEM_ALREADY_EXIST.id)
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      newBalanceSheetList: [
        ...prevState.newBalanceSheetList,
        {
          section_type: newSectionType,
          sub_section_type: newSubSectionType,
          category_name: newCategoryName,
          amount: +newAmount,
          isNew: true,
        }
      ],
      errors: [],
      newSectionType: '',
      newSubSectionType: '',
      newCategoryName: '',
      newAmount: 0
    }))
  }

  handleBalanceSheetListChange = (sectionType, {target}) => {
    this.setState(prev => ({
      newBalanceSheetList: prev.newBalanceSheetList.map(obj => (obj.section_type === sectionType && obj.category_name === target.id ? Object.assign(obj, {amount: +target.value}) : obj))
    }));
  }

  handleRemoveNewItem = (sectionType, categoryName) => {
    this.setState(prev => ({
      ...prev,
      newBalanceSheetList: this.state.newBalanceSheetList.filter(e => !(e.section_type === sectionType && e.category_name === categoryName))
    }))
  }

  validBalanceSheetList = (year, list) => {
    if (list.length === 0) {
      this.addFormError(BALANCE_SHEET_ERROR_CODES.NOTHING_TO_UPDATE.id)
      return false;
    }
    return true;
  }

  onSubmit = () => {
    const {newBalanceSheetList, newBalanceSheetYear} = this.state;
    this.setState({errors: [], isUpdating: true});
    if (this.validBalanceSheetList(newBalanceSheetYear, newBalanceSheetList)) {
      updateBalanceSheetList({
        year: newBalanceSheetYear,
        list: newBalanceSheetList
      })
        .then(res => {
          this.setState({isUpdating: false, isBalanceSheetModalShowing: false});
          this.updateBalanceSheetData();
        })
        .catch(() => {
          this.setState({apiError: true, isUpdating: false})
        })
    } else {
      this.setState({isUpdating: false});
    }
  }

  render() {
    const {
      newBalanceSheetList,
      subSectionOptions,
      sectionOptions,
      categoryNameOptions,
      newSectionType,
      newSubSectionType,
      newCategoryName,
      newAmount,
      categories,
      priorYear,
      priorList,
      currentYear,
      currentList
    } = this.state;
    let assetsList = newBalanceSheetList.filter(e => e.section_type === SECTION_ASSETS);
    let liabilitiesList = newBalanceSheetList.filter(e => e.section_type === SECTION_LIABILITIES);
    let equityList = newBalanceSheetList.filter(e => e.section_type === SECTION_EQUITY);
    let currentAssets = [], fixedAssets = [], otherAssets = [], currentLiabilities = [], longTermLiabilities = [];
    if (assetsList) {
      currentAssets = assetsList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_ASSETS)
      fixedAssets = assetsList.filter(e => e.sub_section_type === SUB_SECTION_FIXED_ASSETS)
      otherAssets = assetsList.filter(e => e.sub_section_type === SUB_SECTION_OTHER_ASSETS)
    }
    if (liabilitiesList) {
      currentLiabilities = liabilitiesList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_LIABILITIES)
      longTermLiabilities = liabilitiesList.filter(e => e.sub_section_type === SUB_SECTION_LONG_TERM)
    }
    const priorTotalCurrentAssets = priorList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_ASSETS).reduce((acc, val) => acc + val.amount, 0)
    const currentTotalCurrentAssets = currentList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_ASSETS).reduce((acc, val) => acc + val.amount, 0)
    const priorTotalFixedAssets = priorList.filter(e => e.sub_section_type === SUB_SECTION_FIXED_ASSETS).reduce((acc, val) => acc + val.amount, 0)
    const currentTotalFixedAssets = currentList.filter(e => e.sub_section_type === SUB_SECTION_FIXED_ASSETS).reduce((acc, val) => acc + val.amount, 0)
    const priorTotalOtherAssets = priorList.filter(e => e.sub_section_type === SUB_SECTION_OTHER_ASSETS).reduce((acc, val) => acc + val.amount, 0)
    const currentTotalOtherAssets = currentList.filter(e => e.sub_section_type === SUB_SECTION_OTHER_ASSETS).reduce((acc, val) => acc + val.amount, 0)
    const priorTotalCurrentLiabilities = priorList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_LIABILITIES).reduce((acc, val) => acc + val.amount, 0)
    const currentTotalCurrentLiabilities = currentList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_LIABILITIES).reduce((acc, val) => acc + val.amount, 0)
    const priorTotalLongTermLiabilities = priorList.filter(e => e.sub_section_type === SUB_SECTION_LONG_TERM).reduce((acc, val) => acc + val.amount, 0)
    const currentTotalLongTermLiabilities = currentList.filter(e => e.sub_section_type === SUB_SECTION_LONG_TERM).reduce((acc, val) => acc + val.amount, 0)
    const priorTotalEquity = priorList.filter(e => e.section_type === SECTION_EQUITY).reduce((acc, val) => acc + val.amount, 0)
    const currentTotalEquity = currentList.filter(e => e.section_type === SECTION_EQUITY).reduce((acc, val) => acc + val.amount, 0)

    return (
      <>
        <Modal
          open={this.state.isBalanceSheetModalShowing}
          onClose={() => this.setOpen(false)}
          size="tiny" closeOnEscape={true} closeOnDimmerClick={false}>
          <Modal.Header
            content="ADD BALANCE SHEET"
            style={{backgroundColor: '#e06665', color: 'white'}}
          />
          <Modal.Content>
            <Form>
              {
                newBalanceSheetList.length > 5 && this.state.errors.length > 0
                && (
                  <Message
                    negative
                    header="It looks like you have some errors"
                    list={this.state.errors}
                  />)
              }
              <p>Select your balance year</p>
              <YearInput
                placeholder='Year'
                className='example-calendar-input'
                name='year'
                popupPosition='bottom right'
                closable
                maxDate={moment().format('YYYY')}
                hideMobileKeyboard
                value={this.state.newBalanceSheetYear}
                iconPosition='left'
                autoComplete='off'
                onChange={this.handleChangeInput}
              />
              <Form.Field widths="equal">
                <div className="Section__Title">ASSETS:</div>
                {
                  assetsList.length === 0 && (
                    <Container textAlign='center'>No data!</Container>
                  )
                }
                {
                  currentAssets.length > 0 && (
                    <>
                      <div className="Sub__Section__Type">Current Assets:</div>
                      {
                        currentAssets.map(item => (
                          <Form.Group key={`${item.section_type}-${item.sub_section_type}-${item.category_name}`}>
                            <Form.Input
                              key={item}
                              id={item.category_name}
                              label={item.category_name}
                              type="number"
                              width={14}
                              onChange={(e) => this.handleBalanceSheetListChange(item.section_type, e)}
                              defaultValue={item.amount}
                              placeholder="0"
                            />
                            {
                              item.isNew && (
                                <Form.Button
                                  width={2}
                                  className="NewItem__Close"
                                  onClick={() => this.handleRemoveNewItem(item.section_type, item.category_name)}
                                >
                                  X
                                </Form.Button>
                              )
                            }
                          </Form.Group>
                        ))
                      }
                    </>
                  )
                }
                {
                  fixedAssets.length > 0 && (
                    <>
                      <div className="Sub__Section__Type">Fixed Assets:</div>
                      {
                        fixedAssets.map(item => (
                          <Form.Group key={`${item.section_type}-${item.sub_section_type}-${item.category_name}`}>
                            <Form.Input
                              key={item}
                              id={item.category_name}
                              label={item.category_name}
                              type="number"
                              width={14}
                              onChange={(e) => this.handleBalanceSheetListChange(item.section_type, e)}
                              defaultValue={item.amount}
                              placeholder="0"
                            />
                            {
                              item.isNew && (
                                <Form.Button
                                  width={2}
                                  className="NewItem__Close"
                                  onClick={() => this.handleRemoveNewItem(item.section_type, item.category_name)}
                                >
                                  X
                                </Form.Button>
                              )
                            }
                          </Form.Group>
                        ))
                      }
                    </>
                  )
                }
                {
                  otherAssets.length > 0 && (
                    <>
                      <div className="Sub__Section__Type">Other Assets:</div>
                      {
                        otherAssets.map(item => (
                          <Form.Group key={`${item.section_type}-${item.sub_section_type}-${item.category_name}`}>
                            <Form.Input
                              key={item}
                              id={item.category_name}
                              label={item.category_name}
                              type="number"
                              width={14}
                              onChange={(e) => this.handleBalanceSheetListChange(item.section_type, e)}
                              defaultValue={item.amount}
                              placeholder="0"
                            />
                            {
                              item.isNew && (
                                <Form.Button
                                  width={2}
                                  className="NewItem__Close"
                                  onClick={() => this.handleRemoveNewItem(item.section_type, item.category_name)}
                                >
                                  X
                                </Form.Button>
                              )
                            }
                          </Form.Group>
                        ))
                      }
                    </>
                  )
                }
                <div className="Section__Title">LIABILITIES:</div>
                {
                  liabilitiesList.length === 0 && (
                    <Container textAlign='center'>No data!</Container>
                  )
                }
                {
                  currentLiabilities.length > 0 && (
                    <>
                      <div className="Sub__Section__Type">Current Liabilities:</div>
                      {
                        currentLiabilities.map(item => (
                          <Form.Group key={`${item.section_type}-${item.sub_section_type}-${item.category_name}`}>
                            <Form.Input
                              key={item}
                              id={item.category_name}
                              label={item.category_name}
                              type="number"
                              width={14}
                              onChange={(e) => this.handleBalanceSheetListChange(item.section_type, e)}
                              defaultValue={item.amount}
                              placeholder="0"
                            />
                            {
                              item.isNew && (
                                <Form.Button
                                  width={2}
                                  className="NewItem__Close"
                                  onClick={() => this.handleRemoveNewItem(item.section_type, item.category_name)}
                                >
                                  X
                                </Form.Button>
                              )
                            }
                          </Form.Group>
                        ))
                      }
                    </>
                  )
                }
                {
                  longTermLiabilities.length > 0 && (
                    <>
                      <div className="Sub__Section__Type">Long-Term Liabilities:</div>
                      {
                        longTermLiabilities.map(item => (
                          <Form.Group key={`${item.section_type}-${item.sub_section_type}-${item.category_name}`}>
                            <Form.Input
                              key={item}
                              id={item.category_name}
                              label={item.category_name}
                              type="number"
                              width={14}
                              onChange={(e) => this.handleBalanceSheetListChange(item.section_type, e)}
                              defaultValue={item.amount}
                              placeholder="0"
                            />
                            {
                              item.isNew && (
                                <Form.Button
                                  width={2}
                                  className="NewItem__Close"
                                  onClick={() => this.handleRemoveNewItem(item.section_type, item.category_name)}
                                >
                                  X
                                </Form.Button>
                              )
                            }
                          </Form.Group>
                        ))
                      }
                    </>
                  )
                }
                <div className="Section__Title">EQUITY:</div>
                {
                  equityList.length === 0 && (
                    <Container textAlign='center'>No data!</Container>
                  )
                }
                {
                  equityList.length > 0 && (
                    <>
                      {
                        equityList.map(item => (
                          <Form.Group key={`${item.section_type}-${item.sub_section_type}-${item.category_name}`}>
                            <Form.Input
                              key={item}
                              id={item.category_name}
                              label={item.category_name}
                              type="number"
                              width={14}
                              onChange={(e) => this.handleBalanceSheetListChange(item.section_type, e)}
                              defaultValue={item.amount}
                              placeholder="0"
                            />
                            {
                              item.isNew && (
                                <Form.Button
                                  width={2}
                                  className="NewItem__Close"
                                  onClick={() => this.handleRemoveNewItem(item.section_type, item.category_name)}
                                >
                                  X
                                </Form.Button>
                              )
                            }
                          </Form.Group>
                        ))
                      }
                    </>
                  )
                }
              </Form.Field>
              {
                this.state.errors.length > 0
                && (
                  <Message
                    negative
                    header="It looks like you have some errors"
                    list={this.state.errors}
                  />)
              }
              <Radio
                checked={this.state.addNewItemToggle}
                label='Add New Category'
                onChange={(e, {checked}) => this.setState({addNewItemToggle: checked})}
                toggle
              />
              {
                this.state.addNewItemToggle && (
                  <Form>
                    <hr/>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>Section Type</label>
                        <Dropdown
                          options={sectionOptions}
                          id="sectionOptions"
                          name="newSectionType"
                          onChange={this.handleSectionTypeChange}
                          selection
                          value={newSectionType}
                          placeholder='Select section...'
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Sub Section Type</label>
                        <Dropdown
                          id="subSectionOptions"
                          name="newSubSectionType"
                          options={subSectionOptions}
                          search
                          selection
                          additionLabel={<i style={{fontWeight: 'bold'}}>Add: </i>}
                          allowAdditions
                          value={newSubSectionType}
                          disabled={newSectionType === SECTION_EQUITY}
                          onAddItem={this.handleAddition}
                          onChange={this.handleChangeInput}
                          placeholder='Select sub section...'
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>Name</label>
                        <Dropdown
                          id="categoryNameOptions"
                          name="newCategoryName"
                          options={categoryNameOptions}
                          search
                          selection
                          value={newCategoryName}
                          onChange={this.handleChangeInput}
                          allowAdditions
                          additionLabel={<i style={{fontWeight: 'bold'}}>Add: </i>}
                          onAddItem={this.handleAddition}
                          placeholder='Select Category Name...'
                        />
                      </Form.Field>
                      <Form.Input
                        label="Amount"
                        type="number"
                        name="newAmount"
                        placeholder='0'
                        value={newAmount}
                        onChange={this.handleChangeInput}
                      />
                    </Form.Group>
                    <Button
                      type='button'
                      primary
                      disabled={!newSectionType || (newSectionType !== SECTION_EQUITY && !newSubSectionType) || !newCategoryName}
                      onClick={this.onAddNewBalanceSheet}
                    >ADD
                    </Button>
                  </Form>
                )
              }
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.onSubmit} loading={this.state.isUpdating}
                    disabled={this.state.isUpdating}>
              Save
            </Button>
            <Button onClick={() => this.setOpen(false)}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Container>
          <Header as='h2'>{this.props.userProfile.fullName}</Header>
          <Button
            floated='right'
            icon
            labelPosition='left'
            primary
            size='small'
            onClick={() => {
              this.setOpen(true, 'add')
            }}
          >
            <Icon name='add'/> Add
          </Button>
          <Header as='h3'>Balance Sheet</Header>
        </Container>
        <Segment
          loading={this.state.isLoading}
        >
          <Table celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={6}/>
                <Table.HeaderCell textAlign='center'>
                  Prior Year
                  <Icon name="edit" size="large" onClick={() => {
                    this.setState({
                      newBalanceSheetYear: this.state.priorYear,
                      newBalanceSheetList: this.state.priorList
                    });
                    this.setOpen(true, 'edit')
                  }} />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>
                  Current Year
                  <Icon name="edit" size="large" onClick={() => {
                    this.setState({
                      newBalanceSheetYear: this.state.currentYear,
                      newBalanceSheetList: this.state.currentList
                    });
                    this.setOpen(true, 'edit');
                  }} />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell/>
                <Table.HeaderCell textAlign='center'>{priorYear}</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>{currentYear}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell className="Table__Section__Type" colSpan={3}>ASSETS</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="Table__Category__Type">Current Assets:</Table.Cell>
                <Table.Cell/>
                <Table.Cell/>
              </Table.Row>
              {
                categories && categories.currentAssets.map((category, index) => {
                  let pAmount = 0, cAmount = 0;
                  const priorItem = priorList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_ASSETS && e.category_name === category.category_name);
                  const currentItem = currentList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_ASSETS && e.category_name === category.category_name);
                  if (priorItem.length) {
                    pAmount = priorItem[0].amount;
                  }
                  if (currentItem.length) {
                    cAmount = currentItem[0].amount;
                  }
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{category.category_name}:</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(pAmount)}</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(cAmount)}</Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row className="Table__Category__Total">
                <Table.Cell>Total Current Assets:</Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalCurrentAssets)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalCurrentAssets)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="Table__Category__Type">Fixed Assets:</Table.Cell>
                <Table.Cell/>
                <Table.Cell/>
              </Table.Row>
              {
                categories && categories.fixedAssets.map((category, index) => {
                  let pAmount = 0, cAmount = 0;
                  const priorItem = priorList.filter(e => e.sub_section_type === SUB_SECTION_FIXED_ASSETS && e.category_name === category.category_name);
                  const currentItem = currentList.filter(e => e.sub_section_type === SUB_SECTION_FIXED_ASSETS && e.category_name === category.category_name);
                  if (priorItem.length) {
                    pAmount = priorItem[0].amount;
                  }
                  if (currentItem.length) {
                    cAmount = currentItem[0].amount;
                  }
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{category.category_name}:</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(pAmount)}</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(cAmount)}</Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row className="Table__Category__Total">
                <Table.Cell>Total Fixed Assets:</Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalFixedAssets)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalFixedAssets)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="Table__Category__Type">Other Assets:</Table.Cell>
                <Table.Cell/>
                <Table.Cell/>
              </Table.Row>
              {
                categories && categories.otherAssets.map((category, index) => {
                  let pAmount = 0, cAmount = 0;
                  const priorItem = priorList.filter(e => e.sub_section_type === SUB_SECTION_OTHER_ASSETS && e.category_name === category.category_name);
                  const currentItem = currentList.filter(e => e.sub_section_type === SUB_SECTION_OTHER_ASSETS && e.category_name === category.category_name);
                  if (priorItem.length) {
                    pAmount = priorItem[0].amount;
                  }
                  if (currentItem.length) {
                    cAmount = currentItem[0].amount;
                  }
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{category.category_name}:</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(pAmount)}</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(cAmount)}</Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row className="Table__Category__Total">
                <Table.Cell>Total Other Assets:</Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalOtherAssets)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalOtherAssets)}
                </Table.Cell>
              </Table.Row>
              <Table.Row className="Table__Section__Total">
                <Table.Cell>
                  TOTAL ASSETS
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalCurrentAssets + priorTotalFixedAssets + priorTotalOtherAssets)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalCurrentAssets + currentTotalFixedAssets + currentTotalOtherAssets)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={3} />
              </Table.Row>
              <Table.Row>
                <Table.Cell className="Table__Section__Type" colSpan={3}>LIABILITIES</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="Table__Category__Type">Current Liabilities:</Table.Cell>
                <Table.Cell/>
                <Table.Cell/>
              </Table.Row>
              {
                categories && categories.currentLiabilities.map((category, index) => {
                  let pAmount = 0, cAmount = 0;
                  const priorItem = priorList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_LIABILITIES && e.category_name === category.category_name);
                  const currentItem = currentList.filter(e => e.sub_section_type === SUB_SECTION_CURRENT_LIABILITIES && e.category_name === category.category_name);
                  if (priorItem.length) {
                    pAmount = priorItem[0].amount;
                  }
                  if (currentItem.length) {
                    cAmount = currentItem[0].amount;
                  }
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{category.category_name}:</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(pAmount)}</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(cAmount)}</Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row className="Table__Category__Total">
                <Table.Cell>Total Current Liabilities:</Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalCurrentLiabilities)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalCurrentLiabilities)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="Table__Category__Type">Long-Term Liabilities:</Table.Cell>
                <Table.Cell/>
                <Table.Cell/>
              </Table.Row>
              {
                categories && categories.longTermLiabilities.map((category, index) => {
                  let pAmount = 0, cAmount = 0;
                  const priorItem = priorList.filter(e => e.sub_section_type === SUB_SECTION_LONG_TERM && e.category_name === category.category_name);
                  const currentItem = currentList.filter(e => e.sub_section_type === SUB_SECTION_LONG_TERM && e.category_name === category.category_name);
                  if (priorItem.length) {
                    pAmount = priorItem[0].amount;
                  }
                  if (currentItem.length) {
                    cAmount = currentItem[0].amount;
                  }
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{category.category_name}:</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(pAmount)}</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(cAmount)}</Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row className="Table__Category__Total">
                <Table.Cell>Total Long-Term Liabilities:</Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalLongTermLiabilities)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalLongTermLiabilities)}
                </Table.Cell>
              </Table.Row>
              <Table.Row className="Table__Section__Total">
                <Table.Cell>
                  TOTAL LIABILITIES
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalCurrentLiabilities + priorTotalLongTermLiabilities)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalCurrentLiabilities + currentTotalLongTermLiabilities)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={3}/>
              </Table.Row>
              <Table.Row>
                <Table.Cell className="Table__Section__Type" colSpan={3}>EQUITY</Table.Cell>
              </Table.Row>
              {
                categories && categories.equityList.map((category, index) => {
                  let pAmount = 0, cAmount = 0;
                  const priorItem = priorList.filter(e => e.section_type === SECTION_EQUITY && e.category_name === category.category_name);
                  const currentItem = currentList.filter(e => e.section_type === SECTION_EQUITY && e.category_name === category.category_name);
                  if (priorItem.length) {
                    pAmount = priorItem[0].amount;
                  }
                  if (currentItem.length) {
                    cAmount = currentItem[0].amount;
                  }
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{category.category_name}:</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(pAmount)}</Table.Cell>
                      <Table.Cell textAlign='right'>{usdCurrency(cAmount)}</Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row className="Table__Category__Total">
                <Table.Cell>Total Equity:</Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalEquity)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalEquity)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={3}/>
              </Table.Row>
              <Table.Row className="Table__Section__Total">
                <Table.Cell>
                  TOTAL LIABILITIES & EQUITY
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalCurrentLiabilities + priorTotalLongTermLiabilities + priorTotalEquity)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalCurrentLiabilities + currentTotalLongTermLiabilities + currentTotalEquity)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={3}/>
              </Table.Row>
              <Table.Row className="Table__Section__Total Check" style={{
                height: 60
              }}>
                <Table.Cell>
                  Balance Sheet Check
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(priorTotalCurrentLiabilities + priorTotalLongTermLiabilities + priorTotalEquity - priorTotalCurrentAssets - priorTotalFixedAssets - priorTotalOtherAssets)}
                </Table.Cell>
                <Table.Cell textAlign='right'>
                  {usdCurrency(currentTotalCurrentLiabilities + currentTotalLongTermLiabilities + currentTotalEquity - currentTotalCurrentAssets - currentTotalFixedAssets - currentTotalOtherAssets)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </>
    );
  }
}