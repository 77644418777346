import React from 'react';
import {
  Grid, Dimmer, Loader,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import './styles.scss';
import LayoutHeader from 'components/containers/Headerv2';
import Notifications from 'components/presentationals/Notification';

/**
 * A layout for screens gated by private routes.
 *
 * PrivateLayoutv2 succeeds @see {@link PrivateLayout}. The reason for succeeding
 * PrivateLayout is because this component does away with route checking before
 * deciding how to render the layout.
 */
export class PrivateLayoutv2 extends React.Component {
  componentDidMount() {
    document.body.classList.add('Layout__Background--color-gray');
  }

  componentWillUnmount() {
    document.body.classList.remove('Layout__Background--color-gray');
  }

  render() {
    const { children, isLoggingOut } = this.props;

    return (
      <>
        <LayoutHeader {...this.props} />
        <div className="Layout__Body">
          {
            isLoggingOut && (
              <Dimmer
                active
                inverted
                className="Layout__Loader"
              >
                <Loader size="big" content="Logging out..." />
              </Dimmer>)
          }
          <Notifications isPrivate />
          <Grid container>
            <Grid.Row centered>
              <Grid.Column>
                { children }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  session: {
    isLoggingOut,
  },
}) => ({
  isLoggingOut,
});

export default connect(mapStateToProps)(PrivateLayoutv2);
