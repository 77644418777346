import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import cn from 'classnames';

import './styles.scss';
import Routes from 'routes';

class Steps extends Component {
  state = {
    step: 1,
    stepsNames: [
      {
        name: 'FORM',
        url: Routes.OnboardingReportForm,
      },
      {
        name: 'BUSINESS_TYPE',
        url: Routes.OnboardingBusinessType,
      },
      {
        name: 'LINK_ACCOUNT',
        url: Routes.OnboardingLinkPlaid,
      },
    ],
  };

  componentDidMount() {
    this.changeStepTo(this.props.location.pathname);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.changeStepTo(this.props.location.pathname);
    }
  }

  changeStepTo = (pathname) => {
    let step = 0;
    switch (pathname) {
      case Routes.OnboardingReportForm:
        step = 1;
        break;
      case Routes.OnboardingBusinessType:
        step = 2;
        break;
      case Routes.OnboardingLinkPlaid:
      case Routes.OnboardingSync:
        step = 3;
        break;
      default:
        break;
    }
    this.setState({ step });
  }

  changeStepView = (url) => {
    this.props.history.replace(url);
  }

  render() {
    return (
      <Grid container className="Steps" padded>
        <Grid.Row columns={this.state.stepsNames.length}>
          {
            this.state.stepsNames.map((step, idx) => {
              const isActive = idx < this.state.step;
              const hasCircleIndicator = idx >= this.state.step - 1;
              return (
                <Grid.Column
                  key={idx}
                  className={cn('Steps__Step', isActive && 'Steps__Step--active')}
                  textAlign="center"
                  onClick={() => isActive && this.changeStepView(step.url)}
                >
                  <div className="Steps__Step-text">{`${step.name.replace('_', ' ')}`}</div>
                  <div className="Steps__Step-circle">
                    {
                      hasCircleIndicator && <div className="Steps__Step-inner-circle" />
                    }
                  </div>
                  {
                    idx > 0 && <div className="Steps__Step-progress-bar" />
                  }
                </Grid.Column>
              );
            })
          }
        </Grid.Row>
      </Grid>
    );
  }
}

export default withRouter(Steps);
