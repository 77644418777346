import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setInviteeEmail, setInviteeFullName, clearInvitee } from 'store/actions/invitations';
import { Button } from 'semantic-ui-react';

import { TaxInput } from 'taxUI';
import './styles.scss';
import {isValidEmail} from "libraries/formValidator";

class NewInvitationView extends Component {
  state = {
    inviteeFullName: this.props.inviteeFullName,
    inviteeEmail: this.props.inviteeEmail,
  }

  handleCancelInvitation = () => {
    const { onCancel } = this.props;

    this.props.clearInvitee();

    onCancel();
  }

  handleNextStepNewInvitation = () => {
    const { onNext } = this.props;

    this.props.setInviteeEmail(this.state.inviteeEmail);
    this.props.setInviteeFullName(this.state.inviteeFullName);

    onNext();
  }

  handleInputChange = (event, { name, value }) => this.setState({ [name]: value });

  render() {
    const { inviteeFullName, inviteeEmail } = this.state;

    const isDisabled = !inviteeFullName || !inviteeEmail || !isValidEmail(inviteeEmail);

    return (
      <div className="NewInvitation__Container">
        <TaxInput
          fluid
          name="inviteeFullName"
          label="Full Name"
          placeholder="Joe Example"
          value={inviteeFullName}
          onChange={this.handleInputChange}
          required
        />
        <br />
        <TaxInput
          fluid
          name="inviteeEmail"
          label="Email"
          placeholder="joe@example.com"
          value={inviteeEmail}
          onChange={this.handleInputChange}
          required
        />
        <br />
        <div className="NewInvitation__Buttons">
          <Button className="NewInvitation__Button__Cancel" onClick={this.handleCancelInvitation} size="small">
            Cancel
          </Button>
          <Button className="NewInvitation__Button__SendInvite" onClick={this.handleNextStepNewInvitation} primary size="small" disabled={isDisabled}>
            Send Invite
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ invitations: { inviteeEmail, inviteeFullName } }) => ({
  inviteeEmail,
  inviteeFullName,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setInviteeEmail,
    setInviteeFullName,
    clearInvitee,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewInvitationView);
