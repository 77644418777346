import React from 'react';
import Routes from 'routes';
import { Switch, Route } from 'react-router-dom';
import Reports from 'components/containers/Reportsv2';
import ProfitAndLoss from './ProfitAndLoss';
import Balance from "./Balance";

const ReportsScreen = () => (
  <Switch>
    <Route exact path={Routes.ClientDashboardReports} component={Reports} />
    <Route exact path={Routes.ClientDashboardReportsProfitAndLoss} component={ProfitAndLoss} />
    <Route exact path={Routes.ClientDashboardReportsBalance} component={Balance} />
  </Switch>
);

export default ReportsScreen;
