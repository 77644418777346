import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import './styles.scss';

class DoughnutChart extends Component {
  state = {
    renderError: false,
  }

  componentDidCatch() {
    this.setState({ renderError: true });
  }

  render() {
    const { renderError } = this.state;
    const { apiError } = this.props;
    if (renderError || apiError) return (<i>Unable to display chart</i>);
    if (this.props.data.datasets[0].data[0] === 0 && this.props.data.datasets[0].data[1] === 0) {
      return (<i>No data found for period</i>);
    }

    return (
      <div className="Doughnut-Chart__Container">
        <Doughnut {...this.props} />
      </div>
    );
  }
}

export default DoughnutChart;
