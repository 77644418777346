import React, { Component } from 'react';
import { Select, Grid, Segment } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { connect } from 'react-redux';
import jsCookie from 'js-cookie';

import Doughnut from 'components/presentationals/DoughnutChart';
import {
  YEAR, CUSTOM, Q1, Q2, Q3, Q4, PREVIOUS_MONTH, CURRENT_MONTH,
} from 'libraries/transactionsFilterManager';
import { splitDateRangeInput } from 'libraries/utils';
import { getProfitAndLossAsProfessional } from 'libraries/api-v2/transactions-service';
import 'chartjs-plugin-labels';
import moment from 'moment';

import ProfitFiltersOptions from './profitFiltersOptions';
import './styles.scss';

const getDefaultDate = () => {
  const yearStart = moment().startOf('year').format('MM/DD/YYYY');
  const today = moment().format('MM/DD/YYYY');
  return `${yearStart} - ${today}`;
};

export class ProfitSummaryv2 extends Component {
  state = {
    doughnutData: {
      datasets: [{
        data: [0, 0],
        // should match chambray and glacier colors from _colors.css
        backgroundColor: ['#3a5695', '#729ec2'],
      }],
      labels: ['Expenses', 'Income'],
    },
    options: {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: 'white',
          precision: 2,
        },
      },
    },
    income: 0,
    expenses: 0,
    optionSelected: YEAR,
    customDate: getDefaultDate(),
    isLoading: false,
    apiError: false,
  }

  componentDidMount() {
    this.updateData();
  }

  updateData = (dateRange = this.state.customDate) => {
    const { username, token } = this.props;
    const clientEmail = jsCookie.get('active_client_email');

    const [startDate, endDate] = splitDateRangeInput(dateRange);
    if (startDate.isValid() && endDate.isValid()) {
      this.setState({ isLoading: true });
      getProfitAndLossAsProfessional(username, token, clientEmail, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
        .then(({ data: { profit_and_loss: { transactions } } }) => {
          const income = Number(transactions[0].gross_income);
          const expenses = Number(transactions[1].total_expenses);
          this.setState({ income, expenses, isLoading: false }, this.updateDoughnutData);
        })
        .catch(() => {
          this.setState({ apiError: true, isLoading: false });
        });
    }
  }

  handleOptionSelect = (event, { value }) => {
    this.setState({
      optionSelected: value,
      customDate: this.getPeriodDates(value),
    }, this.updateData);
  }

  getPeriodDates = (period) => {
    let date = moment();
    let startDate = null;
    let endDate = null;
    switch (period) {
      case CURRENT_MONTH:
      case PREVIOUS_MONTH: {
        date = period === PREVIOUS_MONTH ? date.subtract(1, 'month') : date;
        startDate = date.startOf('month').format('MM/DD/YYYY');
        endDate = date.endOf('month').format('MM/DD/YYYY');
        break;
      }
      case Q1:
      case Q2:
      case Q3:
      case Q4: {
        const quarter = period[1];
        startDate = date.quarter(quarter)
          .startOf('quarter').format('MM/DD/YYYY');
        endDate = date.quarter(quarter)
          .endOf('quarter').format('MM/DD/YYYY');
        break;
      }
      case YEAR:
      default: { // default to year to date
        startDate = moment().startOf('year').format('MM/DD/YYYY');
        endDate = moment().format('MM/DD/YYYY');
        break;
      }
    }
    return `${startDate} - ${endDate}`;
  }

  handleDateSelection = (event, { value }) => {
    this.setState({ customDate: value });
    this.updateData(value);
  }

  updateDoughnutData = () => {
    this.setState((prevState) => {
      const { doughnutData, income, expenses } = prevState;
      const { datasets } = doughnutData;
      const total = income + expenses;
      let percentageIncome = ((income / total) * 100).toFixed(2);
      percentageIncome = isNaN(percentageIncome) ? 0 : percentageIncome;
      let percentageExpenses = ((expenses / total) * 100).toFixed(2);
      percentageExpenses = isNaN(percentageExpenses) ? 0 : percentageExpenses;
      datasets[0].data = [percentageExpenses, percentageIncome];
      return {
        doughnutData: {
          ...doughnutData,
          datasets,
        },
        ...prevState,
      };
    });
  }

  displayNegativeInverseAmount(amount) {
    return (-1 * amount).toLocaleString();
  }

  render() {
    const { income, expenses, customDate } = this.state;
    const total = income + expenses;
    const today = moment().format('MM/DD/YYYY');

    return (
      <Segment
        loading={this.state.isLoading}
        className="Profit-Summary"
      >
        <Grid
          className="Profit-Summary__Container"
          stackable
        >
          <Grid.Row columns={2} className="Profit-Summary__Content">
            <Grid.Column width={4} className="Profit-Summary__Content Profit-Summary__Content--Filters">
              <span className="Profit-Summary__Filters--Header">
                { total <= 0 ? 'Profit' : 'Loss' }
              </span>
              <div className="Profit-Summary__Filters--Profit">{ this.displayNegativeInverseAmount(total) }</div>
              <Select
                value={this.state.optionSelected}
                selectOnBlur={false}
                fluid
                selection
                options={ProfitFiltersOptions}
                onChange={this.handleOptionSelect}
                className="HideForPrint"
              />
              {
                this.state.optionSelected === CUSTOM
                  && (
                  <DatesRangeInput
                    id="custom_date"
                    type="text"
                    fluid
                    placeholder="Select a date"
                    dateFormat="MM/DD/YYYY"
                    iconPosition="left"
                    popupPosition="bottom left"
                    maxDate={today}
                    value={customDate}
                    onChange={this.handleDateSelection}
                    className="HideForPrint"
                  />
                  )
              }
            </Grid.Column>
            <Grid.Column width={12} className="Profit-Summary__Content Profit-Summary__Content--Graphics">
              <div className="Profit-Summary__Doughnut--Label Profit-Summary__Doughnut--Label-income">
                Income
                <br />
                <span>{this.displayNegativeInverseAmount(this.state.income)}</span>
              </div>
              <>
                <Doughnut
                  data={this.state.doughnutData}
                  options={this.state.options}
                  height={250}
                  width={255}
                  apiError={this.state.apiError}
                />
              </>
              <div className="Profit-Summary__Doughnut--Label Profit-Summary__Doughnut--Label-expenses">
                Expenses
                <br />
                <span>{this.state.expenses.toLocaleString()}</span>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  session: { username, token },
  clientTransactions,
}) => ({
  clientTransactions,
  username,
  token,
});

export default connect(mapStateToProps, null)(ProfitSummaryv2);
