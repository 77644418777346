import PlaidLink from 'react-plaid-link';
import React from 'react';
import './styles.scss';

const LinkPlaid = props => (
  <PlaidLink
    clientName={process.env.REACT_APP_PLAID_CLIENT_NAME}
    /**
     * Don't remove the webhook option. Despite that Plaid's doc
     * says that this param is optional, and you could think that
     * it's better to set the webhook in the backend, it's important
     * to keep this setting because it's the only way to make Plaid
     * send the INITIAL_UPDATE and HISTORICAL_UPDATE webhooks.
     * This behaviour is not explained in the docs but you can read
     * this comment in Github: https://github.com/plaid/plaid-ruby/issues/188#issuecomment-388184414
     * */
    webhook={process.env.REACT_APP_PLAID_WEBHOOK}
    env={props.env}
    product={['transactions']}
    publicKey={props.publicKey}
    onExit={props.handleOnExit}
    onEvent={props.handleOnEvent}
    onSuccess={props.handleOnSuccess}
    onLoad={props.openPlaidModal}
    className="Plaid__Button"
    token={props.token}
  />
);

export default LinkPlaid;
