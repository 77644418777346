import React, { Fragment } from 'react';
import cn from 'classnames';
import './styles.scss';

const renderRow = group => group.items.map((item, idx) => (
  <Fragment key={`${item.categoryName}-${idx}`}>
    <div className="index"><b>{item.subsection}</b></div>
    <div className="category-name">
      <div>{item.categoryName}</div>
      <div />
    </div>
    <div className="index"><b>{item.subsection}</b></div>
    <div className="centered">{Math.abs(item.total).toLocaleString()}</div>
    <div />
  </Fragment>
));

const renderRowsExpenses = group => group.items
  .map((item, idx) => (
    <div key={`${item.categoryName}-${idx}`}>
      <div className={cn('index', item.subsection && 'subsection')}>
        <b>{ item.subsection }</b>
      </div>
      <div>
        {item.categoryName}
        <div />
      </div>
      <div className="index"><b>{item.subsection}</b></div>
      <div className="centered">
        {Math.abs(item.total).toLocaleString()}
      </div>
      <div />
    </div>
  ));

// 4 is the fixed height of each item.
const containerHeight = (NoItems, columns) => (((NoItems + (NoItems % columns)) / columns) * 4);

const ScheduleC = props => (
  <>
    <div className="group-title bt bb">
      <b>Part-1</b>
      Income
    </div>
    <section
      className="ScheduleC part-1"
    >
      { props.items.length && renderRow(props.items[0]) }
    </section>
    <div className="group-title bt bb">
      <b>Part-2</b>
      Expenses
    </div>
    { props.items.length && (
    <section
      className="ScheduleC part-2"
      style={{ height: `${containerHeight(props.items[1].items.length, 2)}rem` }}
    >
      { renderRowsExpenses(props.items[1]) }
    </section>)
    }
  </>
);

export default ScheduleC;
