import React from 'react';
import LandingPageLayout from 'components/presentationals/LandingPageLayout';
import ChooseUs from 'components/containers/ChoosUs';
import Banner from 'components/containers/Banner';
import VideoSection from 'components/containers/VideoSection';
import Pricing from 'components/containers/Pricing';
import './styles.scss';

const LandingPage = () => (
  <div className="LandingPage">
    <LandingPageLayout>
      <Banner />
      <ChooseUs />
      <VideoSection />
      <Pricing />
    </LandingPageLayout>
  </div>
);

export default LandingPage;
