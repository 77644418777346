import React, {Component, Fragment} from 'react';
import {Grid, Table,} from 'semantic-ui-react';
import './styles.scss';

class AccountTable extends Component {
  state = { isConfirmOpen: false };

  render() {
    const {
      props: { balance },
      state: {  },
    } = this;
    return (
      <Fragment>
        <div>
          <Grid stackable className="AccountTable__Container">
            <Table celled structured color='grey'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>{`${balance.name} ${balance.mask}`}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={7}>Bank Balance</Table.Cell>
                  <Table.Cell width={3}>{`$${balance.balance_current}`}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>SynkBooks Balance</Table.Cell>
                  <Table.Cell>{`$${balance.synkBooksBalance + balance.opening_balance}`}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

export default AccountTable;
