
import camelcaseKeys from 'camelcase-keys';
import apiRequestV2 from 'libraries/api-v2/api';
import uuidv4 from "uuid/v4";

const basePath = '/web-api';

const getInstitutionById = id => apiRequestV2('get', `${basePath}/plaid/institutions/${id}`)
  .then(res => camelcaseKeys(res.data));

/**
 * V2 wrapper for backwards compatibility
 * Note: username and token are not needed
 */
export const getInstitutionByIdV2 = (username, token, id) => getInstitutionById(id);

export const getInstitutionsV2 = (clientEmail) => apiRequestV2('post', `${basePath}/plaid/institutions/get_customer_institutions/as-professional`, {
  body: {
    clientEmail
  },
})
  .then(res => res.data.institutions)
  .then(res => res.sort((a, b) => {
    const prev = a.institution_name.toLowerCase();
    const next = b.institution_name.toLowerCase();
    if (prev < next) return -1;
    if (prev > next) return 1;
    return 0;
  }))
  .then(res => res.map(inst => ({
    ...inst,
    id: uuidv4(),
    items: inst.items.map(item => ({
      ...item,
      accounts: item.accounts.map(account => ({ ...account, key: uuidv4() })),
    })),
  })))
  .then(res => camelcaseKeys(res, { deep: true }));

export default {
  getInstitutionByIdV2,
};
