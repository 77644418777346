import React from 'react';
import { Message } from 'semantic-ui-react';
import cn from 'classnames';

import AddNotificationsHandlers from 'components/hocs/WithNotifications';
import './styles.scss';

export class Notification extends React.Component {
  hideAfter = (miliseconds, key) => {
    setTimeout(() => {
      this.removeNotification(key);
    }, miliseconds);
  }

  removeNotification = (key) => {
    this.props.removeNotification(key);
  }

  render() {
    return (
      <div className={cn('Notifications-Container',
        this.props.isPrivate && 'Notifications-Container--private')}
      >
        {
          this.props.notifications.active.map((notification) => {
            if (notification.hideAfter !== null) {
              this.hideAfter(notification.hideAfter, notification.key);
            }
            return (<Message
              icon={notification.icon}
              key={notification.key}
              header={notification.header}
              content={notification.content}
              className={cn('Notification',
                notification.type === 'success' && 'Notification--success',
                notification.type === 'info' && 'Notification--info',
                notification.type === 'error' && 'Notification--error')
              }
              onDismiss={() => this.removeNotification(notification.key)}
            />
            );
          })
        }
      </div>
    );
  }
}

export default AddNotificationsHandlers(Notification);
