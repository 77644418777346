import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import LoginComponent from 'components/containers/Login';
import SignUpComponent from 'components/presentationals/SignUp';
import {
  Switch, Route, Link, Redirect,
} from 'react-router-dom';
import Routes from 'routes';
import cn from 'classnames';
import './styles.scss';

export const SignupLogin = (props) => {
  const { location } = props;
  const isActiveTab = route => (location.pathname === route);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <p className="Login-Paragraph">
            Simple, but powerful bookkeeping software to help
            you keep track of your income and expenses.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2} className="Login-Tabs">
        <Grid.Column floated="left" textAlign="center">
          <Header size="large" className="Login-Tabs__Tab">
            <Link
              to={Routes.Login}
              className={cn('Login-Tabs__Tab',
                isActiveTab(Routes.Login) && 'Login-Tabs__Tab--active')}
            >
              LOG IN
            </Link>
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" textAlign="center">
          <Header size="large" className="Login-Tabs__Tab">
            <Link
              to={Routes.Signup}
              className={cn('Login-Tabs__Tab',
                isActiveTab(Routes.Signup) && 'Login-Tabs__Tab--active')}
            >
              SIGN UP
            </Link>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="Login-Tabs__Form">
        <Switch>
          <Route exact path={Routes.Login} component={LoginComponent} />
          <Route exact path={Routes.Signup} component={SignUpComponent} />
          <Redirect from={Routes.Index} to={Routes.Login} />
        </Switch>
      </Grid.Row>
    </Grid>
  );
};

export default SignupLogin;
