import React from 'react';
import { Segment, List } from 'semantic-ui-react';

import './styles.scss';

const BalanceTable = props => (
  <div className="Balance-Table">
    <Segment className="Balance-Table__Top" attached>
      Remaining Balances
    </Segment>
    <Segment attached>
      <List>
        {
          props.items && props.items
            .map((category, index) => props.renderList(category, index === 0))
        }
      </List>
    </Segment>
  </div>
);

export default BalanceTable;
