import {
  CLEAR_CLIENTS,
  SET_CLIENTS,
} from '../ActionTypes';

export const setClients = res => ({
  type: SET_CLIENTS,
  payload: {
    res,
  },
});

export const clearClients = res => ({
  type: CLEAR_CLIENTS,
});
