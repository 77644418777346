import React from 'react';
import { Container } from 'semantic-ui-react';

import PublicLayout from 'components/containers/PublicLayout';
import './styles.scss';

const ContactUs = () => (
  <PublicLayout>
    <Container className="ContactUs-Container">
      <p>
        Hello there! Thank you for being a part of the
        <b> Tax Lab, Inc. </b>
        family!
      </p>
      <p>
        Are you looking for answers?
        <br />
        If so, we recommend emailing us at
        <a href="mailto:support@synkbooks.com"> support@synkbooks.com </a>
        (We do not offer phone support at this time.)
      </p>
      <p>
        Our standard operating business hours are:
        <b> Monday- Friday 10:00 AM - 6:00 PM PST</b>
        <br />
        and our average response time is within 1-2 business days
        (Monday - Friday, not including major holidays and special taco events).
        <br />
        We appreciate your patience and will do whatever we can to get you taken care of.
      </p>
      <p>
        Thank you for your patience and keep up the good work!
      </p>
    </Container>
  </PublicLayout>
);

export default ContactUs;
