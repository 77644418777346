import React from 'react';
import TransactionsTable from 'components/containers/TransactionsTable';
import ProfitSummary from 'components/containers/ProfitSummary';

const Transactions = () => (
  <>
    <ProfitSummary />
    <TransactionsTable />
  </>
);

export default Transactions;
