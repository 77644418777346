import React from 'react';
import cn from 'classnames';
import { withRouter, Link } from 'react-router-dom';

import Routes from 'routes';
import './styles.scss';

export const HeaderSections = (props) => {
  const sections = [
    {
      title: 'Dashboard',
      path: Routes.DashboardTransactions,
    },
    {
      title: 'Reports',
      path: Routes.DashboardReports,
    },
    {
      title: 'Invoicing',
      path: Routes.DashboardInvoicing,
    },
  ];

  const isActive = (pathname, sectionPath) => pathname.match(sectionPath);

  const { location } = props;
  if (location.pathname.match(/onboarding/)) return null;
  return (
    <div className="Header-Sections">
      {
        sections.map(section => (
          <Link
            key={section.path}
            className={cn(
              'Header-Sections__Section',
              isActive(location.pathname, section.path) && 'Header-Sections__Section--active',
            )}
            to={section.path}
            tabIndex={0}
          >
            { section.title }
          </Link>
        ))
      }
    </div>
  );
};

export default withRouter(HeaderSections);
