import React from 'react';
import BalanceSummaryV2 from "components/containers/Balancev2";
import {ProfitSummaryv2} from "components/containers/ProfitSummaryv2";

const Balance = () => (
  <>
    <ProfitSummaryv2 />
    <BalanceSummaryV2 />
  </>
);

export default Balance;
