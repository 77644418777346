import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import './index.print.scss';
import './semantic-ui-theme/semantic.less';
import App from 'screens/App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore, persistor } from 'store';
import ReactGA from 'react-ga';
const TRACKING_ID = "GTM-PCXHRK6";
ReactGA.initialize(TRACKING_ID);

import './aws-cognito-config';

const routes = (
  <Provider store={configureStore}>
    <PersistGate persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>
);

ReactDOM.render(routes, document.getElementById('root'));
