import React from "react";
import {
  Segment,
  Grid,
  Button,
  Divider,
  Dropdown,
  Icon,
  Loader,
} from "semantic-ui-react";
import moment from "moment";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import "./styles.scss";
import Preview from "./preview";
import MoreAction from "components/ui/SelectAction/MoreAction";
import {
  getInvoiceByIdAsProfessional,
  remindInvoiceAsProfessional,
  cancelInvoiceAsProfessional,
} from "libraries/api-v2/invoicing-service";
import { currencyFormat } from "libraries/utils";
import jsCookie from "js-cookie";

const options = [
  { key: 1, text: "Print/PDF", value: "print" },
  { key: 2, text: "Cancel", value: "cancel" },
];
class InvoiceDetail extends React.Component {
  state = {
    invoice: {
      created_at: 0,
      currency: "",
      customer_email: "",
      due_date_num: 0,
      footer: "",
      id: 0,
      invoiceHistories: [],
      invoiceItems: [],
      invoice_item_id: null,
      invoice_number: "",
      invoice_pdf: "",
      memo: "",
      receiver_address: "",
      receiver_email: "",
      receiver_name: "",
      receiver_phone: "",
      sender_address: "",
      sender_email: "",
      sender_name: "",
      sender_phone: "",
      status: "",
      updated_at: null,
    },
    isLoading: true,
    isReminding: false,
    isCanceling: false,
    selectedId: 0,
    selectedData: {
      id: 0,
      amount: 0,
      amount_due: 0,
      currency: "usd",
      date: "",
      invoice: "",
      invoice_pdf: "",
      recipient: "",
      status: "",
    },
    taxRates:[],
  };

  componentDidMount() {
    this.setState({
      selectedId: this.props.match.params.id,
      selectedData: JSON.parse(localStorage.getItem("selectedData")),
      taxRates: JSON.parse(localStorage.getItem('taxRateList')),
    });
    const clientEmail = jsCookie.get('active_client_email');

    getInvoiceByIdAsProfessional(clientEmail, this.props.match.params.id)
      .then((res) => {
        this.setState({
          invoice: res.data,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({isLoading:true});
      });
  }

  invoiceRemind(id) {
    this.setState({ isReminding: true });
    const clientEmail = jsCookie.get('active_client_email');
    remindInvoiceAsProfessional(clientEmail, id)
      .then((res) => {
        console.log(res);
        this.setState({ isReminding: false });
        toast({
          type: "success",
          icon: "check circle outline",
          title: "Remind invoice",
          time: 2000,
          description: <p>Invoice was sent successfully</p>,
        });
      })
      .catch((err) => {
        console.log(err.response);
        toast({
          type: "error",
          icon: "warning circle",
          title: "Remind invoice",
          time: 2000,
          description: <p>Reminding invoice was failed</p>,
        });
      });
  }

  onAction(id, action, pdf) {
    if (action == "print") window.location.href = pdf;
    if (action == "cancel") {
      this.setState({ isCanceling: true });
      const clientEmail = jsCookie.get('active_client_email');
      cancelInvoiceAsProfessional(clientEmail, id)
        .then((res) => {
          console.log(res);
          this.getInvoiceList();
          this.setState({ isCanceling: false });
          toast({
            type: "success",
            icon: "alarm",
            title: "Cancel invoice",
            time: 2000,
            description: <p>Invoice was canceled successfully</p>,
          });
        })
        .catch((err) => {
          console.log(err.response);
          toast({
            type: "error",
            icon: "warning circle",
            title: "Cancel invoice",
            time: 2000,
            description: <p>Canceling invoice was failed</p>,
          });
        });
    }
  }

  render() {
    const getHistory = (invoice, history) => {
      if (history.status == "finalized") return <>Invoice was finalized.</>;
      else if (history.status == "canceled") return <>Invoice was canceled.</>;
      else if (history.status == "sent")
        return <>Invoice was sent to {invoice.receiver_email}.</>;
      else if (history.status == "paid")
        return <>$ {currencyFormat(this.state.selectedData.amount_due)} payment succeeded.</>;
    };
    const getHistoryDate = (date) => {
      var d = new Date(date);
      var ampm = d.getHours() >= 12 ? "PM" : "AM";
      return moment(d).format("MMMM DD, YYYY, hh:mm") + " " + ampm;
    };
    return (
      <Segment>
        {(this.state.isReminding || this.state.isCanceling) && (
          <div className="all-opacity-pan" />
        )}
        {this.state.isReminding && (
          <Loader
            active
            size="large"
            content="Reminding invoice..."
            style={{ position: "fixed" }}
          />
        )}
        {this.state.isCanceling && (
          <Loader
            active
            size="large"
            content="Canceling invoice..."
            style={{ position: "fixed" }}
          />
        )}
        <SemanticToastContainer />
        <Grid className="Invoice--manage" columns={2} verticalAlign="middle">
          <Grid.Column floated="left" width={8}>
            <p className="Invoice--title">Invoice Details</p>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row style={{ float: "right" }}>
              <Button
                onClick={() => this.invoiceRemind(this.state.selectedId)}
                disabled={this.state.invoice.status !== 'open'}
              >
                Remind
              </Button>
              {/* <Button>Edit</Button>  */}
              <MoreAction
                text="More"
                onAction={(value) =>
                  this.onAction(
                    this.state.selectedId,
                    value,
                    this.state.invoice.invoice_pdf
                  )
                }
              />
              {/* <Button.Group style={{ float: "right" }}>
                <Button>More</Button>
                <Dropdown
                  className="button icon"
                  floating
                  options={options}
                  trigger={<></>}
                  onChange={(e, data) =>
                    this.onAction(
                      this.state.selectedId,
                      data.value,
                      this.state.invoice.invoice_pdf
                    )
                  }
                />
              </Button.Group> */}
            </Grid.Row>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16}>
            <Divider className="Invoice--divider" />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <Segment>
              <h3>History</h3>
              {this.state.invoice.invoiceHistories
                .reverse()
                .map((history, index) => (
                  <div key={index}>
                    <Divider className="Invoice--divider" />
                    <Grid columns="2" style={{ padding: "5px" }}>
                      <Grid.Column width={4}>
                        <Icon name="mail" />{" "}
                        {getHistoryDate(history.created_at)}
                      </Grid.Column>
                      <Grid.Column>
                        {getHistory(this.state.invoice, history)}
                      </Grid.Column>
                    </Grid>
                  </div>
                ))}
            </Segment>
          </Grid.Column>
        </Grid>
        {/* <Grid>
          <Grid.Column>
            <p style={{ color: "#f87083", fontSize: "17px" }}>
              <Icon name="plus" />
              Add memo to self
            </p>
          </Grid.Column>
        </Grid> */}
        <Grid>
          <Grid.Column>
            <Preview
              invoice={this.state.invoice}
              selectedData={this.state.selectedData}
              taxRates={this.state.taxRates}
              isLoading={this.state.isLoading}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

export default InvoiceDetail;
