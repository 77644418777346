import uuidv4 from 'uuid/v4';
import camelcaseKeys from 'camelcase-keys';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { ENDPOINT_V1 } from 'libraries/config';
import { getInstitutionByIdV2 } from 'libraries/api-v2/institution-service';
import { setupUserAccountV2, storePlaidItemV2 } from 'libraries/api-v2/customer-service';
import { updateAccountStatusV2 } from 'libraries/api-v2/accounts-service';
import { updateClassificationsV2 } from 'libraries/api-v2/transactions-service';

/** provisional mock data
 * Should be replaced when provided a working endpoint
 */
import mockedCategories from 'libraries/categories.json';

const apiName = ENDPOINT_V1;
const apiRequest = (method, url, params) => API[method](apiName, url, {
  ...params,
  response: true,
});

/** PUBLIC */

export const signup = (fullName, email, password, accountType) => Auth.signUp({
  username: email,
  password,
  attributes: {
    email,
    'custom:account_type': accountType,
    'custom:fullName': fullName,
    'custom:onboardingDone': 'False',
    'custom:accountStatus': 'True',
  },
});

export const sendConfirmEmail = email => Auth.resendSignUp(email);

export const sendPasswordRecoveryEmail = email => Auth.forgotPassword(email);

export const resetPassword = (usr, code, pwd) => Auth.forgotPasswordSubmit(usr, code, pwd);

export const logout = () => Auth.signOut({ global: true });

/** ONBOARDING */

export const setupUserAccount = setupUserAccountV2;

export const getBusinessTypes = (username, token, formName) => apiRequest('get', '/onboarding/getbusinesstypes', {
  queryStringParameters: { formName },
});

/** TRANSACTIONS */

export const updateCategories = updateClassificationsV2;

export const getTransactions = (username, token, startDate, endDate, offset = 0) => {
  const queryStringParameters = startDate && endDate ? { startDate, endDate, offset } : { offset };
  const myInit = { queryStringParameters };
  return apiRequest('get', '/transactions/get_transactions', myInit)
    .then(res => res.data.transactions)
};

export const markAsSeen = (username, token, transactions) => apiRequest('put', '/transactions/mark_as_seen', { body: { transactions } });

export const createTransaction = (username, token, information) => {
  const {
    transactionType, name, category, subcategory, amount, customDate, selectedTags, memo
  } = information;
  return apiRequest('post', '/transactions/create_transaction', {
    body: {
      transaction_type: transactionType.toLowerCase(),
      transaction_name: name,
      category,
      subcategory,
      amount,
      date: customDate,
      selectedTags,
      memo
    },
  });
};

export const editTransaction = (username, token, information) => {
  const {
    transactionID,
    customDate,
    name,
    transactionType,
    amount,
    category,
    subcategory,
    selectedTags,
    memo
  } = information;
  return apiRequest('post', '/transactions/edit_manual_transaction', {
    body: {
      transaction_id: transactionID,
      date: customDate,
      description: name,
      transaction_type: transactionType.toLowerCase(),
      amount,
      category,
      subcategory,
      selectedTags,
      memo
    },
  });
};

export const deleteTransaction = (username, token, transactionID) => apiRequest('post', '/transactions/delete_transaction', {
  body: { transaction_id: transactionID },
});

export const getProfitAndLoss = (username, token, startDate, endDate, tags=[]) => apiRequest('post', '/transactions/get_profit_and_loss', {
  body: {
    start_date: startDate,
    end_date: endDate,
    tags
  },
}).then((response) => {
  const transactions = response.data.profit_and_loss.transactions
    .map(transaction => ({
      ...transaction,
      items: transaction.items.map(item => ({ ...item, id: uuidv4() })),
    }));
  return {
    net_income: response.data.profit_and_loss.net_income,
    transactions,
  };
});

/** PLAID */

export const getInstitutionById = getInstitutionByIdV2;

export const storePlaidItem = storePlaidItemV2;

export const getKeys = () => apiRequest('get', '/plaid/get_keys');

export const updateAccountStatus = updateAccountStatusV2;

/**
 Endpoint: https://z9u7ryc3z6.execute-api.us-east-1.amazonaws.com/sandbox/plaid/force_login
 Note:     Not used in any feature. Used to test invalidation of plaid accounts.
 Use:      Body: { "item_id": "XXXXXX" }
           Corresponding to a bank item (bank account). This will require that the user logs in
           again in this account in order to revalidate it and receive new transactions for
           that account.
*/

export const getPublicToken = (username, token, itemId) => apiRequest('post', '/plaid/get_public_token', { body: { item_id: itemId } });

export const clearItemErrors = (username, token, itemId) => apiRequest('post', '/plaid/clear_item_error', { body: { item_id: itemId } });

/** DATA */
export const exportData = (username, token, transaction_ids = []) => apiRequest('post', '/data_manipulation/export_data', { body: { transaction_ids } });

export const exportActivityLog = () => apiRequest('get', '/data_manipulation/export_audit_trail');

export const exportPNLTransactions = (username, token, type, transactions) => apiRequest('post', '/data_manipulation/export_pnl_transactions', {
  body: {
    transactions_type: type,
    transaction_ids: transactions,
  },
});

export const exportPnlPdf = (username, password, startDate, endDate) => apiRequest('post', '/data_manipulation/export_profit_and_loss', {
  body: {
    start_date: startDate,
    end_date: endDate,
  },
}).then(res => res.data);

export const getCategories = formName => Promise.resolve(mockedCategories[formName] || []);

/** PROFILE */

export const removeInstitutions = id => apiRequest('del', '/plaid/unlink_institution', { body: { id } });

export const getInstitutions = () => apiRequest('get', '/plaid/get_customer_institutions')
  .then(res => res.data.institutions)
  .then(res => res.sort((a, b) => {
    const prev = a.institution_name.toLowerCase();
    const next = b.institution_name.toLowerCase();
    if (prev < next) return -1;
    if (prev > next) return 1;
    return 0;
  }))
  .then(res => res.map(inst => ({
    ...inst,
    id: uuidv4(),
    items: inst.items.map(item => ({
      ...item,
      accounts: item.accounts.map(account => ({ ...account, key: uuidv4() })),
    })),
  })))
  .then(res => camelcaseKeys(res, { deep: true }));

export const updateProfile = (username, token, body) => apiRequest('post', '/users/update_profile', { body }).then(res => res.data);

export const getProfile = () => apiRequest('get', '/users/get_profile').then(res => res.data);

export const deleteUserAccount = () => apiRequest('del', '/users/delete_user_account');

/* ********* */
/*  STRIPE   */
/* ********* */

export const getSubscription = () => apiRequest('get', '/stripe/get_subscription').then(res => res.data);

export const getStripePlans = () => apiRequest('get', '/stripe/get_plans')
  .then(res => res.data
    .filter(plan => (['Premium monthly', 'Premium annually'].includes(plan.name)))
    .sort((a, b) => {
      if (a.name < b.name) return 1;
      if (a.name > b.name) return -1;
      return 0;
    })
    .map(plan => ({ ...plan, amount: plan.amount, id: uuidv4() })));

export const cancelStripePlan = () => apiRequest('post', '/stripe/cancel_subscription').then(res => res.data);

export const getPaymentMethod = () => apiRequest('get', '/stripe/get_payment_method').then(res => res.data.card);
export const addPaymentMethod = tokenId => apiRequest('post', '/stripe/add_payment_method', {
  body: {
    card_token_id: tokenId,
  },
}).then(res => res.data);

export const changeSubscription = (planId, couponId) => apiRequest('post', '/stripe/change_subscription', {
  body: {
    plan_id: planId,
    coupon_id: couponId
  },
}).then(res => res.data);

export const validatePromotionCode = promoCode => apiRequest('post', '/stripe/validate_promotion_code', {
  body: {
    promoCode: promoCode,
  },
}).then(res => res.data);
