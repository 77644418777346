import React from 'react';
import {
  Grid, Form, Container, Button,
} from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Routes from 'routes';
import { updateRecoveryPincode } from 'store/actions/passwordRecovery';

export class PasswordRecoveryPinCode extends React.PureComponent {
  pincodeRef = React.createRef();

  componentDidMount() {
    this.pincodeRef.current.focus();
  }

  handleInputChange = ({ target }) => {
    this.props.updateRecoveryPincode(target.value);
  }

  onSubmit = () => {
    const { history } = this.props;
    history.push(Routes.PasswordRecoveryReset);
  }

  render() {
    const { verificationCode } = this.props;
    return (<>
      <Grid.Row>
        <p>Enter the verification code that was sent to the email address you entered.</p>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form onSubmit={this.onSubmit}>
            <Form.Field>
              <input
                type="text"
                id="recovery_pincode"
                ref={this.pincodeRef}
                placeholder="Verification code"
                value={verificationCode}
                onChange={this.handleInputChange}
                required
              />
            </Form.Field>
            <Container textAlign="center">
              <Button primary>Continue</Button>
            </Container>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Link to={Routes.Login}>Back to login</Link>
    </>);
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ passwordRecovery: { verificationCode } }) => ({
  verificationCode,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  updateRecoveryPincode,
}, dispatch);


const wrappedComponent = withRouter(PasswordRecoveryPinCode);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
