import {
  UPDATE_PNL_DATE_RANGE,
  TOGGLE_PNL_IS_SELECTING_DATE,
  SET_IS_TAX_FORM_VIEW,
  SET_PNL_DATA,
  CLEAR_PNL_DATA, SET_IS_MONTH_PNL_VIEW,
} from '../ActionTypes';

export const setPnlData = data => ({
  type: SET_PNL_DATA,
  payload: data,
});

export const updatePnlDateRange = date => ({
  type: UPDATE_PNL_DATE_RANGE,
  payload: date,
});

export const togglePnlIsSelectingDate = isSelectingDate => ({
  type: TOGGLE_PNL_IS_SELECTING_DATE,
  payload: isSelectingDate,
});

export const setIsTaxFormView = isTaxFormView => ({
  type: SET_IS_TAX_FORM_VIEW,
  payload: isTaxFormView,
});

export const clearPnlData = () => ({
  type: CLEAR_PNL_DATA,
});

export const setIsMonthPNLView = isMonthPNLView => ({
  type: SET_IS_MONTH_PNL_VIEW,
  payload: isMonthPNLView,
});