import React from 'react';
import { connect } from 'react-redux';
import Layout from 'components/containers/PrivateLayout';
import { Grid } from 'semantic-ui-react';
import {
  LinkedAccounts, Professional, ProfileDetails, DeactivateAccount,
} from 'components/containers/MyProfile';
import hasSubscription from 'store/selectors/subscription';

const MyProfilePage = ({ isSubscribed }) => (
  <Layout>
    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column>
          <ProfileDetails />
          {!isSubscribed && <DeactivateAccount />}
        </Grid.Column>
        <Grid.Column>
          <Professional />
          <LinkedAccounts />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>
);

const mapStateToProps = state => ({
  isSubscribed: hasSubscription(state),
});

export default connect(mapStateToProps, null)(MyProfilePage);
