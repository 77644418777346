import React from 'react';
import { Bar } from 'react-chartjs-2';
import "chartjs-plugin-labels";

const options = {
  plugins: {
    labels: {
      render: 'value',
      fontColor: 'black',
      precision: 2,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  }
};

class InvoiceStatistics extends React.Component {
  state = {
    data: null
  }

  componentDidMount() {
    const { openData, paidData, labels } = this.props.data;
    this.setState({
      data: {
        datasets: [{
          label: ' Unpaid Invoices($) ',
          data: openData,
          backgroundColor: 'rgba(48,176,224,0.4)',
          borderColor: 'rgb(48,176,224)',
        }, {
          label: ' Paid Invoices($) ',
          data: paidData,
          backgroundColor: 'rgba(72,210,160,0.4)',
          borderColor: 'rgb(72,210,160)',
        }],
        labels: labels
      }
    })
  }

  render() {
    return (
      <Bar data={this.state.data} options={options} />
    );
  }
}
export default InvoiceStatistics;