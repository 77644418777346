import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

// TODO: API Payloads should be standardized to only accept camelCased keys rather than
// snake_cased keys.

export const getBudgetingList = () => apiRequestV2('get', `${basePath}/budgeting/list`).then(res => res.data);

export const getBudgetingTransactions = (startDate, endDate) => apiRequestV2('post', `${basePath}/budgeting/transactions`, {
  body: {
    startDate,
    endDate,
  },
}).then(res => res.data);

export const updateBudgetingList = (data) => apiRequestV2('post', `${basePath}/budgeting/update`, {
  body: {
    data
  },
}).then(res => res.data);

export default {
  getBudgetingList,
  getBudgetingTransactions,
  updateBudgetingList
}