import React from "react";
import { Grid, Image, Icon } from "semantic-ui-react";
import './styles.scss';

const actions = [
  { key: "Remind", text: "Remind", value: "Remind" },
  { key: "Edit", text: "Edit", value: "Edit" },
  { key: "Copy", text: "Copy", value: "Copy" },
  { key: "RecordPayment", text: "Record Payment", value: "Record Payment" },
  { key: "Print", text: "Print", value: "Print" },
  { key: "Print/PDF", text: "Print/PDF", value: "Print/PDF" },
  { key: "Share link", text: "Share link", value: "Share link" },
  { key: "Cancel", text: "Cancel", value: "Cancel" },
];

class SelectImage extends React.Component {
  render() {
    return (
      <div
        style={{
          border: "2px solid #F48D9C",
          borderRadius: "5px",
          width: "300px",
          height: "100px",
        }}
      >
        <Image
          src={"https://react.semantic-ui.com/images/wireframe/image-square.png"}
        //   size="tiny"
          verticalAlign="middle"
          style={{
            width: "60px",
            height: "60px",
            margin: "20px",
          }}
        />{" "}
        <span className="company-logo" style={{color:'#F48D9C', fontSize:'17px'}}><Icon link name="plus" />Add Company Logo</span>
      </div>
    );
  }
}

export default SelectImage;
