import { Base64 } from 'js-base64';
import {
  SET_USER_LOGIN_STATE,
  SET_ONBOARDING_DONE,
  SET_USER_PROFILE,
  CLEAR_USER_LOGIN_STATE,
  SET_IS_LOGGING_OUT,
  SET_USER_SESSION,
} from '../ActionTypes';

const initialState = {
  userDidLogin: false,
  isAuthenticated: false, // Replaces userDidLogin
  token: '',
  username: '',
  userInfo: {},
  userProfile: {},
  isLoggingOut: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_SESSION: {
      return {
        ...state,
        isAuthenticated: true,
        ...payload,
      };
    }
    case SET_USER_LOGIN_STATE: {
      const encodedStr = payload.token.split(' ')[1];
      const [username, token] = Base64.decode(encodedStr).split(':');
      const urlDecodedUsername = decodeURIComponent(username);
      return {
        ...state,
        userDidLogin: true,
        username: urlDecodedUsername,
        token,
        userInfo: payload.data.user_info,
        userProfile: payload.data.user_profile,
      };
    }
    case SET_ONBOARDING_DONE: {
      return { ...state, userInfo: { ...state.userInfo, onboarding: 'True' } };
    }
    case CLEAR_USER_LOGIN_STATE: {
      return initialState;
    }
    case SET_USER_PROFILE: {
      const userProfile = { ...state.userProfile, ...payload };
      return { ...state, userProfile };
    }
    case SET_IS_LOGGING_OUT:
      return {
        ...state,
        isLoggingOut: payload,
      };
    default:
      return state;
  }
};

export default reducer;
