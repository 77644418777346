import find from 'lodash.find';

export const filterSelectedTransactions = (allTransactions, selected) => {
  const filtered = [];
  for (const index in selected) {
    if (index) {
      const transactionID = selected[index];
      const match = find(allTransactions, { transaction_id: transactionID });
      filtered.push(match);
    }
  }
  return filtered;
};

export const areNotBeingSeen = (transactions) => {
  let flag = false;
  transactions.forEach((item) => {
    if (item.seen !== 'checked') {
      flag = true;
    }
  });
  return flag;
};

export default filterSelectedTransactions;
