import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/ple-api';

export const postPlaidLinkEvent = event => apiRequestV2('post', `${basePath}/plaid-link-events`, {
  body: event,
});

export const createLinkToken = () => apiRequestV2('get', `${basePath}/plaid-link-events/create_link_token`);

export default {
  postPlaidLinkEvent,
  createLinkToken
};
