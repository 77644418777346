import { createSelector } from 'reselect';

const getStatus = state => state.session.userProfile.subscriptionStatus;
const getMessage = state => state.session.userProfile.message;

const hasSubscription = createSelector(
  [getStatus, getMessage],
  (status, message) => status !== 'canceled',
);

export default hasSubscription;
