import React, { Component } from 'react';
import {
  Grid, Segment, Image, Button, Container, Transition,
} from 'semantic-ui-react';
import './styles.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import Routes from 'routes';
import {
  setForm,
  setBusinessType,
  setBusinessTypeDetails,
  clearOnboarding,
} from 'store/actions/onboarding';
import FormToolTip from 'components/presentationals/FormToolTip';
import { clearUserDidLogin, setIsLoggingOut } from 'store/actions/session';
import { clearTransactions } from 'store/actions/transactions';
import { clearSubscription } from 'store/actions/subscription';
import { logout } from 'libraries/api-service';
import SchedulesDescriptions from './descriptions';

export class OnboardingReportForm extends Component {
  constructor(props) {
    super(props);
    const { selectedForm } = this.props;
    const selectedFormIdx = selectedForm
      ? SchedulesDescriptions.findIndex(item => item.title === selectedForm)
      : 0;
    this.state = {
      selectedFormIdx,
      showReadMore: false,
    };
  }

  componentDidMount() {
    const { selectedForm } = this.props;
    !selectedForm && this.props.setForm(SchedulesDescriptions[0].title);
  }

  handleClick = (selectedFormIdx) => {
    this.setState({ selectedFormIdx, showReadMore: false }, () => {
      this.props.setForm(SchedulesDescriptions[selectedFormIdx].title);
      this.props.setBusinessType({ name: '', code: undefined });
      this.props.setBusinessTypeDetails('');
    });
  };

  handleOnExit = () => {
    this.props.setIsLoggingOut(true);
    logout(this.props.token, this.props.username)
      .then(() => {
        this.props.clearTransactions();
        this.props.clearUserDidLogin();
        this.props.clearOnboarding();
        this.props.setIsLoggingOut(false);
        this.props.clearSubscription();
        this.props.history.push(Routes.Index);
      }).catch(() => {
        // To Do handle error
      });
  };

  toggleReadMore = () => this.setState(prevState => ({ showReadMore: !prevState.showReadMore }));

  render() {
    const { selectedFormIdx, showReadMore } = this.state;
    const selectedForm = SchedulesDescriptions[selectedFormIdx];
    return (
      <Grid container columns="equal">
        <Grid.Row centered>
          <FormToolTip />
        </Grid.Row>
        <Grid.Row centered>
          <b>{selectedForm.title}</b>
        </Grid.Row>
        <Grid.Row centered>
          <Container text className="Report-Form__Text-Container">
            <p>{selectedForm.description}</p>
            <Transition.Group animation="slide down">
              {
                showReadMore && (
                  <div>
                    <p>{selectedForm.fullDescription}</p>
                    <p>For further details, you can review:</p>
                    <ol className="Report-Form__Sourcelist">
                      {
                        selectedForm.sources.map(
                          (source, idx) => (
                            <li key={idx} className="Report-Form__Sourcelist-item">
                              <a rel="noopener noreferrer" target="_blank" href={source}>{source}</a>
                            </li>
                          ),
                        )
                      }
                    </ol>
                  </div>
                )
              }
            </Transition.Group>
            <a
              tabIndex={0}
              role="button"
              onClick={this.toggleReadMore}
              onKeyPress={this.toggleReadMore}
              className="Report-Form__Segment-show-more"
            >
              {showReadMore ? 'Read less -' : 'Read more +'}
            </a>
          </Container>
        </Grid.Row>
        <Grid.Row stretched>
          {SchedulesDescriptions.map(({
            id, title, nickname, icon,
          }, idx) => (
            <Grid.Column
              key={`form-item-${id}`}
              textAlign="center"
              className={cs('Report-Form__Segment-Container', {
                selected: selectedFormIdx === idx,
              })}
            >
              <Segment
                circular
                className={cs('Report-Form__Segment--segment', {
                  'Report-Form__Segment--selected': selectedFormIdx === idx,
                })}
              >
                <Image
                  src={icon}
                  circular
                  onClick={e => this.handleClick(idx)}
                  onKeyPress={e => this.handleClick(idx)}
                  className={cs('Report-Form__Segment--image', {
                    'Report-Form__Segment--image--selected': selectedFormIdx === idx,
                  })}
                />
              </Segment>
              <span>{title}</span>
              <div>{nickname}</div>
            </Grid.Column>
          ))}
        </Grid.Row>
        <Grid.Row centered columns={2}>
          <Grid.Column textAlign="right">
            <Button
              size="big"
              onClick={this.handleOnExit}
              className="Report-Form__Exit-button"
            >
            EXIT
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button
              as={Link}
              to={Routes.OnboardingBusinessType}
              primary
              size="big"
              disabled={this.state.selectedForm === ''}
            >
            CONTINUE
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ session: { token, username }, onboarding: { selectedForm } }) => ({
  token,
  username,
  selectedForm,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearUserDidLogin,
  setForm,
  setBusinessType,
  setBusinessTypeDetails,
  clearOnboarding,
  clearTransactions,
  setIsLoggingOut,
  clearSubscription,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingReportForm);
