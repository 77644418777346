import {
  SET_CURRENT_SUBSCRIPTION,
  SET_FUTURE_SUBSCRIPTION,
  SET_HAS_PAYMENT_METHOD,
  SET_IS_MODAL_OPEN,
  SET_STRIPE_PLANS,
  SET_CANCEL_AT_PERIOD_END,
  CLEAR_SUBSCRIPTION,
} from '../ActionTypes';

const getSubscriptionStruct = subscription => ({
  id: subscription.plan.id,
  status: subscription.plan.active,
  interval: subscription.plan.interval,
  nickname: subscription.plan.nickname,
  endDate: subscription.current_period_end,
  startDate: subscription.current_period_start,
  benefits: subscription.plan.metadata,
});

export const setCurrentSubscription = subscription => ({
  type: SET_CURRENT_SUBSCRIPTION,
  payload: getSubscriptionStruct(subscription),
});

export const setFutureSubscription = (subscriptionName, subscriptionId) => ({
  type: SET_FUTURE_SUBSCRIPTION,
  payload: {
    planId: subscriptionId,
    name: subscriptionName,
  },
});

export const setHasPaymentMethod = hasPaymentMethod => ({
  type: SET_HAS_PAYMENT_METHOD,
  payload: hasPaymentMethod,
});

export const setIsPaymentModalOpen = isOpen => ({
  type: SET_IS_MODAL_OPEN,
  payload: isOpen,
});

export const setStripePlans = plans => ({
  type: SET_STRIPE_PLANS,
  payload: plans,
});

export const setCancelSubscription = cancelAtPeriodEnd => ({
  type: SET_CANCEL_AT_PERIOD_END,
  payload: cancelAtPeriodEnd,
});

export const clearSubscription = () => ({
  type: CLEAR_SUBSCRIPTION,
});
