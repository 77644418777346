import React from 'react';
import BudgetingSummary from "components/containers/Budgeting";

const Budgeting = () => (
  <>
    <BudgetingSummary />
  </>
);

export default Budgeting;
