import React from 'react';
import { Bar } from 'react-chartjs-2';
import "chartjs-plugin-labels";

class BudgetingChart extends React.Component {

  render() {
    const {itemLists, modal} = this.props;
    const labels = itemLists.map(item => item.category_name);
    const dataset = itemLists.map(item => Math.abs(item.actual).toFixed(2));

    const options = {
      plugins: {
        labels: {
          render: 'value',
          fontColor: 'black',
          precision: 2,
        },
      },
      scales: {
        xAxes: [{
          ticks: {
            display: !!modal
          }
        }],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      }
    };

    const data = {
      labels: labels,
      datasets: [
        {
          label: '',
          data: dataset,
          backgroundColor: 'rgba(48,176,224,0.4)',
          borderColor: 'rgb(48,176,224)',
          borderWidth: 1,
        },
      ],
    }
    return (
      <Bar data={data} options={options} />
    );
  }
}
export default BudgetingChart;