import React from 'react';
import BannerImage from 'images/banner.png';
import Button from 'components/ui/Button';
import { Grid } from 'semantic-ui-react';
import './styles.scss';

const Banner = () => (
  <div className="Banner">
    <div className="Banner--jumbotron">
      <Grid centered reversed="computer">
        <Grid.Column mobile={16} computer={7}>
          <div className="art">
            <img src={BannerImage} className="Banner--art" alt="" />
          </div>
        </Grid.Column>
        <Grid.Column centered mobile={16} computer={7}>
          <div className="Banner--content">
            <span className="Banner--main-title">SIMPLE BOOKKEEPING FOR SMALL BUSINESSES</span>
            <p className="Banner--intro-graph">
              {`We have simplified bookkeeping for you! Synkbooks automatically
            manages all your complicated bookkeeping tasks.Tax attorneys and
            other tax professionals have helped us create a new, more convenient
            generation of bookeeping solutions. So get tax savvy with
            SynkBooks!`}
            </p>
            <a href="/signup">
              <Button text="SIGN UP" type="outline" size="bg" />
            </a>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  </div>
);

export default Banner;
