import React from 'react';
import {
  Segment, Header, Grid, Icon, Button,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import Routes from 'routes';
import { exportActivityLog } from 'libraries/api-service';
import { exportXLSX } from 'libraries/utils';
import withNotifications from 'components/hocs/WithNotifications';
import {
  setIsTaxFormView,
} from 'store/actions/profitAndLoss';
import './styles.scss';

export class Reports extends React.Component {
  state = {
    isDownloading: false,
  }

  handleActivityLog = () => {
    this.setState({ isDownloading: true });
    exportActivityLog(this.props.username, this.props.token)
      .then((res) => {
        if (res.status === 204) {
          this.props.pushNotification('EMPTY_ACT-LOG_DOWNLOAD', 'No activity log records',
            'Your activity log is empty. Try to download when you have records.', 'error', 5000);
        } else {
          const binaryDataFlow = res.data;
          exportXLSX(binaryDataFlow, 'SynkBooks-activityLog.xlsx');
        }
        this.setState({ isDownloading: false });
      })
      .catch(() => {
        this.setState({ isDownloading: false });
        this.props.pushNotification('FAIL_ACT-LOG_DOWNLOAD', 'Download failed',
          'An error ocurred while trying to download your activities log. Please try again.', 'error', 5000);
      });
  }

  onTaxFormClick = () => {
    this.props.setIsTaxFormView(true);
  }

  render() {
    return (
      <div className="Reports-Segments">
        <Header as="h1" className="Reports__Title">Select a report type</Header>
        <Grid stackable columns="equal" centered>
          <Grid.Column textAlign="center">
            <Segment
              as={Link}
              to={Routes.ReportsProfitAndLoss}
              className="Reports__Segment"
            >
              <Icon name="chart line" size="massive" />
              <Button primary fluid>Profit and Loss</Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              as={Link}
              to={Routes.ReportsProfitAndLoss}
              onClick={this.onTaxFormClick}
              className="Reports__Segment"
            >
              <Icon name="copy outline" size="massive" />
              <Button primary fluid>Tax Form</Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              onClick={this.handleActivityLog}
              className="Reports__Segment Reports__Segment--Audit-Trail"
              id="Reports__Activity-Trail-Segment"
            >
              <Icon name="id badge outline" size="massive" />
              <Button
                primary
                icon
                fluid
                labelPosition="right"
                loading={this.state.isDownloading}
                size="medium"
              >
                Download Activity Log
                <Icon name="download" />
              </Button>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment
              as={Link}
              to={Routes.DashboardReportsBalance}
              className="Reports__Segment Reports__Segment--Audit-Trail"
              id="Reports__Activity-Trail-Segment"
            >
              <Icon name="balance" size="massive" />
              <Button
                primary
                fluid
              >
                Balance
              </Button>
            </Segment>
          </Grid.Column>
        </Grid>
        <Grid stackable columns="equal" centered>
          <Grid.Column textAlign="center">
            <Segment
              as={Link}
              to={Routes.DashboardReportsBudgeting}
              className="Reports__Segment"
            >
              <Icon name="chart bar" size="massive" />
              <Button primary fluid>Budgeting App</Button>
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Segment
              as={Link}
              to={Routes.DashboardReportsBalanceSheet}
              className="Reports__Segment"
            >
              <Icon name="calendar alternate outline" size="massive" />
              <Button primary fluid>Balance Sheet</Button>
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Segment
              as={Link}
              to={Routes.DashboardCustomDashboard}
              className="Reports__Segment"
            >
              <Icon name="low vision" size="massive" />
              <Button primary fluid>Custom Dashboard</Button>
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Segment
              as={Link}
              to={Routes.DashboardCustomProfitAndLoss}
              className="Reports__Segment"
            >
              <Icon name="tags" size="massive" />
              <Button primary fluid>Custom Profit And Loss</Button>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  session: { token, username },
}) => ({
  token,
  username,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  setIsTaxFormView,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withNotifications(withRouter(Reports)));
