import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

export const exportActivityLogAsProfessional = (username, token, clientEmail) => apiRequestV2('post', `${basePath}/audit-trails/export-as-xlsx/as-professional`, {
  body: {
    clientEmail,
  },
});

export default {
  exportActivityLogAsProfessional,
};
