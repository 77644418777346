import {
  SET_PLAID_PUBLIC_KEY,
  SET_PLAID_ENV,
  CLEAR_PLAID_ENV,
} from '../ActionTypes';

export const setPlaidPublicKey = res => ({
  type: SET_PLAID_PUBLIC_KEY,
  payload: {
    res,
  },
});

export const setPlaidEnvironment = res => ({
  type: SET_PLAID_ENV,
  payload: {
    res,
  },
});

export const clearPlaidEnvironment = () => ({
  type: CLEAR_PLAID_ENV,
});
