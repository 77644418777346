import camelcaseKeys from 'camelcase-keys';
import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

export const updateStatusOfAccounts = accountStatusList => apiRequestV2('patch',
  `${basePath}/institutions/accounts/status`, {
    body: accountStatusList,
  }).then(res => camelcaseKeys(res.data));

/**
 * V2 wrapper for backwards compatibility
 */
export const updateAccountStatusV2 = (toTrue, toFalse) => {
  const accountStatusList = [
    ...toTrue.map(id => ({ account_id: id, included: true })),
    ...toFalse.map(id => ({ account_id: id, included: false })),
  ];

  // V1 expects a message property wrapped in a data object
  return updateStatusOfAccounts(accountStatusList).then(institution => ({
    data: { message: 'Successfully updated account information.' },
  }));
};

export default { updateAccountStatusV2 };
