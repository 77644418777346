import moment from 'moment';
import { splitDateRangeInput } from 'libraries/utils';

export const FILTERS = {
  TABS: {
    ALL_TRANSACTIONS: 'ALL_TRANSACTIONS',
    INCOME: 'INCOME',
    EXPENSE: 'EXPENSE',
  },
  AMOUNT: {
    MIN: 0,
    MAX: 0,
  },
  MONTH: -2,
  CUSTOM_DATE: '',
  SEARCH: '',
  ORDER: {
    DIRECTION: 'descending',
    COLUMN: 'date',
  },
  CATEGORIES: [],
  ACCOUNTNAME: [],
};

export const YEAR = 'YEAR';
export const CURRENT_MONTH = 'CURRENT_MONTH';
export const PREVIOUS_MONTH = 'PREVIOUS_MONTH';
export const Q1 = 'Q1';
export const Q2 = 'Q2';
export const Q3 = 'Q3';
export const Q4 = 'Q4';
export const CUSTOM = 'CUSTOM';

export const TRANSACTIONS_DATE_FORMAT = 'YYYY-MM-DD';

export const MAX_ITEMS_PER_PAGE = 20;

export const filterIncomes = transactions => transactions
  .filter(transaction => (transaction.amount < 0));

export const filterExpenses = transactions => transactions
  .filter(transaction => (transaction.amount > 0));

const filterByAmount = (amountRange, transactions) => {
  if (!amountRange.MIN && !amountRange.MAX) return transactions;
  return transactions
    .filter((transaction) => {
      const absoluteAmount = Math.abs(transaction.amount);
      if (amountRange.MAX === 0 || amountRange.MAX === '') {
        return absoluteAmount >= amountRange.MIN;
      }
      return absoluteAmount >= amountRange.MIN && absoluteAmount <= amountRange.MAX;
    });
};

export const filterByPage = (currentPage, transactions) => {
  const lastTransaction = currentPage * MAX_ITEMS_PER_PAGE;
  const firstTransaction = lastTransaction - MAX_ITEMS_PER_PAGE;
  return transactions.slice(firstTransaction, lastTransaction);
};

export const filterByMonth = (month, transactions, year = moment().year()) => {
  const firstDayOfMonth = moment([year, month]).startOf('month');
  const lastDayOfMonth = moment([year, month]).endOf('month');

  return transactions
    .filter((transaction) => {
      const date = moment(transaction.date, TRANSACTIONS_DATE_FORMAT, true);
      return date
        .isBetween(firstDayOfMonth, lastDayOfMonth)
        || date.isSame(firstDayOfMonth)
        || date.isSame(lastDayOfMonth);
    });
};

export const filterBySearch = (search, transactions) => {
  if (search === '') return transactions;
  return transactions
    .filter(transaction => transaction.description.toLowerCase()
      .includes(search.toLowerCase()) || (transaction.memo && transaction.memo.toLowerCase()
      .includes(search.toLocaleString()))
    );
};

export const filterByCategories = (categories, transactions) => {
  if (categories.length === 0) return transactions;
  return transactions.filter(transaction => categories.includes(transaction.category));
};

export const filterBy = (filters, transactions) => {
  let filtered = [...transactions];
  if (filters.TAB) {
    filtered = filterByTab(filters.TAB, filtered);
  }
  if (filters.AMOUNT) {
    filtered = filterByAmount(filters.AMOUNT, filtered);
  }
  if (filters.IS_SELECTING_CUSTOM_DATE) {
    const [startDate, endDate] = splitDateRangeInput(filters.CUSTOM_DATE);
    if (startDate && endDate && startDate.isValid && endDate.isValid()) {
      filtered = filterByCustomDateRange(startDate, endDate, filtered);
    }
  } else if(filters.MONTH === -2) { // year to date
    const yearStart = moment().startOf('year').format('MM/DD/YYYY');
    const today = moment().format('MM/DD/YYYY');
    const [startDate, endDate] = splitDateRangeInput(`${yearStart} - ${today}`);
    if (startDate && endDate && startDate.isValid && endDate.isValid()) {
      filtered = filterByCustomDateRange(startDate, endDate, filtered);
    }
  } else {
    const isValidMonth = filters.MONTH >= 0 && filters.MONTH <= 12;
    if (isValidMonth) {
      filtered = filterByMonth(filters.MONTH, filtered);
    }
  }
  if (filters.SEARCH) {
    filtered = filterBySearch(filters.SEARCH, filtered);
  }
  if (filters.ORDER && filters.ORDER.DIRECTION && filters.ORDER.COLUMN) {
    filtered = orderBy(filters.ORDER, filtered);
  }
  if (filters.CATEGORIES) {
    filtered = filterByCategories(filters.CATEGORIES, filtered);
  }
  if (filters.ACCOUNTNAME) {
    filtered = !filters.ACCOUNTNAME.length
      ? filtered : filterByAccountName(filters.ACCOUNTNAME, filtered);
  }

  if (filters.TAG) {
    filtered = !filters.TAG.length
      ? filtered : filterByTag(filters.TAG, filtered);
  }
  return filtered;
};

/**
 * - Ordering defaults to date.
 * - If any other column is selected:
 *    - we should order them by the selected column AND date.
 *  i.e. transactions name = description.
 *    02/01/2019  Uber
 *    01/30/2019  Uber
 *    01/30/2019  Food
 *    01/30/2019  Food
*/
const orderCriteria = (transaction1, transaction2, column, direction) => {
  const isT1New = transaction1.seen.match('initial|new');
  const isT2New = transaction2.seen.match('initial|new');
  const isDescending = direction === 'descending';
  if ((isT1New && isT2New) || (!isT1New && !isT2New)) {
    const fixedColumn = column.match('income|expense') ? 'amount' : column;

    if (fixedColumn === 'amount') {
      // is income or expense, they have their own criteria
      // since they come in the same object property.
      const res = amountOrderCriteria(transaction1, transaction2, column, direction);
      // if undefined it means they have the same value
      // its order must be defined by DATE
      if (res !== undefined) return res;
    } else {
      // all other columns (i.e date, transactions, category) are resolved by charcode
      if (transaction1[fixedColumn] < transaction2[fixedColumn]) return 1;
      if (transaction1[fixedColumn] > transaction2[fixedColumn]) return -1;
    }

    // for equal values we order by date.
    if (column !== 'date') {
      if (transaction1.date < transaction2.date) return isDescending ? 1 : -1;
      if (transaction1.date > transaction2.date) return isDescending ? -1 : 1;
    }
  }
  // keep unseen transactions at top, they are ordered by the same comparisons as above.
  if (isT1New) return isDescending ? -1 : 1;
  if (isT2New) return isDescending ? 1 : -1;

  // t1 and t2 have same date and column value.
  return 0;
};

const amountOrderCriteria = (transaction1, transaction2, column, direction) => {
  const isDescending = direction === 'descending';
  const t1Amount = Number(transaction1.amount);
  const t2Amount = Number(transaction2.amount);
  if (column === 'income') {
    if (t1Amount < 0 && t2Amount < 0) {
      if (t1Amount < t2Amount) return -1;
      if (t1Amount > t2Amount) return 1;
    }
    if (t1Amount < 0 && t2Amount > 0) return isDescending ? -1 : 1;
    if (t1Amount > 0 && t2Amount < 0) return isDescending ? 1 : -1;
    if (t1Amount > 0 && t2Amount > 0) return 0;
  }
  if (column === 'expense') {
    if (t1Amount > 0 && t2Amount > 0) {
      if (t1Amount < t2Amount) return 1;
      if (t1Amount > t2Amount) return -1;
    }
    if (t1Amount < 0 && t2Amount > 0) return isDescending ? 1 : -1;
    if (t1Amount > 0 && t2Amount < 0) return isDescending ? -1 : 1;
    if (t1Amount < 0 && t2Amount < 0) return 0;
  }
  // they have same value. Must be resolved by another criteria.
  return undefined;
};

export const orderBy = (orderObj, transactions) => {
  const column = orderObj.COLUMN;
  const direction = orderObj.DIRECTION;

  const criteria = direction === 'descending' ? (t1, t2) => orderCriteria(t1, t2, column, direction)
    : (t1, t2) => orderCriteria(t2, t1, column, direction);
  return transactions.sort(criteria);
};

export const filterByTab = (tab, transactions) => {
  switch (tab) {
    case FILTERS.TABS.INCOME:
      return filterIncomes(transactions);
    case FILTERS.TABS.EXPENSE:
      return filterExpenses(transactions);
    default:
      return transactions;
  }
};

export const filterByAccountName = (accountNames, transactions) => transactions.filter(
  ({ institution, mask }) => accountNames.includes(`${institution} ${mask}`),
);

export const filterByTag = (selected, transactions) => transactions.filter(
  ({ tags }) => selected.some(element => tags && tags.split('#').indexOf(element) > -1),
);

export const filterByCustomDateRange = (startDate, endDate, transactions) => (
  transactions.filter((transaction) => {
    const date = moment(transaction.date, TRANSACTIONS_DATE_FORMAT, true);
    return date.isBetween(startDate, endDate)
    || date.isSame(startDate)
    || date.isSame(endDate);
  })
);
