import React from 'react';
import Routes from 'routes';
import { Route, Switch } from 'react-router-dom';
import Reports from 'components/containers/Reports';
import ProfitAndLoss from './ProfitAndLoss';
import Balance from "./Balance";
import Budgeting from "./Budgeting";
import BalanceSheet from "./BalanceSheet";
import CustomDashboard from "./CustomDashboard";
import CustomProfitAndLoss from "screens/Dashboard/Reports/CustomProfitAndLoss";

const ReportsScreen = () => (
  <Switch>
    <Route exact path={Routes.DashboardReports} component={Reports} />
    <Route exact path={Routes.ReportsProfitAndLoss} component={ProfitAndLoss} />
    <Route exact path={Routes.DashboardReportsBalance} component={Balance} />
    <Route exact path={Routes.DashboardReportsBudgeting} component={Budgeting} />
    <Route exact path={Routes.DashboardReportsBalanceSheet} component={BalanceSheet} />
    <Route exact path={Routes.DashboardCustomDashboard} component={CustomDashboard} />
    <Route exact path={Routes.DashboardCustomProfitAndLoss} component={CustomProfitAndLoss} />
  </Switch>
);

export default ReportsScreen;
