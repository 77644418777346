import React from 'react';
import './styles.scss';

const VideoSection = () => (
  <div className="HowItWorks" id="HowItWorks">
    <div>
      <h3 className="HowItWorks--title">
        {'How it '}
        <b>Works</b>
      </h3>
    </div>
    <div className="HowItWorks--video-section">
      <div className="HowItWorks--video-section--wrapper">
        <div className="embed-responsive embed-responsive-16by9  bg-white rounded">
          <iframe
            title="youtube"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/1V-lh9eMtLg?modestbranding=1;rel=0;"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  </div>
);

export default VideoSection;
