import React from 'react';
import {
  Grid, Form, Button, Message,
} from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Routes from 'routes';
import {
  isValidPassword, FORM_ERROR_CODES,
} from 'libraries/formValidator';
import WithNotifications from 'components/hocs/WithNotifications';
import { resetPassword } from 'libraries/api-service';

export class PasswordRecoveryResetPassword extends React.PureComponent {
  state = {
    password: '',
    passwordConfirmation: '',
    errors: [],
    isLoading: false,
  }

  passwordRef = React.createRef();

  componentDidMount() {
    this.passwordRef.current.focus();
  }

  handleInputChange = ({ target }) => {
    this.setState({
      [target.id]: target.value,
    });
  }

  validPasswords = () => (this.state.password === this.state.passwordConfirmation)

  onSubmit = () => {
    this.setState({ errors: [] });
    const { email, verificationCode } = this.props;
    const { password } = this.state;
    this.hasValidFields() && resetPassword(email, verificationCode, password)
      .then(() => {
        this.setState({ isLoading: true });
        const { history, pushNotification } = this.props;
        pushNotification('NOTIFICATION_PASSWORD_RESET', 'Password reset',
          'Your password has been reset successfully.', 'success', 10000);
        history.push(Routes.Login);
      })
      .catch(() => {
        this.addFormError(FORM_ERROR_CODES.INVALID_VERIFICATION_CODE.id);
      });
  }

  hasValidFields = () => {
    let isValid = true;
    if (!this.validPasswords()) {
      isValid = false;
      this.addFormError(FORM_ERROR_CODES.PASSWORD_MISMATCH.id);
    }
    if (!isValidPassword(this.state.password)
      || !isValidPassword(this.state.passwordConfirmation)) {
      isValid = false;
      this.addFormError(FORM_ERROR_CODES.PASSWORD_INVALID_LENGTH.id);
    }
    return isValid;
  }

  addFormError = (errorCode) => {
    this.setState(prevState => ({
      errors: [
        ...prevState.errors,
        FORM_ERROR_CODES[errorCode].content,
      ],
    }));
  }

  render() {
    const {
      isLoading, password, passwordConfirmation, errors,
    } = this.state;
    return (<>
      <Grid.Row>
        <p>Please enter your desired new password.</p>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form
            onSubmit={this.onSubmit}
            loading={isLoading}
          >
            <Form.Field>
              <input
                type="password"
                id="password"
                ref={this.passwordRef}
                placeholder="New Password"
                value={password}
                onChange={this.handleInputChange}
                required
              />
            </Form.Field>
            <Form.Field>
              <input
                type="password"
                id="passwordConfirmation"
                placeholder="Confirm New Password"
                value={passwordConfirmation}
                onChange={this.handleInputChange}
                required
              />
            </Form.Field>
            {
              errors.length > 0
              && (
              <Message
                negative
                header="It looks like you have some errors"
                list={errors}
              />)
            }
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered columns={2}>
        <Grid.Column textAlign="right">
          <Button
            as={Link}
            to={Routes.PasswordRecoveryPinCode}
          >
          GO BACK
          </Button>
        </Grid.Column>
        <Grid.Column>
          <Button
            primary
            onClick={this.onSubmit}
          >
            SAVE
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Link to={Routes.Login}>Back to login</Link>
    </>);
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ passwordRecovery: { email, verificationCode } }) => ({
  email,
  verificationCode,
});

const wrappedComponent = withRouter(WithNotifications(PasswordRecoveryResetPassword));
export default connect(mapStateToProps, null)(wrappedComponent);
