import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

// TODO: API Payloads should be standardized to only accept camelCased keys rather than
// snake_cased keys.

export const getBalance = (startDate, endDate) => apiRequestV2('post', `${basePath}/balances/balance`, {
  body: {
    startDate,
    endDate,
  },
});

export const updateOpeningBalance = (data) => apiRequestV2('post', `${basePath}/balances/update`, {
  body: {
    data
  },
});

export const getBalanceV2 = (clientEmail, startDate, endDate) => apiRequestV2('post', `${basePath}/balances/balance/as-professional`, {
  body: {
    clientEmail,
    startDate,
    endDate,
  },
});

export const updateOpeningBalanceV2 = (clientEmail, data) => apiRequestV2('post', `${basePath}/balances/update/as-professional`, {
  body: {
    clientEmail,
    data
  },
});