import {
  SET_CLIENTS,
  CLEAR_CLIENTS,
} from '../ActionTypes';

const initialState = {
  active: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CLIENTS: {
      const newState = { ...state };
      newState.active = payload.res;
      return newState;
    }
    case CLEAR_CLIENTS: {
      const newState = { ...state };
      newState.active = [];
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
