import {COPY_INVOICE, DETAIL_INVOICE,} from '../ActionTypes';

const initialState = {
  editableStatus: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COPY_INVOICE: {
      const newState = { ...state };
      newState.editableStatus = payload.res;
      return newState;
    }
    case DETAIL_INVOICE: {
      const newState = { ...state };
      newState.editableStatus = '';
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
