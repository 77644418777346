import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import './styles.scss';
import TranTypeLabelsSection from '../TranTypeLabelsSection';

const getLabels = props => (
  <TranTypeLabelsSection
    transactionType={props.transactionType}
    handleLabelStatus={props.handleLabelStatus}
  />
);

const MoreInfoSection = props => (
  <Grid.Row>
    <Grid.Column width={2} />
    <Grid.Column width={12}>
      { props.editionMode && (
        <Header
          as="h4"
          textAlign="center"
          className="ECTransaction__MoreInfo-Section"
          onClick={props.showEditInfo}
        >
          { props.showMoreInfo ? 'Less' : 'More +' }
        </Header>
      )}
      <br />
      <>
        { props.showMoreInfo ? getLabels(props) : ''}
        { props.showMoreInfo && (props.getExtraTransactionInfo()) }
      </>
    </Grid.Column>
    <Grid.Column width={2} />
  </Grid.Row>

);

export default MoreInfoSection;
