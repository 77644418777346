import React from 'react';
import {
  Segment, List,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import PnlHeader from '../Header';

import BalanceTable from '../BalanceTable';
import './styles.scss';

export const ProfitAndLossList = (props) => {
  const renderLists = data => (
    data.map(group => (
      <List key={group.group_name} className="Profit-And-Loss__Group">
        <List.Item>
          <List.Header className="Profit-And-Loss__Group-Title">
            {group.group_name}
          </List.Header>
          <List.Content>
            <List.List className="Profit-And-Loss__List Profit-And-Loss__List--padded">
              {
                group.items && group.items.map((category, index) => renderItem(category,
                  index === 0))
              }
              { group.group_name === 'Income' && renderGrossIncomes(group) }
            </List.List>
          </List.Content>
        </List.Item>
      </List>
    ))
  );

  const renderGrossIncomes = item => (
    <>
      <List.Item>
        <div className="Profit-And-Loss__divider" />
        <List.Header className="Profit-And-Loss__List-Item">
          <div className="Profit-And-Loss__Category-Title">Gross profit</div>
          <div className="Profit-And-Loss__Category-Amount Profit-And-Loss__Item-Amount--asPrice">
            { toPrice(-1 * (item.gross_profit || 0), false) }
          </div>
        </List.Header>
      </List.Item>
      <List.Item>
        <div className="Profit-And-Loss__divider" />
        <List.Header className="Profit-And-Loss__List-Item">
          <div className="Profit-And-Loss__Category-Title">
            {(props.userProfile.form === 'Schedule C' ? 'Gross Income' : 'Total Income')}
          </div>
          <div className="Profit-And-Loss__Category-Amount Profit-And-Loss__Item-Amount--asPrice">
            { toPrice(-1 * (item.gross_income || 0), false) }
          </div>
        </List.Header>
      </List.Item>
    </>
  );

  const onCategoryClick = (event, item) => {
    let transactions = item.transactions || [];
    const subcategories = item.cogs_categories || item.other_categories;
    if (subcategories) {
      subcategories.forEach((cat) => {
        transactions = [...transactions, ...cat.transactions];
      });
    }
    const type = Number(item.total) > 0 ? 'expense' : 'income';
    props.onCategoryClick(event, transactions, type);
  };

  const toPrice = (amount, isAbsolute = true) => {
    const splitted = amount.toString().split('.');
    const intAmount = Number(splitted[0]);
    const absAmount = isAbsolute ? Math.abs(intAmount) : intAmount;
    const decimals = splitted[1] || 0;
    const fAmount = parseFloat(`${absAmount}.${decimals}`).toFixed(2);
    return fAmount;
  };

  const renderItem = (item, isFirstItem) => (
    <List.Item
      key={item.index || item.id}
      onClick={event => onCategoryClick(event, item)}
    >
      { !isFirstItem && <div className="Profit-And-Loss__divider" /> }
      { item.category_name && (
        <div className="Profit-And-Loss__List-Item">
          <div>{ item.category_name }</div>
          <div className="Profit-And-Loss__Item-Amount--asPrice">
            { item.total && toPrice(item.total) }
            { item.total_cogs && toPrice(item.total_cogs) }
            { item.total_other && toPrice(item.total_other) }
          </div>
        </div>
      )
      }
      { hasSubcategories(item) && renderSubcategories(getSubcategories(item)) }
    </List.Item>
  );

  const hasSubcategories = item => (item.cogs_categories !== undefined)
    || (item.other_categories !== undefined);

  const getSubcategories = item => item.cogs_categories || item.other_categories;

  const renderSubcategories = categories => (
    <List.Content>
      <List.List className="Profit-And-Loss__List">
        {
          categories.map((category, idx) => {
            const name = category.cogs_type ? category.cogs_type : category.other_type;
            const amount = category.total;
            return (
              <List.Item
                key={name}
                onClick={event => onCategoryClick(event, category)}
              >
                { idx === 0 && <div className="Profit-And-Loss__divider" /> }
                <div className="Profit-And-Loss__List-Item">
                  <div>{name}</div>
                  <div className="Profit-And-Loss__Item-Amount--asPrice">{toPrice(amount)}</div>
                </div>
              </List.Item>);
          })
        }
      </List.List>
    </List.Content>);

  const totalExpenses = props.pnlData.length
    ? props.pnlData[1].total_expenses : '0';

  return (
    <>
      <PnlHeader updatePnl={props.updatePnl} hideTable={props.hideTable} />
      <Segment
        attached
        className="Profit-And-Loss__List"
        loading={props.isLoading}
      >
        { props.hasError
          ? <i>{'Couldn\'t get profit and loss data. Try again.'}</i>
          : renderLists(props.pnlData.slice(0, 2)) }
      </Segment>
      <Segment
        className="Profit-And-Loss__Bottom"
        attached
      >
        <div
          className="Profit-And-Loss__List-Item"
          onClick={props.onTotalExpensesClick}
          onFocus={props.onTotalExpensesClick}
          onKeyPress={props.onTotalExpensesClick}
          role="button"
          tabIndex="0"
        >
          <div className="Profit-And-Loss__Category-Title">TOTAL EXPENSES:</div>
          <div className="Profit-And-Loss__Item-Amount--asPrice Profit-And-Loss__Category-Amount">
            { toPrice(totalExpenses) }
          </div>
        </div>
        <div className="header Profit-And-Loss__List-Item">
          <div className="Profit-And-Loss__Category-Title">
            {(props.userProfile.form === 'Schedule C' ? 'NET INCOME' : 'NET PROFIT')}
          </div>
          <div className="Profit-And-Loss__Item-Amount--asPrice Profit-And-Loss__Category-Amount">
            { toPrice(props.netIncome || 0, false) }
          </div>
        </div>
      </Segment>
      <BalanceTable
        {...(props.pnlData.length ? props.pnlData.slice(2)[0] : {})}
        renderList={renderItem}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = ({
  profitAndLoss: { pnlData },
}) => ({
  pnlData,
});

export default connect(mapStateToProps, null)(ProfitAndLossList);
