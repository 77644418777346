import { COPY_INVOICE, DETAIL_INVOICE } from '../ActionTypes';


export const copyInvoice = res => ({
  type: COPY_INVOICE,
  payload: {
    res,
  },
});

export const defaultInvoice = () => ({
  type: DETAIL_INVOICE
})