import React from 'react';
import {Button, Container, Form, Grid, Message, Modal, Segment} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import withNotifications from 'components/hocs/WithNotifications';
import './styles.scss';
import AccountTable from "components/containers/Balance/AccountTable";
import moment from "moment";
import {splitDateRangeInput} from "libraries/utils";
import {getBalance, updateOpeningBalance} from "libraries/api-v2/balances-service";
import {DateInput} from "semantic-ui-calendar-react";
import {BALANCE_ERROR_CODES} from "libraries/formValidator";
import {LinkedAccounts} from "components/containers/MyProfile";
import Card from "components/ui/Card";

const getDefaultDate = () => {
  // const yearStart = moment().startOf('year').format('MM/DD/YYYY');
  const yearStart = moment().subtract(4, 'year').startOf('year').format('MM/DD/YYYY'); // 4: ideally, getting transactions during 2 years from plaid.
  const today = moment().format('MM/DD/YYYY');
  return `${yearStart} - ${today}`;
};

export class BalanceSummary extends React.Component {
  state = {
    isLoading: false,
    isUpdating: false,
    isBalanceOpen: false,
    balances: [],
    errors: [],
    balanceAt: null,
  }

  componentDidMount() {
    this.updateBalanceData(false);
  }

  updateBalanceData = (isUpdated: boolean) => {
    const [startDate, endDate] = splitDateRangeInput(getDefaultDate());
    if (startDate.isValid() && endDate.isValid()) {
      this.setState({isLoading: true});
      getBalance(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
        .then((res) => {
          this.setState({
            isLoading: false,
            balances: res.data.balances.totalBalance,
            balanceAt: res.data.balances.balance_at
          });
          if (res.data.balances && res.data.balances.length > 0) {
            res.data.balances.map(balance =>
              this.setState({
                opening: {
                  ...this.state.opening,
                  [balance.account_id]: balance.opening_balance
                }
              })
            )
          }
          if (isUpdated) {
            this.props.pushNotification('NOTIFICATION_BALANCE_UPDATE', 'Opening Balance update',
              'Your opening balances info have been updated successfully.', 'success', 5000);
          }
        })
        .catch(() => {
          this.setState({apiError: true, isLoading: false});
        });
    }
  }

  setOpen = (open) => {
    this.setState({isBalanceOpen: open});
  }

  handleInputChange = ({target}) => {
    this.setState({
      opening: {
        ...this.state.opening,
        [target.id]: +target.value
      }
    });
  }

  validBalance = () => {
    const {opening} = this.state;
    return opening && Object.keys(opening).every(key => typeof opening[key] === "number" && !isNaN(opening[key])) || this.state.balanceAt;
  }

  handleDateChange = (event, {name, value}) => {
    this.setState({balanceAt: value});
  }

  onSubmit = () => {
    this.setState({errors: [], isUpdating: true});
    if (this.validBalance()) {
      updateOpeningBalance({
        opening: this.state.opening,
        balanceAt: this.state.balanceAt ? moment(this.state.balanceAt).format('YYYY-MM-DD') : null
      })
        .then(res => {
          this.setState({isUpdating: false, isBalanceOpen: false});
          this.updateBalanceData(true);
        })
        .catch(() => {
          this.setState({apiError: true, isUpdating: false})
        })
    } else {
      this.setState({isUpdating: false});
      if (this.state.balanceAt) {
        this.addFormError(BALANCE_ERROR_CODES.STRING_INCLUDED.id)
      } else {
        this.addFormError(BALANCE_ERROR_CODES.NOTHING_TO_UPDATE.id)
      }
    }
  }

  addFormError = (errorCode) => {
    this.setState(prevState => ({
      errors: [
        ...prevState.errors,
        BALANCE_ERROR_CODES[errorCode].content,
      ],
    }));
  }

  render() {
    return (
      <>
        <Modal
          onClose={() => this.setOpen(false)}
          onOpen={() => this.setOpen(true)}
          open={this.state.isBalanceOpen}
          size="small" closeOnEscape={true} closeOnDimmerClick={false}>
          <Modal.Header
            content="OPENING BALANCES"
            style={{backgroundColor: '#e06665', color: 'white'}}
          />
          <Modal.Content>
            <h3>About Opening balances</h3>
            <p>
              Opening balances represent the financial position of yours on the day before you start using accounting.
              You can enter opening balances for outstanding transactions since we can get only for two years from
              Plaid.
            </p>
            <Form loading={this.state.isUpdating}>
              <Form.Field widths="equal">
                <Form.Field inline className="started_date" error={!this.state.balanceAt}>
                  <label className="">Started Date</label>
                  <DateInput
                    name="balanceAt"
                    maxDate={moment(Date.now()).format(
                      "MM/DD/YYYY"
                    )}
                    iconPosition="left"
                    onChange={this.handleDateChange}
                    dateFormat="MM/DD/YYYY"
                    value={this.state.balanceAt || moment(Date.now()).format("MM/DD/YYYY")}
                    closable
                  />
                  <span className="Date__Select" hidden={this.state.balanceAt}>
                    You didn't select beginning date of reconciliation yet, so we calculate SynkbooksBalance with all transactions.
                  </span>
                </Form.Field>
                {
                  this.state.balances.map(balance =>
                    <Form.Input
                      id={balance.account_id}
                      key={balance.account_id}
                      label={`${balance.name} ${balance.mask}`}
                      type="number"
                      placeholder="amount"
                      defaultValue={balance.opening_balance}
                      onChange={this.handleInputChange}
                    />
                  )
                }
              </Form.Field>
              {
                this.state.errors.length > 0
                && (
                  <Message
                    negative
                    header="It looks like you have some errors"
                    list={this.state.errors}
                  />)
              }
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.onSubmit}>Save</Button>
            <Button onClick={() => this.setOpen(false)}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Container textAlign="right">
          <Button
            onClick={() => this.setOpen(true)}
            size="medium"
            content="OPENING BALANCES"
            icon="add"
            labelPosition="right"
            primary
            disabled={this.state.isLoading || !this.state.balances.length}
          />
        </Container>
        <Segment
          loading={this.state.isLoading}
        >
          <Grid stackable columns='equal' className="Balance__Container">
            <Grid.Column className="AccountTable">
              <Card header='Balance'>
                <Segment basic>
                  {
                    this.state.balances.map(balance =>
                      <AccountTable key={balance.account_id} balance={balance}/>
                    )
                  }
                </Segment>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <LinkedAccounts />
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
                           session: {username, userProfile},
                         }) => ({
  username,
  userProfile
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  // setIsTaxFormView,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withNotifications(withRouter(BalanceSummary)));
