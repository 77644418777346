import React from 'react';
import HomeScreen from 'screens/Home';
import { Switch, Route } from 'react-router';

import Onboarding from 'screens/Onboarding';
import Dashboard from 'screens/Dashboard';
import ClientDashboard from 'screens/ClientDashboard';
import Clients from 'screens/Dashboard/Clients';
import ClientTransactions from 'screens/Dashboard/ClientTransactions';
import ClientReports from 'screens/Dashboard/ClientReports';
import MyProfileComponent from 'screens/Home/MyProfile';
import MySubscription from 'screens/Home/Subscription';
import withAuthentication from 'components/hocs/withAuthentication';
import Routes from 'routes';
import PrivateRoute from 'routes/privateRoute';
import PrivateLayout from 'components/containers/PrivateLayoutv2';
import ErrorBoundary from 'components/ErrorBoundary';
import ProfitAndLoss from 'screens/Dashboard/ClientReports/ProfitAndLoss';

const Index = () => (
  <Switch>
    <Route path={Routes.Onboarding} component={withAuthentication(Onboarding)} />
    <Route path={Routes.ClientDashboard} component={withAuthentication(ClientDashboard, 'professional')} />
    <Route path={Routes.Dashboard} component={withAuthentication(Dashboard, 'client')} />
    <Route path={Routes.MyProfile} component={withAuthentication(MyProfileComponent, 'client')} />
    <Route path={Routes.Subscription} component={withAuthentication(MySubscription, 'client')} />
    <Route path={Routes.Index} component={HomeScreen} />
  </Switch>
);
export default Index;
