import React from 'react';
import {
  Tab, Segment, Button, Input,
  Grid, Select, Icon, Dropdown, Container, Dimmer, Loader, Header,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getCategories,
} from 'libraries/api-service';
import { exportZip, calculateInputStep } from 'libraries/utils';
import withNotifications from 'components/hocs/WithNotifications';
import getFilteredTransactions from 'store/selectors/clientTransactions';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import jsCookie from 'js-cookie';
import AWS from "aws-sdk";
const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_AWS_ACCESS_KEY_ID,
  REACT_APP_AWS_SECRET_ACCESS_KEY,
  REACT_APP_EXPORT_DATA_ENDPOINT
} = process.env;
AWS.config.update({accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID, secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY, region: REACT_APP_COGNITO_REGION});
const lambda = new AWS.Lambda();

import { FILTERS } from 'libraries/transactionsFilterManager';
import './styles.scss';
import * as ReactGA from "react-ga";

export class Filters extends React.Component {
  state = {
    panes: [
      { menuItem: FILTERS.TABS.ALL_TRANSACTIONS.replace('_', ' ') },
      { menuItem: FILTERS.TABS.INCOME },
      { menuItem: FILTERS.TABS.EXPENSE },
    ],
    categories: [],
    isExporting: false
  }

  componentDidMount() {
    const formName = jsCookie.get('active_client_form_name');
    this.getCategories(formName);
  }

  getCategories = (formName = 'Schedule C') => {
    const uncategorized = { key: 'Uncategorized', text: 'Uncategorized', value: 'Uncategorized' };
    this.setState({ isLoadingCategories: true }, () => {
      getCategories(formName)
        .then(items => items.map(({ name, type }) => ({
          key: `${name}-${type}`,
          text: name,
          value: name,
          description: type,
        })))
        .then(categories => [...categories, uncategorized])
        .then(categories => this.setState({ categories, isLoadingCategories: false }));
    });
  };

  getMonthsOptions = () => {
    const months = moment.months();
    return [
      {
        key: -1,
        value: -1,
        text: 'All',
      },
      {
        key: -2,
        value: -2,
        text: 'Year to date',
      },
      ...months.map((month, idx) => ({
        key: idx,
        value: idx,
        text: month,
      })), {
        key: months.length,
        value: months.length,
        text: 'Custom',
      },
    ];
  }

  handleClick = () => {
    const self = this;
    this.setState({ isExporting: true });
    const { filteredTransactions } = this.props;
    const clientEmail = jsCookie.get('active_client_email');
    const transactionsIds = filteredTransactions.map(({ transaction_id: id }) => id);
    const accountIds = this.getAccountNames().map(({value: id}) => id);
    const params = {
      FunctionName: REACT_APP_EXPORT_DATA_ENDPOINT,
      Payload: JSON.stringify({
        email: clientEmail,
        transactionsIds,
        accountIds: this.props.filters.ACCOUNTNAME.length ? this.props.filters.ACCOUNTNAME : accountIds
      })
    };
    lambda.invoke(params, function(err, res) {
      if (err) {
        self.props.pushNotification('NOT_TRANSACTIONS_DOWNLOAD', 'Download failed',
          'An error occurred while trying to download your transactions data. Please try again.', 'error', 5000);
        self.setState({ isExporting: false });
      } else {
        const data = JSON.parse(res.Payload);
        if (data.statusCode && data.statusCode === "200") {
          ReactGA.event({
            category: "Download Transactions",
            action: "Bookkeeper pressed the download button",
          });
          const binaryDataFlow = JSON.parse(data.body);
          exportZip(binaryDataFlow);
        } else {
          self.props.pushNotification('NOT_TRANSACTIONS_DOWNLOAD', 'Download failed',
            'An error occurred while trying to download your transactions data. Please try again.', 'error', 5000);
        }
        self.setState({ isExporting: false });
      }
    });
  }

  getAccountNames = () => {
    const accounts = {};
    return this.props.allTransactions
      // get all the different accounts names (ins + mask)
      .reduce((options, { institution, mask }) => {
        const accountName = `${institution} ${mask}`;
        if (accounts[accountName]) return options;
        accounts[accountName] = true;
        const newOption = {
          key: accountName,
          value: accountName,
          text: accountName,
        };
        return [...options, newOption];
      }, [])
      // order them alfabetically
      .sort((acc1, acc2) => {
        if (acc1.key < acc2.key) return -1;
        if (acc1.key > acc2.key) return 1;
        return 0;
      });
  }

  render() {
    const today = moment().format('MM/DD/YYYY');
    return (
      <>
        <div className="Filters">
            <Dimmer
              active={this.state.isExporting}
              inverted
            >
              <Loader
                active
                size="large"
                content={<Header icon>Exporting data...</Header>}
                style={{ position: "fixed" }}
              />
            </Dimmer>
          <Tab
            className="Filters__Tabs"
            menu={{ secondary: true, pointing: true }}
            onTabChange={this.props.onTabChange}
            panes={this.state.panes}
          />
          <Container textAlign="right" className="HideForPrint">
            <Button
              onClick={this.props.handleCreateTransaction}
              size="medium"
              content="NEW TRANSACTION"
              icon="add"
              labelPosition="right"
              primary
            />
            <Button
              onClick={this.handleClick}
              size="medium"
              content="EXPORT"
              icon="download"
              labelPosition="right"
              primary
            />
          </Container>
        </div>
        <Segment className="Filters__Segment">
          <div columns="equal" className="Filters__Segment__Grid">
            <Grid columns={5}>
              <Grid.Column mobile={8} tablet={5} computer={4}>
                <div>Category</div>
                <Dropdown
                  id="category"
                  onChange={this.props.onCategoryChange}
                  value={this.props.filters.CATEGORIES}
                  placeholder="Select"
                  options={this.state.categories}
                  fluid
                  multiple
                  search
                  selection
                  scrolling
                  deburr
                  loading={this.state.isLoadingCategories}
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={5} computer={4}>
                <div>Date</div>
                {
                  this.props.filters.IS_SELECTING_CUSTOM_DATE
                    ? (
                      <DatesRangeInput
                        value={this.props.filters.CUSTOM_DATE}
                        placeholder="Select a date range"
                        onChange={this.props.onCustomDateChange}
                        closable
                        id="filters-custom-date"
                        dateFormat="MM/DD/YYYY"
                        popupPosition="bottom right"
                        maxDate={today}
                        onKeyDown={this.onKeyEvent}
                        onClear={this.props.hideDatePicker}
                        clearable
                        clearIcon={<Icon name="remove" color="red" />}
                      />
                    ) : (
                      <Select
                        id="month"
                        type="number"
                        onChange={this.props.onMonthChange}
                        value={this.props.filters.MONTH}
                        size="small"
                        placeholder="Select a month"
                        options={this.getMonthsOptions()}
                      />
                    )
                }
              </Grid.Column>
              <Grid.Column mobile={8} tablet={5} computer={4}>
                <div>
                  <div>Amount</div>
                  <Input
                    id="amountMin"
                    type="number"
                    icon="dollar"
                    iconPosition="left"
                    onChange={this.props.onAmountChange}
                    onBlur={this.props.onAmountBlur}
                    step={calculateInputStep(this.props.filters.AMOUNT.MIN)}
                    value={this.props.filters.AMOUNT.MIN}
                    style={{ width: '8em' }}
                  />
                  <span className="Filters__Range-Divider" />
                  <Input
                    id="amountMax"
                    type="number"
                    icon="dollar"
                    iconPosition="left"
                    onBlur={this.props.onAmountBlur}
                    onChange={this.props.onAmountChange}
                    step={calculateInputStep(this.props.filters.AMOUNT.MAX)}
                    value={this.props.filters.AMOUNT.MAX}
                    style={{ width: '8em' }}
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={5} computer={4}>
                <div>Account name</div>
                <Dropdown
                  id="accountName"
                  onChange={this.props.onAccountNameChange}
                  value={this.props.filters.ACCOUNTNAME}
                  size="small"
                  placeholder="Select an account name"
                  options={this.getAccountNames()}
                  multiple
                  search
                  selection
                  scrolling
                  deburr
                />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={5} computer={15}>
                <div>Find a transaction</div>
                <Input
                  id="search"
                  type="text"
                  onChange={this.props.onSearchChange}
                  value={this.props.filters.SEARCH}
                  placeholder="Search here"
                  icon="search"
                  iconPosition="left"
                  fluid
                />
              </Grid.Column>
            </Grid>
          </div>
        </Segment>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  token: state.session.token,
  username: state.session.username,
  allTransactions: state.clientTransactions.allTransactions,
  filteredTransactions: getFilteredTransactions(state),
});

export default connect(mapStateToProps, null)(withNotifications(Filters));
