export const PASSWORD_REGEXP = /((?=[\w]*[a-z])(?=[\w]*[A-Z])(?=[\w]*[0-9])[\w]{8,})/;
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidPassword = pass => PASSWORD_REGEXP.test(pass);

export const isValidEmail = email => EMAIL_REGEXP.test(email);

export const FORM_ERROR_CODES = {
  PASSWORD_MISMATCH: {
    id: 'PASSWORD_MISMATCH',
    content: 'Password confirmation mismatch',
  },
  PASSWORD_INVALID_LENGTH: {
    id: 'PASSWORD_INVALID_LENGTH',
    content: 'Your password must be at least 8 characters long containing at least 1 lowercase letter, an uppercase letter, and a digit.',
  },
  EMAIL_INVALID_FORMAT: {
    id: 'EMAIL_INVALID_FORMAT',
    content: 'Invalid email format',
  },
  EMAIL_ALREADY_EXIST: {
    id: 'EMAIL_ALREADY_EXIST',
    content: 'Email already exists',
  },
  ACCOUNT_TYPE_MISSING: {
    id: 'ACCOUNT_TYPE_MISSING',
    content: 'You must select an account type',
  },
  TERMS_UNCHECKED: {
    id: 'TERMS_UNCHECKED',
    content: 'You must agree our terms and conditions',
  },
  INVALID_VERIFICATION_CODE: {
    id: 'INVALID_VERIFICATION_CODE',
    content: 'Validate that your verification code is correct',
  },
};

export const BALANCE_ERROR_CODES = {
  NOTHING_TO_UPDATE: {
    id: 'NOTHING_TO_UPDATE',
    content: 'You must select one or more balances or date',
  },

  STRING_INCLUDED: {
    id: 'NOTHING_TO_UPDATE',
    content: 'Amount must be a number',
  }
};

export const BUDGETING_ERROR_CODES = {
  NOTHING_TO_UPDATE: {
    id: 'NOTHING_TO_UPDATE',
    content: 'Nothing to add or update projected value.',
  },
}

export const BALANCE_SHEET_ERROR_CODES= {
  NOTHING_TO_UPDATE: {
    id: 'NOTHING_TO_UPDATE',
    content: 'Nothing to add or update projected value.',
  },
  BALANCE_SHEET_ITEM_ALREADY_EXIST: {
    id: 'BALANCE_SHEET_ITEM_ALREADY_EXIST',
    content: 'Balance sheet item already exists.',
  },
}