import React from 'react';
import { Container } from 'semantic-ui-react';
import PublicLayout from 'components/containers/PublicLayout';
import './terms.scss';

const Privacy = () => (
  <PublicLayout>
    <Container textAlign="justified" className="Terms-And-Privacy">
      <div className="centered"><h1>PRIVACY POLICY FOR SYNKBOOKS</h1></div>
      <br />
      <p>Tax Lab, Inc. (“<b>Company</b>”, “<b>Tax Lab, Inc.</b>”, “<b>we</b>”, or “<b>us</b>”) respects your privacy and are committed to protecting it through our compliance with this policy.</p>

      <p>This policy describes the types of information we may collect from you or that you may provide when you visit the website at <a href="https://www.synkbooks.com">www.synkbooks.com</a> (our “<b>Website</b>”) or access our SynkBooks mobile application (the “<b>App</b>” and together with the Website, the “Services”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
      <div>
        This policy applies to information we collect:
        <ul>
          <li>On this Website.</li>
          <li>In the App.</li>
          <li>In email, text, and other electronic messages sent through or in connection with the Services.</li>
          <li>Through mobile and desktop applications you download from this Website or third-party app stores, which provide dedicated non-browser-based interaction between you and the Website.</li>
          <li>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</li>
        </ul>
      </div>
      <div>
        It does not apply to information collected by:
        <ul>
          <li>Us offline or through any other means, including on any other website operated by Company or any third party, including our affiliates and subsidiaries); or</li>
          <li>Any third party, including our affiliates and subsidiaries, including, but not limited to, through any application or content (including advertising) that may link to or be accessible from the Website.</li>
        </ul>
      </div>
      <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use the Services. By accessing or using the Services, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of the Services after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
      <p>WE PROVIDE THIS WEBSITE FOR USE ONLY BY PERSONS LOCATED WITHIN THE UNITED STATES. WE MAKE NO CLAIMS THAT THE WEBSITE OR ANY OF ITS CONTENT IS ACCESSIBLE OR APPROPRIATE OUTSIDE OF THE UNITED STATES. IF YOU ARE OUTSIDE OF THE UNITED STATES, DO NOT USE THE SERVICES.</p>
      <h3>CHILDREN UNDER THE AGE OF 18</h3>
      <p>Our Services are not intended for children under 18 years of age. No one under age 18 may provide any personal information to us via the Services. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on the Services or on or through any of their respective features, register on the Website or through the App, make any purchases through the Services, use any of the interactive or public comment features of the Services or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at <a href="mailto:support@synkbooks.com">support@synkbooks.com</a>.</p>
      <h3>INFORMATION YOU PROVIDE TO US</h3>
      <h4>Information We Collect About You and How We Collect It</h4>
      <div>
        We collect several types of information from and about users of our Services, including information:
        <ul>
          <li>by which you may be personally identified, such as name, postal address, e-mail address, telephone number, details of your financial transactions, or any other identifier by which you may be contacted online or offline (“<b>personal information</b>”);</li>
          <li>that is about you but individually does not identify you, such as your geo-location, local time zone, financial information, and your actions within the App; and/or</li>
          <li>about your internet connection, the equipment you use to access our Services and usage details.</li>
        </ul>
      </div>
      <div>
        We collect this information:
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>Automatically as you navigate through the Website or use the App. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.</li>
          <li>From third parties, for example, our business partners.</li>
        </ul>
      </div>
      <div>
        The information we collect on or through our Services may include:
        <ul>
          <li>Information that you provide by filling in forms on our Services. This includes information provided at the time of registering to use our Website or the App, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you report a problem with our Services.</li>
          <li>Information from third parties that you give us permission to access, such as details of your financial transactions.</li>
          <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
          <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
          <li>Details of transactions you carry out through our Services and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Services.</li>
          <li>Your search queries on the Services.</li>
        </ul>
      </div>
      <p>
        We may allow you to provide information to be published or displayed (hereinafter, “<b>posted</b>”) on public areas of the Website, or transmitted
        to other users of the Website or third parties (collectively, “<b>User Contributions</b>”). Your User Contributions are posted on and transmitted to others
        at your own risk. Always be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users
        of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions
        will not be viewed by unauthorized persons.
      </p>
      <h4>Information We Collect Through Automatic Data Collection Technologies</h4>
      <div>
        As you navigate through and interact with our Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
        <ul>
          <li>Details of your visits to our Website or use of the App, including traffic data, location data, logs, referring site and/or search engine, date and time of each visitor request, and other communication data and the resources that you access and use on the Website.</li>
          <li>Information about your computer and internet connection, including your IP address, device type, device details (e.g., screen size), operating system, browser type, browser plug-in details, .</li>
        </ul>
      </div>
      <p>We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). If you would like to learn more about this practice or find out how you can opt out from allowing these companies to gather this information or use it to display targeted ads, please visit the NAI’s website at <a href="http://networkadvertising.org/consumer/opt_out.asp">http://networkadvertising.org/consumer/opt_out.asp.</a></p>

      <div>
        The information we collect may include personal information, or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Services and to deliver a better and more personalized service, including by enabling us to:
        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>Store information about your preferences, allowing us to customize our Services according to your individual interests.</li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our Website or the App.</li>
          <li>Provide technical support.</li>
          <li>Help prevent fraud.</li>
        </ul>
      </div>
      <div>
        The technologies we use for this automatic data collection may include, but are not limited to:
        <ul>
          <li>Cookies (or browser/mobile cookies). A cookie is a small file placed on the hard drive of your computer or on your smartphone. You may refuse to accept cookies by activating the appropriate setting on your browser or smartphone. However, if you select this setting you may be unable to access certain parts of our Services. Unless you have adjusted your browser or smartphone settings so that it will refuse cookies, our system will issue cookies when you direct your browser to our Services.</li>
          <li>Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.</li>
          <li>Web Beacons. Pages of our Website and the App and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or and for other related website statistics (for example, recording the popularity of certain website or app content and verifying system and server integrity).</li>
        </ul>
      </div>
      <p>We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.</p>
      <h4>Third-Party Use of Cookies and Other Tracking Technologies.</h4>
      <p>Some content or applications, including advertisements, on the Services are served by third-parties, including advertisers, ad networks and servers, content providers, application providers, and your mobile device manufacturer and service provider. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our Services. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
      <p>We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.</p>
      <h4>HOW WE USE YOUR INFORMATION</h4>
      <div>
        We use information that we collect about you or that you provide to us, including any personal information:
        <ul>
          <li>To present our Services and their contents to you;</li>
          <li>To provide you with information, products, or services that you request from us;</li>
          <li>To fulfill any other purpose for which you provide it;</li>
          <li>To provide you with notices about your account, including expiration and renewal notices;</li>
          <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
          <li>To notify you about changes to our Services or any products or services we offer or provide though them;</li>
          <li>To allow you to participate in interactive features on our Services;</li>
          <li>To provide information to our representatives and/or advisors, such as our attorneys, accountants, and others to help us comply with legal, accounting, or security requirements</li>
          <li>In any other way we may describe at the time you provide the information; or</li>
          <li>For any other purpose with your consent.</li>
        </ul>
      </div>
      <p>
        We may also use your information to contact you about our own and third-parties’ goods and services that may be of interest to you. For more information, see Choices About How We Use and Disclose Your Information.
      </p>
      <p>We may use the information we have collected from you to enable us to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>
      <h4>Disclosure of Your Information</h4>
      <p>We may disclose aggregated information about our users and information that does not identify any individual without restriction.</p>
      <div>
        We may disclose personal information that we collect or you provide as described in this privacy policy:
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
          <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Tax Lab, Inc.’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Tax Lab, Inc. about our Website users is among the assets transferred.</li>
          <li>To third parties to market their products or services to you if you have not opted out of these disclosures. </li>
          <li>To fulfill the purpose for which you provide it. For example, if you give us an email address to use a feature of our Website, we will transmit the contents of that email and your email address to the recipients.</li>
          <li>For any other purpose disclosed by us at the time you provide the information.</li>
          <li>With your consent.</li>
        </ul>
      </div>
      <div>
        We may also disclose your personal information:
        <ul>
          <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
          <li>To enforce or apply our Terms of Use located at <a href="https://www.synkbooks.com/terms-of-service">www.synkbooks.com/terms-of-service</a> and other agreements, including for billing and collection purposes.</li>
          <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Tax Lab, Inc., our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
        </ul>
      </div>
      <h4>CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</h4>
      <div>
        We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:
        <ul>
          <li><b>Tracking Technologies and Advertising.</b> You can set your browser or smartphone to refuse all or some browser or mobile cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note that some parts of the Services may then be inaccessible or not function properly.</li>
          <li>We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“<b>NAI</b>”) on the NAI’s website.</li>
        </ul>
      </div>
      <h4>ACCESSING AND CORRECTING YOUR INFORMATION</h4>
      <p>You can review and change your personal information by logging into the Website or App and adjusting your account profile settings.</p>
      <p>You may also send us an email at <a href="info@synkbooks.com">info@synkbooks.com</a> to request access to, correct or delete certain personal information that you have provided to us.. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>

      <div>
        Note that after your account has been deleted, we will continue to maintain the following information in our database in order to facilitate the functioning of our Services, including:
        <ul>
          <li>Your unique customer ID number;</li>
          <li>Transaction data and your transaction history;</li>
          <li>Your email address;</li>
          <li>The name associated with your account; and</li>
          <li>Details about your business type.</li>
        </ul>
      </div>
      <p>If you delete your User Contributions from the Services, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other users of the Services. Proper access and use of information provided on the Services, including User Contributions, is governed by our Terms of Use, located at <a href="https://www.synkbooks.com/terms-of-service">www.synkbooks.com/terms-of-service</a>.</p>
      <h4>YOUR CALIFORNIA PRIVACY RIGHTS</h4>
      <p>California Civil Code Section § 1798.83 permits users of our Services that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="info@synkbooks.com">info@synkbooks.com</a>.</p>
      <h4>DATA SECURITY</h4>
      <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. In order to achieve a high level of security we use the best tools, for this reason we have chosen to use AWS Cloud Services to maintain a secure environment complying with the highest standards.</p>
      <p>The security offered by AWS Cloud is state of art, having customers with sensitive data in regulated industries, as in the financial services like NASDAQ and the Department of Defense show the level of confidence on their services.</p>
      <p>Cloud security at AWS is the highest priority. As an AWS customer, you will benefit from a data center and network architecture built to meet the requirements of the most security-sensitive organizations. Security in the cloud is much like security in your on-premises data centers—only without the costs of maintaining facilities and hardware. In the cloud, you don't have to manage physical servers or storage devices. Instead, you use software-based security tools to monitor and protect the flow of information into and of out of your cloud resources. For more information visit AWS Security and Compliance web page.</p>
      <p>Amazon Web Services is responsible for protecting the global infrastructure that runs all of the services offered in the AWS cloud. This infrastructure is comprised of the hardware, software, networking, and facilities that run AWS services.</p>
      <p>In addition to protecting this global infrastructure, AWS is responsible for the security configuration of its products that are considered managed services. For these services, AWS will handle basic security tasks like guest operating system (OS) and database patching, firewall configuration, and disaster recovery.</p>
      <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website.</p>
      <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</p>
      <h4>CHANGES TO OUR PRIVACY POLICY</h4>
      <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users’ personal information, we will notify you by email to the primary email address specified in your account or through a notice on the Website home page or in the App. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting this privacy policy to check for any changes.</p>
      <h4>CONTACT INFORMATION</h4>
      <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>
      <p>
        Tax Lab, Inc.<br />
        7083 Hollywood, 5th Floor<br />
        Los Angeles, CA 90028<br />
        <a href="info@synkbooks.com">info@synkbooks.com</a><br />
      </p>
    </Container>
  </PublicLayout>
);

export default Privacy;
