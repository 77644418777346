import React, { useState } from 'react';
import { Grid, Input, Tab } from 'semantic-ui-react';
import ClientRequestsTable from 'components/containers/ClientRequestsTable';
import ClientsTable from 'components/containers/ClientsTable';

export const filterClientsBySearch = (search, clients) => {
  if (search === '') return clients;
  return clients
    .filter(client => client.full_name.toLowerCase().includes(search.toLowerCase()) || client.email.toLowerCase().includes(search.toLowerCase()));
};

const Clients = () => {
  const [search, setSearch] = useState('');

  const panes = [
    {
      menuItem: 'MY CLIENTS',
      render: () => (
        <Tab.Pane attached={false}>
          <ClientsTable search={search} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'REQUESTS',
      render: () => (
        <Tab.Pane attached={false}>
          <ClientRequestsTable search={search} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Grid centered columns={3} textAlign='center'>
        <Grid.Column>
          <Input
            id="search"
            type="text"
            onChange={(e) =>setSearch(e.target.value)}
            value={search}
            placeholder="Search here"
            icon="search"
            iconPosition="left"
            fluid
          />
        </Grid.Column>
      </Grid>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </>
  )
};

export default Clients;
