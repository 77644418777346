import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { ENDPOINT_V1, ENDPOINT_V2 } from 'libraries/config';

const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID,
  REACT_APP_COGNITO_API_GATEWAY,
  REACT_APP_API_GATEWAY_V2,
} = process.env;

const customHeaders = async () => Auth.currentSession().then(
  session => ({
    Authorization: session.idToken.jwtToken,
  }),
  noValidSessionError => ({}),
);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: ENDPOINT_V1,
        endpoint: REACT_APP_COGNITO_API_GATEWAY,
        custom_header: customHeaders,
      },
      {
        name: ENDPOINT_V2,
        endpoint: REACT_APP_API_GATEWAY_V2,
        custom_header: customHeaders,
      },
    ],
  },
});
