import {
  SET_USER_LOGIN_STATE,
  SET_ONBOARDING_DONE,
  SET_USER_PROFILE,
  CLEAR_USER_LOGIN_STATE,
  SET_IS_LOGGING_OUT,
  SET_USER_SESSION,
} from '../ActionTypes';

export const setUserDidLogin = (data, token) => ({
  type: SET_USER_LOGIN_STATE,
  payload: {
    data,
    token,
  },
});

export const setUserProfile = payload => ({ type: SET_USER_PROFILE, payload });

export const setOnboardingDone = () => ({ type: SET_ONBOARDING_DONE });

export const clearUserDidLogin = () => ({
  type: CLEAR_USER_LOGIN_STATE,
});

export const setIsLoggingOut = isLoggingOut => ({
  type: SET_IS_LOGGING_OUT,
  payload: isLoggingOut,
});

export const setUserSession = payload => ({ type: SET_USER_SESSION, payload });
