import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Button, Container, Header, Dimmer, Loader,
} from 'semantic-ui-react';
import './styles.scss';
import { filterSelectedT } from 'libraries/utils';
import { areNotBeingSeen } from 'libraries/selectedTransactionsFilter';

export class BulkEditingBarv2 extends React.Component {
  handleValidAllSeen = () => {
    const { selected, allTransactions } = this.props.transactions;
    const filteredSelected = filterSelectedT(allTransactions, selected);
    const notseen = areNotBeingSeen(filteredSelected);
    return notseen;
  };

  render() {
    return (
      <Container className="BulkEdit__Container">
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column className="BulkEdition__Total" width={6}>
              <Header as="h5">
                {this.props.itemsSelected}
                { this.props.itemsSelected > 1 ? ' items ' : ' item '}
                selected
              </Header>
            </Grid.Column>
            <Grid.Column className="BulkEdit__Button" width={10}>
              {
                <Dimmer
                  active={this.props.isLoading}
                  inverted
                >
                  <Loader
                    active
                    size="large"
                    content={<Header icon>Updating...</Header>}
                    style={{ position: "fixed" }}
                  />
                </Dimmer>
              }
              {
                this.handleValidAllSeen() && (
                  <Button onClick={this.props.handleMarkAsSeen} className="mark-as-reviewed">
                    MARK AS REVIEWED
                  </Button>
                )
              }
              <Button onClick={this.props.showBulkEdition} className="change-category">
                CHANGE CATEGORY
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  transactions: state.clientTransactions,
});

export default connect(mapStateToProps, null)(BulkEditingBarv2);
