import escapeRegExp from 'lodash.escaperegexp';
import size from 'lodash.size';

const isMatch = (result, state) => {
  const regex = new RegExp(escapeRegExp(state), 'i');
  const isMatched = regex.test(result.name);
  return isMatched;
};

const jsonParser = (result, subcategory) => {
  const obj = { ...result };
  let converted = {};
  if (size(obj) > 0) {
    let str = JSON.stringify(obj);
    str = str.replace('name', 'title');
    converted = JSON.parse(str);
    converted.description = subcategory;
  }
  return converted;
};

const filteredResults = (source, state) => {
  let categoryName = '';
  let subCategoryName = '';
  let categoryAC = {};

  for (const catind in source) {
    if (catind.length > 0) {
      const category = source[catind];
      categoryName = category.name;
      const { subcategories } = category;
      const businessTypesAC = [];

      for (const subcatind in subcategories) {
        if (subcatind) {
          const subcategory = subcategories[subcatind];
          subCategoryName = subcategory.name;
          const businessTypes = subcategory.business_types;

          for (const businessind in businessTypes) {
            if (businessind) {
              let businessType = businessTypes[businessind];
              const match = isMatch(businessType, state);
              if (match) {
                businessType = jsonParser(businessType, subCategoryName);
                businessTypesAC.push(businessType);
              }
            }
          }
        }
      }

      if (businessTypesAC.length > 0) {
        categoryAC[categoryName] = {
          name: categoryName,
          results: businessTypesAC,
        };
      }
    }
  }

  const defaultOption = {
    name: 'Others',
    results: [{
      code: 212200,
      description: 'Other business',
      title: 'Other business type',
    }],
  };
  categoryAC = {
    ...categoryAC,
    defaultOption,
  };
  return categoryAC;
};

export default filteredResults;
