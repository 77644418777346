import React from 'react';
import {
  Switch, Route, Redirect, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from 'components/containers/PrivateLayout';
import ReportForm from 'components/containers/OnboardingReportForm';
import PlaidLink from 'components/containers/OnboardingPlaid';
import BusinessType from 'components/containers/OnboardingBusinessType';
import Routes from 'routes';

class Onboarding extends React.Component {
  componentDidMount() {
    const { onboarding, history } = this.props;
    onboarding === 'True' && history.push(Routes.DashboardTransactions);
  }

  render() {
    return (
      <Layout>
        <Switch>
          <Redirect exact from={Routes.Onboarding} to={Routes.OnboardingReportForm} />
          <Route path={Routes.OnboardingReportForm} component={ReportForm} />
          <Route path={Routes.OnboardingBusinessType} component={BusinessType} />
          <Route path={Routes.OnboardingLinkPlaid} component={PlaidLink} />
        </Switch>
      </Layout>
    );
  }
}

const mapStateToProps = ({
  session: {
    userInfo: { onboarding },
  },
}) => ({
  onboarding,
});

export default connect(mapStateToProps, null)(withRouter(Onboarding));
