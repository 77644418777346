import {
  PUSH_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from '../ActionTypes';

export const pushNotification = (id, header, content, type, hideAfter) => ({
  type: PUSH_NOTIFICATION,
  payload: {
    id,
    header,
    content,
    type,
    hideAfter,
  },
});

export const removeNotification = key => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    key,
  },
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});
