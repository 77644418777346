import React, { Component } from 'react';
import {
  getInvitations,
} from 'libraries/api-v2/invitations-service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setInvitations } from 'store/actions/invitations';
import {
  Header,
} from 'semantic-ui-react';
import ShowInviteeView from '../ShowInviteeView';
import ShowAcceptedInviteeView from '../ShowAcceptedInviteeView';
import './styles.scss';

class ListInviteeView extends Component {
  state = {
    isLoading: true,
  }

  constructor(props) {
    super(props);
    this.refreshInvitationsList = this.refreshInvitationsList.bind(this);
  }

  componentDidMount() {
    getInvitations().then((invitations) => {
      this.props.setInvitations(invitations);
      this.setState({ isLoading: false });
    });
  }

  refreshInvitationsList() {
    this.setState({ isLoading: true });

    getInvitations().then((invitations) => {
      this.props.setInvitations(invitations);
      this.setState({ isLoading: false });
    });
  }

  /**
   * createInvitationsList
   *
   * Creates the list of invitations. Since the email property is unique and
   * static, we use it as the key for each child element in our list
   */
  createInvitationsList() {
    const list = [];
    const { isLoading } = this.state;
    const { invitations } = this.props;

    if (isLoading || invitations === undefined) {
      return [];
    }

    list.push(
      <Header className="ListInvitee__Header" as="h3">Accountants</Header>,
    );

    for (let i = 0; i < invitations.length; i++) {
      const invitation = invitations[i];

      if (invitation.status === 'pending') {
        list.push(
          <ShowInviteeView
            name={invitation.full_name}
            email={invitation.email}
            status={invitation.status}
            key={invitation.email}
            onCancel={this.refreshInvitationsList}
            onResend={this.refreshInvitationsList}
          />,
        );
      } else if (invitation.status === 'accepted') {
        list.push(
          <ShowAcceptedInviteeView
            name={invitation.full_name}
            email={invitation.email}
            status={invitation.status}
            key={invitation.email}
            onRevoke={this.refreshInvitationsList}
          />,
        );
      }
    }

    return list;
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div className="list-invitee-container">
        { this.createInvitationsList(isLoading) }
      </div>
    );
  }
}

const mapStateToProps = ({ invitations }) => ({
  invitations: invitations.allInvitations,
});

const mapDispatchToProps = dispatch => bindActionCreators({ setInvitations }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListInviteeView);
