import React from 'react';
import {
  Button, Icon,
} from 'semantic-ui-react';
import './styles.scss';

const Modal = (props) => {
  const showHideClassName = props.show ? 'Modal display-block' : 'Modal display-none';

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div>
          <Button icon onClick={props.handleClose} className="Modal__CloseButton">
            <Icon name="close" />
          </Button>
        </div>
        <br />
        {props.children}
      </section>
    </div>
  );
};

export default Modal;
