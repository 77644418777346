import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

// TODO: API Payloads should be standardized to only accept camelCased keys rather than
// snake_cased keys.

const postClassifications = classificationUpdate => apiRequestV2('post', `${basePath}/transactions/update-classifications`, {
  body: classificationUpdate,
});

export const getTransactionsAsProfessional = (username, token, clientEmail, offset = 0) => apiRequestV2('post', `${basePath}/transactions/as-professional`, {
  body: {
    clientEmail,
    offset
  },
}).then(res => res.data.transactions);

export const getTransactions = (username, offset = 0) => apiRequestV2('post', `${basePath}/transactions/get_transactions`, {
  body: {
    username,
    offset
  },
}).then(res => res.data.transactions);

/**
 * Marks transactions as seen by a professional on behalf of a client.
 *
 * @param {string} username The username of the current logged in user
 * @param {string} token The authorization token
 * @param {string} clientEmail The email of the client
 * @param {string[]} transactionIds A list of transaction ids to be marked as seen.
 */
export const markAsSeenAsProfessional = (
  username,
  token,
  clientEmail,
  transactionIds,
) => apiRequestV2('put', `${basePath}/transactions/mark-as-seen/as-professional`, {
  body: {
    clientEmail,
    transactionIds,
  },
});

export const getProfitAndLossAsProfessional = (username, token, clientEmail, startDate, endDate) => apiRequestV2('post', `${basePath}/transactions/get-profit-and-loss/as-professional`, {
  body: {
    clientEmail,
    startDate,
    endDate,
  },
});

/**
 * Creates a manual transaction by a professional on behalf of a client.
 *
 * @param {string} username The username of the current logged in user
 * @param {string} token The authorization token
 * @param {Object} payload the payload to send to the API
 */
export const createTransactionAsProfessional = (username, token, payload) => {
  const {
    clientEmail,
    transactionType,
    name,
    category,
    subcategory,
    amount,
    date,
  } = payload;
  return apiRequestV2('post', `${basePath}/transactions/create-transaction/as-professional`, {
    body: {
      clientEmail,
      transaction_type: transactionType,
      transaction_name: name,
      category,
      subcategory,
      amount,
      date,
    },
  });
};

/**
 * Updates a manual transaction by a professional on behalf of a client.
 *
 * @param {string} username The username of the current logged in user
 * @param {string} token The authorization token
 * @param {Object} payload the payload to send to the API
 */
export const updateTransactionAsProfessional = (username, token, payload) => {
  const {
    clientEmail,
    transactionID,
    customDate,
    name,
    transactionType,
    amount,
    category,
    subcategory,
  } = payload;

  return apiRequestV2('post', `${basePath}/transactions/edit-manual-transaction/as-professional`, {
    body: {
      clientEmail,
      transaction_id: transactionID,
      date: customDate,
      description: name,
      transaction_type: transactionType.toLowerCase(),
      amount,
      category,
      subcategory,
    },
  });
};

/**
 * Deletes a manual transaction
 *
 * @param {string} username The username of the current logged in user
 * @param {string} token The authorization token
 * @param {Object} payload the payload to send to the API
 */
export const deleteTransactionAsProfessional = (username, token, payload) => {
  const {
    clientEmail,
    transactionId,
  } = payload;

  return apiRequestV2('post', `${basePath}/transactions/delete-transaction/as-professional`, {
    body: {
      clientEmail,
      transaction_id: transactionId,
    },
  });
};

export const updateTransactionClassificationsAsProfessional = (username, token, {
  clientEmail,
  transactionIds,
  category,
  subcategory,
}) => apiRequestV2('post', `${basePath}/transactions/update-classifications/as-professional`, {
  body: {
    clientEmail,
    transactionIds,
    category,
    subcategory,
  },
});


/**
 * V2 wrapper for backwards compatibility
 * Note: username and token are not needed
 */
export const updateClassificationsV2 = (username, token, transactionIds, category, subcategory, selectedTags, memo) => {
  const classificationUpdate = {
    transaction_ids: transactionIds,
    category,
    subcategory,
    selectedTags,
    memo
  };

  return postClassifications(classificationUpdate);
};

export const exportPNLTransactionsAsProfessional = (username, token, clientEmail, transactionIds, transactionType) => apiRequestV2('post', `${basePath}/transactions/export-as-xlsx/as-professional`, {
  body: {
    clientEmail,
    transactionIds,
    transactionType,
  },
});

export const exportPNLPdfAsProfessional = (username, token, clientEmail, startDate, endDate) => apiRequestV2('post', `${basePath}/transactions/profit-and-loss/export-as-pdf/as-professional`, {
  body: {
    clientEmail,
    startDate,
    endDate,
  },
});

export const markAsSeen = (
  transactionIds,
) => apiRequestV2('put', `${basePath}/transactions/mark-as-seen`, {
  body: {
    transactionIds,
  },
});

export const exportPnlMonthXLSX = (startDate, endDate, includingRemainingBalance) => apiRequestV2('post', `${basePath}/transactions/export-profit-and-loss/month-to-month`, {
  body: {
    startDate,
    endDate,
    remainBalance: includingRemainingBalance
  },
}).then(res => res.data);

export const getProfitAndLossMonth = (startDate, endDate) => apiRequestV2('post', `${basePath}/transactions/profit-and-loss/month-to-month`, {
  body: {
    startDate,
    endDate,
  },
}).then(res => res.data);

export const exportPnlMonthXLSXProfessional = (clientEmail, clientFullName, startDate, endDate, includingRemainingBalance) => apiRequestV2('post', `${basePath}/transactions/export-profit-and-loss/month-to-month/as-professional`, {
  body: {
    clientEmail,
    clientFullName,
    startDate,
    endDate,
    remainBalance: includingRemainingBalance
  },
}).then(res => res.data);

export const getProfitAndLossMonthProfessional = (clientEmail, startDate, endDate) => apiRequestV2('post', `${basePath}/transactions/profit-and-loss/month-to-month/as-professional`, {
  body: {
    clientEmail,
    startDate,
    endDate,
  },
}).then(res => res.data);

export const getTransactionTagList = () => apiRequestV2('get', `${basePath}/transactions/transaction_tag_list`).then(res => res.data);

export default {
  getTransactionsAsProfessional,
  getTransactions,
  updateClassificationsV2,
  markAsSeenAsProfessional,
  markAsSeen,
  createTransactionAsProfessional,
  deleteTransactionAsProfessional,
  updateTransactionAsProfessional,
  updateTransactionClassificationsAsProfessional,
  exportPNLTransactionsAsProfessional,
  exportPNLPdfAsProfessional,
  exportPnlMonthXLSX,
  exportPnlMonthXLSXProfessional,
  getProfitAndLossMonthProfessional,
  getTransactionTagList
};
