import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';

import withNotifications from 'components/hocs/WithNotifications';
import Routes from 'routes';

const getMessages = (status, payment) => {
  if (!payment && status === 'past_due') {
    return {
      header: 'YOUR FREE TRIAL HAS ENDED',
      content: 'If you wish to continue using SynkBooks please enter your payment method.',
    };
  }
  if (payment && status === 'past_due') {
    return {
      header: 'THERE WAS A PROBLEM WITH YOUR PAYMENT',
      content: 'If you wish to continue using SynkBooks please confirm your payment method.',
    };
  }
  if (status === 'canceled') {
    return {
      header: 'YOUR SUBSCRIPTION HAS ENDED',
      content:
        'Your subscription has expired. Go to subscription to upgrade your plan to get back on track.',
    };
  }
  throw new Error('Invalid subscription status');
};

const SubscriptionStatus = ({ history, subscriptionStatus, paymentsStatus }) => {
  const statusThatTriggerModal = ['past_due', 'canceled'];
  const shouldDisplayModal = statusThatTriggerModal.includes(subscriptionStatus);
  if (!shouldDisplayModal) return null;

  const messages = getMessages(subscriptionStatus, paymentsStatus);
  return (
    <Modal open size="mini" closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Header
        content={messages.header}
        style={{ backgroundColor: '#e06665', color: 'white' }}
      />
      <Modal.Content>{messages.content}</Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Go to Subscriptions"
          onClick={() => history.push(Routes.Subscription)}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({
  session: {
    userProfile: { subscriptionStatus, paymentsStatus },
  },
}) => ({
  subscriptionStatus,
  paymentsStatus,
});

export default connect(
  mapStateToProps,
  null,
)(withRouter(withNotifications(SubscriptionStatus)));
