import React from "react";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import "./styles.scss";

class SelectTax extends React.Component {
  state = {
    text:'No tax',
    taxRates:[],
  }
  handleSelect = (id, display_name) => {  
    this.props.onTax([id]);
    // if(id != 'noTax' && id != 'add'){
    //   let taxRates = this.state.taxRates;
    //   if(taxRates.indexOf(id) > -1)
    //     taxRates.splice(taxRates.indexOf(id), 1);
    //   else
    //     taxRates.push(id);
    //   this.setState({taxRates: taxRates});
    //   this.props.onTax(taxRates);
    //   console.log('selecttax/taxrates', taxRates);
    // }else if(id == 'noTax')
    //   this.setState({taxRates:[]});
    
    if(id != 'add')
      this.setState({text:display_name});
  };
  render() {
    const {taxRates, taxRateData} = this.props;

    const taxRateText = () => {
      let text = "No tax";
      if(taxRates.length > 0 && taxRateData.length > 0){
        taxRateData.forEach(data => {
          if(data.tax_rate_id == taxRates[0]){
            text = data.display_name + '-' + data.percentage;
          }
        })
      }
      return text;
    }

    return (
      <Dropdown
        text={taxRateText()}
        floating
        basic
        className="icon tax-action"
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => this.handleSelect("add", "")}><Icon name="plus"/> Add tax </Dropdown.Item>
          <Dropdown.Item onClick={() => this.handleSelect("noTax", "No tax")}>No tax</Dropdown.Item>
          {taxRateData.map((data, index) => (
            <Dropdown.Item key={index} 
              onClick={() => this.handleSelect(data.tax_rate_id, data.display_name+"-"+data.percentage+"%")} 
              // style={{backgroundColor:this.state.taxRates.indexOf(data.tax_rate_id) > -1? 'lightgrey':'white', width:'100px'}}
              >
              {/* {this.state.taxRates.indexOf(data.tax_rate_id) > -1 && <Icon name="check"/>} */}
              {data.display_name}
              -
              {data.percentage}%
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default SelectTax;
