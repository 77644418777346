import {
  SET_CURRENT_SUBSCRIPTION,
  SET_FUTURE_SUBSCRIPTION,
  SET_HAS_PAYMENT_METHOD,
  SET_IS_MODAL_OPEN,
  SET_STRIPE_PLANS,
  SET_CANCEL_AT_PERIOD_END,
  CLEAR_SUBSCRIPTION,
} from '../ActionTypes';

const initialState = {
  current: {},
  future: {
    planId: null,
    name: null,
  },
  hasPaymentMethod: false,
  isModalOpen: false,
  stripePlans: [],
  cancelAtPeriodEnd: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_SUBSCRIPTION: {
      return {
        ...state,
        current: payload,
      };
    }
    case SET_FUTURE_SUBSCRIPTION: {
      const { planId, name } = payload;
      return {
        ...state,
        future: {
          planId,
          name,
        },
      };
    }
    case SET_HAS_PAYMENT_METHOD: {
      return {
        ...state,
        hasPaymentMethod: payload,
      };
    }
    case SET_IS_MODAL_OPEN: {
      return {
        ...state,
        isModalOpen: payload,
      };
    }
    case SET_STRIPE_PLANS: {
      return {
        ...state,
        stripePlans: payload,
      };
    }
    case SET_CANCEL_AT_PERIOD_END: {
      return {
        ...state,
        cancelAtPeriodEnd: payload,
      };
    }
    case CLEAR_SUBSCRIPTION: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
