import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon,
  Button,
  Dimmer,
  Header,
} from 'semantic-ui-react';

import Card from 'components/ui/Card';
import withNotifications from 'components/hocs/WithNotifications';
import hasSubscription from 'store/selectors/subscription';

import { setInvitations } from 'store/actions/invitations';
import { getInvitations } from 'libraries/api-v2/invitations-service';

import NewInvitationView from './NewInvitationView';
import ListInviteeView from './ListInviteeView';
import ConfirmSendInvitationView from './ConfirmSendInvitationView';
import './styles.scss';

class Professional extends Component {
  state = {
    newInvitationStatus: 'clear', // clear, new, confirm, (create)
  }

  handleAddAccountant = () => this.setState(_ => ({ newInvitationStatus: 'new' }))

  handleCancelNewInvitation = () => this.setState(_ => ({ newInvitationStatus: 'clear' }))

  handleNextStepNewInvitation = () => this.setState(_ => ({ newInvitationStatus: 'confirm' }))

  handleCancelSendInvitation = () => this.setState(_ => ({ newInvitationStatus: 'new' }))

  handleSendInvitation = () => {
    this.setState(_ => ({ newInvitationStatus: 'clear' }));

    getInvitations().then((invitations) => {
      this.props.setInvitations(invitations);
      this.props.pushNotification(
        'INVITATION_SENT_SUCCESS',
        'Invitation has been successfully sent.',
        '',
        'success',
        8000,
      );
    });
  }

  render() {
    const actions = [
      <button type="button" key="Professional-btnEdit">
        <Icon name="edit" />
      </button>,
    ];
    const { isSubscribed } = this.props;
    const { newInvitationStatus } = this.state;
    const isAddAccountantDisabled = newInvitationStatus !== 'clear';

    if (newInvitationStatus === 'clear') {
      return (
        <Card className="Professional__Card" header="Accounting Pro" actions={actions} autoHide hideActions>
          {!isSubscribed && <Dimmer active inverted />}
          <div className="Professional__Card__Invitation">
            <div className="Professional__Card__Instructions">
              {
                'This user will have access and can modify any data on your books. For security reasons, they won\'t be granted access to your registered accounts.'
              }
            </div>
            <div className="add-accountant">
              <Button primary className="Professional__AddAccountant__Button" onClick={this.handleAddAccountant} size="small" disabled={isAddAccountantDisabled}>
                <Icon name="add user" />
                <span className="Professional__AddAccountant__Text">Add Accountant</span>
              </Button>
            </div>
          </div>
          <ListInviteeView />
        </Card>
      );
    }
    if (newInvitationStatus === 'new') {
      return (
        <Card className="Professional__Card" header="Accounting Pro" actions={actions} autoHide hideActions>
          {!isSubscribed && <Dimmer active inverted />}
          <div className="Professional__Card__Invitation">
            <div className="Professional__Card__Instructions">
              <span>
                {
                  'This user will have access and can modify any data on your books. For security reasons, they won\'t be granted access to your registered accounts.'
                }
              </span>
            </div>
            <div className="add-accountant">
              <Button primary className="Professional__AddAccountant__Button" onClick={this.handleAddAccountant} size="small" disabled={isAddAccountantDisabled}>
                <Icon name="add user" />
                <span className="Professional__AddAccountant__Text">Add Accountant</span>
              </Button>
            </div>
            <NewInvitationView
              onCancel={this.handleCancelNewInvitation}
              onNext={this.handleNextStepNewInvitation}
            />
          </div>
          <ListInviteeView />
        </Card>
      );
    }
    if (newInvitationStatus === 'confirm') {
      return (
        <Card className="Professional__Card" actions={actions} autoHide hideActions>
          {!isSubscribed && <Dimmer active inverted />}
          <div className="Professional__Card__Invitation">
            <ConfirmSendInvitationView
              onCancel={this.handleCancelSendInvitation}
              onSend={this.handleSendInvitation}
            />
          </div>
          <ListInviteeView />
        </Card>
      );
    }
    return (
      <Card header="My Accounting Pro" actions={actions} autoHide hideActions>
        {!isSubscribed && <Dimmer active inverted />}
        <div className="Professional__Card__Invitation">
          <div className="add-accountant">
            <Button primary onClick={this.handleAddAccountant} size="small" disabled={isAddAccountantDisabled}>
              Add Accountant
            </Button>
          </div>
        </div>
        <ListInviteeView />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  isSubscribed: hasSubscription(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ setInvitations }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNotifications(Professional));
