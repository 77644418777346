import scheduleC from 'images/forms/schedule-c.png';
import scheduleE from 'images/forms/schedule-e.png';
import form1120 from 'images/forms/form-1120.png';
import form1120s from 'images/forms/form-1120s.png';
import form1065 from 'images/forms/form-1065.png';

export default [
  {
    id: 1,
    title: 'Schedule C',
    description: 'You are Self-Employed, a Sole Proprietor or Single Member LLC and have not filed any paperwork to become a Corporation or Partnership.',
    fullDescription: 'Use this schedule to report income or loss from a business you operated or a profession you practiced as a sole proprietor. An activity qualifies as a business if:\nyour primary purpose for engaging in the activity is for income or profit, and you are involved in the activity with continuity and regularity.',
    nickname: 'Sole-Prop',
    sources: [
      'https://www.irs.gov/pub/irs-pdf/i1040sc.pdf',
      'https://www.irsvideos.gov/SmallBusinessTaxpayer/StartingaBusiness/ScheduleCWho',
    ],
    icon: scheduleC,
  },
  {
    id: 2,
    title: 'Schedule E',
    description: 'You have rental property and/or royalties for purposes of bookkeeping.',
    fullDescription: 'Use Schedule E (Form 1040) to report income or loss from rental real estate, royalties, partnerships, S corporations, estates, trusts, and residual interests in REMICs. You can attach your own schedule(s) to report income or loss from any of these sourcess. Use the same format as on Schedule E.',
    nickname: 'Rentals',
    sources: [
      'https://www.irs.gov/instructions/i1040se',
    ],
    icon: scheduleE,
  },
  {
    id: 3,
    title: 'Form 1120',
    description: 'You have formally filed paperwork with your State to become a Corporation.\n(Please make sure you know whether you are an 1120 or 1120S)',
    fullDescription: 'Domestic corporations use this form to:\n- Report their income, gains, losses, deductions, credits.\n- Figure their income tax liability.',
    nickname: 'C-Corp',
    sources: [
      'https://www.irs.gov/forms-pubs/about-form-1120',
    ],
    icon: form1120,
  },
  {
    id: 4,
    title: 'Form 1120S',
    description: 'You have formally filed paperwork with your State to become a Corporation and have made or will make an S-Election.\n(Please make sure you know whether you are an 1120 or 1120S)',
    fullDescription: 'Use this form to report the income, gains, losses, deductions, credits, etc., of a domestic corporation or other entity for any tax year covered by an election to be an S corporation.',
    nickname: 'S-Corp',
    sources: [
      'https://www.irs.gov/forms-pubs/about-form-1120-s',
    ],
    icon: form1120s,
  },
  {
    id: 5,
    title: 'Form 1065',
    description: 'Depending on your state you may or may not need to file formal paperwork for a partnership (You and at least one partner). You may be an LLC or other type of partnership entity that chooses to ultimately file a Form 1065 Return.',
    fullDescription: 'Partnerships file an information return to report their income, gains, losses, deductions, credits, etc. A partnership does not pay tax on its income but “passes through” any profits or losses to its partners. Partners must include partnership items on their tax or information returns.',
    nickname: 'Partnership',
    sources: [
      'https://www.irs.gov/forms-pubs/about-form-1065',
    ],
    icon: form1065,
  },
];
