/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import priceImg from 'images/pricing.png';
import dot from 'images/dot.png';
import Button from 'components/ui/Button';
import { Grid } from 'semantic-ui-react';
import './styles.scss';

class Pricing extends Component {
  state = {
    yearly: false,
  };

  render() {
    return (
      <div className="Pricing" id="pricing">
        <div>
          <h2 className="Pricing--heading">
            <b>PRICING</b>
          </h2>
          <p className="Pricing--description">
            {'Start your free'}
            <b> 14 day trial. No credit card is required. </b>
            {'And cancellation is easy and hassle-free!'}
          </p>
        </div>
        <Grid centered>
          <Grid.Column mobile={14} computer={7}>
            <div className="Pricing--cards Pricing--cards-first ">
              <div className="Pricing--cards--card">
                <div className="Pricing--cards--wrap">
                  <h5 className="Pricing--cards--title">Small Business Plan</h5>
                  <img src={priceImg} className="Pricing--cards--card-img" alt="..." />
                  <div className="Pricing--cards--card-body">
                    <h6 className="Pricing--cards--card-price">
                      {!this.state.yearly && (
                        <>
                          <span className="Pricing--cards--card-price--text">$15</span>
                          &nbsp; per month
                        </>
                      )}
                      {this.state.yearly && (
                        <>
                          <span className="Pricing--cards--card-price--text">$120</span>
                          &nbsp; per year
                        </>
                      )}
                    </h6>
                    <div className="Pricing--cards--card-body--switch">
                      <span className="Pricing--cards--card-body--switch-text">
                        {'Save '}
                        <span className="Pricing--cards--card-body--switch-amount">$60</span>
                        {', pay annually'}
                      </span>
                      <input className="tgl tgl-ios" id="cb2" type="checkbox" />
                      <label
                        className="tgl-btn"
                        htmlFor="cb2"
                        onClick={() => {
                          const { yearly } = this.state;
                          this.setState({ yearly: !yearly });
                        }}
                      />
                    </div>
                    <div className="Pricing--cards--card-list">
                      <div className="Pricing--cards--card-list--item">
                        <span>
                          <img src={dot} alt="" />
                        </span>
                        <span>Easy to use transaction categorization tool.</span>
                      </div>
                      <div className="Pricing--cards--card-list--item">
                        <span>
                          <img src={dot} alt="" />
                        </span>
                        <span>Artificial Intelligence Assistance.</span>
                      </div>
                      <div className="Pricing--cards--card-list--item">
                        <span>
                          <img src={dot} alt="" />
                        </span>
                        <span>
                          {`
                            Automatically import your bank and credit card transactions with unlimited expense entries.
                          `}
                        </span>
                      </div>
                      <div className="Pricing--cards--card-list--item">
                        <span>
                          <img src={dot} alt="" />
                        </span>
                        <span>
                          {`
                            Generate, view, and export, Profit and Loss and tax time ready reports.
                          `}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="Pricing--footer">
                  <a href="/signup">
                    <Button text="SELECT" size="bg" />
                  </a>
                </span>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={14} computer={7}>
            <div className="Pricing--cards">
              <div className="Pricing--cards--card">
                <div className="Pricing--cards--wrap">
                  <h5 className="Pricing--cards--title">Custom Package</h5>
                  <img src={priceImg} className="Pricing--cards--card-img" alt="..." />
                  <div className="Pricing--cards--card-body">
                    <h6 className="Pricing--cards--card-price">
                      <span className="Pricing--cards--card-price--text">ENTERPRISE </span>
                    </h6>
                    <div className="Pricing--cards--card-list">
                      <div className="Pricing--cards--card-list--item">
                        <span>
                          <img src={dot} alt="" />
                        </span>
                        <span>Everything in the Small Business Plan</span>
                      </div>
                      <div className="Pricing--cards--card-list--item">
                        <span>
                          <img src={dot} alt="" />
                        </span>
                        <span>One of our professionals will do your bookkeeping </span>
                      </div>
                      <div className="Pricing--cards--card-list--item">
                        <span>
                          <img src={dot} alt="" />
                        </span>
                        <span>Monthly meetings to make sure everything is correct</span>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="Pricing--footer">
                  <a href="mailto:support@synkbooks.com">
                    <Button text="CONTACT US" type="outline" size="bg" />
                  </a>
                </span>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Pricing;
