import moment from 'moment';
import uniqby from 'lodash.uniqby';
import jsonParser from './businessTypeJsonParser';
import exportDataIntoZip, { generateXLSX } from './exportDataIntoZip';
import filterSelectedTransactions from './selectedTransactionsFilter';

export const formatDate = date => moment(date, 'MM/DD/YYYY', true).format('YYYY-MM-DD');

export const slashFormatDate = date => moment(date, 'YYYY-MM-DD', true).format('MM/DD/YYYY');

export const startSyncingDataDate = () => moment().subtract(24, 'months').format('YYYY-MM-DD');

export const splitDateRangeInput = range => range.split('-').map(date => moment(date.trim(), 'MM/DD/YYYY', true));

export const splitMonthRangeInput = range => range.split('-').map(date => moment(date.trim(), 'MM/YYYY', true));

export const businessTypeParser = jsonParser;

export const deleteDuplicatesBy = (collection, by) => uniqby(collection, by);

export const exportZip = exportDataIntoZip;

export const exportXLSX = generateXLSX;

export const calculateInputStep = (value) => {
  const mod = value % 1;
  return mod <= 0 ? 1.0 : 0.1;
};

export const isManualTransaction = transactionID => transactionID.startsWith('mt');

export const filterSelectedT = filterSelectedTransactions;

export const getDaysLeft = (endDate) => {
  let timeLeftStr = '';
  if (endDate) {
    const secondsLeft = endDate - moment().unix();
    const daysLeft = Math.floor(secondsLeft / (3600 * 24));
    const hoursLeft = Math.floor(secondsLeft % (3600 * 24) / 3600);
    const minsLeft = Math.floor(secondsLeft % 3600 / 60);
    if (daysLeft <= 0 && hoursLeft > 0) {
      hoursLeft === 1 ? timeLeftStr = `${hoursLeft} hour` : timeLeftStr = `${hoursLeft} hours`;
    } else if (hoursLeft <= 0 && minsLeft > 0) {
      minsLeft === 1 ? timeLeftStr = `${minsLeft} minute ` : timeLeftStr = `${minsLeft} minutes `;
    } else {
      daysLeft === 1 ? timeLeftStr = `${daysLeft} day` : timeLeftStr = `${daysLeft} days`;
    }
  }
  return timeLeftStr;
};

export const currencyFormat = (num) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const usdCurrency = num => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(num);
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}