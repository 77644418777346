import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import {
  createInvitation,
  cancelInvitation,
} from 'libraries/api-v2/invitations-service';
import './styles.scss';

// TODO: Should be renamed to ShowPendingInviteeView
class ShowInviteeView extends Component {
  constructor(props) {
    super(props);
    this.onCancelInvite = this.onCancelInvite.bind(this);
    this.onResendInvite = this.onResendInvite.bind(this);
  }

  onCancelInvite() {
    const {
      username, token, email, onCancel,
    } = this.props;

    cancelInvitation(username, token, email)
      .then((_) => {
        onCancel();
      });
  }

  onResendInvite() {
    const {
      username,
      token,
      name: inviteeFullName,
      email: inviteeEmail,
      onResend,
    } = this.props;

    createInvitation(username, token, { inviteeEmail, inviteeFullName })
      .then((_) => {
        onResend();
      });
  }

  render() {
    const { email, name, status } = this.props;

    return (
      <div className="ShowInvitee__Container">
        <div className="show-invitee-invitee-details">
          <span className="ShowInvitee__Name"><strong>{ name }</strong></span>
          <br />
          <span className="ShowInvitee__Email">{ email }</span>
          <br />
          <Button className="ShowInvitee__CancelButton" onClick={this.onCancelInvite} size="mini">Cancel Invite</Button>
          <Button className="ShowInvitee__ResendButton" onClick={this.onResendInvite} size="mini">Resend Invite</Button>
        </div>
        <div className="show-invitee-status">
          <span className="ShowInvitee__Status--Pending">Pending Invitation</span>
        </div>
      </div>
    );
  }
}

export default ShowInviteeView;
