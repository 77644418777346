import React from 'react';
import cs from 'classnames';

import './styles.scss';

const withLabel = (Component) => {
  const WithLabel = ({ label, ...props }) => {
    const hasValue = props.value && props.value.length !== 0;
    return (
      <fieldset className={cs('fieldset-hoc', { active: hasValue }, props.className)}>
        <legend className={cs({ 'has-label': label })}>{label && hasValue ? label : ''}</legend>
        <Component {...props} />
      </fieldset>
    );
  };

  return WithLabel;
};

export default withLabel;
