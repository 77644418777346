import React from 'react';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Message,
  Modal,
  Radio,
  Segment,
  Select,
  Table
} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import withNotifications from 'components/hocs/WithNotifications';
import './styles.scss';
import moment from "moment";
import {splitMonthRangeInput, usdCurrency} from "libraries/utils";
import {BUDGETING_ERROR_CODES} from "libraries/formValidator";
import {Scrollbars} from 'react-custom-scrollbars';
import categories from "../../../libraries/categories.json"
import {getBudgetingList, getBudgetingTransactions, updateBudgetingList} from "libraries/api-v2/budgeting-service";
import BTransactionsTable from "components/presentationals/BTransactionsTable";
import {filterByPage, filterBySearch, MAX_ITEMS_PER_PAGE} from "libraries/transactionsFilterManager";
import {MonthRangeInput} from "semantic-ui-calendar-react";
import CategoryPieChart from "components/containers/Budgeting/Chart/CategoryPieChart";
import BudgetingChart from "components/containers/Budgeting/Chart/BudgetingChart";
import cs from "classnames";

const getDefaultYTDDate = () => {
  const fromMonth = moment().startOf('year').format('MM/YYYY');
  const today = moment().subtract(1, 'month').format('MM/YYYY');
  return `${fromMonth} - ${today}`;
};

const newCategoriesOptions = [
  {key: 'i', text: 'Income', value: 'income'},
  {key: 'e', text: 'Expense', value: 'expense'},
]

export class BudgetingSummary extends React.Component {
  state = {
    isLoading: false,
    isUpdating: false,
    isYTDShow: true,
    isBudgetingModalOpen: false,
    isChartModalOpen: false,
    openedChartModal: 'income',
    budgetingList: [],
    errors: [],
    addBudgetToggle: false,
    incomeCategoriesToggle: true,
    expenseCategoriesToggle: true,
    newCategoryName: '',
    newAmount: '',
    newType: 'expense',
    budgetToAdd: [],
    itemLists: [],
    currentPage: 1,
    hasError: false,
    selectedCategoryName: '',
    filters: {
      ORDER: {
        DIRECTION: 'ascending',
        COLUMN: 'date',
      },
      SEARCH: '',
    },
    tableTransactions: [],
    allTableTransactions: [],
    monthRange: getDefaultYTDDate(),
  }

  componentDidMount() {
    this.getBudgetingList();
  }

  getBudgetingList = () => {
    const {isYTDShow, monthRange, isLoading} = this.state;
    let [startMonth, endMonth] = splitMonthRangeInput(monthRange);
    const diffMonths = moment(endMonth).diff(moment(startMonth), 'months', true)
    if (startMonth.isValid() && endMonth.isValid() && !isLoading) {
      this.setState({
        isLoading: true,
        itemLists: [],
        tableTransactions: [],
        allTableTransactions: []
      });
      getBudgetingTransactions(moment(startMonth).startOf('month').format('YYYY-MM-DD'), moment(endMonth).endOf('month').format('YYYY-MM-DD'))
        .then(({transactions}) => {
          const allTransactions = [], categoriesFromTransactions = [], categoriesFromBudgetingList = [], otherDeductionCategories = [];
          transactions.map(transaction => {
            transaction.items.map(item => {
              categoriesFromTransactions.push(item.category_name);
              allTransactions.push(item.transactions || []);
              this.setState(prev => ({
                itemLists: [
                  ...prev.itemLists,
                  {
                    category_name: item.category_name,
                    type: transaction.group_name === "Income" ? 'income' : 'expense',
                    projected: isYTDShow ? (item.projected * (diffMonths + 1) | 0) : item.projected | 0,
                    transactions: item.transactions || [],
                    actual: item.category_name.includes('Other Deductions') ? (isYTDShow ? +item.total_other : +item.total_other / (diffMonths + 1)) : item.category_name.includes('COGS') ? (isYTDShow ? +item.total_cogs : +item.total_cogs / (diffMonths + 1)) : (isYTDShow ? +item.total : +item.total / (diffMonths + 1)),
                  }
                ]
              }))
              if (item.category_name.includes('Other Deductions')) {
                item.other_categories.map(otherItem => {
                  categoriesFromTransactions.push(otherItem.other_type);
                  otherDeductionCategories.push(otherItem.other_type);
                  allTransactions.push(otherItem.transactions || []);
                  this.setState(prev => ({
                    itemLists: [
                      ...prev.itemLists,
                      {
                        category_name: otherItem.other_type,
                        type: 'expense',
                        projected: isYTDShow ? (otherItem.other_projected * (diffMonths + 1) | 0) : otherItem.other_projected,
                        actual: isYTDShow ? +otherItem.total : (+otherItem.total / (diffMonths + 1)),
                        transactions: otherItem.transactions || [],
                        others: true,
                      }
                    ]
                  }))
                })
              }
            })
          })
          const concatAllTransactions = allTransactions.reduce((prev, current) => [...prev, ...current]);
          this.setState({
            allTableTransactions: concatAllTransactions,
            tableTransactions: concatAllTransactions,
            otherDeductionCategories
          }, () => {
            this.orderBy();
          })
          getBudgetingList().then(res => {
            if (res.status) {
              res.data.map(item => {
                categoriesFromBudgetingList.push(item.category_name);
              });
              this.setState({
                budgetingList: res.data,
                isLoading: false,
                budgetToAdd: categoriesFromTransactions.filter(el => categoriesFromBudgetingList.indexOf(el) < 0).map(name => ({
                  key: name,
                  text: name,
                  value: name,
                }))
              });
            } else {
              this.setState({
                isLoading: false,
                budgetingList: categories[this.props.userProfile.form].filter(t =>
                  t.name !== "Uncategorized" &&
                  t.name !== "Withdraw or Owner Draw (Not Deductible)"
                ).map(category => ({
                  category_name: category.name,
                  amount: '',
                  type: category.type.includes('Income') ? 'income' : 'expense',
                }))
              })
            }
          })
        })
        .catch(e => {
          this.setState({apiError: true, isLoading: false});
        })
    }
  }

  setOpen = (open) => {
    this.setState({isBudgetingModalOpen: open});
  }

  setChartOpen = (open, type) => {
    this.setState({
      isChartModalOpen: open,
      openedChartModal: type,
    })
  }

  onBack = () => {
    this.props.history.goBack();
  };

  handleBudgetListChange = ({target}) => {
    this.setState(prev => ({
      budgetingList: prev.budgetingList.map(obj => (obj.category_name === target.id ? Object.assign(obj, {amount: +target.value}) : obj))
    }));
  }

  handleChangeInput = (e, {name, value}) => {
    this.setState({[name]: value})
  }

  onDateChange = (event, {name, value}) => {
    this.setState({
      [name]: value
    });
  }

  onAddNewCategory = () => {
    const {newCategoryName, newAmount, newType} = this.state;
    this.setState(prev => ({
      budgetingList: [
        ...prev.budgetingList,
        {
          category_name: newCategoryName,
          amount: +newAmount,
          isNew: true,
          type: newType
        }
      ],
      budgetToAdd: prev.budgetToAdd.filter(t => t.key !== newCategoryName),
      newCategoryName: '',
      newAmount: '',
      newType: 'expense',
    }))
  }

  handleRemoveNewCategory = (e, {name}) => {
    this.setState(prev => ({
      budgetingList: this.state.budgetingList.filter(item => item.category_name !== name),
      budgetToAdd: [
        {
          key: name,
          text: name,
          value: name,
        },
        ...prev.budgetToAdd,
      ],
    }))
  }

  validBudgetingList = (data) => {
    return data.some(item => typeof item.amount === "number" && item.amount > 0)
  }

  onSubmit = () => {
    const {budgetingList} = this.state;
    this.setState({errors: [], isUpdating: true});
    if (this.validBudgetingList(budgetingList)) {
      updateBudgetingList(budgetingList)
        .then(res => {
          this.setState({isUpdating: false, isBudgetingModalOpen: false});
          this.getBudgetingList();
        })
        .catch(() => {
          this.setState({apiError: true, isUpdating: false})
        })
    } else {
      this.setState({isUpdating: false});
      this.addFormError(BUDGETING_ERROR_CODES.NOTHING_TO_UPDATE.id)
    }
  }

  addFormError = (errorCode) => {
    this.setState(prevState => ({
      errors: [
        ...prevState.errors,
        BUDGETING_ERROR_CODES[errorCode].content,
      ],
    }));
  }

  handlePaginationChange = (e, {activePage}) => {
    this.setState({currentPage: activePage});
  }

  onSearchChange = (event, {value}) => {
    this.setState(prev => ({
      filters: {
        ...prev.filters,
        ['SEARCH']: value
      }
    }));
  }

  filterTransactions = (transactions) => {
    const {filters} = this.state;
    let filtered = transactions;
    if (filters.SEARCH) {
      filtered = filterBySearch(filters.SEARCH, filtered);
    }
    return filtered;
  };

  orderBy = (column = "date") => {
    const {filters: {ORDER}, tableTransactions: data} = this.state;
    let direction = 'descending';
    if (ORDER.COLUMN === column) {
      direction = ORDER.DIRECTION === 'descending' ? 'ascending' : 'descending';
    }

    if (column !== "amount")
      data.sort(
        (a, b) =>
          direction === "descending"
            ? a[column].localeCompare(b[column])
            : b[column].localeCompare(a[column])
      )
    else
      data.sort(
        (a, b) =>
          direction === "descending" ? a[column] - b[column] : b[column] - a[column]
      )
    this.setState({
      tableTransactions: data,
      filters: {
        ['ORDER']: {
          DIRECTION: direction,
          COLUMN: column
        }
      }
    })
  }

  getTotalPages = transactions => Math.ceil(transactions.length / MAX_ITEMS_PER_PAGE)

  onCategoryClick = (event, transactions, category_name, others) => {
    event.stopPropagation();
    if (category_name.includes('Other Deductions') && this.state.itemLists.length > 0) {
      transactions = this.state.itemLists.filter(item => item.others).map(t => t.transactions).reduce((prev, current) => [...prev, ...current]);
    }
    this.setState({
      tableTransactions: transactions || [],
      selectedCategoryName: others ? `Other Deductions / ${category_name}` : category_name,
    });
  }

  onAllCategoryClick = () => {
    this.setState({
      tableTransactions: this.state.allTableTransactions,
      selectedCategoryName: '',
    });
  }

  render() {
    const today = moment().format('MM/YYYY');
    const {
      newCategoryName,
      newAmount,
      newType,
      budgetToAdd,
      itemLists,
      tableTransactions,
      selectedCategoryName,
      isYTDShow,
      otherDeductionCategories,
      monthRange
    } = this.state;

    return (
      <>
        <Modal
          onClose={() => this.setOpen(false)}
          onOpen={() => this.setOpen(true)}
          open={this.state.isBudgetingModalOpen}
          size="small" closeOnEscape={true} closeOnDimmerClick={false}>
          <Modal.Header
            content="MONTHLY PROJECTED BUDGET"
            style={{backgroundColor: '#e06665', color: 'white'}}
          />
          <Modal.Content scrolling>
            <Form>
              <h3>How to use the budgeting feature?</h3>
              <p>
                Gross Receipts (Sales, Revenue, Income): Please enter what you project your gross earnings to be per month.
              </p>
              <p>
                Expenses: Please enter what you project each category expense to be per month.
              </p>
              {
                this.state.errors.length > 0
                && (
                  <Message
                    negative
                    header="It looks like you have some errors"
                    list={this.state.errors}
                  />)
              }
              <Form.Field widths="equal">
                {
                  this.state.budgetingList.sort((a, b) => {
                    if (a.type < b.type) return 1;
                    if (a.type > b.type) return -1;
                    return 0;
                  }).map(item =>
                    <Form.Group key={item.category_name} className={cs(
                      'Category__Group__Row',
                      {'--Income': item.type === 'income'},
                    )}>
                      <Form.Input
                        id={item.category_name}
                        label={otherDeductionCategories.includes(item.category_name) ? `Other Deductions: ${item.category_name}` : item.category_name}
                        type="number"
                        width={14}
                        placeholder="0"
                        defaultValue={item.amount}
                        onChange={this.handleBudgetListChange}
                      />
                      {
                        item.isNew && (
                          <Form.Button
                            width={2}
                            name={item.category_name}
                            onClick={this.handleRemoveNewCategory}
                            className="NewItem__Close">
                            X
                          </Form.Button>
                        )
                      }
                    </Form.Group>
                  )
                }
              </Form.Field>
              {
                this.state.errors.length > 0
                && (
                  <Message
                    negative
                    header="It looks like you have some errors"
                    list={this.state.errors}
                  />)
              }
              <Radio
                checked={this.state.addBudgetToggle}
                label='Add New Projected budget'
                onChange={(e, {checked}) => this.setState({addBudgetToggle: checked})}
                toggle
              />
              {
                this.state.addBudgetToggle && (
                  <>
                    <Divider horizontal className="Budgeting__divider" clearing/>
                    <Form.Group widths="equal"
                                className={cs(
                                  'Category__Group__Row',
                                  {'--Income': newType === 'income'},
                                )}>
                      <Form.Field>
                        <label>Category Name</label>
                        <Dropdown
                          placeholder='Advertising'
                          search
                          selection
                          name="newCategoryName"
                          onChange={this.handleChangeInput}
                          options={this.state.budgetToAdd}/>
                      </Form.Field>
                      <Form.Input
                        label="Amount"
                        type="number"
                        name="newAmount"
                        placeholder={0}
                        value={newAmount}
                        onChange={this.handleChangeInput}
                      />
                      <Form.Field
                        control={Select}
                        label='Type'
                        name="newType"
                        defaultValue='expense'
                        options={newCategoriesOptions}
                        onChange={this.handleChangeInput}
                        placeholder='Type'
                      />
                    </Form.Group>
                    <Form.Field className="NewItem__ADD">
                      <Button
                        type='button'
                        primary
                        disabled={!newCategoryName || !newAmount || newAmount <= 0}
                        onClick={this.onAddNewCategory}>ADD</Button>
                    </Form.Field>
                  </>
                )
              }
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.onSubmit} loading={this.state.isUpdating}
                    disabled={this.state.isUpdating}>Save</Button>
            <Button onClick={
              () => {
                this.setOpen(false)
              }
            }>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          dimmer="blurring"
          size="large"
          onClose={() => this.setChartOpen(false)}
          onOpen={() => this.setChartOpen(true)}
          open={this.state.isChartModalOpen}
        >
          <Modal.Header>
            <Grid>
              <Grid.Column mobile={16} tablet={12} computer={12}>
                {this.state.expenseCategoriesToggle ? <Icon name='chart bar'/> : <Icon name='chart pie'/>}
                &nbsp;&nbsp;
                {this.state.openedChartModal === 'income' ? 'Gross Receipts (Sales, Revenue, Income)' : ' Operating Expenses'}
              </Grid.Column>
              <Grid.Column mobile={16} tablet={4} computer={4}>
                <Radio
                  className="SwitchChart"
                  checked={this.state.expenseCategoriesToggle}
                  label='Pie/Bar'
                  onChange={(e, {checked}) => this.setState({expenseCategoriesToggle: checked})}
                  toggle
                />
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            {
              this.state.expenseCategoriesToggle ? (
                <BudgetingChart itemLists={itemLists.filter(el => this.state.openedChartModal === 'income' ?
                  el.type === 'income' && el.actual < 0 :
                  el.type === 'expense' && el.actual > 0 && !el.others
                )} modal/>
              ) : (
                <CategoryPieChart
                  itemLists={itemLists.filter(el => this.state.openedChartModal === 'income' ?
                    el.type === 'income' && el.actual < 0 :
                    el.type === 'expense' && el.actual > 0 && !el.others
                  )}
                  modal/>
              )
            }
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setChartOpen(false)}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
        <Container textAlign="right">
          <Grid>
            <Grid.Column  mobile={16} tablet={8} computer={4} className="budgeting--YTDShow" style={{display: 'flex'}}>
              <Radio
                slider
                checked={isYTDShow}
                onChange={(e, {checked}) => {
                  if (!this.state.isLoading) {
                    this.setState({isYTDShow: checked}, this.getBudgetingList)
                  }
                }
                }
                label='MONTHLY / YTD'
              />
            </Grid.Column>
            <Grid.Column float='right'  mobile={16} tablet={8} computer={6} className="budgeting--MonthRangeGrid">
              {
                isYTDShow ? (
                  <div>
                    <MonthRangeInput
                      id="budgeting-monthRange"
                      name="monthRange"
                      placeholder="---"
                      dateFormat="MM/YYYY"
                      clearable
                      closable
                      popupPosition="bottom left"
                      maxDate={today}
                      iconPosition="right"
                      value={monthRange}
                      clearIcon={<Icon name="remove" color="red"/>}
                      onChange={this.onDateChange}
                    />
                    <Button
                      onClick={() =>
                        this.getBudgetingList()}
                      disabled={this.state.isLoading}
                    >
                      GO
                    </Button>
                  </div>
                ) : null
              }
            </Grid.Column>
            <Grid.Column floated='right' mobile={16} tablet={6} computer={6}>
              <Button
                onClick={() => this.setOpen(true)}
                size="medium"
                content="PROJECTED BUDGET"
                icon="add"
                labelPosition="right"
                primary
                disabled={this.state.isLoading}
              />
            </Grid.Column>
          </Grid>
        </Container>
        <Segment
          loading={this.state.isLoading}
        >
          <div className="Budgeting__Title">
            Income Budget Summary
          </div>
          <Grid stackable columns='equal' className="Budgeting__Container">
            <Grid.Column verticalAlign="middle" mobile={16} computer={7}>
              <Scrollbars
                style={{height: 300}}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={500}
                thumbMinSize={30}
                universal={true}>
                <Table basic='very' fixed className="Budgeting__Table">
                  <Table.Header>
                    <Table.Row className="Budgeting__Table__Row">
                      <Table.HeaderCell width={7}>{isYTDShow ? `YTD` : `Monthly`} Incomes</Table.HeaderCell>
                      <Table.HeaderCell width={3} textAlign='right'>Projected</Table.HeaderCell>
                      <Table.HeaderCell width={3} textAlign='right'>Actual</Table.HeaderCell>
                      <Table.HeaderCell width={3} textAlign='right'>Variance</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      itemLists.filter(el => el.type === 'income').length ?
                        itemLists.filter(el => el.type === 'income').map(({
                                                                            category_name,
                                                                            projected,
                                                                            actual,
                                                                            transactions,
                                                                            others
                                                                          }) => {
                          const variance = projected + actual;
                          return (
                            <Table.Row
                              key={category_name}
                              onClick={(event) => this.onCategoryClick(event, transactions, category_name, others)}
                              positive={variance < 0}
                              negative={variance > 0}
                            >
                              <Table.Cell className={others && `Others__CategoryName`}>{category_name}</Table.Cell>
                              <Table.Cell textAlign='right'>{usdCurrency(projected)}</Table.Cell>
                              <Table.Cell textAlign='right'>{usdCurrency(Math.abs(actual))}</Table.Cell>
                              <Table.Cell
                                textAlign='right'>{usdCurrency(variance !== 0 ? -variance : variance)}</Table.Cell>
                            </Table.Row>
                          )
                        }) : null
                    }
                  </Table.Body>
                </Table>
              </Scrollbars>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={16} computer={9}>
              {
                itemLists.length ? (
                  <>
                    <Grid className="Chart__Top__Bar">
                      <Grid.Column floated='left' mobile={8} tablet={7} computer={7} className="Chart__Top__Title">
                        Gross Receipts (Sales, Revenue, Income)
                      </Grid.Column>
                      <Grid.Column mobile={8} tablet={4} computer={4}>
                        <Button
                          onClick={() => this.setChartOpen(true, 'income')}
                        >
                          Full Screen
                        </Button>
                      </Grid.Column>
                      <Grid.Column floated='right' mobile={16} tablet={5} computer={5}>
                        <Radio
                          className="SwitchChart"
                          checked={this.state.incomeCategoriesToggle}
                          label='Pie/Bar'
                          onChange={(e, {checked}) => this.setState({incomeCategoriesToggle: checked})}
                          toggle
                        />
                      </Grid.Column>
                    </Grid>
                    {
                      this.state.incomeCategoriesToggle ? (
                        <BudgetingChart itemLists={itemLists.filter(el => el.type === 'income' && el.actual < 0)}/>
                      ) : (
                        <CategoryPieChart itemLists={itemLists.filter(el => el.type === 'income' && el.actual < 0)}/>
                      )
                    }
                  </>
                ) : null
              }
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment
          loading={this.state.isLoading}
        >
          <div className="Budgeting__Title">
            Expense Budget Summary
          </div>
          <Grid stackable columns='equal' className="Budgeting__Container">
            <Grid.Column verticalAlign="middle" mobile={16} computer={7}>
              <Scrollbars
                style={{height: 300}}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={500}
                thumbMinSize={30}
                universal={true}>
                <Table basic='very' fixed className="Budgeting__Table">
                  <Table.Header>
                    <Table.Row className="Budgeting__Table__Row">
                      <Table.HeaderCell width={7}>{isYTDShow ? `YTD` : `Monthly`} Expenses</Table.HeaderCell>
                      <Table.HeaderCell width={3} textAlign='right'>Projected</Table.HeaderCell>
                      <Table.HeaderCell width={3} textAlign='right'>Actual</Table.HeaderCell>
                      <Table.HeaderCell width={3} textAlign='right'>Variance</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      itemLists.filter(el => el.type === 'expense').length ?
                        itemLists.filter(el => el.type === 'expense').map(({
                                                                             category_name,
                                                                             projected,
                                                                             actual,
                                                                             transactions,
                                                                             others
                                                                           }) => {
                          const variance = projected - actual;
                          return (
                            <Table.Row
                              key={category_name}
                              onClick={(event) => this.onCategoryClick(event, transactions, category_name, others)}
                              positive={variance > 0}
                              negative={variance < 0}
                            >
                              <Table.Cell className={others && `Others__CategoryName`}>{category_name}</Table.Cell>

                              {
                                category_name.includes('Other Deductions') ? (
                                  <>
                                    <Table.Cell />
                                    <Table.Cell />
                                    <Table.Cell />
                                  </>
                                ) : (
                                  <>
                                    <Table.Cell textAlign='right'>{usdCurrency(projected)}</Table.Cell>
                                    <Table.Cell textAlign='right'>{usdCurrency(actual)}</Table.Cell>
                                    <Table.Cell textAlign='right'>{usdCurrency(variance)}</Table.Cell>
                                  </>
                                )
                              }
                            </Table.Row>
                          )
                        }) : null
                    }
                  </Table.Body>
                </Table>
              </Scrollbars>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" mobile={16} computer={9}>
              {
                itemLists.length ? (
                  <>
                    <Grid doubling columns={8} className="Chart__Top__Bar">
                      <Grid.Column floated='left' mobile={8} tablet={7} computer={7} className="Chart__Top__Title">
                        Operating Expenses
                      </Grid.Column>
                      <Grid.Column mobile={8} tablet={4} computer={4}>
                        <Button
                          onClick={() => this.setChartOpen(true, 'expense')}
                        >
                          Full Screen
                        </Button>
                      </Grid.Column>
                      <Grid.Column floated='right' mobile={16} tablet={5} computer={5}>
                        <Radio
                          className="SwitchChart"
                          checked={this.state.expenseCategoriesToggle}
                          label='Pie/Bar'
                          onChange={(e, {checked}) => this.setState({expenseCategoriesToggle: checked})}
                          toggle
                        />
                      </Grid.Column>
                    </Grid>
                    {
                      this.state.expenseCategoriesToggle ? (
                        <BudgetingChart
                          itemLists={itemLists.filter(el => el.type === 'expense' && el.actual > 0 && !el.others)}/>
                      ) : (
                        <CategoryPieChart
                          itemLists={itemLists.filter(el => el.type === 'expense' && el.actual > 0 && !el.others)}/>
                      )
                    }
                  </>
                ) : null
              }
            </Grid.Column>
          </Grid>
        </Segment>
        <Grid centered>
          <Grid.Column width={10} className="Categories__BreadCrumb">
            <a onClick={this.onAllCategoryClick}>ALL CATEGORIES</a>
            {
              selectedCategoryName && (
                <>
                  <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span className="active">{selectedCategoryName}</span>
                </>
              )
            }
          </Grid.Column>
          <Grid.Column width={6}>
            <Input icon="search" iconPosition="left" placeholder="Search here" fluid
                   defaultValue={this.state.filters.SEARCH} onChange={this.onSearchChange}/>
          </Grid.Column>
        </Grid>
        <Segment>
          <BTransactionsTable
            hasError={this.state.hasError}
            isLoading={this.state.isLoading}
            currentPage={this.state.currentPage}
            transactions={filterByPage(this.state.currentPage,
              this.filterTransactions(tableTransactions), MAX_ITEMS_PER_PAGE)}
            totalPages={this.getTotalPages(this.filterTransactions(tableTransactions))}
            handlePaginationChange={this.handlePaginationChange}
            orderBy={this.orderBy}
            order={this.state.filters.ORDER}
          />
        </Segment>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
                           session: {username, userProfile},
                         }) => ({
  username,
  userProfile,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  // setIsTaxFormView,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withNotifications(withRouter(BudgetingSummary)));
