import { createSelector } from 'reselect';
import { filterBy } from 'libraries/transactionsFilterManager';

const getTransactions = state => state.transactions.allTransactions;
const getFilters = state => state.filters;

const getFilteredTransactions = createSelector(
  [getFilters, getTransactions],
  (filters, transactions) => filterBy(filters, transactions),
);

export default getFilteredTransactions;
