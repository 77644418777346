import {
  Form, Input, Search, Dropdown,
} from 'semantic-ui-react';
import { DatesRangeInput, DateInput } from 'semantic-ui-calendar-react';
import { CardElement } from 'react-stripe-elements';
import withLabel from '../../hocs/WithLabel';

export const TaxFormInput = withLabel(Form.Input);
export const TaxInput = withLabel(Input);
export const TaxSearch = withLabel(Search);
export const TaxDatesRange = withLabel(DatesRangeInput);
export const TaxDateInput = withLabel(DateInput);
export const TaxDropDown = withLabel(Dropdown);
export const TaxCardElement = withLabel(CardElement);
