import React from 'react';
import ProfitSummary from 'components/containers/ProfitSummary';
import BalanceSummary from "components/containers/Balance";

const Balance = () => (
  <>
    <ProfitSummary />
    <BalanceSummary />
  </>
);

export default Balance;
