import {
  SET_PLAID_PUBLIC_KEY,
  SET_PLAID_ENV,
  CLEAR_PLAID_ENV,
} from '../ActionTypes';

const initialState = {
  plaidPublicKey: '',
  plaidEnv: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PLAID_PUBLIC_KEY: {
      const newState = { ...state };
      newState.plaidPublicKey = payload.res;
      return newState;
    }
    case SET_PLAID_ENV: {
      const newState = { ...state };
      newState.plaidEnv = payload.res;
      return newState;
    }
    case CLEAR_PLAID_ENV: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
