import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import {
  revokeInvitation,
} from 'libraries/api-v2/invitations-service';
import './styles.scss';

class ShowAcceptedInviteeView extends Component {
  constructor(props) {
    super(props);
    this.onRevokeInvite = this.onRevokeInvite.bind(this);
  }

  onRevokeInvite() {
    const {
      username, token, email, onRevoke,
    } = this.props;

    revokeInvitation(username, token, email)
      .then((_) => {
        onRevoke();
      });
  }

  render() {
    const { email, name, status } = this.props;

    return (
      <div className="ShowInvitee__Container">
        <div className="show-invitee-invitee-details">
          <span className="ShowInvitee__Name"><strong>{ name }</strong></span>
          <br />
          <span className="ShowInvitee__Email">{ email }</span>
          <br />
          <Button className="ShowInvitee__RevokeButton" onClick={this.onRevokeInvite} size="mini">Revoke Access</Button>
        </div>
        <div className="show-invitee-status">
          <span className="ShowInvitee__Status--Accepted">Active</span>
        </div>
      </div>
    );
  }
}

export default ShowAcceptedInviteeView;
