import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon, Table, } from 'semantic-ui-react';
import { setReceivedInvitations } from 'store/actions/invitations';
import {
  approveReceivedInvitation,
  declineReceivedInvitation,
  getReceivedInvitations,
} from 'libraries/api-v2/invitations-service';
import './styles.scss';
import { filterClientsBySearch } from "screens/ClientDashboard/Clients";
import cs from "classnames";

class ClientRequestsTable extends React.Component {
  state = {
    isLoading: true,
    order: {
      DIRECTION: "",
      COLUMN: "",
    },
  }

  constructor(props) {
    super(props);
    this.renderClientRequests = this.renderClientRequests.bind(this);
    this.onApproveInvitation = this.onApproveInvitation.bind(this);
    this.onDeclineInvitation = this.onDeclineInvitation.bind(this);
    this.retrieveInvitations = this.retrieveInvitations.bind(this);
  }

  componentDidMount() {
    const { invitations: { received } } = this.props;

    this.retrieveInvitations();
  }

  onApproveInvitation(recipientEmail) {
    const { username, token } = this.props.session;

    approveReceivedInvitation(username, token, recipientEmail)
      .then(() => this.retrieveInvitations())
      .catch(err => console.error('There was a problem approving the received invitation'));
  }

  onDeclineInvitation(recipientEmail) {
    const { username, token } = this.props.session;

    declineReceivedInvitation(username, token, recipientEmail)
      .then(() => this.retrieveInvitations())
      .catch(err => console.error('There was a problem declining the received invitation'));
  }

  retrieveInvitations() {
    this.setState({ isLoading: true });

    getReceivedInvitations()
      .then((resp) => {
        this.props.setReceivedInvitations(resp);
      })
      .catch(err => (
        console.error(`Couldn't get received invitations: ${err}`)
      ));

    this.setState({ isLoading: false });
  }

  renderClientRequests() {
    const { isLoading } = this.state;
    const { invitations: { received } , search} = this.props;

    if (isLoading) {
      return [];
    }
    const filtered = filterClientsBySearch(search, received);

    return filtered.map(invite => (
      <Table.Row className="Requests__Row" key={invite.email}>
        <Table.Cell content={invite.full_name} />
        <Table.Cell className="Requests__Row__Email" content={invite.email} />
        <Table.Cell>
          Sent you a request to join&nbsp;
          <span className="Requests__Row__AccountingPro">Accounting Pro</span>
        </Table.Cell>
        <Table.Cell className="Requests__Row__Actions" textAlign="right">
          <Button className="Requests__Row__DeclineButton" onClick={() => this.onDeclineInvitation(invite.email)}>Decline</Button>
          <Button primary className="Requests__Row__ApproveButton" onClick={() => this.onApproveInvitation(invite.email)}>Approve</Button>
        </Table.Cell>
      </Table.Row>
    ));
  }

  orderBy = (key) => {
    let order = this.state.order;
    const { invitations: { received } } = this.props;
    order.COLUMN = key;
    order.DIRECTION =
      order.DIRECTION === "descending" ? "ascending" : "descending";
    this.setState({ order: order });
    this.props.setReceivedInvitations({
      invitations: received.sort(
        (a, b) =>
          order.DIRECTION === "descending"
            ? a[key].localeCompare(b[key])
            : b[key].localeCompare(a[key])
      )
    });
  };

  render() {
    return (
      <Table basic stackable celled id="Requests__Container">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              onClick={() => this.orderBy("full_name")}
            >
              Name
              <Icon
                name={cs(
                  "sort",
                  this.state.order.COLUMN === "full_name" &&
                  this.state.order.DIRECTION
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              Email
            </Table.HeaderCell>
            <Table.HeaderCell>
              Message
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.renderClientRequests()
          }
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = ({ session, invitations }) => ({
  session,
  invitations,
});

const mapDispatchToProps = dispatch => bindActionCreators({ setReceivedInvitations }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientRequestsTable);
