import React from 'react';
import { Message } from 'semantic-ui-react';
import { postPlaidLinkEvent } from 'libraries/api-v2/plaid-link-events';
import './style.scss';

const ignoreErrors = [
  'INVALID_CREDENTIALS',
  'INVALID_MFA',
];

export class ErrorPlaidMessages extends React.Component {
  state = {
    display_message: '',
    request_id: '',
    hidden: true,
  }

  constructor(props) {
    super(props);
    this.handleOnExit = this.handleOnExit.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.setState({ hidden: true });
  }

  handleOnExit(error, { institution, request_id: requestId, link_session_id: sessionId }) {
    if (error && requestId && ignoreErrors.indexOf(error.error_code) === -1) {
      this.setState({
        hidden: false,
        display_message: error.display_message ? error.display_message : error.error_message,
        request_id: requestId,
      });
      const event = {
        request_id: requestId,
        link_session_id: sessionId,
        error,
        institution,
      };
      postPlaidLinkEvent(event);
    }
  }

  render() {
    return (
      <Message
        negative
        hidden={this.state.hidden}
        className={`errors ${this.props.section}`}
        onDismiss={this.props.closeButton === 'true' ? this.handleDismiss : null}
      >
        <Message.Content>
          <Message.Header>{this.state.display_message}</Message.Header>
          <p>
            You can follow up this error with support team with this code:
            <b className="request-id">{` ${this.state.request_id}`}</b>
          </p>
        </Message.Content>
      </Message>
    );
  }
}
export default (ErrorPlaidMessages);
