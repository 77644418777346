import {
  SET_TRANSACTION_PERIOD,
  SET_ACCOUNT_NAME,
  SET_SELECTED_FORM,
  SET_BUSINESS_TYPE,
  SET_BUSINESS_TYPE_DETAILS,
  SET_PUBLIC_ACCESS_TOKEN,
  REMOVE_PUBLIC_ACCESS_TOKEN,
  CLEAR_ONBOARDING,
} from '../ActionTypes';

const initialState = {
  selectedForm: '',
  businessType: '',
  businessDetails: '',
  businessTypeCode: '',
  startDate: undefined,
  endDate: undefined,
  accountName: '',
  publicAccessToken: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TRANSACTION_PERIOD: {
      const newState = { ...state };
      const period = payload.res;
      const startDate = period.slice(0, 10);
      const endDate = period.slice(13, 24);
      newState.startDate = startDate;
      newState.endDate = endDate;
      return newState;
    }
    case SET_ACCOUNT_NAME: {
      const newState = { ...state };
      newState.accountName = payload.res;
      return newState;
    }
    case SET_SELECTED_FORM: {
      const newState = { ...state };
      newState.selectedForm = payload.res;
      return newState;
    }
    case SET_BUSINESS_TYPE: {
      const newState = { ...state };
      newState.businessType = payload.res.name;
      newState.businessTypeCode = payload.res.code;
      return newState;
    }
    case SET_BUSINESS_TYPE_DETAILS: {
      const newState = { ...state };
      newState.businessDetails = payload.res;
      return newState;
    }
    case CLEAR_ONBOARDING: {
      return initialState;
    }
    case SET_PUBLIC_ACCESS_TOKEN:
      return {
        ...state,
        publicAccessToken: payload,
      };
    case REMOVE_PUBLIC_ACCESS_TOKEN:
      return {
        ...state,
        publicAccessToken: '',
      };
    default:
      return state;
  }
};

export default reducer;
