
import React from 'react';
import {
  Grid, Form, Container, Button, Message, Header,
} from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Routes from 'routes';
import { isValidEmail, FORM_ERROR_CODES } from 'libraries/formValidator';
import withNotifications from 'components/hocs/WithNotifications';
import { sendConfirmEmail } from 'libraries/api-service';
import { updateRecoveryEmail } from 'store/actions/passwordRecovery';

export class PasswordRecoveryEmail extends React.Component {
  state = {
    hasValidEmail: true,
    isLoading: false,
  };

  emailRef = React.createRef();

  componentDidMount() {
    this.emailRef.current.focus();
  }

  handleInputChange = ({ target }) => {
    this.props.updateRecoveryEmail(target.value);
  }

  onSubmit = () => {
    const { email } = this.props;
    if (isValidEmail(email)) {
      const { history, pushNotification } = this.props;
      this.setState({ isLoading: true });
      sendConfirmEmail(email)
        .then(() => {
          pushNotification('CONFIRM_EMAIL_CODE_SENT_SUCCESS',
            'Confirmation email sent.',
            null,
            'success', 7000);
          history.push(Routes.Login);
        })
        .catch(({ message }) => {
          this.setState({ isLoading: false });
          pushNotification('CONFIRM_EMAIL_CODE_SENT_FAIL', message,
            null,
            'error', 7000);
        });
    } else {
      this.setState({ hasValidEmail: false });
    }
  }

  render() {
    const { hasValidEmail } = this.state;
    const { email } = this.props;
    return (
      <Grid centered>
        <Grid.Row>
          <Header as="h1">Confirm email</Header>
        </Grid.Row>
        <Grid.Row>
          <p>{'Didn\'t you receive the confirmation email?'}</p>
          <p>
            Try checking in your
            <b> spam email.</b>
          </p>
          <p>{'If it\'s not there try sending it with the form bellow: '}</p>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form
              onSubmit={this.onSubmit}
              loading={this.state.isLoading}
            >
              <Form.Field required>
                <input
                  type="email"
                  id="recovery_email"
                  ref={this.emailRef}
                  placeholder="youremail@domain.com"
                  value={email}
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Field>
              {
                !hasValidEmail
                && (
                <Message
                  negative
                  header="Verify your email"
                  content={FORM_ERROR_CODES.EMAIL_INVALID_FORMAT.content}
                />
                )
              }
              <Container textAlign="center">
                <Button primary>Send</Button>
              </Container>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Link to={Routes.Login}>Back to login</Link>
      </Grid>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ passwordRecovery: { email } }) => ({
  email,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  updateRecoveryEmail,
}, dispatch);


const wrappedComponent = withRouter(withNotifications(PasswordRecoveryEmail));
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
