import React from 'react';
import moneyImg from 'images/moneyp.png';
import timeImg from 'images/timep.png';
import expImg from 'images/experiencep.png';
import { Grid } from 'semantic-ui-react';
import './styles.scss';

const Card = ({ img, title, description }) => (
  <div className="ChooseUs--cards">
    <div className="ChooseUs--cards--card shadow-lg mb-5 bg-white rounded">
      <img src={img} className="ChooseUs--cards--card-img card-img-top" alt="..." />
      <div className="ChooseUs--cards--card-body">
        <h5 className="ChooseUs--cards--card-title">{title}</h5>
        <p className="ChooseUs--cards--card-text">{description}</p>
      </div>
    </div>
  </div>
);

const ChooseUs = () => (
  <div className="ChooseUs">
    <div className="container">
      <Grid centered>
        <Grid.Column mobile={16} computer={8}>
          <h2 className="ChooseUs--title">
            {'Why we are '}
            <b>different?</b>
          </h2>
          <p className="ChooseUs--description">
            {`We offer an easy-to-use bookkeeping system that helps you with your
            bookkeeping. As you work on your bookkeeping throughout the year,
            you're also building the preview of your end of the year tax return!`}
          </p>
        </Grid.Column>
      </Grid>
      <Grid centered>
        <Grid.Column mobile={14} computer={5}>
          <Card
            img={moneyImg}
            title="SAVE MONEY"
            description={`Our software costs less than most other options. And SynkBooks'
                comprehensive bookkeeping features helps you find more
                deductible business expenses, so you can save more at tax time.`}
          />
        </Grid.Column>
        <Grid.Column mobile={14} computer={5}>
          <Card
            img={timeImg}
            title="SAVE TIME"
            description={`Our easy-to-use, artificial intelligence-powered expense
          categorization helps you understand all your spending -- all the
          time. Our bookkeeping system saves you LOTS of time when you're
          preparing for tax time.`}
          />
        </Grid.Column>
        <Grid.Column mobile={14} computer={5}>
          <Card
            img={expImg}
            title="EXPERIENCE"
            description={`SynkBooks was developed by tax attorneys with years of
          experience helping business owners. They know what you need to
          save the most time and money. And they've put it all into
          Synkbooks!`}
          />
        </Grid.Column>
      </Grid>
    </div>
  </div>
);

export default ChooseUs;
