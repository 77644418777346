import React from 'react';
import './styles.scss';
import { Grid } from 'semantic-ui-react';
import { TaxDateInput, TaxInput } from 'taxUI';
import moment from 'moment';
import { calculateInputStep } from 'libraries/utils';

const getStep = (props) => {
  if (props.contentType === 'creation') {
    return '0.01';
  }
  return calculateInputStep(props.amount);
};

const EditMoreInfoTranSection = (props) => {
  const preventMoreDecimals = (event) => {
    const reg = /^\d*(\.\d{0,2})?$/;
    const actual = `${event.currentTarget.value}${event.key}`;
    !reg.test(actual) && event.preventDefault();
  };

  const today = moment().format('MM/DD/YYYY');
  return (
    <Grid.Row>
      <Grid.Column width={2} />
      <Grid.Column width={12}>
        <Grid>
          <Grid.Column mobile={16} tablet={5} computer={5}>
            <TaxInput
              id="amount"
              name="amount"
              label="Amount"
              onChange={props.handleInputChange}
              onKeyPress={preventMoreDecimals}
              step={getStep(props)}
              type="number"
              icon="dollar"
              iconPosition="left"
              placeholder="Amount"
              value={props.amount}
              className="ECTransaction__Amount"
              style={{ width: '8em' }}
              required
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={5} computer={5}>
            <TaxDateInput
              id="customDate"
              className="ECTransaction__DateRange"
              name="customDate"
              label="Date"
              placeholder="Select a date"
              dateFormat="MM/DD/YYYY"
              iconPosition="left"
              popupPosition="top right"
              maxDate={today}
              value={props.customDate}
              onChange={props.handleInputChange}
              required
            />
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column width={2} />
    </Grid.Row>
  );
};

export default EditMoreInfoTranSection;
