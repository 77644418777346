import React from 'react';
import { Grid, Image, Container } from 'semantic-ui-react';

import taxlabLogo from 'images/logo.svg';
import './styles.scss';
import Notifications from 'components/presentationals/Notification';

const Layout = ({ children }) => (
  <div className="Layout">
    <Notifications />
    <Grid container centered>
      <Grid.Column computer={6} tablet={10} mobile={16}>
        <Container className="Layout__Image-Container">
          <Image centered src={taxlabLogo} size="medium" spaced />
        </Container>
        {children}
      </Grid.Column>
    </Grid>
  </div>
);

export default Layout;
