import API from '@aws-amplify/api';
import { ENDPOINT_V2 } from 'libraries/config';

const apiName = ENDPOINT_V2;

const apiRequestV2 = (method, url, params) => API[method](apiName, url, {
  ...params,
  response: true,
});

export default apiRequestV2;
