import React from 'react';
import cs from 'classnames';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

import './styles.scss';

const Card = ({
  children, header, actions, autoHide, hideActions, className, loading,
}) => (
  <Segment className={cs('Card', className)}>
    <Dimmer active={loading} inverted>
      <Loader size="medium">Loading</Loader>
    </Dimmer>
    {(header || (!hideActions && actions)) && (
      <div className="margin">
        <header>
          {header && <h1>{header}</h1>}
          {!hideActions && actions && <div className={autoHide ? 'autoHide' : ''}>{actions}</div>}
        </header>
      </div>
    )}
    <div>
      {children}
    </div>
  </Segment>
);

Card.defaultProps = { loading: false };

export default Card;
