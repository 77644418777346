import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Routes from 'routes';
import { withRouter } from 'react-router-dom';
import jsCookie from 'js-cookie';
import { Button, Icon, Message, Modal, Table, } from 'semantic-ui-react';
import { setClients } from 'store/actions/clients';
import { clearTransactions as clearClientTransactions } from 'store/actions/clientTransactions';
import { getClients, } from 'libraries/api-v2/clients-service';
import './styles.scss';
import { removeInvitation } from "libraries/api-v2/invitations-service";
import { filterClientsBySearch } from "screens/ClientDashboard/Clients";
import cs from "classnames";

class ClientsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      deactivating: false,
      isModalOpen: false,
      order: {
        DIRECTION: "",
        COLUMN: "",
      },
      removeClientEmail: '',
      removeClientFullName: ''
    };

    this.renderClients = this.renderClients.bind(this);
  }

  componentDidMount() {
    const { clients: { active } } = this.props;

    jsCookie.remove('active_client_email');
    jsCookie.remove('active_client_form_name');
    jsCookie.remove('active_client_full_name');

    this.props.clearClientTransactions();
    this.getClientsData();
  }

  getClientsData = () => {
    getClients().then((resp) => {
      this.props.setClients(resp.clients);
      this.setState({ isLoading: false });
    }).catch(err => (
      console.error(`Couldn't get received invitations: ${err}`)
    ));
    // TODO: Catch the error
  }

  onCloseModal = () => this.setState({ isModalOpen: false });

  onViewBooks(clientRequest) {
    const { history } = this.props;

    return function () {
      jsCookie.set('active_client_email', clientRequest.email);
      jsCookie.set('active_client_form_name', clientRequest.tax_document_name);
      jsCookie.set('active_client_full_name', clientRequest.full_name);

      history.push(Routes.ClientDashboardTransactions);
    };
  }

  onRemoveClient(clientRequest) {
    const props = this;
    return function () {
      props.setState({
        isModalOpen: true,
        removeClientEmail: clientRequest.email,
        removeClientFullName: clientRequest.full_name
      })
    };
  }

  onDeactivate = () => {
    const { removeClientEmail, removeClientFullName } = this.state;
    this.setState({ deactivating: true }, () => {
      removeInvitation(removeClientFullName, removeClientEmail)
        .then((res) => {
          this.setState({
            deactivating: false,
            isModalOpen: false,
            removeClientEmail: '',
            removeClientFullName: ''
          });
          this.getClientsData();
        });
    });
  }

  renderClients() {
    const { isLoading } = this.state;
    const { clients: { active }, search } = this.props;

    if (isLoading) {
      return [];
    }

    const filtered = filterClientsBySearch(search, active);

    return filtered.map(req => (
      <Table.Row key={req.email}>
        <Table.Cell content={req.full_name} />
        <Table.Cell className="Clients__Row__Email" content={req.email} />
        <Table.Cell content={moment.utc(req.updated_at).format('LL')} />
        <Table.Cell textAlign="right">
          <Button icon className="Clients__Row__RemoveButton" onClick={this.onRemoveClient(req)}>
            REMOVE CLIENT
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Icon className="Clients__Row__RemoveIcon" name="remove user" size="large" />
          </Button>
          <Button primary className="Clients__Row__ViewBooksButton" onClick={this.onViewBooks(req)}>
            View Books
            <Icon className="Clients__Row__BookIcon" name="book" size="large" />
          </Button>
        </Table.Cell>
      </Table.Row>
    ));
  }

  orderBy = (key) => {
    let order = this.state.order;
    const { clients: { active } } = this.props;
    order.COLUMN = key;
    order.DIRECTION =
      order.DIRECTION === "descending" ? "ascending" : "descending";
    this.setState({ order: order });
    this.props.setClients(
      active.sort(
        (a, b) =>
          order.DIRECTION === "descending"
            ? a[key].localeCompare(b[key])
            : b[key].localeCompare(a[key])
      )
    );
  };

  render() {
    const { isModalOpen, deactivating, removeClientEmail, removeClientFullName } = this.state;
    return (
      <Table basic stackable celled id="Clients__Container">
        <Modal
          open={isModalOpen}
          size="tiny"
          onClose={this.onCloseModal}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Remove Client</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p className="RemoveClient__Confirm_Text">
                By clicking, Remove, you are removing yourself as Bookkeeper from <span>{removeClientFullName} ({removeClientEmail})</span> and will no longer have access to the
                client's Synkbooks account. <br />
                Would you like to continue with the removal?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {deactivating && (
              <Message icon negative>
                <Icon name="circle notched" loading />
                <Message.Content style={{ textAlign: 'left' }}>
                  <Message.Header>Just one second</Message.Header>
                  We are removing you from client's Synkbooks account...
                </Message.Content>
              </Message>
            )}
            {!deactivating && (
              <Fragment>
                <Button onClick={this.onCloseModal}>Cancel</Button>
                <Button
                  onClick={this.onDeactivate}
                  primary
                  labelPosition="right"
                  icon="user delete"
                  content="Remove"
                />
              </Fragment>
            )}
          </Modal.Actions>
        </Modal>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              onClick={() => this.orderBy("full_name")}
            >
              Name
              <Icon
                name={cs(
                  "sort",
                  this.state.order.COLUMN === "full_name" &&
                  this.state.order.DIRECTION
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              Email
            </Table.HeaderCell>
            <Table.HeaderCell>
              Client Since
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.renderClients()
          }
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = ({ session, clients }) => ({
  session,
  clients,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setClients,
  clearClientTransactions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClientsTable));
