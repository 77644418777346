import React from 'react';
import { Label, Segment } from 'semantic-ui-react';

import './styles.scss';

const ProfileDetailsReadOnly = (props) => {
  const {
    email, fullName, accountType, form = 'Pending', businessType = 'Pending', imgName,
  } = props;
  return (
    <div className="ProfileDetailsReadOnly">
      <div className="margin">
        <Segment
          circular
          className="ProfileDetailsReadOnly-ImgName"
          textAlign="center"
        >
          <div>{imgName}</div>
        </Segment>
        <div>
          <div>
            <Label horizontal>{accountType}</Label>
          </div>
          <br />
          <div className="ProfileDetailsReadOnly__FullName">{fullName}</div>
          <div className="ProfileDetailsReadOnly__Email">{email}</div>
        </div>
      </div>
      <h6>Business information</h6>
      <div className="ProfileDetailsReadOnly__footer">
        <div className="margin">
          <div>{businessType}</div>
          <div>{form}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsReadOnly;
