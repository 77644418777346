import React from 'react';
import { connect } from 'react-redux';
import { Grid, Transition } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';

import { getProfitAndLoss } from 'libraries/api-service';
import { splitDateRangeInput } from 'libraries/utils';
import {
  setPnlData, togglePnlIsSelectingDate, setIsTaxFormView, setIsMonthPNLView,
} from 'store/actions/profitAndLoss';
import ProfitAndLossList from './List';
import DetailTable from './DetailTable';
import TaxFormView from './TaxFormView';

class CustomProfitAndLossSummary extends React.Component {
  state = {
    isLoading: true,
    isMounted: true, // avoids calling setstate on an unmounted component
    showTable: false,
    selectedCategory: '',
    selectedType: '',
    tableTransactions: [],
    pnlNetIncome: '',
    hasError: false,
  }

  componentDidMount() {
    const { dateRange } = this.props;
    this.updateProfitAndLoss(dateRange);
  }

  componentWillUnmount() {
    this.props.setIsTaxFormView(false);
    this.setState({ isMounted: false });
  }

  onCategoryClick = (event, transactions, type) => {
    event.stopPropagation();
    const selectedCategory = event.currentTarget.querySelector('.Profit-And-Loss__List-Item > div:first-child').innerHTML;
    this.setState({
      selectedCategory,
      showTable: true,
      tableTransactions: transactions || [],
      selectedType: type,
    });
  }

  onTotalExpensesClick = () => {
    const { pnlData } = this.props;
    const expensesItems = pnlData[1].items; // expenses
    let tableTransactions = [];
    const otherCategories = ['Other Deductions', 'Other Expenses'];
    expensesItems.forEach((item) => {
      const isCogs = item.category_name === 'COGS';
      const isOthers = otherCategories.includes(item.category_name);
      let subcatTransactionsList = [];
      if (isCogs || isOthers) {
        const categories = isCogs ? item.cogs_categories : item.other_categories;
        categories.forEach((category) => {
          subcatTransactionsList = [
            ...subcatTransactionsList,
            ...category.transactions,
          ];
        });
      }
      const transactionsList = (isCogs || isOthers) ? subcatTransactionsList : item.transactions;
      tableTransactions = [
        ...tableTransactions,
        ...transactionsList,
      ];
    });
    this.setState({
      selectedCategory: 'Total Expenses',
      showTable: true,
      tableTransactions,
      selectedType: 'expense',
    });
  }

  updateProfitAndLoss = (dateRange, tags) => {
    const [startDate, endDate] = splitDateRangeInput(dateRange);
    const { username, token } = this.props;
    if (startDate.isValid() && endDate.isValid()) {
      this.props.togglePnlIsSelectingDate(false);
      this.setState({ isLoading: true });
      getProfitAndLoss(username, token, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), tags)
        .then((pnl) => {
          this.props.setPnlData(pnl.transactions);
          this.state.isMounted && this.setState({
            isLoading: false,
            pnlNetIncome: pnl.net_income,
          });
        })
        .catch(() => {
          this.setState({ hasError: true });
        });
    }
  }

  hideTable = () => {
    this.setState({ showTable: false });
  }

  render() {
    return this.props.isTaxFormView ? (
      <TaxFormView
        updatePnl={this.updateProfitAndLoss}
        hideTable={this.hideTable}
        isLoading={this.state.isLoading}
      />
    ) : (
      <Transition.Group as={Grid} animation="fade up" duration={500}>
        <Grid.Column mobile={16} tablet={10} computer={6}>
          <ProfitAndLossList
            id="PNLList"
            userProfile={this.props.userProfile}
            updatePnl={this.updateProfitAndLoss}
            isLoading={this.state.isLoading}
            onCategoryClick={this.onCategoryClick}
            netIncome={this.state.pnlNetIncome}
            hasError={this.state.hasError}
            onTotalExpensesClick={this.onTotalExpensesClick}
            hideTable={this.hideTable}
          />
        </Grid.Column>
        {
          this.state.showTable && (
            <Grid.Column mobile={16} tablet={16} computer={10}>
              <DetailTable
                transactions={this.state.tableTransactions}
                selectedCategory={this.state.selectedCategory}
                type={this.state.selectedType}
                hideTable={this.hideTable}
              />
            </Grid.Column>
          )
        }
      </Transition.Group>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  session: { username, token, userProfile },
  profitAndLoss: { dateRange, isTaxFormView, pnlData},
}) => ({
  username,
  token,
  userProfile,
  dateRange,
  isTaxFormView,
  pnlData,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  setPnlData, togglePnlIsSelectingDate, setIsTaxFormView, setIsMonthPNLView
}, dispatch);

export const ProfitAndLossCustom =  connect(mapStateToProps, mapDispatchToProps)(CustomProfitAndLossSummary);
