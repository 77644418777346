import React from 'react';
import {connect} from 'react-redux';
import {
  Container, Grid, Form, Message,
} from 'semantic-ui-react';
import {
  updateCategories,
  getCategories,
  createTransaction,
  editTransaction,
  deleteTransaction,
} from 'libraries/api-service';
import EditMoreInfoTranSection from 'components/presentationals/TransactionsForms/EditMoreInfoTranSection';
import EditCategoryTranSection from 'components/presentationals/TransactionsForms/EditCategoryTranSection';
import MoreInfoSection from 'components/presentationals/TransactionsForms/MoreInfoSection';
import ButtonSection from 'components/presentationals/TransactionsForms/ButtonSection';
import Confirmation from 'components/presentationals/Confirmation';
import HeaderSection from 'components/presentationals/TransactionsForms/HeaderSection';
import TranTypeLabelsSection from 'components/presentationals/TransactionsForms/TranTypeLabelsSection';
import './styles.scss';
import withNotifications from 'components/hocs/WithNotifications';
import {slashFormatDate, filterSelectedT} from 'libraries/utils';
import {handleValidation, validateTransactionsType} from './validation';
import { getTransactionTagList } from "libraries/api-v2/transactions-service";

export class TransactionModalContent extends React.Component {
  state = {
    name: this.props.description || '',
    category: this.props.category || '',
    subcategory: this.props.subcategory || '',
    transactionID: this.props.transaction_id || '',
    transactionType: (this.props.amount && (this.props.amount > 0 ? 'Expense' : 'Income')) || '',
    amount: Math.abs(this.props.amount, 10) || '',
    tags: [],
    isLoadingTags: false,
    selectedTags: this.props.tags && this.props.tags.split('#') || [],
    memo: this.props.memo || '',
    customDate: (this.props.date && slashFormatDate(this.props.date)) || '',
    categories: [],
    isLoading: false,
    openConfirmation: false,
    showMoreInfo: !this.props.editionMode || false,
    hasError: false,
    errorReason: '',
    mixedTransactions: false,
  }

  componentDidMount() {
    this.setState({isLoadingTags: true}, () => {
      this.getTransactionTags();
    });
    if (this.props.contentType === 'bulk') {
      const {selected} = this.props.transactions;
      const {allTransactions} = this.props.transactions;
      const filteredSelected = filterSelectedT(allTransactions, selected);
      const isValid = validateTransactionsType(filteredSelected);
      if (isValid) {
        this.setState({
          transactionType: isValid,
          categories: this.getTransactionCategories(isValid),
        });
      } else {
        this.setState({mixedTransactions: true});
        this.handleError(0);
      }
    } else {
      const {transactionType} = this.state;
      document.addEventListener('keydown', this.escFunction, false);
      this.setState({isLoadingCategories: true}, () => {
        this.getTransactionCategories(transactionType);
      });
    }
  }

  componentWillUnmount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  getTransactionTags() {
    getTransactionTagList().then(({ tagList }) => {
      const tags = tagList.map(tag => ({
        key: tag,
        text: tag,
        value: tag
      }));
      this.setState({
        tags,
        isLoadingTags: false
      })
    }).catch(() => {
      this.setState({
        isLoadingTags: false
      })
    });
  }

  getTransactionCategories( transactionType ) {
    const {form: selectedForm} = this.props.session.userProfile;
    const formName = selectedForm || 'Schedule C';
    getCategories(formName)
      .then(( items ) => {
        this.formatToDropDown(items, transactionType);
      });
  }

  getHeader = () => (
    <HeaderSection
      contentType={this.props.contentType}
    />
  );

  getLabels = () => {
    const {transactionType} = this.state;
    const otherType = this.getValidType(transactionType, '');
    return (
      <Grid className="Modal__Content-Labels-Container">
        <Grid.Column>
          <TranTypeLabelsSection
            handleLabelStatus={this.handleLabelStatus}
            transactionType={transactionType}
            otherType={otherType}
          />
        </Grid.Column>
      </Grid>
    );
  };

  getCategoryEditionSection = () => (
    <EditCategoryTranSection
      name={this.state.name}
      handleInputChange={this.handleInputChange}
      category={this.state.category}
      categories={this.state.categories}
      isLoadingCategories={this.state.isLoadingCategories}
      subcategory={this.state.subcategory}
      tags={this.state.tags}
      isLoadingTags={this.state.isLoadingTags}
      selectedTags={this.state.selectedTags}
      handleAddition={this.handleAddition}
      memo={this.state.memo}
      editionMode={this.props.editionMode}
      editableName={this.props.contentType === 'editcategory'}
      showLeyend={this.props.contentType === 'bulk'}
      totalTransactionSelected={this.props.totalTransactionSelected}
    />
  );

  getMoreInfoSection = () => {
    const {transactionType} = this.state;
    const otherType = transactionType === 'Expense' ? 'Income' : 'Expense';
    return (
      <MoreInfoSection
        editionMode={this.props.editionMode}
        showEditInfo={this.showEditInfo}
        showMoreInfo={this.state.showMoreInfo}
        getExtraTransactionInfo={this.getExtraTransactionInfo}
        handleLabelStatus={this.handleLabelStatus}
        transactionType={transactionType}
        otherType={otherType}
      />
    );
  };

  getExtraTransactionInfo = () => {
    const auxAmount = this.state.amount.toString();
    return (
      <EditMoreInfoTranSection
        amount={auxAmount}
        handleInputChange={this.handleInputChange}
        customDate={this.state.customDate}
        contentType={this.props.contentType}
      />
    );
  }

  getButtonsRow = () => (
    <ButtonSection
      contentType={this.props.contentType}
      handleConfirmation={this.handleConfirmation}
      openConfirmation={this.state.openConfirmation}
      handleClose={this.props.handleClose}
      handleSaveCreation={this.handleSaveCreation}
      handleSaveEdition={this.handleSaveEdition}
      handleSaveBulkEdition={this.handleSaveBulkEdition}
      mixedTransactions={this.state.mixedTransactions}
    />
  );

  handleInputChange = ( event, {name, value} ) => {
    this.setState({
      [name]: value,
    });
  }

  handleAddition = ( event, { value} ) => {
    this.setState(prev => ({
      tags: [
        ...prev.tags,
        {key: value, text: value, value}
      ]
    }));
  }

  escFunction = ( event ) => {
    if (event.keyCode === 27) {
      this.props.handleClose();
    }
  }

  handleConfirmation = () => {
    this.setState({openConfirmation: true});
  }

  handleCloseConfirmation = () => {
    this.setState({openConfirmation: false});
  };

  handleLabelStatus = ( clickedType ) => {
    this.setState(( prevState ) => {
      const transactionType = this.getValidType(prevState.transactionType, clickedType);
      return ({
        transactionType,
        categories: this.getTransactionCategories(transactionType),
      });
    });
  }

  getValidType = ( type, clickedType ) => {
    if (type) {
      if (type === 'Expense' || type === 'Income') {
        return type === 'Expense' ? 'Income' : 'Expense';
      }
    } else {
      return clickedType;
    }
    return '';
  };

  showEditInfo = () => {
    this.setState(prevState => ({showMoreInfo: !prevState.showMoreInfo}));
  }

  handleSaveEdition = () => {
    this.setState({hasError: false});
    const {contentType} = this.props;
    const {username, token} = this.props.session;
    const {
      transactionID,
      customDate,
      name,
      transactionType,
      amount,
      category,
      subcategory,
      selectedTags,
      memo
    } = this.state;

    if (contentType === 'editcategory') {
      const res = handleValidation(contentType, [
        name,
        category,
        subcategory,
      ]);

      if (res) {
        const transactions = [];
        transactions.push(transactionID);
        this.setState({isLoading: true},
          () => updateCategories(username, token,
            transactions, category, subcategory, selectedTags, memo)
            .then(() => {
              this.props.handleMark(transactionID);
              this.setState({isLoading: false});
              this.props.handleClose();
            })
            .catch(() => {
              this.props.pushNotification('NOT_UPDATE_TRANSACTION_CATEGORY', 'Edition failed',
                'An error ocurred while trying to update your transactions category. Please try again.', 'error', 6000);
              this.setState({isLoading: false});
              this.props.handleClose();
            }));
      } else {
        this.handleError();
      }
    }

    if (contentType === 'edition') {
      const res = handleValidation(contentType, [
        name,
        category,
        subcategory,
        transactionType,
        amount,
        customDate,
        selectedTags,
        memo
      ]);
      if (res) {
        this.setState({isLoading: true},
          () => editTransaction(username, token,
            {
              transactionID,
              customDate,
              name,
              transactionType,
              amount,
              category,
              subcategory,
              selectedTags,
              memo
            })
            .then(() => {
              this.props.handleMark(transactionID);
              this.setState({isLoading: false});
              this.props.handleClose();
            })
            .catch(() => {
              this.props.pushNotification('NOT_UPDATE_TRANSACTION', 'Edition failed',
                'An error ocurred while trying to update your transaction. Please try again.', 'error', 6000);
              this.setState({isLoading: false});
              this.props.handleClose();
            }));
      } else {
        this.handleError();
      }
    }
  }

  handleSaveBulkEdition = () => {
    this.setState({hasError: false});
    const {
      category,
      subcategory,
      selectedTags,
      memo
    } = this.state;
    const res = handleValidation(this.props.contentType, [
      category,
      subcategory,
    ]);
    if (res) {
      const {username, token} = this.props.session;
      const {selected} = this.props.transactions;
      this.setState({isLoading: true},
        () => updateCategories(username, token,
          selected, category, subcategory, selectedTags, memo)
          .then(() => {
            this.props.handleMark(null);
            this.setState({isLoading: false});
            this.props.handleClose();
          })
          .catch(() => {
            this.props.pushNotification('NOT_UPDATE_TRANSACTION_CATEGORY', 'Edition failed',
              'An error ocurred while trying to update your transactions categories. Please try again.', 'error', 6000);
            this.setState({isLoading: false});
            this.props.handleClose();
          }));
    } else {
      this.handleError();
    }
  }

  handleSaveCreation = () => {
    this.setState({hasError: false});
    const {username, token} = this.props.session;
    const {
      customDate,
      name,
      transactionType,
      amount,
      category,
      subcategory,
      selectedTags,
      memo
    } = this.state;
    const res = handleValidation('creation', [
      name,
      category,
      subcategory,
      transactionType,
      amount,
      customDate,
    ]);
    if (res) {
      this.setState({isLoading: true},
        () => createTransaction(username, token, {
          customDate,
          name,
          transactionType,
          amount,
          category,
          subcategory,
          selectedTags,
          memo
        })
          .then(() => {
            this.setState({isLoading: false});
            this.props.updateTransactions(username, token);
            this.props.handleClose();
          })
          .catch(() => {
            this.props.pushNotification('NOT_CREATE_TRANSACTION', 'Creation failed',
              'An error ocurred while trying to create your transaction. Please try again.', 'error', 6000);
            this.setState({isLoading: false});
            this.props.handleClose();
          }));
    } else {
      this.handleError();
    }
  }

  handleDeletion = () => {
    const {username, token} = this.props.session;
    const transactionID = this.props.transaction_id;
    this.setState({isLoading: true},
      () => deleteTransaction(username, token, transactionID).then(() => {
        this.setState({isLoading: false});
        this.props.updateTransactions(username, token);
        this.props.handleClose();
      }).catch(() => {
        this.props.pushNotification('NOT_DELETE_TRANSACTION', 'Deletion failed',
          'An error ocurred while trying to delete your transaction. Please try again.', 'error', 6000);
        this.setState({isLoading: false});
        this.props.handleClose();
      }));
  };

  handleError = ( err ) => {
    switch (err) {
      case 0:
        this.setState({
          hasError: true,
          errorReason: 'You have mixed incomes and expenses, please select just one type of transactions.',
        });
        break;
      default:
        this.setState({
          hasError: true,
          errorReason: 'Please check every input is filled out.',
        });
        break;
    }
  }

  formatToDropDown( items, transactionType ) {
    const categories = items.map(( {name, type} ) => ({
      key: `${name}-${type}`,
      text: name,
      value: name,
      description: type,
    }));
    const filteredCategories = categories.filter(
      ( {description} ) => description === transactionType,
    );
    this.setState({categories: filteredCategories, isLoadingCategories: false});
  }

  render() {
    console.log(this.props);
    return (
      <Container className="Modal__Content">
        <Container textAlign="center">
          {this.getHeader()}
        </Container>
        <br/>
        <Form loading={this.state.isLoading} error={this.state.hasError}>
          <Grid>
            {this.props.contentType === 'creation' && this.getLabels()}
            {this.getCategoryEditionSection()}
            {
              this.props.contentType === 'edition' && this.getMoreInfoSection()
            }
            {
              this.props.contentType === 'creation' && this.getExtraTransactionInfo()
            }
            {this.getButtonsRow()}
            {
              this.state.openConfirmation && (
                <Container textAlign="center">
                  <Confirmation
                    handleClose={this.handleCloseConfirmation}
                    handleDeletion={this.handleDeletion}
                  />
                </Container>
              )
            }
            <Grid.Row>
              <Grid.Column width={2}/>
              <Grid.Column width={12}>
                <Form.Field>
                  <Message
                    error
                    header="Error"
                    content={this.state.errorReason}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={2}/>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  session: state.session,
});
/* istanbul ignore next */
export default connect(mapStateToProps, null)(withNotifications(TransactionModalContent));
