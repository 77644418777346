export const SECTION_ASSETS = "ASSETS";
export const SECTION_LIABILITIES = "LIABILITIES";
export const SECTION_EQUITY = "EQUITY";

export const SUB_SECTION_CURRENT_ASSETS = "Current Assets";
export const SUB_SECTION_FIXED_ASSETS = "Fixed Assets";
export const SUB_SECTION_OTHER_ASSETS = "Other Assets";
export const SUB_SECTION_CURRENT_LIABILITIES = "Current Liabilities";
export const SUB_SECTION_LONG_TERM = "Long-Term Liabilities";

const sections = [
  "ASSETS",
  "LIABILITIES",
  "EQUITY"
]

// const subSections = [
//   "Current Assets",
//   "Fixed Assets",
//   "Other Assets",
//   "Current Liabilities",
//   "Long-Term Liabilities",
// ]

const subSections = [
  { name: "Current Assets", section_type: "ASSETS" },
  { name: "Fixed Assets", section_type: "ASSETS" },
  { name: "Other Assets", section_type: "ASSETS" },
  { name: "Current Liabilities", section_type: "LIABILITIES" },
  { name: "Long-Term Liabilities", section_type: "LIABILITIES" },
]

const categoryNames = [
  // assets
  "Cash",
  "Petty Cash",
  "Cash and Cash Equivalents",
  "Accounts Receivables",
  "Inventory",
  "Prepaid Expenses",
  "Employee Advances",
  "Temporary Investments",
  "Short-Term Investments",
  "Land",
  "Buildings",
  "Furniture and Equipment",
  "Computer Equipment",
  "Vehicles",
  "Less Accumulated Depreciation",
  "Long-term investments",
  "Property costs",
  "Equipment costs",
  "Intangible assets",
  "Trademarks",
  "Patents",
  "Security Deposits",
  "Bond Issue Costs",
  "Deferred Tax Income",
  "Prepaid Pension Costs",
  "Other Assets",
  // liabilities
  "Accounts Payable",
  "Business Credit Cards",
  "Sales Tax Payable",
  "Payroll Liabilities",
  "Other Liabilities",
  "Accrued expenses",
  "Current Portion of Long-Term Debt",
  "Notes Payable",
  "Mortgage Payable",
  "Less: Current portion of Long-term debt",
  // equity
  "Investment capital",
  "Retained earnings",
  "Capital Stock/Partner's Equity",
  "Opening Retained Earnings",
  "Dividends Paid/Owner's Draw",
  "Net Income (Loss)"
]

export {
  sections,
  subSections,
  categoryNames
}