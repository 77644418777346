import React, { Fragment } from 'react';
import cx from 'classnames';

import './styles.scss';
import cautions from './cautions';
import ScheduleCTemplate from './ScheduleC';

const FormHeader = ({ scheduleName }) => (
  <div
    className="bb"
    style={{ display: 'flex', justifyContent: 'space-around' }}
  >
    <div className="br p" style={{ width: '40%', textAlign: 'center' }}>
      <h2>{scheduleName}</h2>
      <br />
      Template
    </div>
    <div className="br" style={{ width: '60%', textAlign: 'center' }}>
      <h2 className="pb-2">Sample Form</h2>
    </div>
  </div>
);

const Caution = ({ scheduleName }) => (
  <div className="caution">
    {cautions[scheduleName] && (
      <>
        <b>Caution:</b>
        {cautions[scheduleName]}
      </>
    )}
  </div>
);

const SummaryGroup = ({ scheduleName, data }) => data.map(({ groupName, items }) => {
  let gridTemplateColumns;
  const gridTemplateRows = new Array(items.length).fill('1fr').join(' ');
  switch (scheduleName) {
    case 'Form 1065':
    case 'Form 1120':
    case 'Form 1120S':
      gridTemplateColumns = '30px 30px min-content auto 30px 75px 30px';
      break;
    case 'Schedule E':
      gridTemplateColumns = '30px min-content auto 30px 75px 30px 75px 30px 75px 30px';
      break;
    default:
      gridTemplateColumns = '1fr 1fr 1fr 1fr 1fr 1fr';
      break;
  }

  const gridStyle = { gridTemplateColumns, gridTemplateRows };
  const firstColStyle = { gridRow: `1 / span ${items.length}` };
  const isScheduleE = scheduleName === 'Schedule E';
  return (
    <section
      key={`${scheduleName}-${groupName}`}
      className="TaxForm--SummaryGroup"
      style={gridStyle}
    >
      {!isScheduleE && (
      <div className="firstCol" style={firstColStyle}>
        <div>{groupName}</div>
      </div>
      )}
      {items.map(({
        subsection, categoryName, total,
      }, idx) => {
        const lastItem = idx === items.length - 1;
        const commonLastStyle = cx('square', { borderBottomTransparent: lastItem });
        return (
          <Fragment key={`${groupName}-${categoryName}`}>
            <div className="right">{subsection}</div>
            <div />
            <div className="categoryName">
              <div>{categoryName}</div>
              <div />
            </div>
            <div className={`center ${commonLastStyle}`}>
              {subsection ? `${subsection}` : <>&nbsp;</>}
            </div>
            <div className={`right ${commonLastStyle}`}>{Math.abs(total).toLocaleString()}</div>
            <div className={`center ${commonLastStyle}`}>&nbsp;</div>

            {isScheduleE && <div className={`right disabled ${commonLastStyle}`}>&nbsp;</div>}
            {isScheduleE && <div className={`center disabled ${commonLastStyle}`}>&nbsp;</div>}
            {isScheduleE && <div className={`right disabled ${commonLastStyle}`}>&nbsp;</div>}
            {isScheduleE && <div className={`center disabled ${commonLastStyle}`}>&nbsp;</div>}
          </Fragment>
        );
      })}
    </section>
  );
});

const TaxFormScheduleC = props => (
  <div className="TaxForm">
    <FormHeader scheduleName={props.scheduleName} />
    <Caution scheduleName={props.scheduleName} />
    {
      props.scheduleName === 'Schedule C'
        ? <ScheduleCTemplate items={props.data} />
        : <SummaryGroup {...props} />
    }
  </div>
);

export default TaxFormScheduleC;
