import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session', 'onboarding', 'plaid', 'passwordRecovery'],
  stateReconciler: autoMergeLevel2,
};

const pReducer = persistReducer(persistConfig, rootReducer);
const initialState = {};
const enhancers = [];
const middlewares = [
  thunk,
];

const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers,
);

/* eslint-disable no-underscore-dangle */
export const configureStore = createStore(
  pReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composedEnhancers,
);

export const persistor = persistStore(configureStore);
