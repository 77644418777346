import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Grid, Form, Message,
} from 'semantic-ui-react';
import jsCookie from 'js-cookie';
import {
  getCategories,
  editTransaction,
} from 'libraries/api-service';
import {
  updateTransactionAsProfessional,
  updateTransactionClassificationsAsProfessional,
  createTransactionAsProfessional,
  deleteTransactionAsProfessional,
} from 'libraries/api-v2/transactions-service';
import EditMoreInfoTranSection from 'components/presentationals/TransactionsForms/EditMoreInfoTranSection';
import EditCategoryTranSection from 'components/presentationals/TransactionsForms/EditCategoryTranSection';
import MoreInfoSection from 'components/presentationals/TransactionsForms/MoreInfoSection';
import ButtonSection from 'components/presentationals/TransactionsForms/ButtonSection';
import Confirmation from 'components/presentationals/Confirmation';
import HeaderSection from 'components/presentationals/TransactionsForms/HeaderSection';
import TranTypeLabelsSection from 'components/presentationals/TransactionsForms/TranTypeLabelsSection';
import './styles.scss';
import withNotifications from 'components/hocs/WithNotifications';
import { slashFormatDate, filterSelectedT } from 'libraries/utils';
import { handleValidation, validateTransactionsType } from './validation';

export class TransactionModalContentv2 extends React.Component {
    state = {
      name: this.props.description || '',
      category: this.props.category || '',
      subcategory: this.props.subcategory || '',
      transactionID: this.props.transaction_id || '',
      transactionType: (this.props.amount && (this.props.amount > 0 ? 'Expense' : 'Income')) || '',
      amount: Math.abs(this.props.amount, 10) || '',
      customDate: (this.props.date && slashFormatDate(this.props.date)) || '',
      categories: [],
      isLoading: false,
      openConfirmation: false,
      showMoreInfo: !this.props.editionMode || false,
      hasError: false,
      errorReason: '',
      mixedTransactions: false,
    }

    componentDidMount() {
      if (this.props.contentType === 'bulk') {
        const { selected } = this.props.transactions;
        const { allTransactions } = this.props.transactions;
        const filteredSelected = filterSelectedT(allTransactions, selected);
        const isValid = validateTransactionsType(filteredSelected);
        if (isValid) {
          this.setState({
            transactionType: isValid,
            categories: this.getTransactionCategories(isValid),
          });
        } else {
          this.setState({ mixedTransactions: true });
          this.handleError(0);
        }
      } else {
        const { transactionType } = this.state;
        document.addEventListener('keydown', this.escFunction, false);
        this.setState({ isLoadingCategories: true }, () => {
          this.getTransactionCategories(transactionType);
        });
      }
    }

    componentWillUnmount() {
      document.addEventListener('keydown', this.escFunction, false);
    }

    getTransactionCategories(transactionType) {
      const selectedForm = decodeURIComponent(jsCookie.get('active_client_form_name'));
      const formName = selectedForm || 'Schedule C';
      getCategories(formName)
        .then((items) => {
          this.formatToDropDown(items, transactionType);
        });
    }

    getHeader = () => (
      <HeaderSection
        contentType={this.props.contentType}
      />
    );

    getLabels = () => {
      const { transactionType } = this.state;
      const otherType = this.getValidType(transactionType, '');
      return (
        <Grid className="Modal__Content-Labels-Container">
          <Grid.Column>
            <TranTypeLabelsSection
              handleLabelStatus={this.handleLabelStatus}
              transactionType={transactionType}
              otherType={otherType}
            />
          </Grid.Column>
        </Grid>
      );
    };

    getCategoryEditionSection = () => (
      <EditCategoryTranSection
        name={this.state.name}
        handleInputChange={this.handleInputChange}
        category={this.state.category}
        categories={this.state.categories}
        isLoadingCategories={this.state.isLoadingCategories}
        subcategory={this.state.subcategory}
        editionMode={this.props.editionMode}
        editableName={this.props.contentType === 'editcategory'}
        showLeyend={this.props.contentType === 'bulk'}
        totalTransactionSelected={this.props.totalTransactionSelected}
      />
    );

    getMoreInfoSection = () => {
      const { transactionType } = this.state;
      const otherType = transactionType === 'Expense' ? 'Income' : 'Expense';
      return (
        <MoreInfoSection
          editionMode={this.props.editionMode}
          showEditInfo={this.showEditInfo}
          showMoreInfo={this.state.showMoreInfo}
          getExtraTransactionInfo={this.getExtraTransactionInfo}
          handleLabelStatus={this.handleLabelStatus}
          transactionType={transactionType}
          otherType={otherType}
        />
      );
    };

    getExtraTransactionInfo = () => {
      const auxAmount = this.state.amount.toString();
      return (
        <EditMoreInfoTranSection
          amount={auxAmount}
          handleInputChange={this.handleInputChange}
          customDate={this.state.customDate}
          contentType={this.props.contentType}
        />
      );
    }

    getButtonsRow = () => (
      <ButtonSection
        contentType={this.props.contentType}
        handleConfirmation={this.handleConfirmation}
        openConfirmation={this.state.openConfirmation}
        handleClose={this.props.handleClose}
        handleSaveCreation={this.handleSaveCreation}
        handleSaveEdition={this.handleSaveEdition}
        handleSaveBulkEdition={this.handleSaveBulkEdition}
        mixedTransactions={this.state.mixedTransactions}
      />
    );

    handleInputChange = (event, { name, value }) => {
      this.setState({
        [name]: value,
      });
    }

    escFunction = (event) => {
      if (event.keyCode === 27) {
        this.props.handleClose();
      }
    }

    handleConfirmation = () => {
      this.setState({ openConfirmation: true });
    }

    handleCloseConfirmation = () => {
      this.setState({ openConfirmation: false });
    };

    handleLabelStatus = (clickedType) => {
      this.setState((prevState) => {
        const transactionType = this.getValidType(prevState.transactionType, clickedType);
        return ({
          transactionType,
          categories: this.getTransactionCategories(transactionType),
        });
      });
    }

    getValidType = (type, clickedType) => {
      if (type) {
        if (type === 'Expense' || type === 'Income') {
          return type === 'Expense' ? 'Income' : 'Expense';
        }
      } else {
        return clickedType;
      }
      return '';
    };

    showEditInfo = () => {
      this.setState(prevState => ({ showMoreInfo: !prevState.showMoreInfo }));
    }

    handleSaveEdition = () => {
      this.setState({ hasError: false });
      const { contentType } = this.props;
      const { username, token } = this.props.session;
      const {
        transactionID,
        customDate,
        name,
        transactionType,
        amount,
        category,
        subcategory,
      } = this.state;
      const clientEmail = jsCookie.get('active_client_email');

      if (contentType === 'editcategory') {
        const res = handleValidation(contentType, [
          name,
          category,
          subcategory,
        ]);

        if (res) {
          const transactionIds = [];
          transactionIds.push(transactionID);
          this.setState({ isLoading: true },
            () => updateTransactionClassificationsAsProfessional(username, token,
              {
                clientEmail,
                transactionIds,
                category,
                subcategory,
              })
              .then(() => {
                this.props.handleMark(transactionID);
                this.setState({ isLoading: false });
                this.props.handleClose();
              })
              .catch(() => {
                this.props.pushNotification('NOT_UPDATE_TRANSACTION_CATEGORY', 'Edition failed',
                  'An error ocurred while trying to update your transactions category. Please try again.', 'error', 6000);
                this.setState({ isLoading: false });
                this.props.handleClose();
              }));
        } else {
          this.handleError();
        }
      }

      if (contentType === 'edition') {
        const res = handleValidation(contentType, [
          name,
          category,
          subcategory,
          transactionType,
          amount,
          customDate,
        ]);
        if (res) {
          this.setState({ isLoading: true },
            () => updateTransactionAsProfessional(username, token,
              {
                clientEmail,
                transactionID,
                customDate,
                name,
                transactionType,
                amount,
                category,
                subcategory,
              })
              .then(() => {
                this.props.handleMark(transactionID);
                this.setState({ isLoading: false });
                this.props.handleClose();
              })
              .catch(() => {
                this.props.pushNotification('NOT_UPDATE_TRANSACTION', 'Edition failed',
                  'An error ocurred while trying to update your transaction. Please try again.', 'error', 6000);
                this.setState({ isLoading: false });
                this.props.handleClose();
              }));
        } else {
          this.handleError();
        }
      }
    }

    handleSaveBulkEdition = () => {
      this.setState({ hasError: false });
      const {
        category,
        subcategory,
      } = this.state;
      const res = handleValidation(this.props.contentType, [
        category,
        subcategory,
      ]);
      if (res) {
        const { username, token } = this.props.session;
        const { selected } = this.props.clientTransactions;
        const clientEmail = jsCookie.get('active_client_email');

        this.setState({ isLoading: true },
          () => updateTransactionClassificationsAsProfessional(username, token,
            {
              clientEmail,
              transactionIds: selected,
              category,
              subcategory,
            })
            .then(() => {
              this.props.handleMark(null);
              this.setState({ isLoading: false });
              this.props.handleClose();
            })
            .catch(() => {
              this.props.pushNotification('NOT_UPDATE_TRANSACTION_CATEGORY', 'Edition failed',
                'An error ocurred while trying to update your transactions categories. Please try again.', 'error', 6000);
              this.setState({ isLoading: false });
              this.props.handleClose();
            }));
      } else {
        this.handleError();
      }
    }

    handleSaveCreation = () => {
      this.setState({ hasError: false });
      const { username, token } = this.props.session;
      const clientEmail = jsCookie.get('active_client_email');
      const {
        customDate,
        name,
        transactionType,
        amount,
        category,
        subcategory,
      } = this.state;
      const res = handleValidation('creation', [
        name,
        category,
        subcategory,
        transactionType,
        amount,
        customDate,
      ]);
      if (res) {
        this.setState({ isLoading: true },
          () => createTransactionAsProfessional(username, token, {
            clientEmail,
            date: customDate,
            name,
            transactionType,
            amount,
            category,
            subcategory,
          })
            .then(() => {
              this.setState({ isLoading: false });
              this.props.updateTransactions(username, token);
              this.props.handleClose();
            })
            .catch(() => {
              this.props.pushNotification('NOT_CREATE_TRANSACTION', 'Creation failed',
                'An error ocurred while trying to create your transaction. Please try again.', 'error', 6000);
              this.setState({ isLoading: false });
              this.props.handleClose();
            }));
      } else {
        this.handleError();
      }
    }

    handleDeletion = () => {
      const { username, token } = this.props.session;
      const clientEmail = jsCookie.get('active_client_email');
      const transactionId = this.props.transaction_id;
      this.setState({ isLoading: true },
        () => deleteTransactionAsProfessional(username, token, {
          clientEmail,
          transactionId,
        }).then(() => {
          this.setState({ isLoading: false });
          this.props.updateTransactions(username, token);
          this.props.handleClose();
        }).catch(() => {
          this.props.pushNotification('NOT_DELETE_TRANSACTION', 'Deletion failed',
            'An error ocurred while trying to delete your transaction. Please try again.', 'error', 6000);
          this.setState({ isLoading: false });
          this.props.handleClose();
        }));
    };

    handleError = (err) => {
      switch (err) {
        case 0:
          this.setState({
            hasError: true,
            errorReason: 'You have mixed incomes and expenses, please select just one type of transactions.',
          });
          break;
        default:
          this.setState({
            hasError: true,
            errorReason: 'Please check every input is filled out.',
          });
          break;
      }
    }

    formatToDropDown(items, transactionType) {
      const categories = items.map(({ name, type }) => ({
        key: `${name}-${type}`,
        text: name,
        value: name,
        description: type,
      }));
      const filteredCategories = categories.filter(
        ({ description }) => description === transactionType,
      );
      this.setState({ categories: filteredCategories, isLoadingCategories: false });
    }

    render() {
      return (
        <Container className="Modal__Content">
          <Container textAlign="center">
            { this.getHeader() }
          </Container>
          <br />
          <Form loading={this.state.isLoading} error={this.state.hasError}>
            <Grid>
              { this.props.contentType === 'creation' && this.getLabels() }
              { this.getCategoryEditionSection() }
              {
                this.props.contentType === 'edition' && this.getMoreInfoSection()
              }
              {
                this.props.contentType === 'creation' && this.getExtraTransactionInfo()
              }
              { this.getButtonsRow() }
              {
                this.state.openConfirmation && (
                  <Container textAlign="center">
                    <Confirmation
                      handleClose={this.handleCloseConfirmation}
                      handleDeletion={this.handleDeletion}
                    />
                  </Container>
                )
              }
              <Grid.Row>
                <Grid.Column width={2} />
                <Grid.Column width={12}>
                  <Form.Field>
                    <Message
                      error
                      header="Error"
                      content={this.state.errorReason}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={2} />
              </Grid.Row>
            </Grid>
          </Form>
        </Container>
      );
    }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  session: state.session,
  clientTransactions: state.clientTransactions,
});
/* istanbul ignore next */
export default connect(mapStateToProps, null)(withNotifications(TransactionModalContentv2));
