import React from "react";
import "./styles.scss";

class ItemHeader extends React.Component {
  render() {
    return <table style={{ width: "94%", marginBottom: "-25px", fontSize: "15px", fontWeight: "bold" }}>
        <tbody>
          <tr style={{ height: "40px" }}>
            <td style={{ width: this.props.invoiceTemplate == 1 ? "50%" : "65%", paddingLeft: "15px", textAlign: "left", height: "40px" }}>
              Description
            </td>
            {this.props.invoiceTemplate == 1 ? <>
                <td style={{ width: "10%", paddingRight: "15px", textAlign: "right", height: "40px" }}>
                  Quantity
                </td>
                <td style={{ width: "10%", paddingRight: "15px", textAlign: "right", height: "40px" }}>
                  Price
                </td>
              </> : <td style={{ width: "20%", paddingRight: "15px", height: "40px", textAlign: "right" }}>
                Amount
              </td>}

            <td style={{ width: "15%", paddingRight: "15px", height: "40px", textAlign: "right" }}>
              Tax
            </td>
            {this.props.invoiceTemplate == 1 && <td style={{ width: "20%", paddingRight: "15px", height: "40px", textAlign: "right" }}>
                Amount
              </td>}
          </tr>
        </tbody>
      </table>;
  }
}

export default ItemHeader;
