import React from 'react';
import { Redirect } from 'react-router-dom';
import Auth from '@aws-amplify/auth';

const withAuthentication = (Component, accountType: string) => {
  class WithAuthentication extends React.Component {
    state = { user: null, loading: true };

    componentDidMount() {
      Auth.currentAuthenticatedUser()
        .then(authUser => this.setState({ loading: false, user: authUser }))
        .catch(() => this.setState({ loading: false }));
    }

    render() {
      const { user, loading } = this.state;
      if (loading) return <div>Confirming your identity...</div>;
      if (accountType) {
        return user !== null && accountType === user.attributes['custom:account_type'] ? <Component {...this.props} /> : <Redirect to="/login" />;
      } else {
        return user !== null ? <Component {...this.props} /> : <Redirect to="/login" />;
      }
    }
  }
  return WithAuthentication;
};

export default withAuthentication;
