import {
  SET_INVITEE_EMAIL,
  SET_INVITEE_FULL_NAME,
  CLEAR_INVITEE,
  SET_INVITATIONS,
  SET_RECEIVED_INVITATIONS,
  SET_SENT_INVITATIONS,
} from '../ActionTypes';

export const setInviteeEmail = res => ({
  type: SET_INVITEE_EMAIL,
  payload: {
    res,
  },
});

export const setInviteeFullName = res => ({
  type: SET_INVITEE_FULL_NAME,
  payload: {
    res,
  },
});

export const clearInvitee = res => ({
  type: CLEAR_INVITEE,
});

export const setInvitations = invitations => ({
  type: SET_INVITATIONS,
  payload: invitations,
});

export const setReceivedInvitations = invitations => ({
  type: SET_RECEIVED_INVITATIONS,
  payload: invitations,
});

export const setSentInvitations = invitations => ({
  type: SET_SENT_INVITATIONS,
  payload: invitations,
});
