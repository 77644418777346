import {
  UPDATE_PASSWORD_RECOVERY_EMAIL,
  UPDATE_PASSWORD_RECOVERY_PINCODE,
  CLEAR_RECOVERY_DATA,
} from '../ActionTypes';

export const updateRecoveryEmail = payload => ({
  type: UPDATE_PASSWORD_RECOVERY_EMAIL,
  payload,
});

export const updateRecoveryPincode = payload => ({
  type: UPDATE_PASSWORD_RECOVERY_PINCODE,
  payload,
});

export const clearRecoveryData = () => ({
  type: CLEAR_RECOVERY_DATA,
});
