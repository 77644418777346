import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Segment } from 'semantic-ui-react';
import { TaxInput, TaxDropDown } from 'taxUI';
import BusinessTypeSelector from 'components/ui/BusinessTypeSelector';

import { setUserProfile } from 'store/actions/session';
import { updateProfile } from 'libraries/api-service';
import SchedulesDescriptions from '../../../OnboardingReportForm/descriptions';
import './styles.scss';

class ProfileDetailsEdit extends Component {
  state = {
    fullName: this.props.fullName,
    email: this.props.email,
    details: this.props.businessDetails,
    form: this.props.form,
    forms: [],
    businessType: this.props.businessType,
    businessCode: this.props.businessCode,
  };

  componentDidMount() {
    const forms = SchedulesDescriptions.map(({ id, title }) => ({
      key: `${title}-${id}`,
      text: title,
      value: title,
    }));
    this.setState({ forms });
  }

  handleInputChange = (event, { name, value }) => this.setState({ [name]: value });

  onBusinessTypeChange = ({ name: businessType, code: businessCode }) => {
    this.setState({ businessType, businessCode });
  };

  onSaveProfile = () => {
    const { username, token, onSuccess } = this.props;
    const {
      fullName, email, form, businessType, businessCode, details,
    } = this.state;
    const data = {
      fullName,
      selectedForm: form,
      businessCode,
      businessDetails: details,
    };
    updateProfile(username, token, data).then(() => {
      const prevState = {
        fullName,
        email,
        form,
        businessType,
        businessCode,
        businessDetails: details,
      };
      this.props.setUserProfile(prevState);
      onSuccess && onSuccess(prevState);
    });
  };

  render() {
    const { onCancel, imgName } = this.props;
    const {
      fullName, email, details, form, forms, businessType,
    } = this.state;
    const isDisabled = !fullName || !email || !form || !businessType;
    return (
      <div className="ProfileDetailsEdit margin">
        <Segment
          circular
          className="ProfileDetailsReadOnly-ImgName"
          textAlign="center"
        >
          <div>{imgName}</div>
        </Segment>
        <br />
        <div className="ProfileDetailsEdit__accountType">Account type</div>
        <div>Taxpayer account</div>
        <br />
        <TaxInput
          fluid
          name="fullName"
          label="Full Name"
          placeholder="Full Name"
          value={fullName}
          onChange={this.handleInputChange}
          required
        />
        <br />
        <TaxInput
          fluid
          name="email"
          label="Email"
          placeholder="Email"
          disabled
          value={email}
          onChange={this.handleInputChange}
          required
        />
        <br />
        <h6>Business information</h6>
        <br />
        <TaxDropDown
          fluid
          name="form"
          onChange={this.handleInputChange}
          value={form}
          disabled
          label="Form"
          placeholder="Form"
          options={forms}
          selection
          scrolling
          deburr
        />
        <br />
        <BusinessTypeSelector
          form={form}
          value={businessType}
          label="Business Type"
          onChangeValue={this.onBusinessTypeChange}
        />
        <br />
        <TaxInput
          fluid
          name="details"
          label="Add details"
          placeholder="Add details"
          value={details}
          onChange={this.handleInputChange}
          required
        />
        <br />
        <br />
        <div style={{ textAlign: 'center' }}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={this.onSaveProfile} primary disabled={isDisabled}>
            Save Changes
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session: { token, username } }) => ({
  token,
  username,
});

const mapDispatchToProps = dispatch => bindActionCreators({ setUserProfile }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileDetailsEdit);
