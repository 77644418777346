import React from 'react';
import {
  Image, Menu, Container, Responsive,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import taxlabLogo from 'images/logo.svg';
import Sections from 'components/presentationals/HeaderSections';
import HeaderProfileMenu from 'components/containers/HeaderProfileMenu';
import Routes from 'routes';

const Header = props => (
  <Menu borderless size="huge" className="HideForPrint">
    <Container>
      <Menu.Item>
        <Link to={Routes.DashboardTransactions}><Image src={taxlabLogo} size="small" /></Link>
      </Menu.Item>
      <Menu.Item>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Sections location={props.location.pathname} />
        </Responsive>
      </Menu.Item>
      <Menu.Menu position="right">
        <HeaderProfileMenu />
      </Menu.Menu>
    </Container>
  </Menu>
);

export default Header;
