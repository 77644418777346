import React from 'react';
import { Container } from 'semantic-ui-react';
import PublicLayout from 'components/containers/PublicLayout';

import './terms.scss';

const TermsOfService = () => (
  <PublicLayout>
    <Container textAlign="justified" className="Terms-And-Privacy">
      <h1>TAX LAB TERMS OF SERVICE FOR SYNKBOOKS</h1>
      <p><b>Last modified: November 21, 2018</b></p>
      <p>
        Thank you for selecting the Services offered by TAX LAB Inc. and/or its subsidiaries and affiliates (referred to as "TAX LAB", "we", "our", or "us").
        Review these Terms of Service ("Agreement") thoroughly. This Agreement is a legal agreement between you and TAX LAB. <b>By navigating or otherwise
        using the Services, accepting electronically (for example, clicking “I Agree”) when this option is made available to you, establishing an account,
        installing, accessing or using the Services, you accept and agree to be bound and abide by this Agreement and our Privacy Policy, incorporated herein
        by reference.</b> If you do not agree to this Agreement, then you may not use the Services.
      </p>
      
      <p>
        PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THESE TERMS CONTAIN A BINDING AND MANDATORY CUSTOMER ARBITRATION
        AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY
        TRIALS OR CLASS ACTIONS AND LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF CERTAIN DISPUTES.
      </p>
      
      <p>
        The Services are offered and available to users who 18 years of age or older and reside in the United States or any of its territories or possessions.
        By using the Services, you represent and warrant that you are of legal age to form a binding contract with TAX LAB and meet all of the foregoing
        eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.
      </p>
      <h2><b>A. GENERAL TERMS</b></h2>
      <ol>
        <li>
          AGREEMENT
          <p>
            This Agreement, together with any documents it expressly incorporate by reference, describes the terms governing your access to and use of the
            TAX LAB online services provided to you at <a href="https://www.synkbooks.com">www.synkbooks.com</a>, including content, updates and new releases,
            (collectively, the “Services”), whether as a guest or a registered user. It includes by reference:
          </p>
          <ul>
            <li>TAX LAB's Privacy Policy provided to you in the Services available on the website or provided to you otherwise.</li>
            <li>Additional Terms and Conditions, which may include those from third parties.</li>
            <li>Any terms provided separately to you for the Services, including product or program terms, ordering, activation, payment terms, etc.</li>
          </ul>
        </li>
        <li>
          YOUR RIGHTS TO USE THE SERVICES
          <ol>
            <li>
              The Services, their entire contents, features, and functionality (including but not limited to all information, software, text,
              displays, images, video, and audio, and the design, selection, and arrangement thereof), and any of TAX LAB’s Confidential Information
              (defined below) (collectively, the “<b>TAX LAB’s Intellectual Property</b>”) are owned by TAX LAB, its licensors, or other providers of such
              material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws. You are only granted the right to use the Services and only for the purposes described by TAX LAB.
              TAX LAB reserves all other rights in the Services. Until termination of this Agreement and as long as you meet any applicable payment
              obligations and comply with this Agreement, TAX LAB grants to you a personal, limited, nonexclusive, nontransferable right and license
              to use the Services for non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display,
              publicly perform, republish, download, store, or transmit any of TAX LAB’s Intellectual Property, with the exception of the following instances:
              <ul>
                <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
                <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
                <li>
                  If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device
                  solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
                </li>
                <li>If we provide social media features with certain content, you may take such actions as are enabled by such features.</li>
              </ul>
            You agree you will not:
              <ul>
                <li>Provide access to or give any part of the Services to any third party.</li>
                <li>Reproduce, modify, copy, deconstruct, sell, trade or resell the Services.</li>
                <li>Make the Services available on any file-sharing or application hosting service.</li>
                <li>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Services.</li>
              </ul>
            </li>
            <li>
              <b>Prohibited Uses.</b>
              You agree not to use, nor permit any third party to use, the Services or content in a manner that violates any applicable
              law, regulation or this Agreement. You agree not to use the Services:
              <ul>
                <li>
                  In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation,
                  any laws regarding the export of data or software to and from the US or other countries).
                </li>
                <li>
                  For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content,
                  asking for personally identifiable information, or otherwise.
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter”, “spam”,
                  or any other similar solicitation.
                </li>
                <li>
                  To impersonate or attempt to impersonate TAX LAB, a TAX LAB employee, another user, or any other person or entity (including,
                  without limitation, by using e-mail addresses or screen names associated with any of the foregoing).
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Services, or which, as determined by us,
                  may harm the TAX LAB or users of the Services or expose them to liability.
                </li>
                <li>
                  You agree not to circumvent, disable or otherwise interfere with security-related features of the Services or features that prevent
                  or restrict use or copying of any content or enforce limitations on use of the Services or the content therein.
                </li>
              </ul>
              Additionally, you agree not to:
              <ul>
                <li>
                  Use the Services in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the
                  Services, including their ability to engage in real time activities through the Services.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or
                  copying any of the material on the Services.
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material on the Services or for any other unauthorized purpose without our prior written
                  consent.
                </li>
                <li>Use any device, software, or routine that interferes with the proper working of the Services.</li>
                <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                <li>
                  Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services
                  are stored, or any server, computer, or database connected to the Services.
                </li>
                <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.</li>
                <li>Otherwise attempt to interfere with the proper working of the Services.</li>
              </ul>
            </li>
            <li>
              <b>Confidentiality.</b>
              During your use of the Services, you may have access to proprietary, private and/or otherwise confidential information
              belonging to TAX LAB. “Confidential Information” will mean all non-public information which constitutes, relates or refers to the operation of
              the business of TAX LAB, its customers’, suppliers’, and other third parties’ past, present, and future business affairs including without
              limitation, financial, operational, personnel, sales, marketing, TAX LAB’s intellectual property and any trade secrets, proprietary software
              (including but not limited to company-generated domains and backlinks), the identity of the TAX LAB’s customers or clients, or and pricing
              information of TAX LAB.
              <p>
                You will not at any time or in any manner, use for personal benefit, divulge, disclose or communicate in any manner any Confidential Information.
                You acknowledge that TAX LAB shall suffer irreparable injury upon any breach of your confidentiality obligations and that Company will be
                entitled to seek injunctive or relief to prevent any further breach by you; provided, however, that this will not limit any other rights or
                remedies available to TAX LAB.
              </p>
            </li>
            <li>
              TAX LAB’s name, the terms “SYNKBOOKS” and “SYNKBOOKS ACCOUNTING PRO”, TAX LAB’s logo, and all related names, logos, product and service names,
              designs, and slogans are trademarks of TAX LAB or its affiliates or licensors. You must not use such marks without TAX LAB’s prior written
              permission. All other names, logos, product and service names, designs, and slogans on the Services are the trademarks of their respective owners.
            </li>
          </ol>
        </li>
        <li>
          <b>PAYMENT.</b> For Services offered on subscription basis (“Paid Services”), the following terms apply if you are the User paying for the Paid
          Services, unless TAX LAB or its third-party affiliate notifies you otherwise in writing. This Agreement also incorporates by reference and includes
          program ordering and payment terms provided to you on the website for the Paid Services:
          <ul>
            <li>
              You agree to pay us the applicable subscription fee (the “Subscription Fee”). The applicable Subscription Fee is set forth alongside the
              services offered on the Services. We reserve the right to change the Subscription Fee at any time at our discretion without notice. You will
              be responsible for the Subscription Fee stated at the time of your transaction, as well as any applicable sales, use, excise, and related taxes.
            </li>
            <li>
              Payments will be billed to you in U.S. dollars, and your account will be debited when you subscribe and provide your payment information,
              unless stated otherwise in the program ordering or payment terms on the website for the Paid Services.
            </li>
            <li>
              Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates). We may calculate
              taxes payable by you based on the billing information that you provide us at the time of purchase.
            </li>
            <li>
              You must pay with one of the following:
              <ul style={{ listStyleType: 'decimal' }}>
                <li>
                  A valid credit card acceptable to TAX LAB;
                </li>
                <li>
                  A valid debit card acceptable to TAX LAB;
                </li>
                <li>
                  Sufficient funds in a checking or savings account to cover an electronic debit of the payment due; 
                </li>
                <li>
                  Allowable Cryptocurrency payments through Coinbase Commerce or
                </li>
                <li>
                  By another payment option TAX LAB provides to you in writing.
                </li>
              </ul>
            </li>
            <li>
              By using any such card or payment provider listed above, you are hereby representing and warranting your full right and authority to make such
              purchase in the manner elected without violating any applicable law, rule, or regulation.
            </li>
            <li>
              If your payment and registration information is not accurate, current, and complete and you do not notify us promptly when such information
              changes, we may suspend or terminate your account and refuse your use of the Paid Services.
            </li>
            <li>
              We may store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your Paid Services
              and to use to pay other Paid Services you may buy.
            </li>
            <li>
              If you do not notify us of updates to your payment method (e.g., credit card expiration date), to avoid interruption of your service, we may
              participate in programs supported by your card provider (e.g., updater services, recurring billing programs, etc.) to try to update your payment
              information, and you authorize us to continue billing your account with the updated information that we obtain.
            </li>
            <li>
              TAX LAB will automatically renew your monthly or annual Paid Services at the then-current rates, unless the Paid Services are cancelled or
              terminated under this Agreement.
            </li>
            <li>Additional cancellation or renewal terms may be provided to you on the website for the Paid Services.</li>
            <li>All fees or charges paid for Paid Services; including partially used periods and bank/merchant fees, are non-refundable.</li>
          </ul>
        </li>
        <li>
          <b>USE WITH YOUR MOBILE DEVICE.</b> Use of these Services may be available through a compatible mobile device, the Internet, and may require
          software. You agree that you are solely responsible for these requirements, including any applicable changes, updates and fees as well as the
          terms of your agreement with your mobile device and telecommunications provider.
          <p>TAX LAB MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED AS TO:</p>
          <p>THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM YOUR PROVIDER AND ACCESS TO THE SERVICES AT ANY TIME OR FROM ANY LOCATION;
            ANY LOSS, DAMAGE, OR OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION SERVICES; AND
            ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO TRANSMIT ANY DATA, COMMUNICATIONS OR SETTINGS CONNECTED WITH THE SERVICES.
          </p>
        </li>
        <li>
          <b>YOUR PERSONAL INFORMATION.</b>
          You can view TAX LAB’s Privacy Policy provided with the Services and on the website for the Services.
          You agree to the applicable TAX LAB Privacy Policy, and any changes published by TAX LAB. You agree that TAX LAB may use and maintain your data
          according to the TAX LAB Privacy Policy, as part of the Services. You give TAX LAB permission to combine identifiable and non-identifiable
          information you enter or upload to the Services with that of other users of the Services and/or other TAX LAB services. For example, this means
          that TAX LAB may use your and other users’ non-identifiable, aggregated data to improve the Services or to design promotions and provide ways for
          you to compare business practices with other users. TAX LAB is a global company and may access or store personal information in multiple countries,
          including countries outside of your own country to the extent permitted by applicable law.
        </li>
        <li>
          <b>CONTENT</b>
          <ol>
            <li>
              <b>You are responsible for your content.</b> You are responsible for all materials, data, and personal information ("User Contributions") uploaded,
              posted or stored through your use of the Services. You grant TAX LAB a worldwide, royalty-free, non-exclusive license to host and use any User
              Contributions provided through your use of the Services. You represent and warrant that you own or control all rights in and to the User
              Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our
              respective licensees, successors, and assigns; and that all of your User Contributions do and will comply with this Agreement. Archive your
              User Contributions frequently. You are responsible for any lost or unrecoverable User Contributions. You must provide all required and
              appropriate warnings, information and disclosures. TAX LAB is not responsible for the User Contributions or data you submit through the Services.
              You agree not to use, nor permit any third party to use, the Services to upload, post, distribute, link to, publish, reproduce, engage in or
              transmit any of the following, including but not limited to:
              <ul>
                <li>
                  Illegal, fraudulent, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, harassing, offensive, inappropriate or
                  objectionable information or communications of any kind, including without limitation conduct that would encourage "flaming" others, or
                  criminal or civil liability under any local, state, federal or foreign law;
                </li>
                <li>
                  Sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.
                </li>
                <li>Content that promotes any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                <li>Content intended to cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.</li>
                <li>Content that would impersonate someone else or falsely represent your identity or qualifications, or that constitutes a breach of any individual’s privacy;</li>
                <li>
                  Except as permitted by TAX LAB in writing, investment opportunities, solicitations, chain letters, pyramid schemes, commercial activities
                  or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising, or other unsolicited commercial communication
                  or engage in spamming or flooding;
                </li>
                <li>Virus, trojan horse, worm or other disruptive or harmful software or data; and</li>
                <li>Any information, software or User Contributions which is not legally yours and without permission from the copyright owner or intellectual property rights owner.</li>
              </ul>
            </li>
            <li>
              <b>Community forums.</b>
              The Services may include a community forum or other social features to exchange User Contributions and information
              with other users of the Services and the public (“Community Forum”). TAX LAB does not support and is not responsible for the content in these
              Community Forums. Please use respect when you interact with other users in a Community Forum. Do not reveal confidential or other information
              that you do not want to make public. Users may post hypertext links to content of third parties for which TAX LAB is not responsible.
            </li>
            <li>
              <b>TAX LAB may freely use feedback you provide.</b>
              You agree that TAX LAB may use your feedback, suggestions, or ideas in any way, including in future modifications of the Services, other
              products or services, advertising or marketing materials. You grant TAX LAB a perpetual, worldwide, fully transferable, sublicensable,
              non-revocable, fully paid-up, royalty free license to use the feedback you provide to TAX LAB in any way.
            </li>
            <li>
              <b>TAX LAB may monitor your User Contributions.</b> TAX LAB may, but has no obligation to, monitor content on the Services. We may disclose any
              information necessary to satisfy our legal obligations, protect TAX LAB or its customers, or operate the Services properly. TAX LAB, in its sole
              discretion, may refuse to post, remove, or refuse to remove, any User Contributions, in whole or in part, alleged to be unacceptable, undesirable,
              inappropriate, or in violation of this Agreement. We reserve the right to:
              <ul>
                <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
                <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates this Agreement, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Services or the public, or could create liability for TAX LAB.</li>
                <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
                <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services.</li>
                <li>Terminate or suspend your access to all or part of the Services for any or no reason, including without limitation, any violation of these this Agreement.</li>
              </ul>
              <p>
                Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Services. 
              </p>
              <p>YOU WAIVE AND HOLD HARMLESS TAX LAB AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY TAX LAB DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER TAX LAB OR LAW ENFORCEMENT AUTHORITIES.</p>
              <p>We cannot review all material before it is posted on the Services, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>
            </li>
            <li>
              <b>Copyright Infringement.</b>
              We do not endorse any User Contributions submitted to the Services by any user or other licensor, or any opinion,
              recommendation, or advice expressed therein, and we expressly disclaim any and all liability in connection with any User Contributions. We do
              not permit copyright infringing activities and infringement of intellectual property rights on the Services, and we will remove all User
              Contributions if properly notified that such User Submission infringes on another’s intellectual property rights in accordance with the Digital
              Millennium Copyright Act (“DMCA”). TAX LAB reserves the right to remove Content without prior notice.
              <br />
              <br />
              <p>As such, we take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If you believe any materials accessible on or from this site infringe your copyright, you may request removal of those materials (or access to them) from the Services by submitting written notification to our copyright agent designated below. In accordance with the Online Copyright Infringement Liability Limitation Act of the DMCA (17 U.S.C. § 512), the written notice (the “DMCA Notice”) must include substantially the following:</p>
              <ul>
                <li>Your physical or electronic signature.</li>
                <li>Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Services, a representative list of such works.</li>
                <li>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.</li>
                <li>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
                <li>A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.</li>
                <li>A statement that the information in the written notice is accurate.</li>
                <li>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
              </ul>
              <p>Our designated copyright agent to receive DMCA Notices is:</p>
              <p>Isai Bismark Cortez 7083 Hollywood Blvd, 5th Floor, Los Angeles, CA</p>
              <p>If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective.</p>
              <p>Please be aware that if you knowingly materially misrepresent that material or activity on the Services is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees) under Section 512(f) of the DMCA.</p>
            </li>
          </ol>
        </li>
        <li>
          <b>ADDITIONAL TERMS</b>
          <ol>
            <li>
              <b>TAX LAB does not give professional advice.</b> Unless specifically included with the Services, TAX LAB is not in the business of providing legal, financial, accounting, tax, health care, real estate or other professional services or advice. The information presented on or through the Services is made available solely for general information purposes. The Services are not intended to replace or serve as a substitute for the advice of a licensed professional and you should not take action based upon information provided by the Services without obtaining the counsel of an appropriately licensed professional. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other user of the Services, or by anyone who may be informed of any of its contents.
              <br />
              <br />
              <p>
                The Services may include content provided by third parties, including materials provided by other users. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by TAX LAB, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of TAX LAB. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
              </p>
            </li>
            <li>We may tell you about other TAX LAB services. You may be offered other services, products, or promotions by TAX LAB ("TAX LAB Services"). Additional terms and conditions and fees may apply. With some TAX LAB Services you may upload or enter data from your account(s) such as names, addresses and phone numbers, purchases, etc., to the Internet. You grant TAX LAB permission to use information about your business and experience to help us to provide the TAX LAB Services to you and to enhance the Services. You grant TAX LAB permission to combine your business data, if any, with that of others in a way that does not identify you or any individual personally. You also grant TAX LAB permission to share or publish summary results relating to research data and to distribute or license such data to third parties.</li>
            <li>Communications. TAX LAB may be required by law to send you communications about the Services or third-party products. You agree that TAX LAB may send these communications to you via email or by posting them on our websites.</li>
            <li>You will manage your passwords and accept updates. You are responsible for securely managing your password(s) for the Services and to contact TAX LAB if you become aware of any unauthorized access to your account. The Services may periodically be updated with tools, utilities, improvements, third party applications, or general updates to improve the Services. You agree to receive these updates.</li>
          </ol>
        </li>
        <li>
          <b>DISCLAIMER OF WARRANTIES</b>
          <ol>
            <li>
              You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Services will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.
              <p>
                YOUR USE OF THE SERVICES, SOFTWARE, AND CONTENT IS ENTIRELY AT YOUR OWN RISK. EXCEPT AS DESCRIBED IN THIS AGREEMENT, THE SERVICES ARE PROVIDED "AS IS." TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, TAX LAB, ITS AFFILIATES, AND ITS THIRD PARTY PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY,"SUPPLIERS") DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY THAT THE SERVICES ARE FIT FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY, DATA LOSS, NON-INTERFERENCE WITH OR NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHTS, OR THE ACCURACY, RELIABILITY, QUALITY OR CONTENT IN OR LINKED TO THE SERVICES. TAX LAB AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT THE SERVICES ARE SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION. IF THE EXCLUSIONS FOR IMPLIED WARRANTIES DO NOT APPLY TO YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 60 DAYS FROM THE DATE OF PURCHASE OR DELIVERY OF THE SERVICES, WHICHEVER IS SOONER.
              </p>
            </li>
            <li>TAX LAB, ITS AFFILIATES AND SUPPLIERS DISCLAIM ANY REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SERVICES WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR REGULATIONS.</li>
          </ol>
        </li>
        <li>
          <b>LIMITATION OF LIABILITY AND INDEMNITY</b> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY OF TAX LAB, ITS AFFILIATES AND SUPPLIERS FOR ALL CLAIMS RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM. SUBJECT TO APPLICABLE LAW, TAX LAB, ITS AFFILIATES AND SUPPLIERS ARE NOT LIABLE FOR ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF USE, LOSS OF GOODWILL; (B) DAMAGES RELATING TO FAILURES OF TELECOMMUNICATIONS, THE INTERNET, ELECTRONIC COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF DATA, VIRUSES, SPYWARE, LOSS OF BUSINESS, REVENUE, PROFITS OR INVESTMENT, OR USE OF SOFTWARE OR HARDWARE THAT DOES NOT MEET TAX LAB SYSTEMS REQUIREMENTS; AND IN ANY CASE WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. THE ABOVE LIMITATIONS APPLY EVEN IF TAX LAB AND ITS AFFILIATES AND SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF TAX LAB, ITS AFFILIATES AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES AND ITS USE.
          <br />
          <br />
          <p>You agree to indemnify and hold harmless TAX LAB and its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from any and all claims, liability damages, judgments, awards, losses, and expenses, including reasonable attorneys' fees and costs, arising out of your use of the Services or breach of this Agreement (collectively referred to as "Claims"). TAX LAB reserves the right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any Claims. You agree to reasonably cooperate as requested by TAX LAB in the defense of any Claims.</p>
        </li>
        <li><b>CHANGES</b> We reserve the right to change this Agreement at any time, and the changes will be effective when posted through the Services, on our website for the Services or when we notify you by other means. We may also change or discontinue the Services, in whole or in part. Your continued use of the Services indicates your agreement to the changes.</li>
        <li><b>TERMINATION</b> TAX LAB may immediately, in its sole discretion and without notice terminate this Agreement or suspend the Services if you fail to comply with this Agreement or if you no longer agree to receive electronic communications. Upon termination you must immediately stop using the Services and any outstanding payments will become due. Any termination of this Agreement shall not affect TAX LAB’s rights to any payments due to it. TAX LAB may terminate a free account at any time. Sections 2.2, 3 through 16 will survive and remain in effect even if the Agreement is terminated.</li>
        <li><b>EXPORT RESTRICTIONS</b> You acknowledge that the Services, including the mobile application, and the underlying software may include U.S. technical data subject to restrictions under export control laws and regulations administered by the United States government. You agree that you will comply with these laws and regulations, and will not export or re-export any part of the Services, in violation of these laws and regulations, directly or indirectly.</li>
        <li><b>GEOGRAPHIC RESTRICTIONS</b> TAX LAB IS BASED IN THE STATE OF CALIFORNIA AND WE PROVIDE THE SERVICES FOR USE ONLY BY PERSONS LOCATED IN THE UNITED STATES. We make no claims that the Services or any of their content is accessible or appropriate outside of the United States. Access to the Services may not be legal by certain persons or in certain countries. IF YOU ARE OUTSIDE OF THE UNITED STATES, DO NOT USE THE SERVICES.</li>
        <li><b>GOVERNING LAW.</b> California state law governs this Agreement without regard to its conflicts of laws provisions.</li>
        <li>
          <b>DISPUTES</b> ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THE SERVICES OR THIS AGREEMENT WILL BE RESOLVED BY BINDING ARBITRATION, RATHER THAN IN COURT, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act governs the interpretation and enforcement of this provision; the arbitrator shall apply California law to all other matters. Notwithstanding anything to the contrary, any party to the arbitration may at any time seek injunctions or other forms of equitable relief from any court of competent jurisdiction. WE EACH AGREE THAT ANY AND ALL DISPUTES MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. BY ENTERING INTO THIS AGREEMENT AND AGREEING TO ARBITRATION, YOU AGREE THAT YOU AND TAX LAB ARE EACH WAIVING THE RIGHT TO FILE A LAWSUIT AND THE RIGHT TO A TRIAL BY JURY. IN ADDITION, YOU AGREE TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE BASIS. YOU AGREE THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED THESE RIGHTS.
          <br />
          <br />
          <p>To begin an arbitration proceeding, send a letter requesting arbitration and describing your claim to TAX LAB Inc., 7083 Hollywood Blvd 5th Floor, Los Angeles, CA 90028. Arbitration will be conducted by the American Arbitration Association (AAA) before a single AAA arbitrator under the AAA’s rules, which are available at <a href="https://www.adr.org">www.adr.org</a> or by calling 1-800-778-7879. Payment of all filing, administration and arbitrator fees and costs will be governed by the AAA’s rules, but if you are unable to pay any of them, TAX LAB will pay them for you. In addition, TAX LAB will reimburse all such fees and costs for claims totaling less than $75,000 unless the arbitrator determines the claims are frivolous. Likewise, TAX LAB will not seek its attorneys’ fees or costs in arbitration unless the arbitrator determines your claims or defenses are frivolous. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location. The decision of the arbitrator shall be final and not appealable, and judgment on the arbitration award may be entered in any court having jurisdiction thereof. This Section 14 shall survive expiration, termination or rescission of this Agreement.</p>
        </li>
        <li>GENERAL This Agreement, including the Additional Terms below, is the entire agreement between you and TAX LAB and replaces all prior understandings, communications and agreements, oral or written, regarding its subject matter. No waiver by TAX LAB of any term or condition set forth in this Agreement shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of TAX LAB to assert a right or provision under this Agreement shall not constitute a waiver of such right or provision. If any court of law, having the jurisdiction, rules that any part of this Agreement is invalid, that section will be removed without affecting the remainder of the Agreement. The remaining terms will be valid and enforceable. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement. You cannot assign or transfer ownership of this Agreement to anyone without written approval of TAX LAB. However, TAX LAB may assign or transfer it without your consent to (a) an affiliate, (b) a company through a sale of assets by TAX LAB or (c) a successor by merger. Any assignment in violation of this Section shall be void. If you want to request a transfer of this Agreement, contact TAX LAB via an email to: support@synkbooks.com.</li>
      </ol>
      
      <h2><b>B. ADDITIONAL TERMS AND CONDITIONS FOR SYNKBOOKS</b></h2>
      <p><b>Your use of the following Services provided by TAX LAB are subject to the General Terms of Service above and these Additional Terms and Conditions. These Additional Terms and Conditions will prevail over any conflict or inconsistency with the General Terms of Service.</b></p>
      <p>If you are entering into this Agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions as its authorized representative, in which case the terms “you” or “your” will refer to such entity and its affiliates as well as you. If the legal entity that you represent does not agree with these terms and conditions, you must not accept this Agreement, register, and use or access the Services as an authorized representative.</p>
      <ol>
        <li>
          <b>SERVICES.</b> Each of the following products and services are referred to in this Agreement as a “Service” and together as the “Services”:
          <ol>
            <li>
              <b>SYNKBOOKS.</b> SYNKBOOKS (“<b>TB</b>”) is an online solution for businesses to perform accounting and business tasks through an online
              account (each a “TB Account”). Each TB Account may only be used to support one business.
            </li>
            <li><b>SYNKBOOKS ACCOUNTING PRO.</b> SYNKBOOKS ACCOUNTING PRO (“<b>TBAP</b>”) is an online solution for accountants, bookkeepers, and other individuals or entities that provide accounting and other financial services to their clients. If you register for TBAP, you will be able to create and access new and existing TB Accounts of your clients. TBAP also provides you with professional tools and access to our SYNKBOOKS ACCOUNTING PRO Program, which is subject to separate terms and conditions.</li>
            <li><b>Modification to Services.</b>2 We have the right, in our sole discretion, to revise, update, or otherwise modify the Services or alter your access to the Services; and for material changes, to the extent reasonably possible, we will provide you with reasonable notice either posted on the website hosting the Services or to the Administrator’s (as defined below) email address. Note that we may modify the amount of storage space you have through the Services and the number of times (and the maximum duration for which) you may access the Services in a given period of time. In the event we need to maintain the security of the system or comply with any laws or regulations, we reserve the right to modify the Services immediately, and to provide the Administrator with electronic or written notice within thirty (30) days after any material modifications. You may reject any changes to the Services by discontinuing use of the Services to which such changes relate. Your continued use of the Services will constitute your acceptance of and agreement to such changes.</li>
          </ol>
        </li>
        <li>
          <b>USERS.</b>
          <ol>
            <li><b>Types of Users.</b> The Services allow the following types of access and user rights: When you initially register for and create an account for a Service, you are, or a party that you authorize is, the administrator (“Administrator”). Administrators may authorize additional individuals to access the Services through the same account (“Additional Users”). The number of Additional Users may be limited based upon the subscription you purchase. Additional Users may include, for example, your employees, accountant, contractors, agents, and clients. You may be referred to in this Agreement as “you”, “your”, or “User”, or you may be referred to specifically in your applicable role as an Additional User or an Administrator. All Users will be required to accept this Agreement before accessing the Services. With respect to TBAP or each TB Account you access, you agree to these terms as an Administrator or an Additional User, as applicable. As any User of the Services, unless otherwise explicitly stated in this Agreement, all of these terms apply to you each time you access the Services.</li>
            <li><b>For Administrators (users that have access to TAX LAB accounts).</b> As an Administrator, the following applies to you: Only Administrators may designate another individual as a replacement Administrator. You agree that Additional Users are TAX LAB customers, but that you are responsible for your Additional Users’ access to the Services. Depending on the types of access rights you grant to Additional Users, Additional Users may be able to delete, copy, or view the User Contributions and data accessible in your account and add charges to the subscription. As Administrator, you are responsible for the access to the Services you grant to Additional Users. If you choose to close or terminate your access to a Service, Additional Users will no longer be able to access such Service or any of the User Contributions within such Service. You agree to provide sufficient notice to Additional Users of your desire to terminate access to the Services before taking such actions. A violation of any terms of this Agreement by an Additional User may result in the termination of an Administrator's or any Additional User's access to the Services.</li>
            <li><b>For Additional Users.</b> As an Additional User, the following applies to you: When you register to access an account for which you are not an Administrator, you understand that you are accessing the Service as an Additional User and you may not have the same level of access or the same rights as an Administrator. Depending on the types of rights you are granted by the Administrator, you may be able to delete, copy, or view other User’s User Contributions and data. Please make sure that you have an agreement with the Administrator about your role and rights. If an Administrator chooses to close or terminate access to a Service, Additional Users will no longer be able to access such Service or any of the User Contributions within such Service. As an Additional User, you understand that if you violate this Agreement, your access to our Services may be terminated, and we retain the right to also terminate access for any other Users of the same account.</li>
          </ol>
        </li>
        <li>
          <b>SUBSCRIPTION.</b>
          <ol>
            <li><b>Payment for Services.</b> The Services may be licensed on a monthly or yearly subscription basis to the User that pays for the Service. As the Administrator, you may choose whether you or another User pays for the license. Please review all of the details of the subscription that you purchase; some subscriptions provide access to one Service and others provide access to more than one Service. If any amount payable by you is dishonored or returned for any reason, such as, but not limited to, non-sufficient funds, account closed, inability to locate account, or reversal by you and/or your bank, TAX LAB may: a) reverse any corresponding credit issued to TAX LAB, you, or any other party without liability to you or any other party, (b) apply any money currently held by TAX LAB to any amount owed to TAX LAB by you, (c) initiate another debit in substitution for the dishonored debit until the debit is honored and to initiate separate debits to cover any NSF fee or dishonored payment fees, (d) charge you a one-time insufficient funds penalty fee for each occurrence, (e) report this information to any and all credit agencies and/or financial institutions, and/or (f) immediately terminate this Agreement. TAX LAB may assess and collect interest at the rate of one and one-half percent (1.5%) per month (18% per annum) or the highest rate permissible under California law on any amounts owing and unpaid ten (10) days after demand. If further collection attempts are required, all collections costs will be charged to you, including but not limited to any costs associated with termination of this Agreement, and attorney fees, where permitted by law.</li>
            <li><b>Subscription Cancellation.</b> The Administrator may notify us if he or she wants to cancel the subscription prior the beginning of the new subscription period. In the event that TAX LAB is unable to charge a User's chosen payment method in accordance with this Agreement, we may terminate this Agreement and access to the Services immediately, without notice. If you stop using the Services, TAX LAB has fulfilled your subscription term. Fees for the Services are not eligible for any proration of unused subscriptions or refunds, even if access to the Services is cancelled or terminated. After your access to the Services is terminated, you may no longer have access to any of the data or User Contributions in the Services. We suggest you retain your own copies of any data or User Contributions that you may need as TAX LAB is not responsible for providing you with access to your User Contributions or the Services after any cancellation or termination of this Agreement.</li>
          </ol>
        </li>
        <li>
          <b>ACCOUNT FEATURES.</b>
          <ol>
            <li><b>Trial Version.</b> If you registered for a trial use of the Services, you will have access to the Services for the specified period of the trial (“Trial Period”) unless you terminate before the trial period ends. You must decide to purchase a license to the Services within the Trial Period in order to retain access to any User Contributions or data provided or created during the Trial Period. If you terminate before the Trial Period ends or do not purchase a license to the Services by the end of the Trial Period, you will not be able to access or retrieve any of the data or User Contributions you added to or created with the Services during the trial.</li>
            <li><b>Beta Features.</b> From time to time, we may include new or updated beta features in the Services (“Beta Features”). Beta Features may have associated fees, which will be disclosed to you at the time you choose to use the Beta Features. We understand that your use of any Beta Feature is voluntary. You understand that once you use a Beta Feature, you may be unable to revert back to a prior non-beta version of the same or similar feature. Additionally, if such reversion is possible, you may not be able to return or restore data created within the Beta Feature back to the prior non-beta version. The Beta Features are provided on an "as is" basis and may contain errors or inaccuracies that could cause failures, corruption or loss of data and information from any connected device. YOU ACKNOWLEDGE AND AGREE THAT ALL USE OF ANY BETA FEATURE IS AT YOUR SOLE RISK.</li>
          </ol>
        </li>
        <li>
          <b>DATA.</b>
          <ol>
            <li><b>Personal Data.</b> If you are providing data to us that is not personal to you, you agree that you have either provided the owner of such personal data notice or received permission from the owner of such personal data, as required by applicable law, for us to: (a) use or disclose the data in accordance with our Privacy Policy, (b) move the data outside of the country of residence of such owner of the personal data, if applicable, (c) provide the data to Third Party Products that you approve, and (d) otherwise use and disclose the data in accordance with this Agreement. You acknowledge and agree that TAX LAB may provide data in your account to any Additional Users to which that data is applicable or personal to.</li>
            <li><b>Public Content.</b> As a User you may have the opportunity to share your data, User Contributions, or ways in which you aggregate data (“Account Content”) with other Users, other TAX LAB customers, and other third parties. When sharing any Account Content, you agree not to share any confidential information. If you have the option of accessing another User's Account Content, you understand and agree that the Account Content is being provided by the User, and not TAX LAB, for information and guidance purposes only, and TAX LAB and such User are not responsible in any way for your use the Account Content.</li>
            <li><b>Telephone numbers.</b> You may provide us with your telephone number as part of your customer record or registration or via other methods. You understand and agree that TAX LAB may use your telephone number for "multi-factor authentication” (“MFA"), to confirm your identity and help protect the security of your account. Part of the MFA identity verification process may involve TAX LAB sending text messages containing security codes to your telephone number. You agree to receive these texts from TAX LAB containing security codes as part of the MFA process. In addition, you agree that TAX LAB may send automated text messages and pre-recorded voice messages to the telephone number you provide for other limited purposes, including: providing you with important critical notices regarding your use of the Services, or fulfilling a request made by you through the Services. When Tax Lab offers a  Mobile App Download Program, Message and Data rates may apply. Mobile carriers are not liable for delayed or undelivered messages. With MFA, you also agree and consent to us obtaining and using information from your mobile phone service provider, solely for the purposes of verifying your identity and to compare information you have provided to TAX LAB (such information may include from your mobile phone service provider account record: your name, address, email, customer type, customer role, billing type, mobile device identifiers (IMSI and IMEI) and other mobile phone subscriber details).</li>
          </ol>
        </li>
        <li>
          <b>OTHER PRODUCTS AND SERVICES</b>
          <ol>
            <li><b>Third Party Products.</b> By using these Services, you agree that we may market to you or offer you access to products or services from third parties (“<b>Third Party Products</b>”). If you decide to use or access any Third Party Products, you agree that you are solely responsible for your relationship with the provider of the product. TAX LAB is not affiliated with Third Party Products and does not endorse or recommend any Third Party Products. You agree that the providers of the Third Party Products, and not TAX LAB, are solely responsible for their own actions or inactions. TAX LAB is not liable for any damages, claims or liabilities arising out of or related to any Third Party Products. You agree that you will (a) review and comply with all Third Party Product terms and conditions, and (b) not use the Third Party Product in any manner that would infringe or violate the rights of TAX LAB or any other party or in furtherance of criminal, fraudulent or other unlawful activity.</li>
            <li><b>Data Transfer Service.</b> We may provide you with the opportunity to transfer your data and User Contributions from the Services to
              certain supported online Third Party Products or other online TAX LAB services (the “<b>Ancillary Services</b>”) that you sign up for or use
              in connection with the Services (the “<b>Data Transfer Service</b>”). You may need to be an active subscriber of the Third Party Product or
              Ancillary Services to use the Data Transfer Service. In order to access a Third Party Product or an Ancillary Service on your behalf to provide
              the Data Transfer Service, you may need to provide us with your account number, password, security questions and answers, and any other necessary
              log in information from time to time (“<b>Login Details</b>”). We will maintain your Login Details in encrypted form, and will only use them
              in connection with the Data Transfer Service or otherwise at your direction. You hereby represent that you have the authority to provide the
              Login Details to TAX LAB and you expressly appoint TAX LAB as your, or the third party who owns the Login Details’, agent with limited power
              of attorney to access any Third Party Products or Ancillary Services on your behalf. With respect to each Data Transfer Service, you grant
              TAX LAB the right to transfer data to the Third Party Product or Ancillary Service, and to reformat and manipulate the data as reasonably
              necessary for the data to function with such product or service. After the transfer occurs, the original data and User Contributions will remain in the Services unless we disclose to you otherwise.</li>
              <br />
              <p>
                You agree that you will (a) review and comply with all Third Party Product or Ancillary Service terms and conditions before you access the
                Data Transfer Service, and (b) not use the Data Transfer Service in any manner that would infringe or violate the rights of TAX LAB or any
                other party, or in furtherance of criminal, fraudulent or other unlawful activity. Depending on the Third Party Product or Ancillary Service
                you choose, you further agree and acknowledge that your data, including your financial or personal information, may be transferred through the
                Data Transfer Service to another country where security and privacy controls may not be adequate for data protection. We do not guarantee that
                you will be able to use the Data Transfer Service with any specific products or services. You will only have access to the Data Transfer
                Services during the period for which you have paid for a subscription. Third Party Products may make changes to their websites, with or without
                notice to us, that may prevent or delay the overall performance of the Data Transfer Service.
              </p>
            <li><b>Data Receipt Service.</b> We may provide you with the opportunity to transfer certain data from a Third Party Product or an Ancillary Service to these Services (the “Data Receipt Service”). You may need to be an active subscriber of the Third Party Product or Ancillary Services to use the Data Receipt Service. In order to access a Third Party Product or an Ancillary Service on your behalf, you may need to provide us with your Login Details. We will maintain your Login Details in encrypted form, and will only use them in connection with the Data Receipt Service or otherwise at your direction. You hereby represent that you have the authority to provide the Login Details to TAX LAB and you expressly appoint TAX LAB as your, or the third party who owns the Login Details’, agent with limited power of attorney to access any Third Party Products or Ancillary Services and retrieve data on your behalf. With respect to each Data Receipt Service, you grant TAX LAB the right to transfer data to the Services, and to reformat and manipulate your data as reasonably necessary for the data to function with the Services. After the transfer occurs, your original data and User Contributions may not remain in the Third Party Product or the Ancillary Services; please review the terms of those products and services to confirm.</li>
              You agree that you will (i) review and comply with all Third Party Product and Ancillary Service terms and conditions before you access the Data Receipt Services, and (ii) not use the Data Receipt Services in any manner that would infringe or violate the rights of TAX LAB or any other party, or in furtherance of criminal, fraudulent or other unlawful activity. In the event that any Third Party Product charges for access to data, you agree that you are responsible for any fees due and owing. You agree that the providers of the Third Party Products, and not TAX LAB, are solely responsible for their own actions or inactions. TAX LAB is not liable for any damages, claims or liabilities arising out of or related to any Third Party Products.
              We do not guarantee that you will be able to use the Data Receipt Service with any specific products or services. You will only have access to the Data Receipt Services during the period for which you have paid for a subscription. Third Party Products may make changes to their websites, with or without notice to us, that may prevent or delay aggregation of data or the overall performance of the Data Receipt Service. Your most recent data from a Third Party Product or Ancillary Service may not always be available in the Services. Any data obtained through the Data Receipt Service will be made available in the Services, and will be subject to the terms and conditions of this Agreement, including our Privacy Policy.
            <li><b>Third Party Code.</b> The Services uses Third Party Code  (“<b>Third Party Code</b>”), which is subject to the following additional license terms. You agree that you (a) will use the Third Party Code only as an integral component of the Services; (b) will not use the Third Party Code for development, compilation, debugging and similar design-time purposes; (c) will not reverse-compile or decompile, analyze, reverse-engineer, reverse-assemble or disassemble, unlock or otherwise attempt to discover the source code or underlying algorithms of the Third Party Code or attempt to do any of the foregoing in relation to the object code of the Third Party Code; and (iv) will not modify, adapt, translate or create any derivative works of the Third Party Code or merge the Third Party Code into any other software.</li>
            <li><b>Service Providers.</b> We may use third parties in the operation of our Services or to perform any of our obligations in this Agreement (each a “<b>Service Provider</b>”). In order for our Service Providers to be able to provide you with certain aspects of the Services, we may share a limited amount of your data or User Contributions with such Service Provider. Our agreements with Service Providers outline the appropriate use and handling of this information and prohibit the Service Provider from using any of your information for purposes unrelated to the Services.</li>
          </ol>
        </li>
        <li><b>COMMUNICATION SERVICES.</b> We may provide you with the opportunity to communicate with other Users within the Services, or with other third parties through the Services (“Communication Services”). You may choose whether or not you would like to use the Communication Services. You agree that you have received permission to communicate with any third parties who are not Users through the Communication Services, and that such third parties have agreed to TAX LAB’s use and disclosure of data available in the Communication Services in accordance with this Agreement. Users who you communicate with may have access in the future to the data provided through the Communication Services, and you may not have the ability to restrict that access. TAX LAB’s systems may extract certain data (e.g., numbers, names, or attachments) from the Communication Services and provide it to you in other parts of the Services</li>
        <li><b>NOT A FINANCIAL PLANNER, BROKER OR TAX ADVISOR.</b> NEITHER TAX LAB NOR THE SERVICES ARE INTENDED TO PROVIDE LEGAL, TAX OR FINANCIAL ADVICE. TAX LAB IS NOT A FINANCIAL PLANNER, BROKER OR TAX ADVISOR AND THE SERVICES ARE NOT INTENDED TO REPLACE OR SERVE AS A SUBSTITUTE FOR THE ADVICE OF A LICENSED PROFESSIONAL. We do not warrant the accuracy, completeness, or usefulness of any information provided by the Services. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other user of the Services, or by anyone who may be informed of any of its contents.. TB is intended only to assist you in your financial organization and decision-making and is broad in scope. Your personal financial situation is unique, and any information and advice obtained through TB may not be appropriate for your situation. Accordingly, before making any final decisions or implementing any financial strategy, you should obtain additional information and advice from your accountant or other financial advisers who are fully aware of your individual circumstance.</li>
        <li>
          <b>SYNKBOOKS NOTIFICATION AND DISCLAIMER</b>
          <ol>
            <li>TAX LAB will provide you with account-related notifications due to inaction on the account, confirmation of information and reminders to categorize your transactions. These notifications will be sent to the email address you have provided as your primary email address when you register for TB. Anyone with access to your email will be able to view the content of these notifications.</li>
            <li>You understand and agree that any notifications provided to you through TB may be delayed or prevented by a variety of factors. TAX LAB does its best to provide notifications in a timely manner with accurate information. However, we neither guarantee the delivery nor the accuracy of the content of any notification. You also agree that TAX LAB shall not be liable for any delays, failure to deliver, or misdirected delivery of any notification; for any errors in the content of a notification; or for any actions taken or not taken by you in reliance on a notification.</li>
          </ol>
        </li>
      </ol>
    </Container>
  </PublicLayout>);

export default TermsOfService;