import React from 'react';
import { Header } from 'semantic-ui-react';

const getTitle = (contentType) => {
  let title;
  switch (contentType) {
    case 'bulk':
      title = 'Edit Category';
      break;
    case 'creation':
      title = 'Create Transaction';
      break;
    default:
      title = 'Edit Transaction';
      break;
  }
  return title;
};

const HeaderSection = props => (
  <Header as="h3">
    {getTitle(props.contentType)}
  </Header>
);

export default HeaderSection;
