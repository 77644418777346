import React from 'react';
import Layout from 'components/containers/PrivateLayoutv2';
import Routes from 'routes';
import { Switch, Route } from 'react-router-dom';
import Transactions from './Transactions';
import Clients from './Clients';
import Reports from './Reports';
import * as ReactGA from "react-ga";
import Invoicing from "screens/ClientDashboard/Invoicing";

ReactGA.set({ bookkeeperPage: window.location.pathname });
ReactGA.pageview(window.location.pathname);

const Dashboard = () => (
  <Layout>
    <Switch>
      <Route exact path={Routes.ClientDashboardClients} component={Clients} />
      <Route exact path={Routes.ClientDashboardTransactions} component={Transactions} />
      <Route path={Routes.ClientDashboardReports} component={Reports} />
      <Route path={Routes.ClientDashboardInvoicing} component={Invoicing} />
    </Switch>
  </Layout>
);

export default Dashboard;
