import React from 'react';
import {
  Image, Menu, Container, Responsive,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import taxlabLogo from 'images/logo.svg';
import Sections from 'components/presentationals/HeaderSectionsv2';
import HeaderProfileMenu from 'components/containers/HeaderProfileMenuv2';
import Routes from 'routes';

/**
 * A menu with headers
 *
 * Succeeds @see {@link Header}. This Header component is designed to be
 * decoupled from routing.
 */
const Headerv2 = props => (
  <Menu borderless size="huge" className="Headerv2__Menu">
    <Container>
      <Menu.Item>
        <Link to={Routes.ClientDashboardClients}><Image src={taxlabLogo} size="small" /></Link>
      </Menu.Item>
      <Menu.Item>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Sections />
        </Responsive>
      </Menu.Item>
      <Menu.Menu position="right">
        <HeaderProfileMenu />
      </Menu.Menu>
    </Container>
  </Menu>
);

export default Headerv2;
