import React from 'react';
import footerLogo from 'images/logo-new.svg';
import linkedin from 'images/linkedinb.png';
import facebook from 'images/fb.png';
import twitter from 'images/twitterb.png';
import './styles.scss';

const Footer = () => (
  <>
    <div className="Footer">
      <img className="Footer--logo" src={footerLogo} alt="" />
      <a
        href="/contact-us"
        target="_blank"
        rel="noopener noreferrer"
        className="Footer--contact-us"
      >
        {'CONTACT US'}
      </a>
      <div className="Footer--social-icons">
        <span className="left ">
          <a href="" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="Facebook Logo" />
          </a>
        </span>
        <span className="center left right">
          <a
            href="https://www.linkedin.com/company/tax-lab-inc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="LinkedIn Logo" />
          </a>
        </span>
        <span className="right">
          <a rel="noopener noreferrer" href="https://twitter.com/synkbooks" target="_blank">
            <img src={twitter} alt="Twitter Logo" />
          </a>
        </span>
      </div>
    </div>
    <div className="Footer--credits">
      <p className="Footer--copyrights"> SynkBooks © 2021 Copyright</p>
    </div>
  </>
);

export default Footer;
