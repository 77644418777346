import {
  YEAR, CURRENT_MONTH, PREVIOUS_MONTH, Q1, Q2, Q3, Q4, CUSTOM,
} from 'libraries/transactionsFilterManager';
import moment from 'moment';

const options = [
  {
    value: YEAR,
    text: 'Year to date',
  }, {
    value: CURRENT_MONTH,
    text: 'This month',
  }, {
    value: PREVIOUS_MONTH,
    text: 'Last month',
  }, {
    value: Q1,
    text: 'Q1 - current year',
  }, {
    value: Q2,
    text: 'Q2 - current year',
  }, {
    value: Q3,
    text: 'Q3 - current year',
  }, {
    value: Q4,
    text: 'Q4 - current year',
  }, {
    value: CUSTOM,
    text: 'Custom',
  },
];

export default options.reduce((prevOptions, option, idx) => {
  if (option.value.match(/(Q1|Q2|Q3|Q4)/)) {
    const currentQuarter = moment().quarter();
    const optionQuarter = Number(option.value[1]);
    if (optionQuarter > currentQuarter) return prevOptions;
  }
  return [
    ...prevOptions,
    {
      key: idx,
      ...option,
    },
  ];
}, []);
