import React from "react";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import './styles.scss';

class SelectAction extends React.Component {
  state = {
    isDeletingModal: false,
  }

  setOpenDeletingModal = (open) => {
    this.setState({
      isDeletingModal: open
    })
  }

  handleChange = (e, data) => {
    this.props.onAction(this.props.item.id, data.value);
  };

  handleSelect = (value) => {
    this.props.onAction(this.props.item.id, value);
  }

  render() {
    const status = this.props.item.status;
    const show = status === "paid" || status === "canceled";
    return (
      <>
        <Modal
          size="tiny"
          open={this.state.isDeletingModal}
        >
          <Modal.Header>Delete draft invoice</Modal.Header>
          <Modal.Content>
            <h3 style={{ fontWeight: "normal" }}>
              Are you sure you want to permanently delete this draft invoice? This cannot be undone.
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setOpenDeletingModal(false)}>Cancel</Button>
            <Button primary onClick={() => {
              this.handleSelect('delete');
              this.setState({ isDeletingModal: false })
            }}>
              Delete draft
            </Button>
          </Modal.Actions>
        </Modal>
        <Dropdown
          text={show ? "Copy" : "Remind"}
          floating
          button
          className="icon select-action"
          basic
        >
          <Dropdown.Menu>
            {!show && <Dropdown.Item onClick={() => this.handleSelect('remind')} disabled = {status === "draft"}>Remind</Dropdown.Item>}
            {show && <Dropdown.Item onClick={() => this.handleSelect('copy')}>Copy</Dropdown.Item>}
            <Dropdown.Item value="edit" onClick={() => this.handleSelect('edit')}>{status === "draft" ? "Edit" : "Detail"}</Dropdown.Item>
            <Dropdown.Item onClick={() => this.handleSelect('print')} disabled = {status === "draft"}>Print/PDF</Dropdown.Item>
            {!show && <Dropdown.Item onClick={() => this.handleSelect('cancel')} disabled = {status === "draft"}>Cancel</Dropdown.Item>}
            {!show && <Dropdown.Item onClick={() => this.setOpenDeletingModal(true)} disabled = {status !== "draft"}>Delete draft</Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

export default SelectAction;
