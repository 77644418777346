import React from 'react';
import { ProfitCustomSummary } from "components/containers/TransactionsCustomTable/CustomProfitSummary"
import { TransactionsCustom } from "components/containers/TransactionsCustomTable";

const CustomDashboard = () => (
  <>
    <ProfitCustomSummary />
    <TransactionsCustom />
  </>
);

export default CustomDashboard;
