import React from 'react';
import Routes from 'routes';
import { Switch, Route } from 'react-router-dom';
import ManageInvoice from 'components/containers/ManageInvoice';
import CreateInvoice from 'components/containers/CreateInvoice';
import DetailInvoice from 'components/containers/ManageInvoice/detail';

const Invoicing = () => (
    <Switch>
      <Route exact path={Routes.DashboardInvoicing} component={ManageInvoice} />
      <Route path={Routes.DashboardInvoicingCreate} component={CreateInvoice} />
      <Route path={Routes.DashboardInvoicingDetail} component={DetailInvoice} />
    </Switch>
);

export default Invoicing;
