import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, Grid } from 'semantic-ui-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Routes from 'routes';
import PasswordRecoveryEmail from 'components/containers/PasswordRecoveryEmail';
import PasswordRecoveryPinCode from 'components/containers/PasswordRecoveryPinCode';
import PasswordRecoveryReset from 'components/containers/PasswordRecoveryReset';
import { clearRecoveryData } from 'store/actions/passwordRecovery';

class PasswordRecovery extends React.Component {
  componentWillUnmount() {
    this.props.clearRecoveryData();
  }

  render() {
    const logged = this.props.session.userDidLogin;
    if (logged) {
      return (<Redirect to={Routes.OnboardingReportForm} />);
    }
    return (
      <Grid centered>
        <Grid.Row>
          <Header as="h1">Password Recovery</Header>
        </Grid.Row>
        <Switch>
          <Route exact path={Routes.PasswordRecoveryPinCode} component={PasswordRecoveryPinCode} />
          <Route exact path={Routes.PasswordRecoveryReset} component={PasswordRecoveryReset} />
          <Route component={PasswordRecoveryEmail} />
        </Switch>
      </Grid>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  session: state.session,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearRecoveryData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
