import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pushNotification, removeNotification, clearNotifications } from 'store/actions/notifications';

export const WrappedComponent = (props) => {
  const { Component } = props;
  return (<Component {...props} />);
};

const WithNotifications = (Component) => {
  const WrappedNotifications = props => <WrappedComponent {...props} Component={Component} />;

  /* istanbul ignore next */
  const mapStateToProps = state => ({
    notifications: state.notifications,
  });

  /* istanbul ignore next */
  const mapDispatchToProps = dispatch => bindActionCreators({
    pushNotification,
    removeNotification,
    clearNotifications,
  }, dispatch);

  /* istanbul ignore next */
  return connect(mapStateToProps, mapDispatchToProps)(WrappedNotifications);
};

export default WithNotifications;
