import React from 'react';
import {
  Grid, Form, Container, Button, Message,
} from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Routes from 'routes';
import { isValidEmail, FORM_ERROR_CODES } from 'libraries/formValidator';
import withNotifications from 'components/hocs/WithNotifications';
import { sendPasswordRecoveryEmail } from 'libraries/api-service';
import { updateRecoveryEmail } from 'store/actions/passwordRecovery';

export class PasswordRecoveryEmail extends React.Component {
  state = {
    hasValidEmail: true,
    isLoading: false,
  };

  emailRef = React.createRef();

  componentDidMount() {
    this.emailRef.current.focus();
  }

  handleInputChange = ({ target }) => {
    this.props.updateRecoveryEmail(target.value);
  }

  onSubmit = () => {
    const { email } = this.props;
    if (isValidEmail(email)) {
      const { history, pushNotification } = this.props;
      this.setState({ isLoading: true });
      sendPasswordRecoveryEmail(email)
        .then(() => {
          pushNotification('NOTIFICATION_PIN_CODE_SENT', 'Verification code sent',
            'A verification code has been sent to your email. Copy that code in the field below in order to reset your password.',
            'success', 7000);
          history.push(Routes.PasswordRecoveryPinCode);
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          pushNotification('NOTIFICATION_PIN_CODE_SENT_FAIL', 'Verification code couldnt be sent.',
            'We couldnt send the verification code to your email. Validate your email and try again.',
            'error', 7000);
        });
    } else {
      this.setState({ hasValidEmail: false });
    }
  }

  render() {
    const { hasValidEmail } = this.state;
    const { email } = this.props;
    return (<>
      <Grid.Row>
        <p>Enter your email below to send you the instructions for your password recovery.</p>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Form
            onSubmit={this.onSubmit}
            loading={this.state.isLoading}
          >
            <Form.Field required>
              <input
                type="email"
                id="recovery_email"
                ref={this.emailRef}
                placeholder="youremail@domain.com"
                value={email}
                onChange={this.handleInputChange}
                required
              />
            </Form.Field>
            {
              !hasValidEmail
              && (
              <Message
                negative
                header="Verify your email"
                content={FORM_ERROR_CODES.EMAIL_INVALID_FORMAT.content}
              />)
            }
            <Container textAlign="center">
              <Button primary>Send</Button>
            </Container>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Link to={Routes.Login}>Back to login</Link>
    </>);
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ passwordRecovery: { email } }) => ({
  email,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  updateRecoveryEmail,
}, dispatch);


const wrappedComponent = withRouter(withNotifications(PasswordRecoveryEmail));
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
