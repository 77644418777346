import React from "react";
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Loader,
  Message,
  Modal,
  Segment,
  Select,
  TextArea
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import "./styles.scss";
import Routes from "routes";
import TotalTable from "./TotalTable";
import LineItem from "./LineItem";
import ItemHeader from "./ItemHeader";
import {
  addTaxAsProfessional,
  createInvoiceAsProfessional,
  getInvoiceByIdAsProfessional,
  getTaxesAsProfessional
} from "libraries/api-v2/invoicing-service";
import { isValidEmail } from "libraries/formValidator";
import { currencyFormat } from "libraries/utils";
import InvoicePreview from "./invoicePreview";
import connect from "react-redux/lib/connect/connect";
import jsCookie from "js-cookie";

const options = [
  { key: 1, text: "Quantity(default)", value: 1 },
  { key: 2, text: "Amount", value: 2 }
];

//{ description: "", quantity: 1, unitPrice: 0, amount: 0 }
class CreateInvoiceV2 extends React.Component {
  state = {
    lineItems: [],
    info: {
      receiverInfo: {
        name: "",
        email: "",
        address: ""
      }
    },
    invoiceNumber: "",
    invoice_id: "",
    currency: "usd",
    memo: "",
    footer: "",
    dueDateNum: 1,
    invoiceDate: moment(new Date()).format("MM/DD/YYYY"),
    dueDate: moment(Date.now() + 1000 * 3600 * 24).format("MM/DD/YYYY"),
    selected: 0,
    open: false,
    invoiceTemplate: 1,
    error: "",
    isSending: false,
    isSaving: false,
    isLoading: false,
    taxRateData: [],
    show: false,
    display_name: "",
    percentage: "",
    taxError: "",
    coupon: {
      discount_type: "percent",
      discount: 0
    },
    isPreview: false
  };

  addLineItem = () => {
    let lineItems = this.state.lineItems;
    lineItems.push({
      description: "",
      quantity: 1,
      unitPrice: 0,
      amount: 0,
      taxRates: []
    });
    this.setState({ lineItems: lineItems, selected: lineItems.length - 1 });
  };

  removeLineItem = index => {
    let lineItems = this.state.lineItems;
    lineItems.splice(index, 1);
    this.setState({ lineItems: lineItems, selected: lineItems.length - 1 });
  };

  selectLineItem = index => {
    this.setState({ selected: index });
  };

  setLineItem = (description, quantity, unitPrice, amount) => {
    let lineItems = this.state.lineItems;
    lineItems[this.state.selected].description = description;
    lineItems[this.state.selected].quantity = Number(quantity);
    lineItems[this.state.selected].unitPrice = Number(unitPrice);
    lineItems[this.state.selected].amount = Number(amount);
    let error = this.state.error.replace("lineItems", "");
    this.setState({ lineItems: lineItems, error: error });
  };

  setOpen = open => {
    this.setState({ open: open });
  };
  setCurrency = (e, data) => {
    this.setState({ currency: data.value });
  };
  setTemplate = (e, data) => {
    this.setState({ invoiceTemplate: data.value });
  };
  onTax = tax => {
    let lineItems = this.state.lineItems;
    let currentItem = lineItems[this.state.selected];

    if (tax.indexOf("add") > -1) this.setState({ show: true });
    else if (tax.indexOf("noTax") > -1) currentItem.taxRates = [];
    else {
      currentItem.taxRates = tax;
    }
    lineItems[this.state.selected] = currentItem;
    this.setState({ lineItems: lineItems });
  };
  addTaxItem = () => {
    const display_name = this.state.display_name;
    const percentage = this.state.percentage;
    const taxRateData = this.state.taxRateData;
    const clientEmail = jsCookie.get("active_client_email");
    let taxError = "";
    if (taxRateData.filter(el => el.display_name == display_name).length > 0)
      taxError =
        "You have alreay saved a tax with this name, Enter a new name.";
    if (display_name == "" || percentage == 0)
      taxError = "Please input tax rate information.";
    this.setState({ taxError: taxError });
    if (taxError != "") return;
    this.setState({ isSending: true });
    addTaxAsProfessional(clientEmail, {
      display_name: this.state.display_name,
      percentage: this.state.percentage
    })
      .then(res => {
        this.setState({ isSending: false, show: false });
        this.getTaxData();
      })
      .catch(err => {
        console.log(err.response);
        this.setState({ isSending: false, show: false });
      });
  };
  handleTax = (type, value) => {
    if (type == "display_name") this.setState({ display_name: value });
    if (type == "percentage") this.setState({ percentage: value });
  };

  getTaxData() {
    const clientEmail = jsCookie.get("active_client_email");
    getTaxesAsProfessional(clientEmail)
      .then(res => {
        this.setState({ taxRateData: res.data });
      })
      .catch(err => {});
  }

  setCoupon = (type, value) => {
    this.setState({
      coupon: {
        discount_type: type,
        discount: value
      }
    });
  };

  /****send invoce ********/
  getInvoiceData = () => {
    return {
      receiverName: this.state.info.receiverInfo.name,
      receiverEmail: this.state.info.receiverInfo.email,
      receiverAddress: this.state.info.receiverInfo.address,
      currency: this.state.currency,
      dueDateNum: (
        Math.round(
          new Date(this.state.dueDate).getTime() -
            new Date(this.state.invoiceDate).getTime()
        ) /
        (1000 * 3600 * 24)
      ).toFixed(0),
      invoiceItems: [
        ...this.state.lineItems.filter(
          item => item.unitPrice !== 0 || item.amount !== 0
        )
      ],
      memo: this.state.memo,
      footer: this.state.footer,
      draft: false,
      coupon: this.state.coupon
    };
  };

  validation = () => {
    let error = "";
    let data = this.getInvoiceData();
    if (data.receiverEmail == "" || !isValidEmail(data.receiverEmail))
      error += "email";
    if (data.receiverName == "") error += "name";
    if (data.invoiceItems.length < 1) error += "lineItems";
    if (data.dueDateNum < 1) error += "dueDate";

    this.setState({ error: error });
    if (error == "") return true;
    else return false;
  };
  createInvoice = data => {
    const clientEmail = jsCookie.get("active_client_email");
    createInvoiceAsProfessional(clientEmail, data)
      .then(res => {
        this.setState({ isSending: false, isSaving: false });
        this.setOpen(false);
        this.props.history.push(Routes.ClientDashboardInvoicing);
      })
      .catch(err => console.log(err));
  };
  sendInvoiceData = () => {
    if (!this.validation()) return;
    let data = this.getInvoiceData();
    if (this.state.invoice_id != "") data["invoiceId"] = this.state.invoice_id;
    this.setState({ isSending: true });
    this.createInvoice(data);
  };
  saveAsDraft = () => {
    if (!this.validation()) return;
    let data = this.getInvoiceData();
    if (this.state.invoice_id != "") data["invoiceId"] = this.state.invoice_id;
    data.draft = true;
    this.setState({ isSaving: true });
    this.createInvoice(data);
  };

  handleDateChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      let error = this.state.error.replace("dueDate", "");
      this.setState({ [name]: value, error: error });
    }
  };

  /********************** */

  componentDidMount() {
    const selectedId = localStorage.getItem("selectedId");
    const clientEmail = jsCookie.get("active_client_email");
    this.getTaxData();
    if (selectedId == 0) {
      this.addLineItem();
    } else {
      this.setState({ isLoading: true });
      getInvoiceByIdAsProfessional(selectedId)
        .then(res => {
          let info = this.state.info;
          info.receiverInfo.name = res.data.receiver_name;
          info.receiverInfo.email = res.data.receiver_email;
          let lineItems = this.state.lineItems;
          res.data.invoiceItems.forEach(el =>
            lineItems.push({
              description: el.description,
              quantity: el.quantity,
              unitPrice: el.unit_price,
              amount: el.amount,
              taxRates: el.taxRates.filter(rate => rate != "")
            })
          );

          this.setState({
            info: info,
            lineItems: lineItems,
            selected: lineItems.length - 1,
            isLoading: false,
            memo: res.data.memo,
            footer: res.data.footer,
            invoiceDate: moment(
              this.props.editableStatus === "duplicate"
                ? new Date()
                : new Date(res.data.created_at)
            ).format("MM/DD/YYYY"),
            dueDate: moment(
              this.props.editableStatus === "duplicate"
                ? new Date().getTime() + 1000 * 3600 * 24
                : new Date(
                    res.data.created_at +
                      res.data.due_date_num * (1000 * 3600 * 24)
                  )
            ).format("MM/DD/YYYY"),
            coupon: {
              discount_type: res.data.discount_type,
              discount: res.data.discount
            },
            invoice_id:
              this.props.editableStatus === "duplicate"
                ? ""
                : res.data.invoice_id
          });
        })
        .catch(err => {
          console.log(err.response);
          this.setState({ isLoading: false });
        });
    }
  }

  getSubtotal = () => {
    let subtotal = 0;
    this.state.lineItems.forEach(item => (subtotal += item.amount));
    return subtotal;
  };

  getTax = () => {
    let tax = 0;
    if (this.state.taxRateData.length > 0 && this.state.lineItems.length > 0)
      this.state.lineItems.forEach(item => {
        let taxRateData = this.state.taxRateData.filter(
          el => item.taxRates.indexOf(el.tax_rate_id) > -1
        );
        tax +=
          ((item.amount - item.amount * this.getDiscountRate()) *
            (taxRateData.length > 0 ? taxRateData[0].percentage : 0)) /
          100.0;
      });
    return tax;
  };

  getDiscountRate = () => {
    let rate = 0;
    if (this.state.coupon.discount_type === "percent") {
      rate = this.state.coupon.discount;
    } else if (this.state.coupon.discount_type === "amount")
      rate = (this.state.coupon.discount / this.getSubtotal()) * 100;
    return rate / 100;
  };

  getDiscount = () => {
    let discount = 0;
    if (this.state.lineItems.length > 0) {
      this.state.lineItems.forEach(item => {
        discount += item.amount * this.getDiscountRate();
      });
    }
    return discount;
  };

  getTotal = () => {
    return this.getSubtotal() - this.getDiscount() + this.getTax();
  };

  render() {
    const { editableStatus } = this.props;
    const { open, show, isSending, isPreview } = this.state;

    const invoiceControl = () => {
      return (
        <Grid.Column style={{ float: "right" }}>
          <Button
            onClick={() => {
              if (this.validation())
                this.setState({ isPreview: !this.state.isPreview });
            }}
          >
            {isPreview ? "Back" : "Preview"}
          </Button>
          <Button
            onClick={() => {
              if (this.validation()) this.setOpen(true);
            }}
          >
            Send
          </Button>
          <Button onClick={this.saveAsDraft}> Save as Draft</Button>
        </Grid.Column>
      );
    };

    return (
      <Segment className="Create">
        {this.state.isLoading && (
          <Dimmer active inverted>
            <Loader
              active
              size="large"
              content={<Header icon>Loading invoice detail...</Header>}
              style={{ position: "fixed" }}
            />
          </Dimmer>
        )}
        {this.state.isSaving && (
          <Loader
            active
            size="large"
            content={<Header icon>Saving as draft...</Header>}
            style={{ position: "fixed" }}
          />
        )}
        <Grid>
          <Grid.Row className="Create--invoice" verticalAlign="middle">
            <Grid.Column floated="left" width={8}>
              <p className="Create--title" style={{ float: "left" }}>
                {this.state.isPreview ? "Preview" : "Create Invoice"}
              </p>
            </Grid.Column>
            <Grid.Column width={8}>{invoiceControl()}</Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Column width={16}>
            <Divider className="Create--divider" />
          </Grid.Column>
        </Grid>
        {!isPreview ? (
          <>
            <Grid columns={2} widths="equal" style={{ padding: "0px 20px" }}>
              <Grid.Column>
                {/* <SelectImage /> */}
                {/* <Image src={invoiceLogo} size="tiny"/> */}
                <p className="Create--subtitle Create--left">My Templates</p>
                <Select
                  defaultValue={1}
                  options={options}
                  className="Create--left"
                  onChange={this.setTemplate}
                />
              </Grid.Column>
              <Grid.Column>
                <Form style={{ float: "right" }} widths="equal">
                  <Form.Field inline className="Create--field">
                    <label className="Create--label">Invoice Date</label>
                    <DateInput
                      name="invoiceDate"
                      minDate={moment(Date.now()).format("MM/DD/YYYY")}
                      iconPosition="left"
                      onChange={this.handleDateChange}
                      dateFormat="MM/DD/YYYY"
                      value={this.state.invoiceDate}
                    />
                  </Form.Field>
                  <Form.Field inline className="Create--field">
                    <label className="Create--label">Due date</label>
                    <DateInput
                      name="dueDate"
                      minDate={moment(Date.now() + 1000 * 3600 * 24).format(
                        "MM/DD/YYYY"
                      )}
                      iconPosition="left"
                      onChange={this.handleDateChange}
                      dateFormat="MM/DD/YYYY"
                      value={this.state.dueDate}
                    />
                  </Form.Field>
                  <p
                    style={{
                      color: "red",
                      marginLeft: "140px",
                      display:
                        this.state.error.indexOf("dueDate") > -1
                          ? "block"
                          : "none"
                    }}
                  >
                    <Icon name="info circle" /> Invalid due date.
                  </p>
                </Form>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={16}>
                <Divider className="Create--divider" />
              </Grid.Column>
            </Grid>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Grid columns={2} verticalAlign="middle">
                    <Grid.Row>
                      <Grid.Column width={2}>
                        <h3 style={{ float: "right" }}>Bill to:</h3>
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <Input
                          placeholder="Email address"
                          icon="mail"
                          fluid
                          error={this.state.error.indexOf("email") > -1}
                          defaultValue={this.state.info.receiverInfo.email}
                          onChange={e => {
                            let info = this.state.info;
                            let error = this.state.error.replace("email", "");
                            info.receiverInfo.email = e.target.value;
                            this.setState({ info: info, error: error });
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <Input
                          placeholder="Full Name"
                          icon="user"
                          fluid
                          error={this.state.error.indexOf("name") > -1}
                          defaultValue={this.state.info.receiverInfo.name}
                          onChange={e => {
                            let info = this.state.info;
                            let error = this.state.error.replace("name", "");
                            info.receiverInfo.name = e.target.value;
                            this.setState({ info: info, error: error });
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <Grid>
                          <Grid.Column width={2} />
                          <Grid.Column width={6}>
                            <p
                              style={{
                                color: "red",
                                display:
                                  this.state.error.indexOf("email") > -1
                                    ? "block"
                                    : "none"
                              }}
                            >
                              <Icon name="info circle" /> Please input email
                              address.
                            </p>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <p
                              style={{
                                color: "red",
                                display:
                                  this.state.error.indexOf("name") > -1
                                    ? "block"
                                    : "none"
                              }}
                            >
                              <Icon name="info circle" /> Please input name.
                            </p>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={2}>
                        <h3 style={{ float: "right" }}>Address:</h3>
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <Input
                          placeholder="Optional"
                          icon="address card"
                          fluid
                          defaultValue={this.state.info.receiverInfo.address}
                          onChange={e => {
                            let info = this.state.info;
                            info.receiverInfo.address = e.target.value;
                            this.setState({ info: info });
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Column width={16}>
                <Divider className="Create--divider" />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={16}>
                <Grid width={16}>
                  <Grid.Column width={16}>
                    {this.state.lineItems.map((item, index) => {
                      return (
                        <Grid
                          key={index}
                          columns={2}
                          // verticalAlign="middle"
                          style={{ margin: "-1rem -1rem" }}
                        >
                          {index == 0 && (
                            <ItemHeader
                              width={15}
                              invoiceTemplate={this.state.invoiceTemplate}
                            />
                          )}
                          <Grid.Column width={15}>
                            <LineItem
                              key={index}
                              width={16}
                              item={item}
                              setItem={this.setLineItem}
                              selected={this.state.selected == index}
                              addRow={this.addLineItem}
                              selectRow={() => this.selectLineItem(index)}
                              invoiceTemplate={this.state.invoiceTemplate}
                              error={this.state.error.indexOf("lineItems") > -1}
                              taxRateData={this.state.taxRateData}
                              onTax={this.onTax}
                            />
                          </Grid.Column>
                          <Grid.Column width={1}>
                            <Icon
                              name="remove circle"
                              className="Create--icon"
                              onClick={() => this.removeLineItem(index)}
                            />
                          </Grid.Column>
                        </Grid>
                      );
                    })}
                  </Grid.Column>
                </Grid>
                <Grid columns={2} width={16}>
                  <Grid.Column width={15}>
                    <Message color="blue" width={16} floated="left">
                      <Message.Header
                        // style={{ fontSize: "17px" }}
                        className="Create--add-item"
                        onClick={this.addLineItem}
                      >
                        <Icon link name="plus circle" />
                        Add another line item
                      </Message.Header>
                    </Message>
                  </Grid.Column>
                  <Grid.Column width={1} floated="right" />
                </Grid>
                <Grid columns={2} width={16}>
                  <Grid.Column width={15}>
                    <Grid columns={2} floated="left">
                      <Grid.Column
                        width={8}
                        floated="left"
                        style={{
                          borderTop: "2px solid lightgray",
                          top: "14px",
                          left: "15px"
                        }}
                      />
                      <Grid.Column width={8} floated="right">
                        <TotalTable
                          lineItems={this.state.lineItems}
                          taxRateData={this.state.taxRateData}
                          coupon={this.state.coupon}
                          setCoupon={this.setCoupon}
                          subtotal={this.getSubtotal()}
                          tax={this.getTax()}
                          discount={this.getDiscount()}
                          total={this.getTotal()}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={1} floated="right" />
                </Grid>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={16}>
                <Form style={{ fontSize: "17px" }}>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={TextArea}
                      label="Note to recipient"
                      placeholder="Such as 'Thank you for your business'"
                      value={this.state.memo}
                      onChange={e => this.setState({ memo: e.target.value })}
                    />
                    <Form.Field
                      control={TextArea}
                      label="Terms and conditions"
                      placeholder="Include your return or cancelation policy"
                      value={this.state.footer}
                      onChange={e => this.setState({ footer: e.target.value })}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column width={16}>
                <Divider className="Create--divider" />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Row className="Create--invoice" verticalAlign="middle">
                <Grid.Column
                  floated="right"
                  width={16}
                  style={{ marginBottom: "20px" }}
                >
                  <Grid.Column width={8}>{invoiceControl()}</Grid.Column>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        ) : (
          <Grid>
            <Grid.Column>
              <InvoicePreview
                invoiceData={{
                  address: this.state.info.receiverInfo.address,
                  receiver_name: this.state.info.receiverInfo.name,
                  receiver_email: this.state.info.receiverInfo.email,
                  memo: this.state.memo,
                  footer: this.state.footer,
                  line_items: this.state.lineItems,
                  invoice_date: this.state.invoiceDate,
                  due_date: this.state.dueDate,
                  subtotal: this.getSubtotal(),
                  coupon: this.state.coupon,
                  discount: this.getDiscount(),
                  tax: this.getTax(),
                  total: this.getTotal(),
                  tax_rate_data: this.state.taxRateData,
                  invoice_template: this.state.invoiceTemplate
                }}
              />
            </Grid.Column>
          </Grid>
        )}

        <Modal size="tiny" open={open} onClose={() => this.setOpen(open)}>
          <Modal.Header>Send Invoice</Modal.Header>
          <Modal.Content>
            <Header>{`Send invoice for $${currencyFormat(this.getTotal())} to ${
              this.state.info.receiverInfo.email
            }?`}</Header>
            <p style={{ fontSize: "16px" }}>
              Invoices can’t be edited after they’re sent.
            </p>
            {isSending && (
              <>
                <div className="opacity-pan" />
                <Loader active size="large" />
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.sendInvoiceData} disabled={isSending}>
              Send invoice
            </Button>
            <Button onClick={() => this.setOpen(false)} disabled={isSending}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal size="mini" open={show}>
          <Modal.Header>Add new tax</Modal.Header>
          <Modal.Content>
            {this.state.taxError != "" && (
              <Message
                warning
                header={this.state.taxError}
                // content='Visit our registration page, then try again.'
                icon={<Icon name="warning" style={{ fontSize: "20px" }} />}
              />
            )}

            <Form>
              <Form.Group>
                <Form.Input
                  width={10}
                  label="Tax name"
                  placeholder="Enter a tax name"
                  onChange={e => this.handleTax("display_name", e.target.value)}
                />
                <Form.Input
                  width={6}
                  label="Rate"
                  placeholder="Rate %"
                  type="number"
                  onChange={e => this.handleTax("percentage", e.target.value)}
                />
              </Form.Group>
            </Form>
            {isSending && (
              <>
                <div className="opacity-pan" />
                <Loader
                  active
                  size="large"
                  content={<Header icon>Add a new tax</Header>}
                />
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.addTaxItem}>
              Add
            </Button>
            <Button onClick={() => this.setState({ show: false })}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  editableStatus: state.invoice.editableStatus
});

export default connect(
  mapStateToProps,
  null
)(CreateInvoiceV2);
