import React from 'react';
import {
  Table, Checkbox, Pagination, Container,
  Segment, Message, Header, Loader, Icon, Image,
} from 'semantic-ui-react';
import cs from 'classnames';
import star from 'images/star.svg';
import './styles.scss';
import { slashFormatDate } from 'libraries/utils';

export const CustomTransactionsTable = props => {
  const { CATEGORY, SUBCATEGORY, TAG, MEMO, ACCOUNT, INCOME, EXPENSE } = props.customHeaders;
  return (
    <Segment className="Transactions__Container">
      {!props.hasError && (
        <Container textAlign="center" className="Transactions__Pagination-container header">
          <Pagination
            activePage={props.currentPage}
            totalPages={props.totalPages}
            firstItem={false}
            lastItem={false}
            onPageChange={props.handlePaginationChange}
          />
        </Container>
      )}
      <Table basic stackable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="Transactions__Checkbox-Cell">
              <div>
                <Checkbox
                  checked={props.pagesSelected.includes(props.currentPage)}
                  onChange={props.handleSelectAll}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => props.orderBy('date')}
              className="Transactions__Header--HeaderCell"
              textAlign="center"
              width={1}
            >
              Date
              <Icon name={cs('sort', props.order.COLUMN === 'date' && props.order.DIRECTION)} />
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              className="Transactions__Header--HeaderCell"
              onClick={() => props.orderBy('description')}
              width={3}
            >
              Transaction
              <Icon name={cs('sort', props.order.COLUMN === 'description' && props.order.DIRECTION)} />
            </Table.HeaderCell>
            {
              CATEGORY && (
                <Table.HeaderCell
                  textAlign="center"
                  className="Transactions__Header--HeaderCell"
                  onClick={() => props.orderBy('category')}
                  width={3}
                >
                  Category
                  <Icon name={cs('sort', props.order.COLUMN === 'category' && props.order.DIRECTION)} />
                </Table.HeaderCell>
              )
            }
            {
              SUBCATEGORY && (
                <Table.HeaderCell
                  width={2}
                  textAlign="center"
                  className="Transactions__Header--HeaderCell"
                  onClick={() => props.orderBy('subcategory')}
                >
                  Subcategory
                  <Icon name={cs('sort', props.order.COLUMN === 'subcategory' && props.order.DIRECTION)} />
                </Table.HeaderCell>
              )
            }
            {
              TAG && (
                <Table.HeaderCell textAlign="center" width={3} content="Tags" />
              )
            }
            {
              MEMO && (
                <Table.HeaderCell
                  width={2}
                  textAlign="center"
                  className="Transactions__Header--HeaderCell"
                  onClick={() => props.orderBy('memo')}
                >
                  Memo
                  <Icon name={cs('sort', props.order.COLUMN === 'memo' && props.order.DIRECTION)} />
                </Table.HeaderCell>
              )
            }
            {
              ACCOUNT && (
                <Table.HeaderCell textAlign="center" width={3} content="Account" />
              )
            }
            {
              INCOME && (
                <Table.HeaderCell
                  className="Transactions__Header--HeaderCell"
                  onClick={() => props.orderBy('income')}
                  textAlign="center"
                  width={2}
                >
                  Income
                  <Icon name={cs('sort', props.order.COLUMN === 'income' && props.order.DIRECTION)} />
                </Table.HeaderCell>
              )
            }
            {
              EXPENSE && (
                <Table.HeaderCell
                  className="Transactions__Header--HeaderCell"
                  onClick={() => props.orderBy('expense')}
                  textAlign="center"
                  width={2}
                >
                  Expense
                  <Icon name={cs('sort', props.order.COLUMN === 'expense' && props.order.DIRECTION)} />
                </Table.HeaderCell>
              )
            }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.transactions.map(transaction => (
            <Table.Row
              key={transaction.transaction_id}
              seen={transaction.seen}
              onClick={() => props.handleRowClick(transaction)}
              className={cs(
                'Transactions__Row',
                { '--NotSeen': transaction.seen !== 'checked' },
                { '--Income': transaction.amount < 0 },
              )}
            >
              <Table.Cell
                className={cs('Transactions__Checkbox-Cell', {
                  '--NewTransaction': transaction.seen !== 'checked',
                })}
                verticalAlign="middle"
                onClick={event => props.handleCheckboxSelect(event, {
                  value: transaction.transaction_id,
                  checked: !props.selected.includes(transaction.transaction_id),
                })
                }
              >
                <div>
                  {transaction.seen !== 'checked' ? (
                    <div>
                      <Image src={star} size="mini" />
                    </div>
                  ) : (
                    ''
                  )}
                  <Checkbox
                    value={transaction.transaction_id}
                    checked={props.selected.includes(transaction.transaction_id)}
                  />
                </div>
              </Table.Cell>
              <Table.Cell content={slashFormatDate(transaction.date)} />
              <Table.Cell content={transaction.description} />
              {
                CATEGORY && (
                  <Table.Cell
                    content={transaction.category}
                    className={cs({
                      Transaction__Category: false,
                    })}
                  />
                )
              }
              {
                SUBCATEGORY && (
                  <Table.Cell content={transaction.subcategory} />
                )
              }
              {
                TAG && (
                  <Table.Cell>
                    <div className="Transactions__Tag__Container">
                      {
                        transaction.tags && transaction.tags.split('#').map((tag, index) => {
                          return (
                            <div key={index} className="Transactions__Tag__Item">
                              {tag}
                            </div>
                          )
                        })
                      }
                    </div>
                  </Table.Cell>
                )
              }
              {
                MEMO && (
                  <Table.Cell content={transaction.memo} />
                )
              }
              {
                ACCOUNT && (
                  <Table.Cell content={`${transaction.institution} ${transaction.mask}`} />
                )
              }
              {
                (transaction.amount < 0 && INCOME) ? (
                  <>
                    <Table.Cell content={`$${transaction.amount * -1}`} />
                    {
                      EXPENSE && (
                        <Table.Cell>{transaction.iso_currency_code !== 'USD' && `CONVERTED FROM "${transaction.iso_currency_code}"`}</Table.Cell>
                      )
                    }
                  </>
                ) : null
              }
              {
                (transaction.amount > 0 && EXPENSE) ? (
                  <>
                    {
                      INCOME && (
                        <Table.Cell>{transaction.iso_currency_code !== 'USD' && `CONVERTED FROM "${transaction.iso_currency_code}"`}</Table.Cell>
                      )
                    }
                    <Table.Cell content={`$${transaction.amount}`} />
                  </>
                ) : null
              }
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {props.isLoading && (
        <Segment textAlign="center">
          <Loader
            active
            size="medium"
            inline
            content={<Header icon>Loading transactions...</Header>}
          />
        </Segment>
      )}
      <Message
        hidden={!props.hasError}
        error
        icon="database"
        header="Failed to get your transactions"
        content="Please try again"
      />
      {!props.hasError && (
        <Container textAlign="center" className="Transactions__Pagination-container">
          <Pagination
            activePage={props.currentPage}
            totalPages={props.totalPages}
            firstItem={false}
            lastItem={false}
            onPageChange={props.handlePaginationChange}
          />
        </Container>
      )}
    </Segment>
  );
}
