import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  addSelectedPage,
  addSelectedTransaction,
  markAsSeenTransactions,
  removeSelectedPage,
  removeSelectedTransaction,
  resetSelectedPages,
  resetSelectedTransactions,
  setTransactions,
} from 'store/actions/transactions';
import { isManualTransaction } from 'libraries/utils';
import { filterByCustomDateRange, filterByPage, MAX_ITEMS_PER_PAGE, } from 'libraries/transactionsFilterManager';
import getFilteredTransactions from 'store/selectors/transactions';
import { addFilter } from 'store/actions/filters';
import Modal from 'components/presentationals/Modal';
import SyncMessage from 'components/presentationals/SyncMessage';
import TransactionModalContent from 'components/containers/TransactionModalContent';
import BulkEditingBar from 'components/containers/BulkEdit';
import NewTransactionsNotificator from 'components/containers/NewTransactionsNotificator';
import withNotifications from 'components/hocs/WithNotifications';
import moment from "moment";
import { getTransactions, markAsSeen } from "libraries/api-v2/transactions-service";
import { CustomTransactionsFilters } from "components/containers/TransactionsCustomFilters";
import { CustomTransactionsTable } from "components/presentationals/TransactionsCustomTable";

export class CustomTransactions extends React.Component {
  state = {
    currentPage: 1,
    isLoading: true,
    hasError: false,
    modalShow: false,
    transaction: undefined,
    editionMode: true,
    contentType: undefined,
    offset: 0,
    editableTransactions: [],
    MAX_COUNT_PER_FETCH: 10000,
    CUSTOM_HEADER: {
      CATEGORY: true,
      SUBCATEGORY: true,
      TAG: true,
      MEMO: true,
      ACCOUNT: true,
      INCOME: true,
      EXPENSE: true
    }
  }

  componentDidMount() {
    const { username, token } = this.props.session;
    if (!this.props.transactions.allTransactions.length) {
      this.updateTransactions(username, token);
    } else {
      this.setState({ isLoading: false });
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.filteredTransactions.length !== this.props.filteredTransactions.length) {
  //     this.setState({ currentPage: 1 });
  //     this.props.resetSelectedTransactions();
  //     this.props.resetSelectedPages();
  //   }
  // }

  getTypeOfContent = (isEditable) => {
    const { editionMode } = this.state;
    if (!editionMode)
      return 'creation';
    return isEditable === false ? 'editcategory' : 'edition';
  }

  handleCheckboxSelect = (event, { value, checked }) => {
    event.stopPropagation();
    if (checked) {
      this.props.addSelectedTransaction(value);
    } else {
      this.props.removeSelectedTransaction(value);
    }
  }

  handleSelectAll = (event, { checked }) => {
    const currentPageTransactions = filterByPage(this.state.currentPage, this.props.filteredTransactions, MAX_ITEMS_PER_PAGE);
    const transactionsIds = currentPageTransactions.map(transaction => transaction.transaction_id);
    if (checked) {
      this.props.addSelectedPage(this.state.currentPage, transactionsIds);
    } else {
      this.props.removeSelectedPage(this.state.currentPage, transactionsIds);
    }
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  }

  handleShowModal = () => {
    this.setState({
      modalShow: true,
    });
  }

  handleCreateTransaction = () => {
    this.setState({
      contentType: 'creation',
      editionMode: false,
      transaction: undefined,
    }, () => {
      this.handleShowModal();
    });
  }

  handleCustomHeader = (name) => {
    this.setState(prev => ({
      CUSTOM_HEADER: {
        ...prev.CUSTOM_HEADER,
        [name]: !prev.CUSTOM_HEADER[name]
      }
    }))
  }

  handleRowClick = (key) => {
    const type = this.getTypeOfContent(key.iseditable);
    if (type === 'edition' || type === 'editcategory') {
      this.setState({
        transaction: key,
      });
    }
    this.setState({
      contentType: type,
    }, () => {
      this.handleShowModal();
    });
  };

  handleMark = (transaction) => {
    const { contentType } = this.state;
    let obj;
    let statefulTransactions = [];
    const { selected } = this.props.transactions;
    if (selected.length) {
      statefulTransactions = selected.map(transactionID => ({
        transaction_id: transactionID,
        status: false,
      }));
    }

    switch (contentType) {
      case 'bulk':
        this.callMarkAsSeen(statefulTransactions, selected, this.updateTransactions);
        break;
      default:
        obj = {
          transaction_id: transaction,
          status: false,
        };
        this.callMarkAsSeen([obj], [transaction], this.updateTransactions);
        break;
    }
  };

  handleMarkAllAsSeen = () => {

    const newTransactions = this.props.filteredTransactions
      .filter(item => ['new', 'initial'].includes(item.seen))
      .map(({ transaction_id: id }) => id);
    markAsSeen(newTransactions)
      .then(() => this.props.markAsSeenTransactions(newTransactions))
      .then(() => this.props.resetSelectedTransactions())
      .then(() => this.props.resetSelectedPages());
  }

  handleMarkAsSeen = () => {
    this.setState({
      contentType: 'markaseen',
      isLoading: true,
    }, () => {
      let obj;
      let statefulTransactions = [];
      const { selected } = this.props.transactions;
      if (selected.length) {
        statefulTransactions = selected.map((transactionID) => {
          obj = {
            transaction_id: transactionID,
            status: false,
          };
          return obj;
        });
      }
      this.callMarkAsSeen(statefulTransactions, selected, this.props.markAsSeenTransactions);
    });
  };

  callMarkAsSeen = (statefulTransactions, selected, executeAfter) => {
    const { username, token } = this.props.session;
    const { contentType } = this.state;
    const transactions = statefulTransactions.map(({ transaction_id: id }) => id);
    markAsSeen(transactions)
      .then(() => {
        switch (contentType) {
          case 'markaseen':
            executeAfter(selected);
            this.props.resetSelectedTransactions();
            this.props.resetSelectedPages();
            break;
          default:
            executeAfter(username, token);
            this.props.resetSelectedTransactions();
            this.props.resetSelectedPages();
            break;
        }
        this.setState({ isLoading: false });
      }).catch(() => {
        this.props.pushNotification('NOT_MARKED_AS_SEEN', 'Operation failed',
          'An error ocurred while trying to mark as seen your transaction.Please try again.', 'error', 8000);
        this.setState({ isLoading: false });
      });
  };

  handleClose = () => {
    this.setState({ modalShow: false, isLoading: false, editionMode: true });
  };

  showBulkEdition = () => {
    this.setState({
      modalShow: true,
      editionMode: true,
      contentType: 'bulk',
      transaction: undefined,
    });
  }

  getTotalPages = transactions => Math.ceil(transactions.length / MAX_ITEMS_PER_PAGE)

  getTotalSelectedTransactions = () => this.props.transactions.selected.length;

  orderBy = (column) => {
    const { ORDER } = this.props.filters;
    let direction = 'descending';
    if (ORDER.COLUMN === column) {
      direction = ORDER.DIRECTION === 'descending' ? 'ascending' : 'descending';
    }
    this.props.addFilter('ORDER', { DIRECTION: direction, COLUMN: column });
  }

  /**
   This function populates the dashboard and polls new transactions,
   to distinguish between the manual created transactions from the ones returned by plaid the
   iseditable flag is added to every transaction.
   * */
  updateTransactionsAfterReset = (username, token) => {
    this.setState({ isLoading: true },
      () => getTransactions(username, this.state.offset)
        .then(async (response) => {
          let editableTransactions = response.transactions.map((item) => {
            let transaction = item;
            const isEditable = isManualTransaction(transaction.transaction_id);
            transaction = { ...transaction, iseditable: isEditable, mask: item.mask === 'None' ? '' : item.mask };
            return transaction;
          });
          this.setState(prevState => ({
            editableTransactions: [...prevState.editableTransactions, ...editableTransactions]
          }));
          if (response.total > this.state.MAX_COUNT_PER_FETCH && response.total !== this.state.editableTransactions.length) {
            this.setState( prevState => ({
              offset: prevState.offset + 1
            }), () => this.updateTransactionsAfterReset(this.props.session.username, this.props.session.token));
          } else {
            this.props.setTransactions(this.state.editableTransactions);
            this.setState({ isLoading: false });
          }
        })
        .catch(() => {
          this.setState({
            isLoading: false,
            hasError: true,
          });
        }));
  }

  updateTransactions = (username, token) => {
    this.setState({
      offset: 0,
      editableTransactions: []
    });
    this.updateTransactionsAfterReset(username, token);
  }

  reloadTransactionsTable = (username, token) => {
    this.props.setTransactions([]);
    this.updateTransactions(username, token);
  }

  render() {
    const newTransactions = this.props.filteredTransactions.filter(item => ['new', 'initial'].includes(item.seen)).length;
    let priorYearTransactions = 0;
    if (this.props.filters.MONTH === -2) { // year to date
      const yearStart = moment().subtract(4, 'year').startOf('year').format('MM/DD/YYYY'); // 4: ideally, getting transactions during 2 years from plaid.
      const yearEnd = moment().subtract(1, 'year').endOf('year').format('MM/DD/YYYY');
      priorYearTransactions = filterByCustomDateRange(yearStart, yearEnd, this.props.transactions.allTransactions).filter(item => ['new', 'initial'].includes(item.seen)).length;
    }
    const withSelectedTransactions = this.props.transactions.selected.length > 0;
    return (
      <>
        {
          this.state.modalShow && (
            <Modal
              show={this.state.modalShow}
              handleClose={this.handleClose}
            >
              <TransactionModalContent
                {...this.state.transaction}
                handleClose={this.handleClose}
                contentType={this.state.contentType}
                updateTransactions={this.updateTransactions}
                editionMode={this.state.editionMode}
                totalTransactionSelected={this.getTotalSelectedTransactions()}
                transactions={this.props.transactions}
                resetSelectedTransactions={this.props.resetSelectedTransactions}
                resetSelectedPages={this.props.resetSelectedPages}
                handleMark={this.handleMark}
              />
            </Modal>
          )
        }
        <CustomTransactionsFilters
          handleCreateTransaction={this.handleCreateTransaction}
          handleCustomHeader={this.handleCustomHeader}
          customHeaders={this.state.CUSTOM_HEADER}
        />
        {
          this.props.transactions.selected.length > 0 && (
            <BulkEditingBar
              isLoading={this.state.isLoading}
              itemsSelected={this.getTotalSelectedTransactions()}
              showBulkEdition={this.showBulkEdition}
              handleMarkAsSeen={this.handleMarkAsSeen}
            />
          )
        }
        {!withSelectedTransactions && newTransactions > 0 && (
          <NewTransactionsNotificator num={newTransactions} priorNum={priorYearTransactions} isYTD={this.props.filters.MONTH === -2} onMarkAll={this.handleMarkAllAsSeen} />
        )}
        <SyncMessage
          section="dashboard"
          session={this.props.session}
          updateTransactions={this.reloadTransactionsTable}
        />
        <CustomTransactionsTable
          hasError={this.state.hasError}
          handleRowClick={this.handleRowClick}
          isLoading={this.state.isLoading}
          currentPage={this.state.currentPage}
          transactions={filterByPage(this.state.currentPage,
            this.props.filteredTransactions, MAX_ITEMS_PER_PAGE)}
          totalPages={this.getTotalPages(this.props.filteredTransactions)}
          handlePaginationChange={this.handlePaginationChange}
          handleCheckboxSelect={this.handleCheckboxSelect}
          pagesSelected={this.props.transactions.pagesSelected}
          selected={this.props.transactions.selected}
          handleSelectAll={this.handleSelectAll}
          orderBy={this.orderBy}
          order={this.props.filters.ORDER}
          customHeaders={this.state.CUSTOM_HEADER}
        />
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  session: state.session,
  onboarding: state.onboarding,
  transactions: state.transactions,
  filters: state.filters,
  filteredTransactions: getFilteredTransactions(state),
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  addSelectedTransaction,
  removeSelectedTransaction,
  resetSelectedTransactions,
  addSelectedPage,
  removeSelectedPage,
  resetSelectedPages,
  setTransactions,
  addFilter,
  markAsSeenTransactions,
}, dispatch);

export const TransactionsCustom = connect(mapStateToProps, mapDispatchToProps)(withNotifications(CustomTransactions));
