import React, {Component} from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import {bindActionCreators} from 'redux';
import {setPlaidEnvironment, setPlaidPublicKey} from 'store/actions/plaid';
import withNotifications from 'components/hocs/WithNotifications';
import LinkPlaid from 'components/presentationals/LinkPlaid';
import Card from 'components/ui/Card';
import {SyncButton} from 'components/presentationals/SyncButton';
import SyncMessage from 'components/presentationals/SyncMessage';
import ErrorPlaidMessages from 'components/presentationals/ErrorPlaidMessages';
import uuidv4 from 'uuid/v4';
import {updateTransactions} from 'store/actions/transactions';
import {Button, Checkbox, Confirm, Dimmer, Icon, Image, List, Popup, Segment,} from 'semantic-ui-react';
import {
  clearItemErrors,
  getInstitutionById,
  getKeys,
  getPublicToken,
  removeInstitutions,
  storePlaidItem,
  updateAccountStatus,
} from 'libraries/api-service';
import {getInstitutionsV2} from "libraries/api-v2/institution-service";
import hasSubscription from 'store/selectors/subscription';

import './styles.scss';
import jsCookie from "js-cookie";

class LinkedAccountsv2 extends Component {
  state = {
    linkedAccounts: [],
    isConfirmOpen: false,
    processing: false,
    isSyncing: false,
    updateMode: {
      publicToken: undefined,
      itemId: undefined,
    },
    editItemAccounts: {
      itemId: undefined,
      accounts: {},
    },
  };

  constructor(props) {
    super(props);
    this.ErrorPlaidMessages = React.createRef();
    this.syncMessage = React.createRef();
  }

  componentDidMount() {
    getKeys().then((res) => {
      const plaidPublicKey = res.data.plaid_public_key;
      const plaidEnv = res.data.plaid_env;
      this.props.setPlaidEnvironment(plaidEnv);
      this.props.setPlaidPublicKey(plaidPublicKey);
    });
    this.fetchInstitutions();
  }

  fetchInstitutions = () => {
    const clientEmail = jsCookie.get('active_client_email');
    this.setState({ processing: true }, () => {
      getInstitutionsV2(clientEmail)
        .then(this.addLogos)
        .then(linkedAccounts => this.setState({ linkedAccounts, processing: false }));
    });
  }

  addLogos = (institutions) => {
    const { username, token } = this.props;
    const logos = {};

    const promises = institutions
      .map((inst) => {
        if (Object.prototype.hasOwnProperty.call(logos, inst.institutionId)) return null;
        logos[inst.institutionId] = '';
        return getInstitutionById(username, token, inst.institutionId).then((res) => {
          logos[inst.institutionId] = res;
        });
      })
      .filter(Boolean);

    return Promise.all(promises)
      .then(() => institutions.map(inst => ({ ...inst, ...logos[inst.institutionId] })));
  };

  openPlaidModal = () => window.linkHandler && window.linkHandler.open();

  onPlaidSuccess = (
    publicToken,
    { accounts, institution: { institution_id: institutionId, name: institutionName } },
  ) => {
    const { username, token } = this.props;
    const selectedAccounts = accounts.map(acc => acc.id);
    const getLogoPromise = getInstitutionById(username, token, institutionId);
    const storePlaidItemPromise = storePlaidItem(publicToken, selectedAccounts);
    this.syncMessage.current.verifySyncedAccounts();
    this.setState({ processing: true }, () => {
      Promise.all([getLogoPromise, storePlaidItemPromise]).then(
        ([getLogoResponse, storePlaidItemResponse]) => {
          const { logo, primaryColor } = getLogoResponse;
          const {
            data: { stored_item: itemId },
          } = storePlaidItemResponse;
          const newInstitution = {
            id: uuidv4(),
            institutionId,
            institutionName,
            logo,
            primaryColor,
            items: [
              {
                itemId,
                hasErrors: false,
                accounts: accounts.map(({ id, name, mask }) => ({
                  accountId: id,
                  accountName: name,
                  accountMask: mask,
                  included: true,
                })),
              },
            ],
          };
          this.setState((pState) => {
            const idx = pState.linkedAccounts.findIndex(ac => ac.institutionId === institutionId);
            if (idx === -1) {
              return {
                processing: false,
                linkedAccounts: [...pState.linkedAccounts, newInstitution].sort((a, b) => {
                  const prev = a.institutionName.toLowerCase();
                  const next = b.institutionName.toLowerCase();
                  if (prev < next) return -1;
                  if (prev > next) return 1;
                  return 0;
                }),
              };
            }
            const linkedAccountsCopy = [...pState.linkedAccounts];
            linkedAccountsCopy[idx].items = [
              ...linkedAccountsCopy[idx].items,
              ...newInstitution.items,
            ];
            return { processing: false, linkedAccounts: linkedAccountsCopy };
          });
        },
      ).catch(() => {
        this.setState({ processing: false });
        this.props.pushNotification(
          'ERROR_STORING_ITEM',
          'Error storing item',
          'An error ocurred while trying to link the account. Please try again.',
          'error',
          5000,
        );
      });
    });
  };

  onUpdateSuccess = () => {
    const { username, token } = this.props;
    const { itemId } = this.state.updateMode;
    clearItemErrors(username, token, itemId)
      .then(() => {
        const { linkedAccounts } = this.state;
        const updatedAccounts = linkedAccounts.map(account => ({
          ...account,
          items: account.items.map(item => ({
            ...item,
            hasErrors: (item.itemId === itemId) ? false : item.hasErrors,
          })),
        }));
        this.setState({
          linkedAccounts: updatedAccounts,
          updateMode: { itemId: undefined, publicToken: undefined },
        });
        this.props.pushNotification(
          'SUCCESS_CLEAR_ITEM_ERRORS',
          'Your account has been updated',
          'You\'ve successfully updated your account.',
          'success',
          7000,
        );
      })
      .catch(() => {
        this.setState({
          updateMode: { itemId: undefined, publicToken: undefined },
        });
        this.props.pushNotification(
          'ERROR_CLEAR_ITEM_ERRORS',
          'Error clearing your account errors.',
          'An error ocurred while clearing the errors for your account in our system. We\'ll try again if you click on the UPDATE DATA warning.',
          'error',
          7000,
        );
      });
  }

  onOpenConfirmationDialog = itemId => this.setState({ isConfirmOpen: true, itemId });

  onCancelConfirmationDialog = () => this.setState({ isConfirmOpen: false, itemId: undefined });

  onConfirmConfirmationDialog = () => {
    const { itemId } = this.state;
    const { username, token } = this.props;
    this.setState({ processing: true, isConfirmOpen: false }, () => {
      removeInstitutions(itemId, username, token)
        .then(() => {
          this.setState(prevState => ({
            linkedAccounts: prevState.linkedAccounts
              .map(account => ({
                ...account,
                items: account.items.filter(item => item.itemId !== itemId),
              }))
              .filter(account => account.items.length > 0),
            itemId: undefined,
            processing: false,
          }));
        })
        .catch(() => {
          this.setState({ itemId: undefined, processing: false });
          this.props.pushNotification(
            'ERROR_UNLINKING_ACCOUNT',
            'Error unlinking account',
            'An error ocurred while trying to unlink the account. Please try again.',
            'error',
            5000,
          );
        });
    });
  };

  updatePlaidAccount = (itemId) => {
    const { username, token } = this.props;
    this.setState({ processing: true }, () => getPublicToken(username, token, itemId)
      .then(({ data }) => {
        const hasResponse = !!data.response;
        const publicToken = hasResponse && data.response.public_token
          ? data.response.public_token : undefined;
        this.setState({
          processing: false,
          updateMode: { itemId, publicToken },
        }, publicToken ? this.openPlaidModal : this.onUpdateSuccess);
      })
      .catch(() => {
        this.props.pushNotification(
          'ERROR_GET_PUBLIC_TOKEN',
          'Update failed',
          'Unable to initialize plaid',
          'error',
          7000,
        );
      }));
  }

  handleOnExit = (error, metadata) => {
    this.ErrorPlaidMessages.current.handleOnExit(error, metadata);
    this.setState({ updateMode: { itemId: undefined, publicToken: undefined } });
  }

  setItemEditMode = (itemId, accounts) => {
    const markedAccounts = {};
    accounts && accounts.forEach((account) => {
      markedAccounts[account.accountId] = account.included;
    });
    this.setState({
      editItemAccounts: {
        itemId,
        accounts: markedAccounts,
      },
    });
  }

  handleOnSync = (syncing) => {
    this.setState({ isSyncing: syncing });
  }

  handleOnItemEditChange = (accountId) => {
    const { accounts, itemId } = this.state.editItemAccounts;
    const hasAccount = accounts[accountId];
    const updatedAccounts = {
      ...accounts,
      [accountId]: !hasAccount,
    };
    this.setState({
      editItemAccounts: {
        itemId,
        accounts: updatedAccounts,
      },
    });
  }

  handleOnAccountsSave = () => {
    const toTrue = [];
    const toFalse = [];
    const { accounts } = this.state.editItemAccounts;
    for (const accId in accounts) {
      if (accounts[accId]) toTrue.push(accId);
      else toFalse.push(accId);
    }
    updateAccountStatus(toTrue, toFalse)
      .then((response) => {
        this.setState({ editItemAccounts: { itemId: undefined, accounts: [] } });
        this.fetchInstitutions();
        this.props.pushNotification(
          'SUCCESS_UPDATING_ITEM_ACCOUNTS',
          'Account updated',
          response.data.message,
          'success',
          5000,
        );
      })
      .catch(({ response }) => {
        this.setState({ editItemAccounts: { itemId: undefined, accounts: [] } });
        this.props.pushNotification(
          'ERROR_UPDATING_ITEM_ACCOUNTS',
          'Something went wrong',
          response.data.message,
          'error',
          7000,
        );
      });
  }

  render() {
    const {
      linkedAccounts, isConfirmOpen, processing, updateMode: { publicToken },
      editItemAccounts,
    } = this.state;
    const editItemId = editItemAccounts.itemId;
    const { plaidPublicKey, plaidEnv, isSubscribed } = this.props;
    const actions = [
      <Button primary size="small" key="LinkedAccounts-btnAdd" onClick={this.openPlaidModal} disabled={processing || this.state.isSyncing}>
        Add account
      </Button>,
      <SyncButton
        key="LinkedAccounts-btnSync"
        pushNotification={this.props.pushNotification}
        updateTransactions={updateTransactions}
        processing={processing}
        syncMessage={this.syncMessage.current}
      />,
    ];
    return (
      <>
        <Confirm
          open={isConfirmOpen}
          content="Removing a banking institution will remove all the data from your general ledger and your profit and loss. You will not be able to find transactions from the institution you remove. We do not recommend doing this, unless your absolutely sure you do not want this financial institution account associated with your SynkBooks account. Do you wish to proceed?"
          onCancel={this.onCancelConfirmationDialog}
          onConfirm={this.onConfirmConfirmationDialog}
        />
        {plaidEnv
          && plaidPublicKey && !publicToken && (
            <LinkPlaid
              env={plaidEnv}
              publicKey={plaidPublicKey}
              handleOnSuccess={this.onPlaidSuccess}
              handleOnExit={this.handleOnExit}
            />
        )}
        {plaidEnv
          && plaidPublicKey && publicToken && (
            <LinkPlaid
              env={plaidEnv}
              publicKey={plaidPublicKey}
              handleOnSuccess={this.onUpdateSuccess}
              token={publicToken}
              handleOnExit={this.handleOnExit}
            />
        )}
        <Card header="Linked Accounts" actions={actions}>
          {!isSubscribed && <Dimmer active inverted />}
          <SyncMessage
            section="profile"
            closeButton="true"
            ref={this.syncMessage}
            session={this.props.session}
          />
          <ErrorPlaidMessages
            section="profile"
            closeButton="true"
            ref={this.ErrorPlaidMessages}
          />
          <div className="margin linked-accounts">
            <Segment basic>
              <List celled className="LinkedAccounts">
                {linkedAccounts.map(({
                  id, institutionName, logo, items,
                }) => (
                  <List.Item key={id} className="LinkedAccounts__Item" disabled={processing || this.state.isSyncing}>
                    <Image avatar src={`data:image/jpeg;charset=utf-8;base64,${logo}`} />
                    <List.Content className="LinkedAccounts__Item__Content">
                      <List.Header className="LinkedAccounts__Item__InstitutionName">
                        {institutionName}
                      </List.Header>
                      <List.Description className="LinkedAccounts__Item__Details">
                        {items.map(({ itemId, hasErrors, accounts }) => (
                          <div
                            key={itemId}
                            className={cn(
                              editItemId === itemId
                                ? 'LinkedAccounts__Item__Details__Container-edit'
                                : 'LinkedAccounts__Item__Details__Container',
                            )}
                          >
                            {
                              editItemId !== itemId && (
                              <div
                                role="presentation"
                                className="LinkedAccounts__Item__Details__Container__Btns"
                              >
                                <Icon
                                  name="edit"
                                  size="small"
                                  className="action"
                                  onClick={() => this.setItemEditMode(itemId, accounts)}
                                />
                                <Icon
                                  name="delete"
                                  size="small"
                                  className="action"
                                  onClick={() => this.onOpenConfirmationDialog(itemId)}
                                />
                              </div>)
                            }
                            {accounts.map(({
                              accountId, accountName, accountMask, included, key,
                            }) => (
                              <div key={key}>
                                {
                                  editItemId === itemId
                                    ? (
                                      <Checkbox
                                        checked={editItemAccounts.accounts[accountId]}
                                        label={`${accountName} (**** **** **** ${accountMask})`}
                                        onChange={() => this.handleOnItemEditChange(accountId)}
                                      />)
                                    : (
                                      <>
                                        <Icon name={cn(!included && 'broken', 'chain')} />
                                        {`${accountName} (**** **** **** ${accountMask})`}
                                      </>
                                    )
                                }
                                {}
                              </div>
                            ))}
                            <div className="LinkedAccounts__Item__Details__Container__Actions">
                              { editItemId !== itemId && (
                                <div>
                                  <Icon name="check outline circle" color="green" />
                                  LINKED
                                </div>)
                              }
                              {hasErrors && (
                              <div
                                onClick={() => this.updatePlaidAccount(itemId)}
                                tabIndex={0}
                                role="button"
                                onKeyPress={() => this.updatePlaidAccount(itemId)}
                                className="LinkedAccounts__Item__Details__ActionUpdate"
                              >
                                <Popup
                                  content="The login details of these accounts have changed (credentials or MFA). Please, reconnect these accounts by
                                  clicking this link."
                                  trigger={(
                                    <div>
                                      <Icon name="warning circle" color="orange" />
                                      RE-AUTHENTICATION REQUIRED
                                    </div>
                                    )}
                                />
                              </div>
                              )}
                            </div>
                            { editItemId === itemId && (
                              <div className="LinkedAccounts__Item__Details__Edit__Actions">
                                <Button
                                  size="tiny"
                                  onClick={() => this.setItemEditMode()}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  primary
                                  size="tiny"
                                  onClick={this.handleOnAccountsSave}
                                >
                                  Save
                                </Button>
                              </div>)
                            }
                          </div>
                        ))}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>
          </div>
        </Card>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  username: state.session.username,
  plaidPublicKey: state.plaid.plaidPublicKey,
  plaidEnv: state.plaid.plaidEnv,
  isSubscribed: hasSubscription(state),
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setPlaidPublicKey,
    setPlaidEnvironment,
    updateTransactions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNotifications(LinkedAccountsv2));
