import React from 'react';
import { connect } from 'react-redux';
import Card from 'components/ui/Card';
import { Button, Modal } from 'semantic-ui-react';
import size from 'lodash.size';
import { bindActionCreators } from 'redux';

import { getSubscription, cancelStripePlan } from 'libraries/api-service';
import { getDaysLeft } from 'libraries/utils';
import {
  setCurrentSubscription, setCancelSubscription,
} from 'store/actions/subscription';

class Unsubscribe extends React.Component {
  state = {
    isLoading: false,
    isUnsubscribeModalOpen: false,
    isUnsubscribring: false,
  }

  componentDidMount() {
    const { currentSubscription } = this.props;
    if (!size(currentSubscription)) {
      this.setState({ isLoading: true }, () => getSubscription()
        .then((subscription) => {
          this.props.setCurrentSubscription(subscription);
          this.props.setCancelSubscription(subscription.cancel_at_period_end);
          this.setState({ isLoading: false });
        }));
    }
  }

  isTrialing = () => this.props.subscriptionStatus === 'trialing';

  openUnsubscribeModal = () => this.setState({ isUnsubscribeModalOpen: true });

  closeUnsubscribeModal = () => this.setState({ isUnsubscribeModalOpen: false });

  cancelSubscription = () => {
    this.setState({ isUnsubscribring: true }, () => cancelStripePlan()
      .then((res) => {
        this.props.setCancelSubscription(true);
        this.setState({ isUnsubscribring: false });
        this.closeUnsubscribeModal();
      }).catch((err) => {
        this.setState({ isUnsubscribring: false });
        this.closeUnsubscribeModal();
        this.props.pushNotification('NOT_CANCELLED_PLAN', 'Operation failed',
          'An error ocurred while trying to cancel your subscription. Please try again.', 'error', 7000);
      }));
  };

  getUnsubscribeContent = () => {
    const { currentSubscription: { endDate, nickname }, cancelAtPeriodEnd } = this.props;
    if (this.isTrialing()) {
      return (
        <div>
        You have
          <b>{` ${getDaysLeft(endDate)} `}</b>
        left until your free trial ends.
        </div>
      );
    }
    if (this.props.cancelAtPeriodEnd) {
      return (
        <>
          <div>
            You have
            <b>{` ${getDaysLeft(endDate)} `}</b>
            until your
            <b>{` ${nickname} `}</b>
            plan ends.
          </div>
        </>
      );
    }
    return (
      <>
        <span>
          Unsubscribe from&nbsp;
          <b>{ nickname }</b>
        </span>
        { !cancelAtPeriodEnd && (
          <Button primary onClick={this.openUnsubscribeModal}>Unsubcribe</Button>
        )
        }
      </>
    );
  }

  render() {
    const { currentSubscription } = this.props;
    const { isLoading, isUnsubscribring, isUnsubscribeModalOpen } = this.state;
    return (
      <>
        {
        isUnsubscribeModalOpen && (
          <Modal open size="mini" closeOnEscape={false} closeOnDimmerClick={false}>
            <Modal.Header
              content="CANCEL SUBSCRIPTION"
              style={{ backgroundColor: '#e06665', color: 'white' }}
            />
            <Modal.Content>
              You will no longer have access to your synced data.
              <br />
              Are you sure you want to
              <b> CANCEL </b>
              your subscription?
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                content="Continue"
                onClick={this.cancelSubscription}
                loading={isUnsubscribring}
              />
              <Button
                content="Cancel"
                onClick={this.closeUnsubscribeModal}
              />
            </Modal.Actions>
          </Modal>
        )
        }
        <Card header="Subscription" loading={isLoading}>
          { !isLoading && size(currentSubscription) && (
            <div className="margin" style={{ display: 'flex', justifyContent: 'space-between' }}>
              {
                this.getUnsubscribeContent()
              }
            </div>
          )
          }
        </Card>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({
  subscription: { current, cancelAtPeriodEnd },
  session: { userProfile: { subscriptionStatus } },
}) => ({
  currentSubscription: current,
  subscriptionStatus,
  cancelAtPeriodEnd,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  setCurrentSubscription,
  setCancelSubscription,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Unsubscribe);
