import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getTransactionsAsProfessional,
  markAsSeenAsProfessional,
} from 'libraries/api-v2/transactions-service';
import jsCookie from 'js-cookie';

// TODO: TransactionsTable assumes snake-cased properties of the transaction object
import TransactionsTable from 'components/presentationals/TransactionsTable';
// TODO: API payload must match exactly that of V1 for bottom actions to work
// May want to consider creating store/actions/v2/transactions.js
import {
  addSelectedTransaction,
  removeSelectedTransaction,
  addSelectedPage,
  removeSelectedPage,
  setTransactions,
  resetSelectedTransactions,
  resetSelectedPages,
  markAsSeenTransactions,
} from 'store/actions/clientTransactions';
import {
  formatDate,
  isManualTransaction,
} from 'libraries/utils';
// NOTE: Looks reausable for the most part
import TransactionsFilters from 'components/containers/TransactionsFiltersv2';
// TODO: Transaction object assumed to be snake_cased
import {
  MAX_ITEMS_PER_PAGE,
  filterByPage, filterByCustomDateRange,
} from 'libraries/transactionsFilterManager';
import getFilteredTransactions from 'store/selectors/clientTransactions';
// NOTE: Looks reusable
import { addFilter } from 'store/actions/filters';
// NOTE: Looks reusable
import Modal from 'components/presentationals/Modal';
import SyncMessage from 'components/presentationals/SyncMessagev2';

// TODO: Heavily dependent on transaction object and modifying it
import TransactionModalContent from 'components/containers/TransactionModalContentv2';
import BulkEditingBar from 'components/containers/BulkEditv2';
// NOTE: Seems reusable
import NewTransactionsNotificator from 'components/containers/NewTransactionsNotificator';
// NOTE: Looks reusable
import withNotifications from 'components/hocs/WithNotifications';
import moment from "moment";

export class Transactionsv2 extends React.Component {
  state = {
    currentPage: 1,
    isLoading: true,
    hasError: false,
    modalShow: false,
    transaction: undefined,
    editionMode: true,
    contentType: undefined,
    offset: 0,
    editableTransactions: [],
    maxCount: 10000
  }

  constructor(props) {
    super(props);

    this.updateTransactions = this.updateTransactions.bind(this);
  }

  componentDidMount() {
    const { username, token } = this.props.session;
    if (!this.props.clientTransactions.allTransactions.length) {
      // FIXME: What does this do?
      this.updateTransactions(username, token);
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filteredTransactions.length !== this.props.filteredTransactions.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: 1 });
      this.props.resetSelectedTransactions();
      this.props.resetSelectedPages();
    }
  }

  getTypeOfContent = (isEditable) => {
    const { editionMode } = this.state;
    if (!editionMode) return 'creation';
    return isEditable === false ? 'editcategory' : 'edition';
  }

  handleCheckboxSelect = (event, { value, checked }) => {
    event.stopPropagation();
    if (checked) {
      this.props.addSelectedTransaction(value);
    } else {
      this.props.removeSelectedTransaction(value);
    }
  }

  handleSelectAll = (event, { checked }) => {
    const currentPageTransactions = filterByPage(this.state.currentPage,
      this.props.filteredTransactions, MAX_ITEMS_PER_PAGE);
    const transactionsIds = currentPageTransactions.map(transaction => transaction.transaction_id);
    if (checked) {
      this.props.addSelectedPage(this.state.currentPage, transactionsIds);
    } else {
      this.props.removeSelectedPage(this.state.currentPage, transactionsIds);
    }
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage });
  }

  handleShowModal = () => {
    this.setState({
      modalShow: true,
    });
  }

  handleCreateTransaction = () => {
    this.setState({
      contentType: 'creation',
      editionMode: false,
      transaction: undefined,
    }, () => {
      this.handleShowModal();
    });
  }

  handleRowClick = (key) => {
    const type = this.getTypeOfContent(key.iseditable);
    if (type === 'edition' || type === 'editcategory') {
      this.setState({
        transaction: key,
      });
    }
    this.setState({
      contentType: type,
    }, () => {
      this.handleShowModal();
    });
  };

  handleMark = (transaction) => {
    const { contentType } = this.state;
    let obj;
    let statefulTransactions = [];
    const { selected } = this.props.clientTransactions;
    if (selected.length) {
      statefulTransactions = selected;
    }

    switch (contentType) {
      case 'bulk':
        this.callMarkAsSeen(statefulTransactions, selected, this.updateTransactions);
        break;
      default:
        this.callMarkAsSeen([transaction], [transaction], this.updateTransactions);
        break;
    }
  };

  handleMarkAllAsSeen = () => {
    const clientEmail = jsCookie.get('active_client_email');
    const { username, token } = this.props.session;

    const newTransactions = this.props.filteredTransactions
      .filter(item => ['new', 'initial'].includes(item.seen))
      .map(({ transaction_id: id }) => id);
    // TODO: why are there null parameters? -> markAsSeen(null, null, transactions)
    markAsSeenAsProfessional(username, token, clientEmail, newTransactions)
      .then(() => this.props.markAsSeenTransactions(newTransactions))
      .then(() => this.props.resetSelectedTransactions())
      .then(() => this.props.resetSelectedPages());
  }

  handleMarkAsSeen = () => {
    this.setState({
      contentType: 'markaseen',
      isLoading: true,
    }, () => {
      let statefulTransactions = [];
      const { selected } = this.props.clientTransactions;
      if (selected.length) {
        statefulTransactions = selected;
      }
      this.callMarkAsSeen(statefulTransactions, selected, this.props.markAsSeenTransactions);
    });
  };

  callMarkAsSeen = (statefulTransactions, selected, executeAfter) => {
    const { username, token } = this.props.session;
    const { contentType } = this.state;
    const clientEmail = jsCookie.get('active_client_email');

    markAsSeenAsProfessional(username, token, clientEmail, statefulTransactions)
        .then(() => {
          switch (contentType) {
            case 'markaseen':
              executeAfter(selected);
              this.props.resetSelectedTransactions();
              this.props.resetSelectedPages();
              break;
            default:
              executeAfter(username, token);
              this.props.resetSelectedTransactions();
              this.props.resetSelectedPages();
              break;
          }
          this.setState({ isLoading: false });
        }).catch(() => {
      this.props.pushNotification('NOT_MARKED_AS_SEEN', 'Operation failed',
          'An error ocurred while trying to mark as seen your transaction.Please try again.', 'error', 8000);
      this.setState({ isLoading: false });
    });
  };

  handleClose = () => {
    this.setState({ modalShow: false, isLoading: false, editionMode: true });
  };

  showBulkEdition = () => {
    this.setState({
      modalShow: true,
      editionMode: true,
      contentType: 'bulk',
      transaction: undefined,
    });
  }

  getTotalPages = transactions => Math.ceil(transactions.length / MAX_ITEMS_PER_PAGE)

  getTotalSelectedTransactions = () => this.props.clientTransactions.selected.length;

  orderBy = (column) => {
    const { ORDER } = this.props.filters;
    let direction = 'descending';
    if (ORDER.COLUMN === column) {
      direction = ORDER.DIRECTION === 'descending' ? 'ascending' : 'descending';
    }
    this.props.addFilter('ORDER', { DIRECTION: direction, COLUMN: column });
  }

  getAccountsList = (transactions) => {
    const accounts = {};
    return transactions.reduce((options, { institution, mask }) => {
      const accountName = `${institution} ${mask}`;
      if (accounts[accountName]) return options;
      accounts[accountName] = true;
      const newOption = {
        key: accountName,
        value: accountName,
        text: accountName,
      };
      return [...options, newOption];
    }, []);
  }

  /**
   This function populates the dashboard and polls new transactions,
   to distinguish between the manual created transactions from the ones returned by plaid the
   iseditable flag is added to every transaction.
   * */
  updateTransactionsAfterReset = (username, token) => {
    // TODO: At what point is startDate and endDate set???
    // let { startDate, endDate } = this.props.onboarding;
    // FIXME: We should start by getting transactions as of the current date minus 2 years.
    const clientEmail = jsCookie.get('active_client_email');

    this.setState({ isLoading: true },
        // FIXME: Get the client email from the store
        () => getTransactionsAsProfessional(username, token, clientEmail, this.state.offset) // startDate, endDate)
            .then(async (response) => {
              let editableTransactions = response.transactions.map((item) => {
                let transaction = item;
                const isEditable = isManualTransaction(transaction.transaction_id);
                transaction = { ...transaction, iseditable: isEditable, mask: item.mask === 'None' ? '' : item.mask };
                return transaction;
              });
              this.setState(prevState => ({
                editableTransactions: [...prevState.editableTransactions, ...editableTransactions]
              }));
              if (response.total > this.state.maxCount && response.total !== this.state.editableTransactions.length) {
                this.setState( prevState => ({
                  offset: prevState.offset + 1
                }), () => this.updateTransactionsAfterReset(this.props.session.username, this.props.session.token));
              } else {
                this.props.resetSelectedTransactions();
                this.props.setTransactions(this.state.editableTransactions);
                this.setState({ isLoading: false });
              }
            })
            .catch(() => {
              console.log('there was a problem getting transactions for professional');
              this.setState({
                isLoading: false,
                hasError: true,
              });
            }));
  }

  updateTransactions = (username, token) => {
    this.setState({
      offset: 0,
      editableTransactions: []
    });
    this.updateTransactionsAfterReset(username, token);
  }

  reloadTransactionsTable = (username, token) => {
    this.props.setTransactions([]);
    this.updateTransactions(username, token);
  }

  render() {
    const newTransactions = this.props.filteredTransactions.filter(item => ['new', 'initial'].includes(item.seen)).length;
    let priorYearTransactions = 0;
    if (this.props.filters.MONTH === -2) { // year to date
      const yearStart = moment().subtract(4, 'year').startOf('year').format('MM/DD/YYYY'); // 4: ideally, getting transactions during 2 years from plaid.
      const yearEnd = moment().subtract(1, 'year').endOf('year').format('MM/DD/YYYY');
      priorYearTransactions = filterByCustomDateRange(yearStart, yearEnd, this.props.clientTransactions.allTransactions).filter(item => ['new', 'initial'].includes(item.seen)).length;
    }
    const withSelectedTransactions = this.props.clientTransactions.selected.length > 0;

    return (
        <>
          {
            this.state.modalShow && (
                <Modal
                    show={this.state.modalShow}
                    handleClose={this.handleClose}
                >
                  <TransactionModalContent
                      {...this.state.transaction}
                      handleClose={this.handleClose}
                      contentType={this.state.contentType}
                      updateTransactions={this.updateTransactions}
                      editionMode={this.state.editionMode}
                      totalTransactionSelected={this.getTotalSelectedTransactions()}
                      transactions={this.props.clientTransactions}
                      resetSelectedTransactions={this.props.resetSelectedTransactions}
                      resetSelectedPages={this.props.resetSelectedPages}
                      handleMark={this.handleMark}
                  />
                </Modal>
            )
          }
          <TransactionsFilters
              handleCreateTransaction={this.handleCreateTransaction}
          />
          {
            this.props.clientTransactions.selected.length > 0 && (
                <BulkEditingBar
                  isLoading={this.state.isLoading}
                  itemsSelected={this.getTotalSelectedTransactions()}
                  showBulkEdition={this.showBulkEdition}
                  handleMarkAsSeen={this.handleMarkAsSeen}
                />
            )
          }
          {!withSelectedTransactions && newTransactions > 0 && (
              <NewTransactionsNotificator num={newTransactions} priorNum={priorYearTransactions} isYTD={this.props.filters.MONTH === -2} onMarkAll={this.handleMarkAllAsSeen} />
          )}
          <SyncMessage
              section="dashboard"
              session={this.props.session}
              updateTransactions={this.reloadTransactionsTable}
          />
          <TransactionsTable
              hasError={this.state.hasError}
              handleRowClick={this.handleRowClick}
              isLoading={this.state.isLoading}
              currentPage={this.state.currentPage}
              transactions={filterByPage(this.state.currentPage,
                  this.props.filteredTransactions, MAX_ITEMS_PER_PAGE)}
              totalPages={this.getTotalPages(this.props.filteredTransactions)}
              handlePaginationChange={this.handlePaginationChange}
              handleCheckboxSelect={this.handleCheckboxSelect}
              pagesSelected={this.props.clientTransactions.pagesSelected}
              selected={this.props.clientTransactions.selected}
              handleSelectAll={this.handleSelectAll}
              orderBy={this.orderBy}
              order={this.props.filters.ORDER}
          />
        </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  session: state.session,
  onboarding: state.onboarding,
  transactions: state.transactions,
  filters: state.filters,
  filteredTransactions: getFilteredTransactions(state),
  clientTransactions: state.clientTransactions,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => bindActionCreators({
  addSelectedTransaction,
  removeSelectedTransaction,
  resetSelectedTransactions,
  addSelectedPage,
  removeSelectedPage,
  resetSelectedPages,
  setTransactions,
  addFilter,
  markAsSeenTransactions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withNotifications(Transactionsv2));
