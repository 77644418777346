import React from 'react';
import {Pie} from 'react-chartjs-2';
import "chartjs-plugin-labels";
import {getRandomInt} from "libraries/utils";
import {Grid} from "semantic-ui-react";

const colors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
]

class CategoryPieChart extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return !nextProps.itemLists.every( e => this.props.itemLists.includes(e));
  }

  render() {
    const {itemLists, modal} = this.props;
    const labels = itemLists.map(item => item.category_name);
    const dataset = itemLists.map(item => Math.abs(item.actual).toFixed(2));
    const options = {
      legend: {
        display: !!modal,
      }
    }
    const data = {
      labels: labels,
      datasets: [
        {
          label: '',
          data: dataset,
          backgroundColor: dataset.map(() => colors[getRandomInt(colors.length)]),
          borderWidth: 1,
        },
      ],
    }

    return (
      <>
        {
          dataset && dataset.length>0 ? (
          <Pie data={data} options={options}/>
        ) : (
          <Grid centered columns={2} textAlign='center'>
            <Grid.Column>
              <h3>No expenses data</h3>
            </Grid.Column>
          </Grid>
        )
        }
      </>
    );
  }
}

export default CategoryPieChart;