import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from 'images/logo-new.svg';
import HeaderProfileMenu from 'components/containers/HeaderProfileMenu';
import HeaderProfileMenuv2 from 'components/containers/HeaderProfileMenuv2';
import './styles.scss';

class Navbar extends Component {
  state = {
    toggle: false,
  };

  render() {
    const { userInfo } = this.props.session;
    if (document.getElementById('navbarNavAltMarkup')) {
      document.getElementById('navbarNavAltMarkup').className = 'collapsing navbar-collapse';
      setTimeout(() => {
        if (this.state.toggle) {
          document.getElementById('navbarNavAltMarkup').className = 'collapse navbar-collapse show';
        } else {
          document.getElementById('navbarNavAltMarkup').className = 'collapse navbar-collapse';
        }
      }, 80);
    }

    return (
      <div id="Navbar">
        <nav className="navbar container ">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              const { toggle } = this.state;
              this.setState({ toggle: !toggle });
            }}
          >
            <i className="navbar-toggler-icon">
              <a id="nav-toggle" href="#" className={this.state.toggle ? 'active' : ''}>
                <span />
              </a>
            </i>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link nav-menu-item rpb" href="/#HowItWorks">
                  {'HOW IT WORKS'}
                </a>
              </li>
              <li className="nav-item mt-0">
                <a className="nav-link nav-menu-item rpt " href="/#pricing">
                  {'PRICING'}
                </a>
              </li>
            </ul>
            <div className="divider" />
            <div id="buttons" className="d-flex flex-row">
              {
                userInfo.accountType === "client" ? (
                  <HeaderProfileMenu />
                ) : (
                  <HeaderProfileMenuv2 />
                )
              }
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(mapStateToProps)(Navbar);
