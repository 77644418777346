import apiRequestV2 from 'libraries/api-v2/api';

const basePath = '/web-api';

// TODO: Rename to getSentInvitations
export const getInvitations = () => apiRequestV2('get', `${basePath}/invitations`).then(res => res.data);

export const createInvitation = (username, token, body) => {
  const { inviteeFullName, inviteeEmail } = body;

  return apiRequestV2('post', `${basePath}/invitations`, {
    body: {
      invitation: {
        full_name: inviteeFullName,
        email: inviteeEmail,
      },
    },
  })
    .then(res => res.data);
};

export const revokeInvitation = (username, token, inviteeEmail) => (
  apiRequestV2('post', `${basePath}/invitations/revoke`, {
    body: {
      invitation: {
        email: inviteeEmail,
      },
    },
  })
    .then(res => res.data)
);

export const cancelInvitation = (username, token, inviteeEmail) => (
  apiRequestV2('post', `${basePath}/invitations/cancel`, {
    body: {
      invitation: {
        email: inviteeEmail,
      },
    },
  })
    .then(res => res.data)
);

export const getReceivedInvitations = () => apiRequestV2('get', `${basePath}/invitations/list_received`)
  .then(res => res.data);

export const declineReceivedInvitation = (username, token, senderEmail) => apiRequestV2('post', `${basePath}/invitations/decline-received`, {
  body: {
    invitation: {
      senderEmail,
    },
  },
});

export const approveReceivedInvitation = (username, token, senderEmail) => apiRequestV2('post', `${basePath}/invitations/approve-received`, {
  body: {
    invitation: {
      senderEmail,
    },
  },
});

export const removeInvitation = (inviteeFullName, inviteeEmail) => (
  apiRequestV2('post', `${basePath}/invitations/revoke/as-professional`, {
    body: {
      invitation: {
        email: inviteeEmail,
        full_name: inviteeFullName
      },
    },
  })
    .then(res => res.data)
);

export default {
  getInvitations,
  createInvitation,
  cancelInvitation,
  getReceivedInvitations,
  declineReceivedInvitation,
  approveReceivedInvitation,
  removeInvitation,
};
