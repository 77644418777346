const save = require('save-file');
const utf8 = require('utf8');

const decodeUtf8 = s => utf8.decode(s);

const generateZip = (dataExport) => {
  const decodeFromUTF8 = decodeUtf8(dataExport.data);
  const zippedFile = Buffer.from(decodeFromUTF8, 'base64');
  save(zippedFile, 'export.zip');
};

export const generateXLSX = ({ data }, name) => {
  const decodedFromUTF8 = decodeUtf8(data);
  save(decodedFromUTF8, name);
};

export default generateZip;
