import React from 'react';
import {
  Header, Button, Loader, Grid,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from 'components/ui/Card';
import { getDaysLeft } from 'libraries/utils';

import './styles.scss';

export const CurrentPlan = ({
  openModal,
  isLoading,
  isExpired,
  subscription,
  isLoadingCancel,
  isTrialing,
  isPremiumActive,
}) => {
  const { current: { nickname, id, endDate } } = subscription;
  const isFreeTrial = nickname && nickname.includes('Free trial');
  const hasContent = Object.keys(subscription.current).length;
  const benefits = subscription.current.benefits
    ? Object.values(subscription.current.benefits) : [];
  return (
    <Card header="YOUR PLAN" autoHide className="Current-Plan__Title">
      {
        isLoading && (<Loader active inline="centered" className="Current-Plan-Loading" />)
      }
      {
        !isLoading && !hasContent ? (
          <div className="margin No-Current-Plan">
            <Header as="h3" content="No active subscription found." />
          </div>
        ) : (
          <Grid container>
            {
              isTrialing && (
                <Grid.Column width="16">
                  <Header as="h4" className="Current-Plan__Subtitle">
                    Free Trial
                  </Header>
                  <div>
                    You have
                    <b>{` ${getDaysLeft(endDate)} `}</b>
                    left until your free trial ends.
                  </div>
                </Grid.Column>
              )
            }
            {
              isTrialing && !isFreeTrial && !subscription.cancelAtPeriodEnd && (
                <Grid.Column width="16">
                  Once your free trial finishes you will be charged for the following plan:
                </Grid.Column>
              )
            }
            {
              (isPremiumActive || (isTrialing && !subscription.cancelAtPeriodEnd)) && (
                <Grid.Column computer="10" tablet="10" mobile="16">
                  <Header as="h4" className="Current-Plan__Subtitle">
                    {nickname}
                    <span className="Current-Plan__Expired">{ isExpired && ' - Expired'}</span>
                  </Header>
                  {
                    benefits && (
                      <ul className="Current-Plan__Description">
                        {
                          benefits.map((item, idx) => (<li key={`benefit_${idx}_${id}`}>{`${item}`}</li>))
                        }
                      </ul>
                    )
                  }
                  { isPremiumActive && (
                    <>
                      You have
                      <b>{` ${getDaysLeft(endDate)} `}</b>
                      until your
                      <b>{` ${nickname} `}</b>
                      plan
                      {subscription.cancelAtPeriodEnd ? ' ends.' : ' is renewed.'}
                    </>
                  )
                  }
                </Grid.Column>
              )
            }
            {
              isTrialing && !subscription.cancelAtPeriodEnd && (
                <Grid.Column computer="6" tablet="6" mobile="16" textAlign="center" verticalAlign="middle">
                  <Button
                    className="Current-Plan__Unsubscribe-Btn"
                    onClick={openModal}
                    loading={isLoadingCancel}
                  >
                  Cancel
                  </Button>
                </Grid.Column>
              )
            }
          </Grid>
        )
      }
    </Card>
  );
};

/* istanbul ignore next */
const mapStateToProps = ({
  subscription,
}) => ({
  subscription,
});

export default connect(mapStateToProps, null)(withRouter(CurrentPlan));
