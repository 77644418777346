import React from "react";
import { Button, Dropdown } from "semantic-ui-react";
import './styles.scss';

class MoreAction extends React.Component {
  handleSelect = (value) => {
    this.props.onAction(value);
  }
  render() {
    return (
      <Dropdown
        text={this.props.text}
        floating
        button
        className="icon more-action"
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => this.handleSelect('print')}>Print/PDF</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default MoreAction;
