import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic-ui-react';

const LoggedOutMenu = () => (
  <>
    <Menu.Item>
      <Button basic primary as={Link} to="/signup" content="Sign Up" />
    </Menu.Item>
    <Menu.Item>
      <Button basic primary as={Link} to="/login" content="Log In" />
    </Menu.Item>
  </>
);

export default LoggedOutMenu;
