import moment from 'moment';

const otherCategoriesExceptions = ['Other Deductions', 'Other Expenses', 'Other'];

const getTemplate = (contentType) => {
  let isValid = {};
  if (contentType === 'editcategory') {
    isValid = {
      nameValid: false,
      categoryValid: false,
      subcategoryValid: false,
    };
  } else if (contentType === 'bulk') {
    isValid = {
      categoryValid: false,
      subcategoryValid: false,
    };
  } else {
    isValid = {
      nameValid: false,
      categoryValid: false,
      subcategoryValid: false,
      typeValid: false,
      amountValid: false,
      dateValid: false,
    };
  }
  return isValid;
};

export const validateTransactionsType = (transactions) => {
  let prevType = '';
  for (const index in transactions) {
    if (index) {
      const item = transactions[index];
      for (const itemInd in item) {
        if (itemInd) {
          if (itemInd === 'amount') {
            const amount = item[itemInd];
            const type = amount > 0 ? 'Expense' : 'Income';
            if (prevType === '') {
              prevType = type;
            } else {
              if (prevType !== type) return false; // eslint-disable-line no-lonely-if
            }
          }
        }
      }
    }
  }
  return prevType;
};

export const handleValidation = (contentType, elements) => {
  const isValid = getTemplate(contentType);
  let isOtherType = false;
  switch (contentType) {
    case 'bulk':
      elements.forEach((item, index) => {
        switch (index) {
          case 0:
            if (otherCategoriesExceptions.includes(item)) {
              isOtherType = true;
            }
            isValid.categoryValid = item.length > 0;
            break;
          case 1:
            if (isOtherType) {
              isValid.subcategoryValid = item && item.length > 0;
            } else {
              isValid.subcategoryValid = true;
            }
            break;
          default:
            break;
        }
      });
      break;
    case 'editcategory':
      elements.forEach((item, index) => {
        switch (index) {
          case 0:
            isValid.nameValid = item && item.length > 0;
            break;
          case 1:
            if (otherCategoriesExceptions.includes(item)) {
              isOtherType = true;
            }
            isValid.categoryValid = item.length > 0;
            break;
          case 2:
            if (isOtherType) {
              isValid.subcategoryValid = item && item.length > 0;
            } else {
              isValid.subcategoryValid = true;
            }
            break;
          default:
            break;
        }
      });
      break;
    default:
      elements.forEach((item, index) => {
        switch (index) {
          case 0:
            isValid.nameValid = item && item.length > 0;
            break;
          case 1:
            if (otherCategoriesExceptions.includes(item)) {
              isOtherType = true;
            }
            isValid.categoryValid = item.length > 0;
            break;
          case 2:
            if (isOtherType) {
              isValid.subcategoryValid = item && item.length > 0;
            } else {
              isValid.subcategoryValid = true;
            }
            break;
          case 3:
            isValid.typeValid = item && (item === 'Expense' || item === 'Income');
            break;
          case 4:
            isValid.amountValid = item && item > 0;
            break;
          case 5:
            isValid.dateValid = item && moment(item, 'MM/DD/YYYY', true).format('MM/DD/YYYY') === item;
            break;
          default:
            break;
        }
      });
      break;
  }
  return Object.keys(isValid).every(k => isValid[k] === true);
};

export default handleValidation;
