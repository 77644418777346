import React from 'react';
import Layout from 'components/containers/PrivateLayout';
import Routes from 'routes';
import { Switch, Route } from 'react-router-dom';
import Transactions from './Transactions';
import Reports from './Reports';
import Invoicing from './Invoicing';
import * as ReactGA from "react-ga";

ReactGA.set({ clientPage: window.location.pathname });
ReactGA.pageview(window.location.pathname);

const Dashboard = () => (
  <Layout>
    <Switch>
      <Route exact path={Routes.DashboardTransactions} component={Transactions} />
      <Route path={Routes.DashboardReports} component={Reports} />
      <Route path={Routes.DashboardInvoicing} component={Invoicing} />
    </Switch>
  </Layout>
);

export default Dashboard;
