import React from 'react';
import Routes from 'routes';
import { Switch, Route } from 'react-router-dom';
import ManageInvoice from 'components/containers/ManageInvoicev2';
import DetailInvoice from 'components/containers/ManageInvoicev2/detail';
import CreateInvoiceV2 from "components/containers/CreateInvoicev2";

const Invoicing = () => (
    <Switch>
      <Route exact path={Routes.ClientDashboardInvoicing} component={ManageInvoice} />
      <Route path={Routes.ClientDashboardInvoicingCreate} component={CreateInvoiceV2} />
      <Route path={Routes.ClientDashboardInvoicingDetail} component={DetailInvoice} />
    </Switch>
);

export default Invoicing;
