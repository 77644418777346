import React from 'react';
import PasswordRecoveryComponent from 'screens/Home/PasswordRecovery';
import SignupLoginComponent from 'screens/Home/SignupLogin';
import TermsOfService from 'screens/Home/TermsAndPrivacy/terms';
import PrivacyPolicy from 'screens/Home/TermsAndPrivacy/privacy';
import LandingPageComponent from 'screens/Home/LandingPage';
import { Switch, Route } from 'react-router-dom';
import Routes from 'routes';
import PublicLayout from 'components/presentationals/Layout';
import ContactUs from 'screens/Home/ContactUs';
import ConfirmNow from 'screens/Home/ConfirmEmail';
import ErrorBoundary from 'components/ErrorBoundary';

export const Home = () => (
  <Switch>
    <Route exact path={Routes.Index} component={LandingPageComponent} />
    <Route exact path={Routes.TermsOfService} component={TermsOfService} />
    <Route exact path={Routes.PrivacyPolicy} component={PrivacyPolicy} />
    <Route exact path={Routes.ContactUs} component={ContactUs} />
    <PublicLayout>
      <Switch>
        <Route exact path={Routes.ConfirmNow} component={ConfirmNow} />
        <Route path={Routes.PasswordRecovery} component={PasswordRecoveryComponent} />
        <Route path={Routes.Index} component={SignupLoginComponent} />
      </Switch>
    </PublicLayout>
  </Switch>
);

export default Home;
