import { combineReducers } from 'redux';
import session from './session';
import transactions from './transactions';
import onboarding from './onboarding';
import notifications from './notifications';
import filters from './filters';
import plaid from './plaid';
import passwordRecovery from './passwordRecovery';
import profitAndLoss from './profitAndLoss';
import subscription from './subscription';
import invitations from './invitations';
import clients from './clients';
import clientTransactions from './clientTransactions';
import invoice from "./invoice";

export default combineReducers({
  clients,
  clientTransactions,
  session,
  transactions,
  invitations,
  onboarding,
  notifications,
  filters,
  plaid,
  passwordRecovery,
  profitAndLoss,
  subscription,
  invoice,
});
