import moment from 'moment';
import {
  UPDATE_PNL_DATE_RANGE,
  TOGGLE_PNL_IS_SELECTING_DATE,
  SET_IS_TAX_FORM_VIEW,
  SET_PNL_DATA,
  CLEAR_PNL_DATA,
  SET_IS_MONTH_PNL_VIEW,
} from '../ActionTypes';

const getDefaultDate = () => {
  const yearStart = moment().startOf('year').format('MM/DD/YYYY');
  const today = moment().format('MM/DD/YYYY');
  return `${yearStart} - ${today}`;
};

const getDefaultYTDDate = () => {
  const fromMonth = moment().startOf('year').format('MM/YYYY');
  const today = moment().format('MM/YYYY');
  return `${fromMonth} - ${today}`;
};

const initialState = {
  dateRange: getDefaultDate(),
  monthDateRange: getDefaultYTDDate(),
  isSelectingDate: false,
  isTaxFormView: false,
  pnlData: [],
  isMonthPNLView: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PNL_DATE_RANGE: {
      return {
        ...state,
        dateRange: payload,
      };
    }
    case TOGGLE_PNL_IS_SELECTING_DATE:
      return {
        ...state,
        isSelectingDate: payload,
      };
    case SET_IS_TAX_FORM_VIEW:
      return {
        ...state,
        isTaxFormView: payload,
      };
    case SET_PNL_DATA:
      return {
        ...state,
        pnlData: payload,
      };
    case CLEAR_PNL_DATA:
      return {
        ...initialState,
      };
    case SET_IS_MONTH_PNL_VIEW:
      return {
        ...state,
        isMonthPNLView: payload,
      };
    default:
      return state;
  }
};

export default reducer;
