import React from 'react';
import Navbar from '../../containers/Navbar';
import Footer from '../../containers/Footer';
import './styles.scss';

const LandingPageLayout = ({ children }) => (
  <div>
    <Navbar />
    {children}
    <Footer />
  </div>
);

export default LandingPageLayout;
